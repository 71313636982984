import React from 'react';

export const Ears = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>{t('ears.ears')}</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                <input type="checkbox" id='ringing-in-ears' name='ringing-in-ears' onChange={e => handleChange(e, 'ears')} checked={checkIfProblemExist('ears', 'ringing-in-ears') ? 'checked' : ''} />
                <label htmlFor='ringing-in-ears'>{t('ears.ringing_in_ears')}</label>
                <input type="checkbox" id='loss-of-hearing' name='loss-of-hearing' onChange={e => handleChange(e, 'ears')} checked={checkIfProblemExist('ears', 'loss-of-hearing') ? 'checked' : ''} />
                <label htmlFor='loss-of-hearing'>{t('ears.loss_of_hearing')}</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('ears.comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('ears.type_here')} onChange={e => handleChange(e, 'ears')} value={getCategoryComment('ears') ? getCategoryComment('ears') : ''}></textarea>
    </React.Fragment>
}