import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Tab, Tabs } from "react-bootstrap";
import PermissionSection from '../../components/RequestPermission/PermissionSections';
import Paginator from '../../components/Paginator';
import * as PrescriptionAction from "../../actions/prescriptionAction";
import PrescriptionService from '../../services/prescriptionService';
import _ from 'lodash';
import Notification from "../../utils/notification";
import { withTranslation } from 'react-i18next';

class RequestPending extends React.Component {
  constructor(props) {
    super(props);

    this.props.paginatePrescriptionRequest({
      sortBy: { sortField: "created_at", sortOrder: "asc" },
      filter: { status: "requested" }
    });
    this.state = {
      key: "requested",
      prescriptions: []
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      prescriptions: props.prescriptions.data,
      current_page: props.prescriptions.meta.current_page,
      last_page: props.prescriptions.meta.last_page,
    });
  }

  listPrescription = key => {
    if (key === "requested") {
      this.props.paginatePrescriptionRequest({
        sortBy: { sortField: "created_at", sortOrder: "asc" },
        filter: { status: "requested" }
      });
    } else {
      this.props.paginatePrescriptionRequest({
        sortBy: { sortField: "updated_at", sortOrder: "asc" },
        filter: { status: "approved" }
      });
    }
    this.setState({
      key: key
    });
  };
  handleChangePermissions = (idx, data) => {
    const newprescriptions = this.state.prescriptions.map(
      (prescriptions, sidx) => {
        if (idx !== sidx) return prescriptions;

        return { ...prescriptions, ...data };
      }
    );

    this.setState({ prescriptions: newprescriptions });
  };

  handleSubmitPermission = (idx, data) => {
    let { t } = this.props;
    PrescriptionService.grantPermissionAccess(data)
      .then(resp => {
        Notification.show("success", {
          message: t('access_granted')
        });
        if (this.state.key === "requested") {
          this.props.paginatePrescriptionRequest({
            sortBy: { sortField: "created_at", sortOrder: "asc" },
            filter: { status: "requested" }
          });
        } else {
          this.props.paginatePrescriptionRequest({
            sortBy: { sortField: "created_at", sortOrder: "asc" },
            filter: { status: "approved" }
          });
        }
      })
      .catch(error => {
        Notification.show("error", { error: t('something_went_wrong') });
      });
  };

  onCurrPageChange1 = (pageNum) => {
    this.props.paginatePrescriptionRequest({
      page: pageNum,
      sortBy: { sortField: "created_at", sortOrder: "asc" },
      filter: { status: "requested" }
    });
  }

  onCurrPageChange2 = (pageNum) => {
    this.props.paginatePrescriptionRequest({
      page: pageNum,
      sortBy: { sortField: "updated_at", sortOrder: "asc" },
      filter: { status: "approved" }
    });
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>{t('patient')}</title>
        </Helmet>
        <section className="container-fluid">
          <div className="row">
            <div className="col-md-24 requester-tabs">
              <h4 className="page-title">{t('request_pending')}</h4>
            </div>
            <div className="col-md-24 mt-3 request-pending">
              <Tabs
                defaultActiveKey="requested"
                onSelect={this.listPrescription.bind(this)}
              >
                <Tab eventKey="requested" title={t('request_pending')}>
                  <div className="content-wrapper lg-height">
                    {_.isEmpty(this.state.prescriptions) && (
                      <div>{t('no_request')}</div>
                    )}
                    {!_.isEmpty(this.state.prescriptions) &&
                      this.state.key === "requested" &&
                      this.state.prescriptions.map((request, idx) => (
                        <PermissionSection
                          idx={idx}
                          key={request.id}
                          request={request}
                          handleSharePermission={this.handleChangePermissions}
                          handleSubmitPermission={this.handleSubmitPermission}
                          status={request.status}
                        />
                      ))}
                    {!_.isEmpty(this.state.prescriptions) && <Paginator
                      currPage={this.state.current_page}
                      lastPage={this.state.last_page}
                      onChange={this.onCurrPageChange1}
                    />}
                  </div>
                </Tab>
                <Tab eventKey="approved" title={t('request_done')}>
                  <div className="content-wrapper">
                    {_.isEmpty(this.state.prescriptions) && (
                      <div>No request</div>
                    )}
                    {!_.isEmpty(this.state.prescriptions) &&
                      this.state.key === "approved" &&
                      this.state.prescriptions.map((request, idx) => (
                        <PermissionSection
                          idx={idx}
                          key={request.id}
                          request={request}
                          handleSharePermission={this.handleChangePermissions}
                          handleSubmitPermission={this.handleSubmitPermission}
                          status={request.status}
                        />
                      ))}
                    {!_.isEmpty(this.state.prescriptions) && <Paginator
                      currPage={this.state.current_page}
                      lastPage={this.state.last_page}
                      onChange={this.onCurrPageChange2}
                    />}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    selected_user_profile: state.selected_user_profile,
    prescriptions: state.prescription.paginate
  }
};

const mapActionsToProps = {
  paginatePrescriptionRequest: PrescriptionAction.listPrescriptionRequest
};


export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/prescription/RequestPending')(RequestPending));
