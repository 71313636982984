import * as types from './actionTypes';
import UserNotificationService from '../services/userNotificationService.js';
import Notification from '../utils/notification';
import * as authUtils from '../utils/auth';

export function userNotification({ page, sizePerPage, sortBy, searchText }) {
    return dispatch => {
        dispatch({ type: 'SHOW_PAGE_LOADER' });
        dispatch({ type: types.LIST_USER_NOTIFICATION_REQUEST, request: { page, sizePerPage, sortBy, searchText } });

        UserNotificationService.userNotification({ page, sizePerPage, sortBy, searchText })
            .then(resp => {
                let data = resp.data;
                dispatch({ type: 'HIDE_PAGE_LOADER' });
                dispatch({ type: types.LIST_USER_NOTIFICATION_SUCCESS, ...data });
                return dispatch(getNotificationCount());
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    dispatch({ type: 'HIDE_PAGE_LOADER' });
                    let errorData = error.response.data;
                    Notification.show('error', errorData)
                }
            });
    }
}

export function getNotificationCount(t) {
    return dispatch => {
        UserNotificationService.getNotificationCount().then(resp => {
            const { notification_count } = resp.data;
            const currentNotificationCount = authUtils.getNotificationCount();
            if (notification_count > 0 && parseInt(currentNotificationCount) !== notification_count) {
                Notification.show('info', { message: t('new_notification') })
            }
            authUtils.setNotificationCount(notification_count);
            dispatch({ type: types.USER_NOTIFICATION_COUNT, ...resp.data });
        }).catch(error => {
            if (error.response && error.response.status) {
                let errorData = error.response.data;
                Notification.show('error', errorData)
            }
        })
    }
}

export function resetNotificationCount() {
    return dispatch => {
        UserNotificationService.resetNotificationCount().then(resp => {
            dispatch({ type: types.USER_NOTIFICATION_COUNT_RESET, ...resp.data });
        }).catch(error => {
            if (error.response && error.response.status) {
                let errorData = error.response.data;
                Notification.show('error', errorData)
            }
        })
    }
}

export function clearNotifications() {
    return dispatch => {
        dispatch({ type: types.CLEAR_USER_NOTIFICATION_LIST });
    }
}