import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import MedicineService from '../../services/medicineService';
import Notification from '../../utils/notification';
import { withTranslation } from 'react-i18next';

class MedicineLockModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonText: this.props.t('yes'),
            isSubmitDisabled: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {

        this.setState({
            buttonText: this.props.t('please_wait'),
            isSubmitDisabled: true
        })

        let id = (this.props.id) ? this.props.id : null;

        MedicineService.lock(id)
            .then((resp) => {
                Notification.show('success', { message: this.props.t('medicine_locked_success') });
                this.props.onSuccess(this.props);

                this.setState({
                    buttonText: this.props.t('yes'),
                    isSubmitDisabled: false
                })
            })
            .catch((error) => {
                Notification.show('error', error.response.data);
                this.setState({
                    buttonText: this.props.t('yes'),
                    isSubmitDisabled: false
                })
            })
    }



    render() {
        const { t } = this.props;

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <Form>
                        <div>
                            <label>{t('medicine_lock_modal_label') }</label>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={this.state.isSubmitDisabled} onClick={this.handleSubmit}>
                        {this.state.buttonText}
                    </Button>
                    <Button variant="secondary" disabled={this.state.isSubmitDisabled} onClick={this.props.handleClose}>
                        {t('no')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default (withTranslation('components/Medicine/MedicineLockModal')(MedicineLockModal));
