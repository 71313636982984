import React from 'react';

export const NervousSystem = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>{t('nervous_system.nervous_system')}</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                {/* Headache */}
                <input type="checkbox" id='headaches' name='headaches' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'headaches') ? 'checked' : ''} />
                <label htmlFor='headaches'>{t('nervous_system.headaches')}</label>
                {/* Dizziness */}
                <input type="checkbox" id='dizziness' name='dizziness' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'dizziness') ? 'checked' : ''} />
                <label htmlFor='dizziness'>{t('nervous_system.dizziness')}</label>
                {/* Fainting */}
                <input type="checkbox" id='fainting-or-loss-of-consciousness' name='fainting-or-loss-of-consciousness' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'fainting-or-loss-of-consciousness') ? 'checked' : ''} />
                <label htmlFor='fainting-or-loss-of-consciousness'>{t('nervous_system.fainting_loss_onsciousness')}</label>
                {/* Numbness */}
                <input type="checkbox" id='numbness-or-tingling' name='numbness-or-tingling' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'numbness-or-tingling') ? 'checked' : ''} />
                <label htmlFor='numbness-or-tingling'>{t('nervous_system.numbness_or_tingling')}</label>
                {/* Memory loss */}
                <input type="checkbox" id='memory-loss' name='memory-loss' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'memory-loss') ? 'checked' : ''} />
                <label htmlFor='memory-loss'>{t('nervous_system.memory_loss')}</label>
                {/* syncope */}
                <input type="checkbox" id='syncope' name='syncope' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'syncope') ? 'checked' : ''} />
                <label htmlFor='syncope'>{t('nervous_system.syncope')}</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('nervous_system.comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('nervous_system.type_here')} onChange={e => handleChange(e, 'nervous-system')} value={getCategoryComment('nervous-system') ? getCategoryComment('nervous-system') : ''}></textarea>
    </React.Fragment>
}