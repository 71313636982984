import React from 'react';
import AmbulanceService from '../../services/ambulanceService';
import _ from 'lodash';
import AutoSuggest from "../AutoSuggest";
import { withTranslation } from 'react-i18next';

class SearchHospial extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            options: [],
        }
    }

    _handleSearch = (query) => {
        this.setState({ 
            isLoading: true,
        });

        AmbulanceService.searchHosiptal(query)
            .then(resp => {
                const items = resp.data.data;
                
                const options = items.map(i => ({
                    service_provider_id: i.service_provider_id,
                    service_provider_name: i.service_provider_name,
                    user_location_id: i.user_location_id,
                    user_location_name: i.user_location_name,
                }));
                
                this.setState({
                    isLoading: false,
                    options: options
                });
            });
    }

    _onChange = (option) => {
        let { hospital } = this.props;

        if (!_.isEmpty(option)) {
            hospital.service_provider_id = option.service_provider_id;
            hospital.service_provider_name = option.service_provider_name;
            hospital.user_location_id = option.user_location_id;
            hospital.user_location_name = option.user_location_name;
        } else {
            hospital.service_provider_id = "";
            hospital.service_provider_name = "";
            hospital.user_location_id = "";
            hospital.user_location_name = "";
        }

        this.props.handleSearchHospital(hospital);
    }

    render() {
        const { t } = this.props;
        return (<React.Fragment>
                <AutoSuggest
                    allowNew={false}
                    isLoading={this.state.isLoading}
                    options={this.state.options}
                    labelKey="hospital_name"
                    placeholder={t('search_hospital')}
                    defaultValue={this.props.hospital.service_provider_name && this.props.hospital.service_provider_name +" - "+ this.props.hospital.user_location_name}
                    onSearch={this._handleSearch}
                    onChange={this._onChange}
                    renderMenuItem={(option) => {
                        return <div key={option.service_provider_id}>
                            <div>{option.service_provider_name}</div>
                            <small>Location: {option.user_location_name}</small>
                        </div>
                    }}
                />
                {this.props.validator.message(t("hospital_name"),this.props.hospital.service_provider_name,"required")}
        </React.Fragment>
        );
    }
}

export default (withTranslation('components/AmbulanceRecord/SearchHospial')(SearchHospial));