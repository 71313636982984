import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as PrescriptionAction from "../../actions/prescriptionAction";
import DataGrid from "../../components/DataGrid";
import * as Helper from "../../utils/helpers";
import { hasRole } from '../../utils/auth';
import PrescriptionService from '../../services/prescriptionService';
import * as LoaderAction from '../../actions/loaderAction';
import Notification from '../../utils/notification';
import { withTranslation } from 'react-i18next';

class ListEmergencyPrescriptionPage extends React.Component {
    state = {
        filter: {
            'medicine_status': 'prescribed,partially-fulfilled'
        }
    }
    componentDidMount() {
        this.props.prescriptionList({
            sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: this.state.filter
        });
    }

    columns = () => {
        let { t } = this.props;
        return [{
            dataField: 'id',
            text: t('id'),
            hidden: true
        }, {
            dataField: 'prescription_id',
            text: t('prescription_id'),
            formatter: this.prescriptionFormatter
        }, {
            dataField: 'patient_full_name',
            text: t('patient_name'),
        }, {
            dataField: 'pharmacist_full_name',
            text: t('pharmacist_name')
        }, {
            dataField: 'prescription_date',
            text: t('prescription_date'),
            sort: true,
            formatter: this.dateFormatter
        }, {
            formatter: this.statusFormatter,
            text: t('status'),
        }, {
            dataField: 'actions',
            text: t('actions'),
            isDummyField: true,
            formatter: this.actionFormatter
        }
        ];
    };
    statusFormatter = (_cell, row) => {
        if (row.medicine_status) {
            return Helper.getStatus(row.medicine_status)
        }
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        this.props.prescriptionList({ page, sizePerPage, sortBy: { sortField, sortOrder }, filter: this.state.filter });
    };

    dateFormatter = (cell) => {
        return Helper.getDateFormat(cell);
    };

    nameFormatter = (cell) => {
        return Helper.fullNameStr(cell);
    };

    cancelPrescription = (prescription_id) => {
        this.props.showLoader();

        PrescriptionService.cancelPharmacyPrescription(prescription_id).then(_resp => {
            this.props.hideLoader();
            Notification.show('success', { message: this.props.t('prescription_cancelled_success') });
            this.props.prescriptionList({
                sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: this.state.filter
            });
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }

    actionFormatter = (_cell, row) => {
        let { user } = this.props;

        if (hasRole(user, ['pharmacist'])) {
            return (<React.Fragment>
                <Link to={"/pharmacy/prescription/" + row.prescription_id + "/view"} title={this.props.t('view_prescription')} className="btn btn-primary">{this.props.t('resume')}</Link>
                <button className="btn btn-outline-primary ml-2" onClick={e => this.cancelPrescription(row.prescription_id)}>{this.props.t('cancel')}</button>
            </React.Fragment>);
        }
    }

    render() {
        let { data, meta } = this.props.prescription_list;
        const { t } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('pharmacy_rx_draft')}</title>
                </Helmet>
                <h4 className="page-title">{t('rx_draft')}</h4>
                <div className="content-wrapper">
                    <DataGrid
                        columns={this.columns()}
                        data={data}
                        meta={meta}
                        noDataIndication={t('no_record')}
                        handleTableChange={this.handleTableChange}
                    />
                </div>
            </React.Fragment>
        );
    }

}
const mapStateToProps = state => ({
    prescription_list: state.prescription.paginate,
    user: state.selected_user_profile,
});

const mapActionsToProps = {
    prescriptionList: PrescriptionAction.getPharmacyEmergencyPrescriptionList,
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/pharmacy/ListEmergencyPrescriptionPage')(ListEmergencyPrescriptionPage))
