import React from "react";
import MedicineService from "../../services/medicineService";
import AutoSuggest from "../AutoSuggest";
import { withTranslation } from 'react-i18next';

class MedicineBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            options: [],
        }
    }

    _handleChange = (evt) => {
        const { medicine } = this.props;

        let fieldName = evt.target.name;
        let fieldValue = evt.target.value;

        medicine[fieldName] = fieldValue;
        this.props.handleChangeMedicine(this.props.idx, medicine);
    }

    _handleSearch = (query) => {
        this.setState({ isLoading: true, options: [] });
        MedicineService.searchMedicines({ searchText: query })
            .then(resp => {
                let data = resp.data;
                const items = data.data;
                const options = items.map(i => ({
                    id: i.id,
                    name: i.name,
                    brand_name: i.name,
                    generic_id: i.generic_drug.id,
                    generic_name: i.generic_drug.name,
                }));

                this.setState({
                    isLoading: false,
                    options: options
                });
            });
    }

    componentDidMount() {
        let { medicine } = this.props;
        this.props.handleChangeMedicine(this.props.idx, medicine);
    }

    _onChange = (option) => {
        let { medicine } = this.props;

        if (option) {
            if (option.customOption === true) {
                medicine.drug_id = "";
                medicine.drug_generic_id = "";
                medicine.drug_name = option.brand_name;
                medicine.drug_generic_name = "";
            } else {
                medicine.drug_id = option.id;
                medicine.drug_name = option.brand_name;
                medicine.drug_generic_id = option.generic_id;
                medicine.drug_generic_name = option.generic_name;
            }
        } else {
            medicine.drug_id = "";
            medicine.drug_name = "";
            medicine.drug_generic_id = "";
            medicine.drug_generic_name = "";
        }

        this.props.handleChangeMedicine(this.props.idx, medicine);
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <li key={"medicine" + this.props.idx}>
                    <div className="form-group half">
                        <label>{t('brand_name')}</label>
                        <AutoSuggest
                            disabled={this.props.disabled}
                            allowNew={true}
                            isLoading={this.state.isLoading}
                            options={this.state.options}
                            newSelectionPrefix={t('new_medicine') + ": "}
                            labelKey="brand_name"
                            placeholder={t('search_drug')}
                            defaultValue={this.props.medicine.drug_name}
                            onSearch={this._handleSearch}
                            onChange={this._onChange}
                            renderMenuItem={(option) => {
                                return <div key={option.id}>
                                    <div>{option.brand_name}</div>
                                    <small>{t('generic_name')}: {option.generic_name}</small>
                                </div>
                            }}
                        />
                        {this.props.validator.message(t('brand_name'), this.props.medicine.drug_name, 'required')}
                    </div>
                    <div className="form-group half">
                        <label>{t('generic_name')}</label>
                        <input type="text" name="drug_generic_name" className="form-control" value={this.props.medicine.drug_generic_name} onChange={this._handleChange} readOnly="readOnly" />
                    </div>
                    <div className="form-group full">
                        <label>{t('remarks')}</label>
                        <textarea className="form-control" name="remarks" value={this.props.medicine.remarks ? this.props.medicine.remarks : ''} onChange={this._handleChange} placeholder={t('type_instructions')} disabled={this.props.disabled}></textarea>
                    </div>
                    <span className="cancel" data-id={this.props.idx} onClick={this.props.handleRemoveMedicine}></span>
                </li>
            </React.Fragment>
        )
    }
}

export default (withTranslation('components/AmbulanceRecord/MedicineSection')(MedicineBlock));
