import React from 'react';

export const Muscle = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <div className="heading">
            <h6> {t('muscle.muscular_skeletal_system')}</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                <input type="checkbox" id='numbness' name='numbness' onChange={e => handleChange(e, 'muscle_joint_bones')} checked={checkIfProblemExist('muscle_joint_bones', 'numbness') ? 'checked' : ''} />
                <label htmlFor='numbness'>{t('muscle.numbness')}</label>
                <input type="checkbox" id='joint-pain' name='joint-pain' onChange={e => handleChange(e, 'muscle_joint_bones')} checked={checkIfProblemExist('muscle_joint_bones', 'joint-pain') ? 'checked' : ''} />
                <label htmlFor='joint-pain'>{t('muscle.joint_pain')}</label>
                <input type="checkbox" id='muscle-weakness' name='muscle-weakness' onChange={e => handleChange(e, 'muscle_joint_bones')} checked={checkIfProblemExist('muscle_joint_bones', 'muscle-weakness') ? 'checked' : ''} />
                <label htmlFor='muscle-weakness'>{t('muscle.muscle_weakness')}</label>
                <input type="checkbox" id='joint-swelling' name='joint-swelling' onChange={e => handleChange(e, 'muscle_joint_bones')} checked={checkIfProblemExist('muscle_joint_bones', 'joint-swelling') ? 'checked' : ''} />
                <label htmlFor='joint-swelling'>{t('muscle.joint_swelling')}</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('muscle.comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('muscle.type_here')} onChange={e => handleChange(e, 'muscle_joint_bones')} value={getCategoryComment('muscle_joint_bones') ? getCategoryComment('muscle_joint_bones') : ''}></textarea>
    </React.Fragment>
}