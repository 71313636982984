import React from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import * as AuthAction from "../../actions/authAction";
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import * as HELPER from '../../utils/helpers';

class ChangePasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            current_password: '',
            password: '',
            password_confirmation: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validator = new SimpleReactValidator({
            locale: HELPER.getLanguage(),
            validators: {
                password: {
                    message: 'The password must contain atleast 1 uppercase letter, 1 lower case, 1 special character and must be of 8 digits',
                    rule: (val, params, validator) => {
                        return (
                          validator.helpers.testRegex(
                            val,
                            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
                          ) && params.indexOf(val) === -1
                        );
                    }
                }
            }
        });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        } 
        
        this.props.changePassword(this.state, this.props.history, this.props.selected_user_profile);
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('title')}</title>
                </Helmet>
            <div className="row">
                <div className="col-md-12">
                    <h4 className="page-title">{t('title')}</h4>
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-md-24">
                                <Loader/>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <input type="password" className="form-control" placeholder={t('current_password')} id="current_password" name="current_password" onChange={this.handleChange} />
                                        {this.validator.message(t('current_password'), this.state.current_password, 'required')}
                                    </div>
                                    < div className = "form-group input-tooltip" >
                                        <input type="password" className="form-control" placeholder={t('new_password')} id="password" name="password" value={this.state.password} onChange={this.handleChange} />
                                        {this.validator.message(t('password'), this.state.password, 'required|password')}
                                           <span className="tooltip-custom">
                                                <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                <span className="tooltiptext">
                                                    {t('password_help')}
                                                </span>
                                            </span>
                                    </div>
                                    <div className="form-group">
                                        <input type="password" className="form-control" placeholder={t('confirm_new_password')} id="password_confirmation" name="password_confirmation" value={this.state.password_confirmation} onChange={this.handleChange} />
                                        {this.validator.message(t('confirm_password'), this.state.password_confirmation, `required|in:${this.state.password}`, { messages: { in: 'Passwords need to match!' } })}
                                    </div>
                                    
                                    <div className="form-group clearfix">
                                        <button type="submit" className="btn btn-primary">{t('title')}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile
});

const mapActionsToProps = ({
    changePassword: AuthAction.changePassword
})

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/auth/ChangePasswordPage')(ChangePasswordPage));