import React from 'react';
import ImmunizationService from '../../services/immunizationService';
import Notification from '../../utils/notification';
import * as LoaderAction from '../../actions/loaderAction';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { immunizationsData } from '../../config/immunization';
import _ from 'lodash'
import { Link } from "react-router-dom";
import { hasRole } from '../../utils/auth'
import SimpleReactValidator from "simple-react-validator";
import PinModal from "../../components/Signature/PinModal";
import { getDateFormat, getLanguage } from '../../utils/helpers';
import { withTranslation } from 'react-i18next';

class ImmunizationPage extends React.Component {
    constructor(props) {
        super(props);
        let { patientId } = this.props.match.params
        this.state = {
            patient_id: patientId,
            fetched_data: '',
            selected_age: [],
            selected_age_label: '',
            items: [],
            showPinModal: false,
            immunization_id: '',
            immunizations: _.cloneDeep(immunizationsData),
            isloading: '',
        }
        this.validator = new SimpleReactValidator({ locale: getLanguage() });
    }

    componentDidMount() {
        this.props.showLoader();
        ImmunizationService.getPatientsImmunization(this.state.patient_id, 'child-vaccine').then(resp => {
            let age_slugs = [];
            age_slugs = Object.keys(_.groupBy(this.state.immunizations, 'age_slug'));
            this.setState({
                fetched_data: resp.data.data,
                selected_age: age_slugs,
            });
            age_slugs && age_slugs.forEach(age => {
                this.handleCheckedVaccineItems(age)
            })
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }

    handleCheckedVaccineItems = (selected_age) => {
        let result = [];
        result = _.find(this.state.immunizations, (vaccine) => { return vaccine.age_slug === selected_age });
        let other_vaccines = _.filter(this.state.fetched_data, (item) => { return item.age_slug === selected_age && item.is_other_vaccine });
        let vaccines = [...result.vaccines];
        let items = this.state.items;
        items[selected_age] = vaccines.concat(other_vaccines)
        this.setState({
            selected_age: this.state.selected_age.concat(selected_age),
            items: items,
        })
    }

    showPinModal = () => {
        this.setState({ showPinModal: true });
    }
    handleClose = () => {
        this.setState({ showPinModal: false });
    }

    onSuccessRedirect = () => {
        this.setState({ showPinModal: false });
        this.setLoading(false);
        this.props.history.push("/patients/" + this.state.patient_id + "/immunizations/view");
    }

    setLoading = (value) => {
        this.setState({
            isloading: value
        });
    }

    renderVaccineBlock = (age_slug, age_label) => {
        let { t } = this.props;
        return (
            <div className="table-responsive">
                <table className="table table-striped table-sm table-bordered" id="example-1">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t('type_of_vaccine')}</th>
                            <th>{t('date_of_immunization')}</th>
                            <th>{t('manufacturer')}</th>
                            <th>{t('batch_no')}</th>
                            <th>{t('performed_by')}</th>
                            <th>{t('doctor_name')}</th>
                            <th>{t('comment')}</th>
                            <th>{t('next_date_of_immunization')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.items && this.state.items[age_slug] && this.state.items[age_slug].map((vaccine, key) => (
                            this.renderVaccineItem(vaccine, age_slug, age_label, key)
                        ))}
                    </tbody></table>
                <div className="button-wrapper align-right">
                    <button onClick={e => this.handleOtherVaccine(age_slug, age_label)} className="btn btn-primary">{t('add_other_vaccine')}</button>
                </div>
            </div>
        )
    }

    handleOtherVaccine = (age_slug, age_label) => {
        let items = this.state.items;
        items[age_slug] = items[age_slug].concat(({
            'is_other_vaccine': true,
            'immunization_date': '',
            'manufacturer_name': '',
            'batch_number': '',
            'approved_type': '',
            'approved_name': '',
            'approved_comment': '',
            'next_immunization_date': '',
            'age_slug': age_slug,
            'vaccine_slug': "other-vaccines",
            "isChecked": false,
            "age_label": age_label
        }))
        this.setState({
            items: items
        })
    }
    //checked/unchecked vaccine items
    handleClickedVaccineItem = (e, age_slug, age_label, index) => {
        let { items } = this.state
        let vaccine = items[age_slug][index]
        vaccine.isChecked = e.target.checked;
        if (e.target.checked) {
            vaccine.age_label = age_label;
            vaccine.age_slug = age_slug;
        } else {
            vaccine.manufacturer_name = '';
            vaccine.batch_number = '';
            vaccine.approved_type = '';
            vaccine.approved_name = '';
            vaccine.immunization_date = '';
            vaccine.approved_comment = '';
            vaccine.next_immunization_date = '';
        }
        this.setState({ items: items })
    }

    renderVaccineItem = (selected_vaccine, age_slug, age_label, index) => {
        let result = _.find(this.state.fetched_data, (item) => { return selected_vaccine.vaccine_slug === item.vaccine_slug && item.age_slug === age_slug && selected_vaccine.vaccine_label === item.vaccine_label });
        let { t } = this.props;
        return (
            <tr key={index}>
                {/* Checkbox */}
                <th scope="row">
                    <input type="checkbox" name={selected_vaccine.vaccine_slug} id={age_slug + '-' + index + '-' + selected_vaccine.vaccine_slug} onChange={(e) => this.handleClickedVaccineItem(e, age_slug, age_label, index)} disabled={result && this.checkVaccineStatus(result.status)} />
                    <label htmlFor={age_slug + '-' + index + '-' + selected_vaccine.vaccine_slug} ></label>
                </th>
                {/* vaccine name */}
                <td className="tabledit-view-mode">
                    {selected_vaccine.vaccine_label && !selected_vaccine.is_other_vaccine ? <span className="tabledit-span">{selected_vaccine.vaccine_label}</span> : <input className="tabledit-input form-control input-sm fill" type="text" name="vaccine_label" value={selected_vaccine && selected_vaccine.vaccine_label} onChange={(e) => this.changeHandler(e, age_slug, index)} disabled={this.disableInput(selected_vaccine)} />}
                    {this.validateSelectedAge(selected_vaccine.age_slug) && selected_vaccine.isChecked && selected_vaccine.is_other_vaccine && this.validator.message('vaccine name', selected_vaccine.vaccine_label, 'required')}
                    <div><small>{(selected_vaccine.note)}</small></div>
                </td>
                {/* immunizatin date */}
                <td className="tabledit-view-mode">
                    {result && this.checkVaccineStatus(result.status) ? <span className="tabledit-span">{getDateFormat(result.immunization_date)}</span>
                        : <input className="tabledit-input form-control input-sm fill" type="date" name="immunization_date" value={this.valueOfInput(result, selected_vaccine, 'immunization_date')} onChange={(e) => this.changeHandler(e, age_slug, index)} disabled={this.disableInput(selected_vaccine)} />}
                    {/* {this.validateSelectedAge(selected_vaccine.age_slug) && selected_vaccine.isChecked && this.validator.message('immunization date', selected_vaccine.immunization_date, 'required')} */}
                </td>
                {/* manufacturer_name */}
                <td className="tabledit-view-mode">
                    {result && this.checkVaccineStatus(result.status) ? <span className="tabledit-span">{result.manufacturer_name}</span>
                        : <input className="tabledit-input form-control input-sm fill" type="text" name="manufacturer_name" value={this.valueOfInput(result, selected_vaccine, 'manufacturer_name')} onChange={(e) => this.changeHandler(e, age_slug, index)} disabled={this.disableInput(selected_vaccine)} />}
                    {/* {this.validateSelectedAge(selected_vaccine.age_slug) && selected_vaccine.isChecked && this.validator.message('manufacturer name', selected_vaccine.manufacturer_name, 'required')} */}
                </td>
                {/* batch_number */}
                <td className="tabledit-view-mode">
                    {result && this.checkVaccineStatus(result.status) ? <span className="tabledit-span">{result.batch_number}</span>
                        : <input className="tabledit-input form-control input-sm fill" type="text" name="batch_number" value={this.valueOfInput(result, selected_vaccine, 'batch_number')} onChange={(e) => this.changeHandler(e, age_slug, index)} disabled={this.disableInput(selected_vaccine)} />}
                    {/* {this.validateSelectedAge(selected_vaccine.age_slug) && selected_vaccine.isChecked && this.validator.message('batch number', selected_vaccine.batch_number, 'required')} */}
                </td>
                {/* approved_type */}
                <td className="tabledit-view-mode">
                    {result && this.checkVaccineStatus(result.status) ? <span className="tabledit-span">{this.performedByLabelFormatter(result.approved_type)}</span>
                        : <select className="tabledit-input form-control input-sm fill" value={this.valueOfInput(result, selected_vaccine, 'approved_type')} name="approved_type" onChange={(e) => this.changeHandler(e, age_slug, index)} disabled={this.disableInput(selected_vaccine)}>
                            <option value='' defaultValue>---{t('select')}---</option>
                            <option value="self">{t('self')}</option>
                            <option value="before">{t('other_doctor')}</option>
                        </select>}
                    {this.validateSelectedAge(selected_vaccine.age_slug) && selected_vaccine.isChecked && this.validator.message('performed by', selected_vaccine.approved_type, 'required')}
                </td>
                {/* approved_name */}
                <td className="tabledit-view-mode">
                    {result && this.checkVaccineStatus(result.status) ? <span className="tabledit-span">{result.approved_name}</span>
                        : <input className="tabledit-input form-control input-sm fill" type="text" name="approved_name" value={this.valueOfInput(result, selected_vaccine, 'approved_name')} onChange={(e) => this.changeHandler(e, age_slug, index)} disabled={!selected_vaccine.isChecked || this.handleApprovedBy(selected_vaccine)} />}
                    {this.validateSelectedAge(selected_vaccine.age_slug) && selected_vaccine.approved_type !== "self" && selected_vaccine.isChecked && this.validator.message('signature', selected_vaccine.approved_name, 'string')}
                </td>
                {/* approved_comment */}
                <td className="tabledit-view-mode">
                    {result && this.checkVaccineStatus(result.status) ? <span className="tabledit-span">{result.approved_comment}</span>
                        : <input className="tabledit-input form-control input-sm fill" type="text" name="approved_comment" value={this.valueOfInput(result, selected_vaccine, 'approved_comment')} onChange={(e) => this.changeHandler(e, age_slug, index)} disabled={this.disableInput(selected_vaccine)} />}
                    {/* {this.validateSelectedAge(selected_vaccine.age_slug) && selected_vaccine.isChecked && this.validator.message('approved comment', selected_vaccine.approved_comment, 'string')} */}
                </td>
                {/* next_immunization_date */}
                <td className="tabledit-view-mode">
                    {result && this.checkVaccineStatus(result.status) ? <span className="tabledit-span">{getDateFormat(result.next_immunization_date)}</span>
                        : <input className="tabledit-input form-control input-sm fill" type="date" name="next_immunization_date" value={this.valueOfInput(result, selected_vaccine, 'next_immunization_date')} onChange={(e) => this.changeHandler(e, age_slug, index)} disabled={this.disableInput(selected_vaccine)} />}
                    {/* {this.validateSelectedAge(selected_vaccine.age_slug) && selected_vaccine.isChecked && this.validator.message('next immunization date', selected_vaccine.next_immunization_date, 'required')} */}
                </td>
            </tr>
        )
    }

    validateSelectedAge = (age_slug) => {
        return this.state.selected_age.includes(age_slug)
    }

    valueOfInput = (fetchedVaccine, selectedVaccine, inputField) => {
        if (fetchedVaccine && !selectedVaccine[inputField]) {
            selectedVaccine[inputField] = fetchedVaccine[inputField]
        }
        return selectedVaccine[inputField]
    }

    handleApprovedBy = (vaccine) => {
        if (vaccine.approved_type === "self")
            return true;
        return false
    }

    disableInput = (vaccine) => {
        if (vaccine && vaccine.isChecked)
            return false
        return true
    }

    performedByLabelFormatter = (label) => {
        return label === "self" ? this.props.t("self") : this.props.t("other_doctor")
    }

    changeHandler = (e, age_slug, index) => {
        let targetName = e.target.name;
        let targetValue = e.target.value;
        let items = this.state.items
        if (targetName === "approved_type" && targetValue === "self") {
            items[age_slug][index]['approved_name'] = '';
        }
        items[age_slug][index][targetName] = targetValue;
        this.setState({ items: items })
    }

    checkVaccineStatus = (status) => {
        if (status === "approved")
            return true;
        return false;
    }

    submit = (e) => {
        e.preventDefault();
        let { items } = this.state;
        let filtered_data = [];
        for (var key in items) {
            let data;
            data = _.filter(items[key], function (itemValue) { return itemValue.isChecked });
            if (data.length > 0) {
                filtered_data = filtered_data.concat(data);
            }
        }
        if (!this.validator.allValid() && !_.isEmpty(filtered_data)) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        if (_.isEmpty(filtered_data)) {
            Notification.show('error', { 'message': this.props.t('select_atleast_one_vaccine') });
            return;
        }
        let postData = { "immunization_items": filtered_data, type: 'child-vaccine' }
        this.props.showLoader()
        ImmunizationService.createPatientsImmunization(this.state.patient_id, postData).then(response => {
            this.props.hideLoader()
            Notification.show('success', response.data);
            if (!_.isEmpty(response.data) && hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"])) {
                this.setState({ immunization_id: response.data.immunization_id });
                this.showPinModal();
            } else {
                this.props.history.push('/patients/search');
            }
        }).catch(error => {
            this.props.hideLoader()
            Notification.show('error', error.response.data)
        })
    }

    render() {
        const { t } = this.props;
        return (<React.Fragment>
            <Helmet>
                <title>{t('patient_immunization')}</title>
            </Helmet>
            <div className="row">
                <div className="col-sm-24">
                    <h4 className="page-title">{t('immunization_card')}
                    </h4>
                    {this.state.fetched_data && this.state.immunizations.map((data, index) => (
                        <React.Fragment key={index}>
                            <div className="content-wrapper add-user-section" key={index}>
                                <label htmlFor={data.age_slug}>{data.age_label}</label>
                            </div>
                            {data && this.renderVaccineBlock(data.age_slug, data.age_label)}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div className="button-wrapper align-right">
                <button type="submit" className="btn btn-primary" onClick={this.submit}>{hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) ? t('submit') : t('send_for_approval')}</button>
                <Link to={"/patients/" + this.state.patient_id + "/immunizations/view"} className="btn btn-outline-primary">{t('cancel')}</Link>
            </div>
            {this.state.showPinModal &&
                <PinModal show={this.state.showPinModal} handleClose={this.handleClose} id={this.state.immunization_id} type="immunization" onSuccess={this.onSuccessRedirect} setLoading={this.setLoading} />
            }
        </React.Fragment>)
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile
});

const mapActionsToProps = {
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
};
export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/immunization/ImmunizationPage')(ImmunizationPage))
