import React from 'react';
import { Helmet } from "react-helmet";
import DataGrid from "../../components/DataGrid";
import { getProcedureList } from '../../actions/patientAction';
import { connect } from 'react-redux';
import { getDateFormat } from '../../utils/helpers'
import Notification from '../../utils/notification';
import ProcedureModal from '../../components/Procedure/ProcedureModal';
import ProcedureService from '../../services/procedureService';
import DeleteProcedureModal from '../../components/Procedure/DeleteProcedureModal';
import * as LoaderAction from '../../actions/loaderAction';
import { hasRole } from "../../utils/auth";
import { withTranslation } from 'react-i18next';

class ListProcedurePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditModal: false,
            showAddModal: false,
            procedure: "",
            showAddEditModal: false,
            showDeleteModal: false,
            id: 0
        }
    }
    componentDidMount() {
        this.getProcedureList();
    }

    getProcedureList = () => {
        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" } });
    }

    columns = () => {
        let { t } = this.props;
        return [
            {
                dataField: "id",
                text: t('id'),
                hidden: true,
            },
            {
                dataField: "created_at",
                text: t('date_added'),
                formatter: (cell, row) => getDateFormat(cell)
            },
            {
                dataField: "name",
                text: t('procedure_name')
            },
            {
                dataField: "price",
                text: "Cost in JMD",
                isDummyField: true,
                formatter: (cell, row) => this.priceFormatter(row,'JMD'),
                csvFormatter: (cell, row) => this.priceFormatter(row,'JMD')
            },
            {
                dataField: "price",
                text: "Cost in USD",
                isDummyField: true,
                formatter: (cell, row) => this.priceFormatter(row,'USD'),
                csvFormatter: (cell, row) => this.priceFormatter(row,'USD')
            },
            {
                dataField: "price",
                text: "Cost in CAD",
                isDummyField: true,
                formatter: (cell, row) => this.priceFormatter(row,'CAD'),
                csvFormatter: (cell, row) => this.priceFormatter(row,'CAD')
            },
            {
                dataField: "price",
                text: "Cost in GBP",
                isDummyField: true,
                formatter: (cell, row) => this.priceFormatter(row,'GBP'),
                csvFormatter: (cell, row) => this.priceFormatter(row,'GBP')
            },
            {
                dataField: "price",
                text: "Cost in EUR",
                isDummyField: true,
                formatter: (cell, row) => this.priceFormatter(row,'EUR'),
                csvFormatter: (cell, row) => this.priceFormatter(row,'EUR')
            },
            {
                dataField: "action",
                text: t('action'),
                isDummyField: true,
                formatter: this.actionFormatter,
                csvExport: false
            }
        ];
    };

    priceFormatter = (row, currency) => {
        if(row.id){
            let data = row.prices.filter( (items) => items.currency.name === currency )
            return data[0] ? data[0].price : ''
        }
        return ''
    }

    actionFormatter = (cell, row) => {
        if(row.id){
            return <div><a onClick={ () => this.handleModal(row)} title={this.props.t('edit')}>
                    <span className="edit-icon"></span>
                </a>
                
                <a className='ml-4' onClick={() => this.handleDeleteModal(row.id)} title={this.props.t('delete')}>
                <span className="delete-icon"></span>
            </a></div>
                
        }
        return ''
    }

    handleDeleteModal = (id) => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal, id })
    }

    handleDelete = (id) => {
        let message = this.props.t('procedure_deleted');
        ProcedureService.delete(id).then(resp => {
            Notification.show('success', { message: message });
            this.getProcedureList()
            this.handleDeleteModal()
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    handleModal = (row) => {
        this.handleShow()
        if(row === "add"){
            this.setState({ showAddModal: true});
        }else{
            this.setState({ procedure: row, showEditModal: true});
        }
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField: "created_at", sortOrder: "desc" }, });
    }

	onSuccessRedirect = () => {
        this.getProcedureList()
        this.handleClose()
	}
	
	handleClose = () => {
        this.setState({ showAddEditModal: false, procedure: "" });
        this.handleHideModal()
	}
	
	handleShow = () => {
        this.setState({ showAddEditModal: true });
	}

    handleHideModal = () => {
        this.setState({ showAddModal: false, showEditModal: false});
    }

    handleUploadedFiles = (e) => {
        this.props.showLoader()
        e.preventDefault();
        const files = e.target.files[0];
        e.target.value = null;

        let formData = new FormData();
        formData.append('files', files);
        ProcedureService.bulkUpload(formData).then(resp => {
            this.props.hideLoader()
            Notification.show('success', resp.data);
            this.getProcedureList();
        }).catch(error => {
            this.props.hideLoader()
            Notification.show('error', error.response.data)
        })
    }

    render() {
        let { data, meta } = this.props.procedures
        const { t } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('procedure_list')}</title>
                </Helmet>
                <div className="row">
                    <div className="col-sm-24">
                        <div className='page-title'>
                            <span>{t('procedure_list')}</span>
                            <button onClick={() => this.handleModal("add")} className="float-right btn btn-outline-primary">{t('add_procedure')}</button>
                            {!hasRole(this.props.selected_user_profile, ["associate-doctor"]) && <>
                                <input type="file" ref={procedureFile => this.fileInput = procedureFile} onChange={this.handleUploadedFiles} name="files" id="procedureFile" hidden accept='.csv' />
                                <button onClick={() => this.fileInput.click()} className="float-right btn btn-outline-primary" name="file" id="file">Upload Procedure</button>
                                </>
                            }
                        </div>
                        <div className="content-wrapper">
                            <DataGrid
                                columns={this.columns()}
                                data={data}
                                meta={meta}
                                handleTableChange={this.handleTableChange}
                                noDataIndication={t('no_record_found')}
                                pagination={true}
                                export_csv={!hasRole(this.props.selected_user_profile, ["associate-doctor"])}
                            />
                        </div>
                    </div>

                {this.state.showEditModal && <ProcedureModal
                    show={this.state.showAddEditModal}
                    onSuccess={this.onSuccessRedirect}
                    handleClose={this.handleClose}
                    buttonText={"Update"}
                    procedure={this.state.procedure}
                />}
                {this.state.showAddModal && <ProcedureModal
                    show={this.state.showAddEditModal}
                    onSuccess={this.onSuccessRedirect}
                    handleClose={this.handleClose}
                    buttonText={"Submit"}
                />}
                <DeleteProcedureModal showModal={this.state.showDeleteModal}
                handleDelete={this.handleDelete}
                id={this.state.id}
                handleClose={this.handleDeleteModal}
                t={t} />
                </div>

                
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    procedures: state.procedure_list,
    selected_user_profile: state.selected_user_profile
});

const mapActionsToProps = {
    paginate: getProcedureList,
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow
};
export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/procedure/ListProcedurePage')(ListProcedurePage))