import React from 'react';
import { isEqual } from 'lodash';
import * as ConductedSubmittedBlock from '../ConductedSubmittedBlock';
import * as ViewRecommendationComponents from './components/ViewRecommendationComponents';
import { withTranslation } from 'react-i18next';

class ShowRecommendationBlock extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            prescription: {
                prescription_medicine: props.prescription_medicine ? props.prescription_medicine : null,
                prescription_pathology_test: props.prescription_pathology_test ? props.prescription_pathology_test : null,
                prescription_radiology_test: props.prescription_radiology_test ? props.prescription_radiology_test : null,
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.prescription_medicine !== prevProps.prescription_medicine) {
            this.setState(prevState => ({
                prescription: {
                    ...prevState.prescription,
                    prescription_medicine: this.props.prescription_medicine ? this.props.prescription_medicine : null,
                }
            }))
        }
        if (this.props.prescription_pathology_test !== prevProps.prescription_pathology_test) {
            this.setState(prevState => ({
                prescription: {
                    ...prevState.prescription,
                    prescription_pathology_test: this.props.prescription_pathology_test ? this.props.prescription_pathology_test : null,
                }
            }))
        }
        if (this.props.prescription_radiology_test !== prevProps.prescription_radiology_test) {
            this.setState(prevState => ({
                prescription: {
                    ...prevState.prescription,
                    prescription_radiology_test: this.props.prescription_radiology_test ? this.props.prescription_radiology_test : null,
                }
            }))
        }
    }

    reloadPrescriptionMedicine = () => {
        console.log("reached")
        this.props.reloadPrescriptionMedicine()
    }

    render() {
        let { prescription } = this.state;
        const { t } = this.props;
        return (
            <React.Fragment>

                {isEqual(this.props.type, "medication") &&
                    <React.Fragment>
                        <ConductedSubmittedBlock.ConductedBySubmittedAt
                            name={prescription.prescription_medicine.submitted_by}
                            date={prescription.prescription_medicine.submitted_at}
                            t={t}
                        />
                        <ViewRecommendationComponents.ViewMedication
                            prescription_medicine={prescription.prescription_medicine}
                            reloadPrescriptionMedicine={this.reloadPrescriptionMedicine}
                            t={t}
                        />
                    </React.Fragment>
                }

                {isEqual(this.props.type, "pathology") &&
                    <React.Fragment>
                        <ConductedSubmittedBlock.ConductedBySubmittedAt
                            name={prescription.prescription_pathology_test.submitted_by}
                            date={prescription.prescription_pathology_test.submitted_at}
                            t={t}
                        />
                        <ViewRecommendationComponents.ViewPathology
                            prescription_pathology_test={prescription.prescription_pathology_test}
                            t={t}
                        />
                    </React.Fragment>
                }

                {isEqual(this.props.type, "radiology") &&
                    <React.Fragment>
                        <ConductedSubmittedBlock.ConductedBySubmittedAt
                            name={prescription.prescription_radiology_test.submitted_by}
                            date={prescription.prescription_radiology_test.submitted_at}
                            t={t}
                        />
                        <ViewRecommendationComponents.ViewRadiology
                            prescription_radiology_test={prescription.prescription_radiology_test}
                            t={t}
                        />
                    </React.Fragment>
                }

            </React.Fragment>
        )
    }
}
export default (withTranslation('components/UrgentCare/Recommendation/ViewRecommendation')(ShowRecommendationBlock));