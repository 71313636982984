import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

const FreeSelectModal = ({ showModal, handleModal, t }) => {
    return (
        <Modal show={showModal} backdrop="static" keyboard={false} onHide={(e) => handleModal(e)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('change_tab')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t('save_changes')}</Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={(e) => handleModal(e, 'save')}>
                    {t('save_continue')}
                </Button>
                <Button variant="primary" onClick={(e) => handleModal(e, 'cancel')}>
                    {t('cancel')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default (withTranslation('components/FreeSelectModal')(FreeSelectModal));