import React from "react";
import * as Helper from "../../utils/helpers";
import PrescriptionService from "../../services/prescriptionService";
import Loader from '../Loader';
import { withTranslation } from 'react-i18next';

const OrderBlock = ({ order, t }) => {
  return <React.Fragment>
    <h4 class="page-title">{t('prescription_rx')}</h4>
    <div class="content-wrapper padding-bottom-10">
      <h6>{t('pharmacy_info')}</h6>
      <ul class="prescription-patient-details">
        <li>
          {t('rx_number')}: <span>{order && order.order_id}</span>
        </li>
        <li>
          {t('pharmacy_name')}:
          <span>
            {order &&
              order.service_provider_info &&
              order.service_provider_info.service_provider_name}
          </span>
        </li>
        <li>
          {t('rx_date')}:{" "}
          <span>{order && Helper.getDateFormat(order.order_date)}</span>
        </li>
      </ul>
    </div>
    <div class="content-wrapper  padding-bottom-10">
      <h6>{t('prescribed_medicine')}</h6>
      <table class="table table table-responsive common-table">
        <thead>
          <tr>
            <th>{t('brand_name')}</th>
            <th>{t('generic_name')}</th>
            <th>Frequency</th>
            <th>{t('duration')}</th>
            {/* <th>Unit Required</th> */}
            <th>{t('unit_dispensed')}</th>
          </tr>
        </thead>
        <tbody>
          {order &&
            order.prescription_order_items &&
            order.prescription_order_items.map(item => {
              return (
                <React.Fragment>
                  <tr>
                    <td>{item.drug_name}</td>
                    <td>{item.drug_generic_name}</td>
                    <td>{item.dosage}</td>
                    <td>
                      {item.duration}
                      {Helper.getDurationType(item.duration_type)}
                    </td>
                    {/* <td>{_.isEmpty(item.drug_id) ? 1 : item.required_unit} {item.drug_type === "tablespoon" ? "ml" : "pc"}</td> */}
                    <td>
                      {item.fulfilled_unit}{" "}
                      {item.drug_type === "tablespoon" ? "ml" : "pc"}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5} className="remark-table-cell">
                      <div className="fourth">
                        <label>{t('remark')}:</label>
                        <span>{item.remark}</span>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
        </tbody>
      </table>
    </div>
  </React.Fragment>
}

class PrescriptionOrdersBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prescription_orders: null,
      loader: {
        isloading: false
      }
    };
  }

  componentDidMount() {
    PrescriptionService.getPrescriptionOrders({}, this.props.prescriptionId)
      .then(resp => {
        this.setState({
          prescription_orders: resp.data.data
        });
      })
      .catch(error => {
        return error;
      });
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Loader />
        {this.state.prescription_orders && this.state.prescription_orders.length > 0 && this.state.prescription_orders.map((order, index) =>
          <OrderBlock key={index} order={order} t={t} />)}
      </React.Fragment>
    );
  }
}

export default (withTranslation('components/Prescription/PrescriptionOrdersBlock')(PrescriptionOrdersBlock));
