import React from 'react'
import _ from "lodash";
import * as Helper from "../../utils/helpers";

export default function PatientInfoSection({ isLoading=false, patient, t }) {
    return (
        <div className="row">
            <div className="col-md-5">
                <div className="content-wrapper padding-none patient-pic">
                    {patient && !_.isEmpty(patient.profile_image) && <img src={patient.profile_image.thumbnail_url} alt="Patient Profile" />}
                </div>
            </div>
            <div className="col-md-9">
                <div className="content-wrapper padding-none">
                    <h5> {t('profile.personal.personal_information')} </h5>
                    <ul className="details-listing full-width clearfix">
                        <li> <span>{t('profile.personal.full_name')}:</span>{patient && patient.full_name }</li>
                        <li> <span>{t('profile.personal.gender')}:</span> {patient && _.capitalize(patient.gender) } </li>
                        <li> <span>{t('profile.personal.date_of_birth')}:</span> {patient && Helper.getDateFormat(patient.date_of_birth)} ({patient && Helper.getAgeString(patient.date_of_birth)})</li>
                        <li> <span>{t('profile.personal.insurance')}:</span> {patient && Helper.patientInsurance(patient.insurance_type) } </li>
                        <li> <span>{t('profile.personal.occupation')}:</span> {patient && patient.occupation } </li>
                        <li> <span>{t('profile.personal.kin_name')}:</span> {patient && patient.kin_name } </li>
                        <li> <span>{t('profile.personal.kin_contact_number')}:</span> {patient && patient.kin_number} </li>
                    </ul>
                </div>
            </div>

            <div className="col-md-10">
                <div className="content-wrapper padding-none">
                    <h5>
                        Contact Detail
                    </h5>
                    <ul className="details-listing clearfix full-width">
                        <li> <span>{t('profile.contact.contact_number')}:</span> {patient && patient.contact_number} </li>
                        <li> <span>{t('profile.contact.alternate_number')}:</span> {patient && patient.alt_contact_number} </li>
                        <li> <span>{t('profile.contact.email')}:</span> {patient && patient.email} </li>
                        <li> <span>{t('profile.contact.address')}:</span> 
                            <span className="address">{patient && Helper.fullAddressStr(patient.address) }</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
