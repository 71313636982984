import React from 'react';

export const Cardiovascular = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <h6>{t('cardiovascular.cardiovascular')}</h6>
        <input type="checkbox" id='cardiovascular-normal' name='cardiovascular-normal' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-normal') ? 'checked' : ''} />
        <label htmlFor='cardiovascular-normal'>{t('cardiovascular.normal')}</label>
    </React.Fragment>
}
export const Pulse = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <label className="heading">{t('cardiovascular.pulse')} </label>
        <div className="options-details">
            {/* regular */}
            <input type="checkbox" id='cardiovascular-plus-regular' name='cardiovascular-plus-regular' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-plus-regular') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-plus-regular'>{t('cardiovascular.regular')}</label>
            {/* irregular */}
            <input type="checkbox" id='cardiovascular-plus-irregular' name='cardiovascular-plus-irregular' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-plus-irregular') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-plus-irregular'>{t('cardiovascular.irregular')}</label>
            {/* bounding */}
            <input type="checkbox" id='cardiovascular-plus-bounding' name='cardiovascular-plus-bounding' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-plus-bounding') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-plus-bounding'>{t('cardiovascular.bounding')}</label>
            {/* fast */}
            <input type="checkbox" id='cardiovascular-plus-fast' name='cardiovascular-plus-fast' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-plus-fast') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-plus-fast'>{t('cardiovascular.fast')}</label>
        </div>
    </React.Fragment>
}
export const HS = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <label className="heading">{t('cardiovascular.hs')} </label>
        <div className="options-details">
            {/* s1 */}
            <input type="checkbox" id='cardiovascular-hs-s1' name='cardiovascular-hs-s1' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-hs-s1') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-hs-s1'>{t('cardiovascular.s1')}</label>
            {/* s2 */}
            <input type="checkbox" id='cardiovascular-hs-s2' name='cardiovascular-hs-s2' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-hs-s2') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-hs-s2'>{t('cardiovascular.s2')}</label>
            {/* s3 */}
            <input type="checkbox" id='cardiovascular-hs-s3' name='cardiovascular-hs-s3' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-hs-s3') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-hs-s3'>{t('cardiovascular.s3')}</label>
            {/* s4 */}
            <input type="checkbox" id='cardiovascular-hs-s4' name='cardiovascular-hs-s4' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-hs-s4') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-hs-s4'>{t('cardiovascular.s4')}</label>
            {/* murmur */}
            <input type="checkbox" id='cardiovascular-hs-murmur' name='cardiovascular-hs-murmur' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-hs-murmur') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-hs-murmur'>{t('cardiovascular.murmur')}</label>
            {/* thrill */}
            <input type="checkbox" id='cardiovascular-hs-thrill' name='cardiovascular-hs-thrill' onChange={e => handleChange(e, 'cardiovascular')} checked={checkIfProblemExist('cardiovascular', 'cardiovascular-hs-thrill') ? 'checked' : ''} />
            <label htmlFor='cardiovascular-hs-thrill'>{t('cardiovascular.thrill')}</label>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('type_here')} disabled={checkIfProblemExist('cardiovascular', 'cardiovascular-normal') ? 'disabled' : ''} onChange={e => handleChange(e, 'cardiovascular')} value={getCategoryComment('cardiovascular') ? getCategoryComment('cardiovascular') : ''}></textarea>
    </React.Fragment>
}