import React from 'react';
import SearchDoctor from './SearchDoctor';

const ReferralLetter = ({ validator, form_attributes, handleChangeCertificate, handleSearchDoctor, t }) => {
    return (
        <React.Fragment>
            <h6 className="blue-main-heading grey-border-bottom mb-4">{t('referral.referral_letter')}</h6>
            <div className="mb-4 row">
                <label htmlFor="staticEmail" className="col-auto col-form-label color-grey">{t('referral.referred_to')} :</label>
                <div className="col-sm-10">
                    <SearchDoctor
                        doctor={form_attributes.doctor}
                        validator={validator}
                        handleSearchDoctor={handleSearchDoctor}
                    />
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-24">
                    <h6 className="color-grey">{t('referral.dear_dr')}:</h6>
                </div>
                <div className="col-md-24">
                    <textarea className="form-control" placeholder={t('referral.type_content_here')} name="referred_reason" value={form_attributes.referred_reason} onChange={handleChangeCertificate.bind(this)}></textarea>
                    {validator.message(t('referral.reason_referring'), form_attributes.referred_reason, 'required|string')}
                </div>
            </div>
        </React.Fragment>
    )
}

export default ReferralLetter;