import React, { memo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import _ from 'lodash'

export default memo(function BarGraph({ barColor, barLabel, yScaleLabel, datasetLabels, datasetCounts, handleViewChange, t }) {
    
    const [view, setView] = useState('week')

    const data = {
        labels: datasetLabels,
        datasets: [{
            type: 'bar',
            label: barLabel,
            data: datasetCounts,
            fill: false,
            borderWidth: 1,
            backgroundColor: barColor,
            borderColor: barColor,
        }]
    };

    const options = {
        responsive: true,
        borderSkipped:'bottom',
        scales: {
            xAxes: [
                {
                    display: true,
                    lineWidth: 10,
                    gridLines: {
                        display: false
                    },
                    scaleLabel: {
                        display: true,
                        labelString: _.capitalize(t(view))
                    }
                }
            ],
            yAxes: [
                {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    id: 'y-axis-1',
                    scaleLabel: {
                        display: true,
                        labelString: yScaleLabel
                    }
                },
            ]
        }
    };

    const viewButtonClick = (view) => {
        handleViewChange(view)
        setView(view)
    }
    
    return (
        <div className="graph-wrapper">
            <div className="graph-title-head">
                <button className={view === "month" ? "btn btn-primary" : "btn btn-outline-primary"} onClick={e => viewButtonClick("month")}>{t('month')}</button>
                <button className={view === "week" ? "btn btn-primary" : "btn btn-outline-primary"} onClick={e => viewButtonClick("week")}>{t('week')}</button>
                <button className={view === "day" ? "btn btn-primary" : "btn btn-outline-primary"} onClick={e => viewButtonClick("day")}>{t('day')}</button>
            </div>
            <div className="graph-content">
                <Bar
                    width={100}
                    height={30}
                    data={data}
                    options={options}
                />
            </div>
        </div>
    )
})
