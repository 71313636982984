import React from 'react';
import { withTranslation } from 'react-i18next';

export const PersonalNote = ({ note, handleChange, t }) => {
    return <React.Fragment>
    <div className="content-wrapper">
	    <h6>{t('personal_note')}:</h6>
	    <p>
	        <textarea className="form-control" name="personal_note" value={note ? note : ''} onChange={handleChange} placeholder={t('type_here')}></textarea>
	    </p>
    </div>
    </React.Fragment>
}
export default (withTranslation('components/Prescription/Recommendation/components/PersonalNote')(PersonalNote))