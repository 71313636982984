import React from 'react';
import { Tab, Tabs } from "react-bootstrap";
import SimpleReactValidator from 'simple-react-validator';
import Notification from '../../../utils/notification';
import MedicationBlock from '../MedicationBlock';
import PathologyBlock from '../PathologyBlock';
import RadiologyBlock from '../RadiologyBlock';
import RemarkReferralBlock from '../RemarkReferralBlock';
import UrgentCareService from '../../../services/urgentCareService';
import _ from 'lodash';
import FreeSelectModal from '../FreeSelectModal';
import { hasRole } from '../../../utils/auth';
import { withTranslation } from 'react-i18next';
import * as HELPER from '../../../utils/helpers';

class AddRecommendation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentKey: 7,
            selectedKey: null,
            isEdited: props.isEdited,
            editedSection: props.editedSection,
            prescription: {},
            showFreeSelectModal: false,
        }
        this.recommendationValidator = new SimpleReactValidator({ locale: HELPER.getLanguage() });
        this.recommendationMedicationRef = React.createRef();
        this.recommendationPathologyRef = React.createRef();
        this.recommendationRadiologyRef = React.createRef();
        this.recommendationRemarkRef = React.createRef();
    }
    componentDidMount() {
        this.getPrescriptionRecommendation();
    }
    componentDidUpdate(prevProps) {
        if (this.props.editedSection !== prevProps.editedSection) {
            this.setState({isEdited: this.props.isEdited, editedSection: this.props.editedSection})
        }
    }

    getPrescriptionRecommendation = () => {
        this.props.showLoader();
        UrgentCareService.getPrescriptionRecommendation(this.props.prescriptionId).then(resp => {
            let data = resp.data
            this.setState({
                prescription: {
                    prescription_drugs: data.prescription_medicines ? data.prescription_medicines : [],
                    prescription_pathology_tests: data.prescription_pathology_tests ? data.prescription_pathology_tests : [],
                    prescription_radiology_tests: data.prescription_radiology_tests ? data.prescription_radiology_tests : [],
                    refer_to: data.refer_to ? data.refer_to : {},
                    personal_note: data.personal_note ? data.personal_note : null,
                    remarks: data.remarks ? data.remarks : null,
                    patient_id: this.props.patientId,
                }
            })
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    };

    handlePreviousRecommendation = () => {
        this.setState((prevState, _props) => {
            return { currentKey: prevState.currentKey - 1 }
        })
    }
    _onSelect = (selectedKey) => {
        if(selectedKey<7){
            this.props._onSelect(selectedKey)
        }else{
            this.state.isEdited ? this.setState({ showFreeSelectModal: true, selectedKey }) : this.setState({ currentKey: parseInt(selectedKey), selectedKey: '' })
        }
    };
    handleSaveAndNextRecommendation = () => {
        if(this.props.selectedKey<7 && this.props.selectedKey>0){
            this.props._onSelect(this.props.selectedKey)
        }else{
            this.setState((prevState, _props) => {return { currentKey: (this.state.selectedKey ? parseInt(this.state.selectedKey) : prevState.currentKey + 1), selectedKey: '', isEdited: false }})
        }
    }
    handleRecommendationSaveAndNext = (e, type = null, recommendationType) => {
        if(recommendationType === "medication"){
            this.recommendationMedicationRef.current.handleMedicationSaveAndNext(e, type);
        }else if(recommendationType === "pathology"){
            this.recommendationPathologyRef.current.handlePathologySaveAndNext(e, type);
        }else if(recommendationType === "radiology"){
            this.recommendationRadiologyRef.current.handleRadiologySaveAndNext(e, type);
        }else if(recommendationType === "remark"){
            this.recommendationRemarkRef.current.handleRemarkReferralFinalSubmit(e, type);
        }
    }
    handleFreeSelect = (evt, type = null) => {
        let { selectedKey } = this.state
        if (type === 'save') {
            this.state.editedSection === 'recommendation_medication' && this.handleRecommendationSaveAndNext(evt, 'save_and_next', "medication");
            this.state.editedSection === 'recommendation_pathology' && this.handleRecommendationSaveAndNext(evt, 'save_and_next', "pathology");
            this.state.editedSection === 'recommendation_radiology' && this.handleRecommendationSaveAndNext(evt, 'save_and_next', "radiology");
            this.state.editedSection === 'recommendation_remark_referral' && this.handleRecommendationSaveAndNext(evt, 'draft_and_next', "remark");
            this.setState({ showFreeSelectModal: false });
        }
        if (type === 'draft') {
            this.state.editedSection === 'recommendation_medication' && this.handleRecommendationSaveAndNext(evt, 'draft_and_next', "medication");
            this.state.editedSection === 'recommendation_pathology' && this.handleRecommendationSaveAndNext(evt, 'draft_and_next', "pathology");
            this.state.editedSection === 'recommendation_radiology' && this.handleRecommendationSaveAndNext(evt, 'draft_and_next', "radiology");
            this.state.editedSection === 'recommendation_remark_referral' && this.handleRecommendationSaveAndNext(evt, 'draft_and_next', "remark");
            this.setState({ showFreeSelectModal: false });
        }
        if (type === 'cancel') {
            selectedKey = '';
            this.setState({ showFreeSelectModal: false, selectedKey });
        }
    }
    render() {
        let { prescription } = this.state;
        const { t } = this.props;
        this.recommendationValidator.purgeFields();
        let tabDisable = hasRole(this.props.selected_user_profile, ["doctor-nurse"]) ? true : false;
        return (
            <React.Fragment>
                <div className=" lg-height">
                    <div className="row">
                        <div className="col-md-24">
                            <Tabs activeKey={this.state.currentKey} onSelect={this._onSelect}>
                                <Tab eventKey={7} title={t('medication')}>
                                    {this.state.prescription.prescription_drugs && <MedicationBlock
                                        validator={this.recommendationValidator}
                                        selected_user_profile={this.props.selected_user_profile}
                                        disabled={this.props.recordId ? true : false}
                                        not_required={false}
                                        prescription_drugs={this.state.prescription.prescription_drugs}
                                        handleIsPrescriptionEdited={this.props.handleIsPrescriptionEdited}
                                        handleSubmitComponent={this.props.handleSubmitComponent}
                                        prescriptionId={this.props.prescriptionId}
                                        showLoader={this.props.showLoader}
                                        hideLoader={this.props.hideLoader}
                                        getPrescriptionRecommendation={this.getPrescriptionRecommendation}
                                        patientId={this.props.patientId}
                                        handlePrevious={this.props.handlePrevious}
                                        handleSaveAndNext={this.handleSaveAndNextRecommendation}
                                        ref={this.recommendationMedicationRef}
                                    />}
                                </Tab>
                                <Tab eventKey={8} title={t('lab_test')} disabled= {hasRole(this.props.selected_user_profile, ["doctor-nurse", "junior-doctor"])}>
                                    {this.state.prescription.prescription_pathology_tests && <PathologyBlock
                                        validator={this.recommendationValidator}
                                        selected_user_profile={this.props.selected_user_profile}
                                        disabled={this.props.recordId ? true : false}
                                        not_required={false}
                                        prescription_pathology_tests={prescription.prescription_pathology_tests}
                                        handleIsPrescriptionEdited={this.props.handleIsPrescriptionEdited}
                                        handleSubmitButton={this.props.handleSubmitComponent}
                                        prescriptionId={this.props.prescriptionId}
                                        showLoader={this.props.showLoader}
                                        hideLoader={this.props.hideLoader}
                                        getPrescriptionRecommendation={this.getPrescriptionRecommendation}
                                        patientId={this.props.patientId}
                                        handlePrevious={this.handlePreviousRecommendation}
                                        handleSaveAndNext={this.handleSaveAndNextRecommendation}
                                        ref={this.recommendationPathologyRef}
                                    />}
                                </Tab>
                                <Tab eventKey={9} title={t('radiology')} disabled= {hasRole(this.props.selected_user_profile, ["doctor-nurse", "junior-doctor"])}>
                                    {<RadiologyBlock
                                        prescription_radiology_tests={prescription.prescription_radiology_tests}
                                        validator={this.recommendationValidator}
                                        selected_user_profile={this.props.selected_user_profile}
                                        disabled={this.props.recordId ? true : false}
                                        handleIsPrescriptionEdited={this.props.handleIsPrescriptionEdited}
                                        handleSubmitButton={this.props.handleSubmitComponent}
                                        prescriptionId={this.props.prescriptionId}
                                        showLoader={this.props.showLoader}
                                        hideLoader={this.props.hideLoader}
                                        getPrescriptionRecommendation={this.getPrescriptionRecommendation}
                                        patientId={this.props.patientId}
                                        handlePrevious={this.handlePreviousRecommendation}
                                        handleSaveAndNext={this.handleSaveAndNextRecommendation}
                                        ref={this.recommendationRadiologyRef}
                                    />}
                                </Tab>
                                <Tab eventKey={10} title={t('remark_referral')} disabled= {hasRole(this.props.selected_user_profile, ["doctor-nurse", "junior-doctor"])}>
                                    {!_.isEmpty(this.state.prescription)&& <RemarkReferralBlock
                                        validator={this.recommendationValidator}
                                        selected_user_profile={this.props.selected_user_profile}
                                        disabled={this.props.recordId ? true : false}
                                        not_required={false}
                                        refer_to={prescription.refer_to}
                                        personal_note={prescription.personal_note}
                                        remarks={prescription.remarks}
                                        handleIsPrescriptionEdited={this.props.handleIsPrescriptionEdited}
                                        handleSubmitButton={this.props.handleSubmitComponent}
                                        prescriptionId={this.props.prescriptionId}
                                        edit_refer_remark={this.edit_refer_remark}
                                        showLoader={this.props.showLoader}
                                        hideLoader={this.props.hideLoader}
                                        getPrescriptionRecommendation={this.getPrescriptionRecommendation}
                                        patientId={this.props.patientId}
                                        handlePrevious={this.handlePreviousRecommendation}
                                        handleSaveAndNext={this.handleSaveAndNextRecommendation}
                                        ref={this.recommendationRemarkRef}
                                    />}
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
                {this.state.showFreeSelectModal &&
                    <FreeSelectModal
                        showModal={this.state.showFreeSelectModal}
                        handleModal={this.handleFreeSelect}
                        currentTab={this.state.currentKey} />
                }
            </React.Fragment >
        )
    }
}
export default (withTranslation('components/UrgentCare/Recommendation/AddRecommendation')(AddRecommendation));
