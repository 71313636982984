import React from 'react';
import * as ViewPersonalHistoryComponents from './ViewPersonalHistoryComponent';
import { PersonalHistory } from '../../../../../config/add_prescription';
import { withTranslation } from 'react-i18next';

class ViewPersonalHistoryBlock extends React.Component {
    render() {
        let { personal_history, t } = this.props;
        return (
            <React.Fragment>
                <div className="content-wrapper margin-bottom-none">
                    <ul className="prescription-filed-details margin-bottom">
                        <li> {t('education')}: <span>{personal_history.education ? t(personal_history.education) : '--'}</span></li>
                    </ul>
                </div>
                <ViewPersonalHistoryComponents.Abuse
                    is_abuse={personal_history.is_abuse}
                    abuse_reason={personal_history.abuse_reason}
                    t={t}
                />
                {personal_history.substance.map((item, index) => (
                    <ViewPersonalHistoryComponents.SubstanceAbuse
                        substanceAbuse={item}
                        key={index}
                        t={t} />
                ))}

                <ViewPersonalHistoryComponents.Excercise
                    is_exercise={personal_history.is_exercise}
                    exercise_frequency={personal_history.exercise_frequency}
                    is_sleep_snore={personal_history.is_sleep_snore}
                    t={t}
                />
                <ViewPersonalHistoryComponents.Sexual
                    personal_history={personal_history}
                    t={t}
                />
                <ViewPersonalHistoryComponents.Vaccine
                    hiv_vaccines={personal_history.hiv_vaccines}
                    influenza_vaccines={personal_history.influenza_vaccines}
                    tetanus_vaccacines={personal_history.tetanus_vaccacines}
                    t={t}
                />
            </React.Fragment>
        )
    }
}
export default (withTranslation('components/Prescription/History/components/PersonalHistory/ViewPersonalHistory')(ViewPersonalHistoryBlock))