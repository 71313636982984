import React from "react";
import { capitalize } from "lodash";
import { FamilyHistory } from "../../../../../config/add_prescription";
import { withTranslation } from 'react-i18next';

class ViewFamilyHistory extends React.Component {
  render() {
    let { family_history, t } = this.props;

    return (
      <div className="prescription-filed-details margin-bottom auto-width">
        <ul className="prescription-filed-details margin-bottom">
          <li>
          {t('are_you_adopted')} :
            <span>
              {family_history.is_adopted === true
                ? t('yes')
                : family_history.is_adopted === false
                ? t('no')
                : "--"}
            </span>
          </li>
        </ul>
        <ul className="prescription-filed-details margin-bottom auto-width">
          <li>
          {t('father')} :
            <span>
              {family_history.father_status
                ? capitalize(family_history.father_status)
                : "--"}
              , {family_history.father_age ? family_history.father_age : "--"}
            </span>
          </li>
          {family_history.father_death_reason && (
            <li>
              Unknown cause of death:
              <span>{family_history.father_death_reason}</span>
            </li>
          )}
        </ul>
        <ul className="prescription-filed-details margin-bottom auto-width">
          <li>
          {t('mother')} :
            <span>
              {family_history.mother_status
                ? capitalize(family_history.mother_status)
                : "--"}
              , {family_history.mother_age ? family_history.mother_age : "--"}
            </span>
          </li>
          {family_history.mother_death_reason && (
            <li>
              {t('unknown_cause_death')}:
              <span>{family_history.mother_death_reason}</span>
            </li>
          )}
        </ul>
        {family_history.relation_conditions && (
          <React.Fragment>
            <h6 className="inner_heading">
                {t('family_member_llness_details')}
            </h6>
            <ul className="family-member-list">
              {family_history.relation_conditions.map((item, index) => (
                <React.Fragment key={index}>
                  <li>
                    <span>{t('illness.' + item.illness_type)} :</span>
                    <span>
                      {item.family_relation &&
                        item.family_relation.map((relation, key) => (
                          <span key={key}>
                            {t('family_relation.' + relation)} &nbsp;
                          </span>
                        ))}
                    </span>

                    {item.illness_type && item.remark && (
                      <li>
                        {" "}
                        <div className="prescription-filed-details">
                          <label className="main-label">{t('remark')}: </label>
                          <span> {item.remark}</span>
                        </div>
                      </li>
                    )}
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default (withTranslation('components/Prescription/History/components/FamilyHistory/ViewFamilyHistory')(ViewFamilyHistory))
