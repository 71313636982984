import React from 'react';

export const NervousSystem = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <h6>{t('nervous_system.nervous_system')} </h6>
        <input type="checkbox" id='nervous-system-normal' name='nervous-system-normal' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'nervous-system-normal') ? 'checked' : ''} />
        <label htmlFor='nervous-system-normal'>{t('nervous_system.normal')}</label>
    </React.Fragment>
}
export const Gait = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <label className="heading">{t('nervous_system.gait')} </label>
        <div className="options-details">
            <input type="checkbox" id='nervous-system-gait-normal' name='nervous-system-gait-normal' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'nervous-system-gait-normal') ? 'checked' : ''} />
            <label htmlFor='nervous-system-gait-normal'>{t('nervous_system.normal')}</label>
            <input type="checkbox" id='nervous-system-gait-abnormal' name='nervous-system-gait-abnormal' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'nervous-system-gait-abnormal') ? 'checked' : ''} />
            <label htmlFor='nervous-system-gait-abnormal'>{t('nervous_system.abnormal')}</label>
        </div>
    </React.Fragment>
}
export const Reflex = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <label className="heading">{t('nervous_system.reflex')} </label>
        <div className="options-details">
            <input type="checkbox" id='nervous-system-reflex-normal' name='nervous-system-reflex-normal' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'nervous-system-reflex-normal') ? 'checked' : ''} />
            <label htmlFor='nervous-system-reflex-normal'>{t('nervous_system.normal')}</label>
            <input type="checkbox" id='nervous-system-reflex-abnormal' name='nervous-system-reflex-abnormal' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'nervous-system-reflex-abnormal') ? 'checked' : ''} />
            <label htmlFor='nervous-system-reflex-abnormal'>{t('nervous_system.abnormal')}</label>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('type_here')} onChange={e => handleChange(e, 'nervous-system')} disabled={checkIfProblemExist('nervous-system', 'nervous-system-normal') ? 'disabled' : ''} value={getCategoryComment('nervous-system') ? getCategoryComment('nervous-system') : ''}></textarea>
    </React.Fragment>
}
export const Power = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <label className="heading">{t('nervous_system.power')} </label>
        <div className="options-details">
            <input type="checkbox" id='nervous-system-power-normal' name='nervous-system-power-normal' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'nervous-system-power-normal') ? 'checked' : ''} />
            <label htmlFor='nervous-system-power-normal'>{t('nervous_system.normal')}</label>
            <input type="checkbox" id='nervous-system-power-abnormal' name='nervous-system-power-abnormal' onChange={e => handleChange(e, 'nervous-system')} checked={checkIfProblemExist('nervous-system', 'nervous-system-power-abnormal') ? 'checked' : ''} />
            <label htmlFor='nervous-system-power-abnormal'>{t('nervous_system.abnormal')}</label>
        </div>
    </React.Fragment>
}