import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getDateFormat } from '../../utils/helpers';
import DataGrid from "../../components/DataGrid";
import * as CovidCenterActions from '../../actions/covidCenterAction';
import { Link } from "react-router-dom";
import SearchComponent from '../../components/Covid/SearchComponent';
import { withTranslation } from 'react-i18next';

class CovidReportPage extends React.Component {
    constructor(props) {
        super(props);
        const { params: { type } } = this.props.match;

        this.state = {
            type: type,
            filter: {
                status: 'conducted'
            }

        };
    }
    componentDidMount() {
        this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: this.state.filter });
    }
    columns = () => {
        let { t } = this.props;
        return [{
            dataField: 'collected_at',
            text: t('date_collected'),
            formatter: this.dateTimeFormat,
        }, {
            dataField: 'received_at',
            text: t('received_date'),
            formatter: this.dateTimeFormat,
        }, {
            text: t('patient_name'),
            dataField: 'patient_name',
        }, {
            text: t('registered_by'),
            dataField: 'conducted_by',
        }, {
            dataField: 'actions',
            text: t('actions'),
            isDummyField: true,
            formatter: this.actionFormatter
        }];
    };
    dateTimeFormat = (cell, row) => {
        return getDateFormat(cell)
    }
    actionFormatter = (cell, row) => {
        return <Link to={`/patients/${row.patient_id}/reports/${row.id}`}>
            <span className="icon file"></span>
        </Link >
    };
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: this.state.filter });
    }
    onSearch = (filter) => {
        let filterData = {
            ...filter,
            ...this.state.filter
        }
        this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: filterData });
    }
    render() {
        let { data, meta } = this.props.covid_reports;
        const { t } = this.props;
        return <React.Fragment>
            <Helmet>
                <title>{t('pending_reports')}</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">{t('pending_reports')}</h4>
                </div>
            </div>
            <SearchComponent onSearch={this.onSearch} list_type="pending" />
            <div className="content-wrapper">
                {data && <DataGrid
                    columns={this.columns()}
                    data={data}
                    meta={meta}
                    handleTableChange={this.handleTableChange}
                    noDataIndication={t('no_record')}

                />}
            </div>
        </React.Fragment>
    }
}
const mapStateToProps = (state) => ({
    covid_reports: state.covid_reports,
});

const mapActionsToProps = {
    paginate: CovidCenterActions.paginate,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/covid-center/CovidPendingReportPage')(CovidReportPage));