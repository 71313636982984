import React from 'react';

export const Abdomen = ({ handleChange, checkIfProblemExist ,t }) => {
    return <React.Fragment>
        <h6>{t('abdomen.abdomen')}</h6>
        <input type="checkbox" id='abdomen-normal' name='abdomen-normal' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-normal') ? 'checked' : ''} />
        <label htmlFor='abdomen-normal'>{t('abdomen.normal')}</label>
    </React.Fragment>
}
export const AbdomenOptions = ({ handleChange, checkIfProblemExist ,t }) => {
    return <React.Fragment>
        <div className="options-details">
            {/* flat */}
            <input type="checkbox" id='abdomen-flat' name='abdomen-flat' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-flat') ? 'checked' : ''} />
            <label htmlFor='abdomen-flat'>{t('abdomen.flat')}</label>
            {/* distended */}
            <input type="checkbox" id='abdomen-distended' name='abdomen-distended' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-distended') ? 'checked' : ''} />
            <label htmlFor='abdomen-distended'>{t('abdomen.distended')}</label>
            {/* visible mass */}
            <input type="checkbox" id='abdomen-visible-mass' name='abdomen-visible-mass' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-visible-mass') ? 'checked' : ''} />
            <label htmlFor='abdomen-visible-mass'>{t('abdomen.visible_mass')}</label>
            {/* scar */}
            <input type="checkbox" id='abdomen-scar' name='abdomen-scar' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-scar') ? 'checked' : ''} />
            <label htmlFor='abdomen-scar'>{t('abdomen.scar')}</label>
            {/* non tender */}
            <input type="checkbox" id='abdomen-non-tender' name='abdomen-non-tender' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-non-tender') ? 'checked' : ''} />
            <label htmlFor='abdomen-non-tender'>{t('abdomen.non_tender')}</label>
            {/* tender */}
            <input type="checkbox" id='abdomen-tender' name='abdomen-tender' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-tender') ? 'checked' : ''} />
            <label htmlFor='abdomen-tender'>{t('abdomen.tender')}</label>
            {/* organomegally */}
            <input type="checkbox" id='abdomen-organomegally' name='abdomen-organomegally' onChange={e => handleChange(e, 'abdomen')} checked={checkIfProblemExist('abdomen', 'abdomen-organomegally') ? 'checked' : ''} />
            <label htmlFor='abdomen-organomegally'>{t('abdomen.organomegally')}</label>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist ,t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('type_here')} onChange={e => handleChange(e, 'abdomen')} disabled={checkIfProblemExist('abdomen', 'abdomen-normal') ? 'disabled' : ''} value={getCategoryComment('abdomen') ? getCategoryComment('abdomen') : ''}></textarea>
    </React.Fragment>
}