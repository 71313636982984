import React from 'react';

export const Eyes = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>{t('eyes.eyes')}</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                <input type="checkbox" id="pain" name="pain" onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', "pain") ? 'checked' : ''} />
                <label htmlFor="pain">{t('eyes.pain')}</label>
                <input type="checkbox" id="redness" name="redness" onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', "redness") ? 'checked' : ''} />
                <label htmlFor="redness">{t('eyes.redness')}</label>
                <input type="checkbox" id='loss-of-vision' name='loss-of-vision' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'loss-of-vision') ? 'checked' : ''} />
                <label htmlFor='loss-of-vision'>{t('eyes.loss_of_vision')}</label>
                <input type="checkbox" id='double-or-blurred-vision' name='double-or-blurred-vision' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'double-or-blurred-vision') ? 'checked' : ''} />
                <label htmlFor='double-or-blurred-vision'>{t('eyes.blurred_vision')}</label>
                <input type="checkbox" id='dryness' name='dryness' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'dryness') ? 'checked' : ''} />
                <label htmlFor='dryness'>{t('eyes.dryness')}</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('eyes.comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('eyes.type_here')} onChange={e => handleChange(e, 'eyes')} value={getCategoryComment('eyes') ? getCategoryComment('eyes') : ''}></textarea>
    </React.Fragment>
}