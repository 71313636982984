import React from 'react';
import { overall_body_chart_label_config, body_chart_sub_part } from '../../../config/add_prescription';
import FemaleBack from './components/FemaleBack';
import FemaleFront from './components/FemaleFront';
import MaleBack from './components/MaleBack';
import MaleFront from './components/MaleFront';
import { withTranslation } from 'react-i18next';
class ViewBodyChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isViewBodyChart: true,
        }
    }
    getBodyChartValueByKey = (key) => {
        return overall_body_chart_label_config[key]
    }
    getBodyChartSubPart = (item) => {
        let key = item.image_slug.split("_");
        return body_chart_sub_part[key[0]][key[1]][item.part_slug][item.sub_part];
    }

    render() {
        let { body_charts, t } = this.props;

        return (
            <React.Fragment>
                {/* personal history */}
                {body_charts && Object.keys(body_charts).map((charts, index) =>

                    <div className="content-wrapper padding-bottom-10">
                        <h6>{this.getBodyChartValueByKey(charts)}</h6>
                        <div className="prescription-patient-details medicine-details">
                            <div className="content-wrapper">
                                <div className="row">
                                    <div className="col-md-18">
                                        <ul className="doctor-tests">
                                            {body_charts && body_charts[charts].map((parts, index) =>

                                                <li key={index}>
                                                    {parts.part_name && <div>{t('part_name')}: <span>{parts.part_name} {parts.sub_part && " - " + this.getBodyChartSubPart(parts)}</span></div>}
                                                    {parts.description && <div>{t('description')}: <span>{parts.description}</span></div>}
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                        {charts && charts === 'male_front' && <MaleFront body_chart={body_charts[charts]} isViewBodyChart={this.state.isViewBodyChart} />}
                                        {charts && charts === 'male_back' && <MaleBack body_chart={body_charts[charts]} isViewBodyChart={this.state.isViewBodyChart} />}
                                        {charts && charts === 'female_front' && <FemaleFront body_chart={body_charts[charts]} isViewBodyChart={this.state.isViewBodyChart} />}
                                        {charts && charts === 'female_back' && <FemaleBack body_chart={body_charts[charts]} isViewBodyChart={this.state.isViewBodyChart} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* family history */}

            </React.Fragment>
        )
    }
}
export default (withTranslation('components/Prescription/BodyChart/ViewBodyChart')(ViewBodyChart))