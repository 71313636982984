import React from "react";
import SimpleReactValidator from "simple-react-validator";
import * as HELPER from "../utils/helpers";
import PatientService from '../services/patientService';
import Notification from "../utils/notification";
import CardSwipe from 'react-card-swipe';
import { withTranslation } from 'react-i18next';

class SearchPatientWithoutCode extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            card: '',
        };

        CardSwipe.init({
            success: this.handleSwipeSuccess,
            debug: false
        });

        this.searchByCardBtn = React.createRef();
        this.validatorOne = new SimpleReactValidator({ locale: HELPER.getLanguage() });
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmitOne = this.handleSubmitOne.bind(this);
    }

    handleSwipeSuccess = (swipeData) => {
        this.setState({
            card: swipeData.line1
        });

        this.searchByCardBtn.current.click();
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmitOne = (e) => {
        e.preventDefault();
        if (!this.validatorOne.allValid()) {
            this.validatorOne.showMessages();
            this.forceUpdate();
            return false;
        }

        let cardObj = HELPER.getCardObj(this.state.card);

        if (cardObj) {
            this.setState({
                card: cardObj.user_code
            });

            let postData = {
                user_code: cardObj.user_code,
                card_number: cardObj.card_number,
                hash: cardObj.hash,
            };

            PatientService.searchPatientCard(postData)
                .then(resp => {
                    if (resp.data && resp.data.patient_id <= 0) {
                        Notification.show("error", { message: this.props.t('patient_not_found') });
                    }
                    this.props.handlePatientSearch(resp.data.patient_id);
                })
                .catch(error => {
                    if (error.response && error.response.status) {
                        Notification.show("error", error.response.data);
                    }
                });
        } else {
            this.setState({
                card: ""
            });
            Notification.show("error", { message: this.props.t('invalid_card') });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="col-md-24 search-by-id show">
                    <form onSubmit={this.handleSubmitOne} id="number_search_form" ref={form => this.searchCardForm = form}>
                        <div className="row">
                            <div className="col-md-20">
                                <fieldset>
                                    {HELPER.config('CARD_DEBUG') === 'false' ?
                                        <input type="text" className="form-control" id="card" name="card" value={this.state.card} onChange={this.handleChange} placeholder={t('search_by_card')} readOnly /> :
                                        <input type="text" className="form-control" id="card" name="card" value={this.state.card} onChange={this.handleChange} placeholder={t('search_by_card')} />}
                                    {this.validatorOne.message(t('card'), this.state.card, 'required')}
                                </fieldset>
                            </div>
                            <div className="col-md-4">
                                <button type="submit" className="btn btn-primary card_number_search_btn show" form="number_search_form" ref={this.searchByCardBtn} data-index="14" data-tooltip={t('card_swipe_text')}>{t('search_patient')}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}


export default (withTranslation('components/SearchPatientWithoutCode')(SearchPatientWithoutCode));
