import React from 'react';
import { isEmpty } from 'lodash';

const AppointmentFilter = ({ tags, filter, handleSearch, handleFilterChange, doctors, handleResetSearch, removeFilter, t }) => {
    return <div className="search-wrapper mt-3">
        <label>{t('filter.search')}</label>
        <div className="search-box clearfix">
            {!isEmpty(tags) && (Object.keys(tags)).map((tag) => {
                if (tag !== 'doctor_profile_id') {
                    return <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={() => removeFilter(tag)}> x </span> </span>
                }
                return '';
            })
            }
            <span className="search-box-icon"></span>
            <div className="search-container">
                <form>
                    <ul>
                        <li>
                            <span>{t('filter.doctor_name')}</span>
                            <select className="form-control" name="doctor_profile_id" onChange={handleFilterChange} value={filter.doctor_profile_id || ''}>
                                <option value=""> {t('filter.select_doctor')} </option>
                                {doctors && doctors.map(doctor => (
                                    <option value={doctor.user_profile_id} key={doctor.user_profile_id}>{doctor.full_name}</option>
                                ))}
                            </select>
                        </li>
                        <li>
                            <span>{t('filter.patient_name')}</span>
                            <input
                                type="text"
                                value={filter.patient_name || ''}
                                onChange={handleFilterChange}
                                name="patient_name"
                            ></input>
                        </li>
                        <li>
                            <span>{t('filter.contact_number')}</span>
                            <input
                                type="number"
                                value={filter.patient_contact || ''}
                                onChange={handleFilterChange}
                                name="patient_contact"
                                size='10'
                            ></input>
                        </li>
                        <li>
                            <button type="button" onClick={handleResetSearch} className="btn btn-secondary">{t('filter.reset')}</button>
                            <button type="button" onClick={handleSearch} className="btn btn-primary">{t('filter.search')}</button>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    </div>
}
export default AppointmentFilter