import React from 'react';
import { TOLL_FREE_NO } from '../config';
import { withTranslation } from 'react-i18next';

class Footer extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <footer className="clearfix no-print inner-footer">
                    <div className="content-wrapper">
                        <p><small>{t('copyright_text', { year: new Date().getFullYear() })}
                            <a href={`${process.env.PUBLIC_URL}/privacy.html`} className="text-secondary" target="_blank" rel="noopener noreferrer"> {t('privacy_policy')}</a> {t('and')}
                            <a href={`${process.env.PUBLIC_URL}/terms.html`} className="text-secondary" target="_blank" rel="noopener noreferrer"> {t('terms_conditions')}</a>.</small>
                            <small> {t('toll_free_no')} <span className="text-secondary">{TOLL_FREE_NO}</span></small></p>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default withTranslation('components/Footer')(Footer);
