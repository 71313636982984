import React from 'react';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from 'moment';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

const DateRangePickerComponent = ({ date_type, filter, handleEvent }) => {
    return <DateRangePicker
        startDate={filter.show_start_date}
        onApply={(_event, picker) => handleEvent(_event, picker, date_type)}
        endDate={filter.show_end_date}
    >
        <input
            type="text"
            className="search-input"
            name="start_date"
            autoComplete="off"
            value={filter.start_date ? moment(filter.start_date).format('DD-MM-YYYY') : ''}

        ></input>
        <input
            type="text"
            className="search-input"
            name="end_date"
            autoComplete="off"
            value={filter.end_date ? moment(filter.end_date).format('DD-MM-YYYY') : ''}

        ></input>
    </DateRangePicker>
}

class SearchComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: {
                collected_at: {
                    show_start_date: new Date(),
                    show_end_date: new Date(),
                },
                received_at: {
                    show_start_date: new Date(),
                    show_end_date: new Date(),
                },
                reported_at: {
                    show_start_date: new Date(),
                    show_end_date: new Date(),
                },
                uploaded_at: {
                    show_start_date: new Date(),
                    show_end_date: new Date(),
                },
                conducted_at: {
                    show_start_date: new Date(),
                    show_end_date: new Date(),
                },
            },
            tags: {},
        }
    }

    renderTags = (tags) => {
        let options = [];
        (Object.keys(tags)).map((tag, i) => {
            if (tags[tag] !== '' && !(_.isEmpty(tags[tag]))) {
                options.push(<span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>)
            } else {
                return false
            }
        })
        return options;
    }
    removeFilter = (e) => {
        let { filter, tags } = this.state;
        let filterData = {}
        if (e.target.dataset.tag !== 'patient_name') {
            filter[e.target.dataset.tag] = {
                show_start_date: new Date(),
                show_end_date: new Date(),
            };
        } else {
            filter[e.target.dataset.tag] = '';
        }

        Object.keys(filter).map((data) => {
            if (data !== 'patient_name') {
                filterData[`${data}_start_date`] = filter[data].start_date
                filterData[`${data}_end_date`] = filter[data].end_date
            } else {
                filterData[data] = filter[data];
            }
        });
        tags[e.target.dataset.tag] = '';
        this.setState({ filter, tags });
        this.props.onSearch(filterData)
    }
    handleFilterChange = (e) => {
        let { filter } = this.state;
        filter[e.target.name] = e.target.value
        this.setState({ filter })
    }
    resetSearch = () => {
        this.setState({
            filter: {
                collected_at: {
                    show_start_date: new Date(),
                    show_end_date: new Date(),
                },
                received_at: {
                    show_start_date: new Date(),
                    show_end_date: new Date(),
                },
                reported_at: {
                    show_start_date: new Date(),
                    show_end_date: new Date(),
                },
                uploaded_at: {
                    show_start_date: new Date(),
                    show_end_date: new Date(),
                },
                conducted_at: {
                    show_start_date: new Date(),
                    show_end_date: new Date(),
                },
            },
            tags: {},
        });
        window['searchToggle']();
        this.props.onSearch({})
    }
    handleEvent = (_event, picker, date_type) => {
        let { filter } = this.state;
        filter[date_type].show_start_date = picker.startDate._d;
        filter[date_type].show_end_date = picker.endDate._d;
        filter[date_type].start_date = (filter[date_type].show_start_date) ? moment(filter[date_type].show_start_date).format('YYYY-MM-DD') : '';
        filter[date_type].end_date = (filter[date_type].show_end_date) ? moment(filter[date_type].show_end_date).format('YYYY-MM-DD') : '';
        this.setState({ filter });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        let { tags, filter } = this.state;
        let filterData = {}
        Object.keys(filter).map((data) => {
            if (data !== 'patient_name') {
                tags[data] = (filter[data].start_date && filter[data].end_date) ? moment(filter[data].start_date).format('DD-MM-YYYY') + ' to ' + moment(filter[data].end_date).format('DD-MM-YYYY') : '';
                filterData[`${data}_start_date`] = filter[data].start_date
                filterData[`${data}_end_date`] = filter[data].end_date
            } else {
                filterData[data] = filter[data];
                tags.patient_name = filter.patient_name || ''
            }
        });
        this.setState({ tags })
        window['searchToggle']();
        this.props.onSearch(filterData)
    }

    render() {
        const { t } = this.props;
        return <div className="search-wrapper">
            <label>{t('search')}</label>
            <div className="search-box clearfix">
                {this.state.tags && this.renderTags(this.state.tags)}
                <span className="search-box-icon"></span>
                <div className="search-container">
                    <form onSubmit={this.handleSubmit}>
                        <ul>
                            {this.props.list_type !== 'uploaded' && <>
                                <li>
                                    <span>{t('collected_date')}</span>
                                    <DateRangePickerComponent
                                        date_type="collected_at"
                                        filter={this.state.filter.collected_at}
                                        handleEvent={this.handleEvent}
                                    />
                                </li>
                                <li>
                                    <span>{t('received_date')}</span>
                                    <DateRangePickerComponent
                                        date_type="received_at"
                                        filter={this.state.filter.received_at}
                                        handleEvent={this.handleEvent}
                                    />
                                </li>
                            </>}
                            {this.props.list_type === 'uploaded' && <>
                                <li>
                                    <span>{t('uploaded_date')}</span>
                                    <DateRangePickerComponent
                                        date_type="uploaded_at"
                                        filter={this.state.filter.uploaded_at}
                                        handleEvent={this.handleEvent}
                                    />
                                </li>
                                <li>
                                    <span>{t('conducted_date')}</span>
                                    <DateRangePickerComponent
                                        date_type="conducted_at"
                                        filter={this.state.filter.conducted_at}
                                        handleEvent={this.handleEvent}
                                    />
                                </li>
                            </>}
                            {this.props.list_type === "completed" && <li>
                                <span>{t('reported_date')}</span>
                                <DateRangePickerComponent
                                    date_type="reported_at"
                                    filter={this.state.filter.reported_at}
                                    handleEvent={this.handleEvent}
                                />
                            </li>}
                            <li>
                                <span>{t('patient_name')}</span>
                                <input
                                    type="text"
                                    value={this.state.filter.patient_name}
                                    onChange={this.handleFilterChange}
                                    name="patient_name"
                                ></input>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    onClick={this.resetSearch}
                                    className="btn btn-secondary"
                                >
                                    {t('reset')}
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    {t('search')}
                                </button>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    }
}
export default (withTranslation('components/Covid/SearchComponent')(SearchComponent))