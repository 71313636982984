import React from 'react';
import BarGraph from '../Graph/BarGraph';
import { withTranslation } from 'react-i18next';

class PatientAddedGraph extends React.Component {
    handlePatientAdded = (view) => {
        this.props.renderDashboardGraph('patient_added', view)
    }

    render() {
        const { t } = this.props;
        return (
            <BarGraph 
                barColor="#F9F336"
                barLabel={t("new_patients_onboarded")}
                yScaleLabel={t("no_of_patients")}
                datasetLabels={this.props.patient_added_graph_data.x_axis_label}
                datasetCounts={this.props.patient_added_graph_data.counts}
                handleViewChange={this.handlePatientAdded}
                t={t}
            />
        )
    }
}
export default (withTranslation('components/Dashboard/PatientAddedGraph')(PatientAddedGraph))