import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import * as PriceListAction from '../../actions/priceListAction';
import DataGrid from '../../components/DataGrid';
import { withTranslation } from 'react-i18next';

class RadiologyTestPriceListPage extends React.Component {

    columns = () => {
        let { t } = this.props;
        return [{
            dataField: 'id',
            text: t('id'),
            hidden: true
        }, {
            dataField: 'test_name',
            text: t('test_name'),
        }, {
            dataField: 'current_price',
            text: t('price'),
        }, {
            dataField: 'future_price',
            text: t('future_price'),
            formatter: this.priceFormatter
        }, {
            dataField: 'update_date',
            text: t('price_update_date'),
            formatter: this.priceFormatter
        }];
    }

    priceFormatter = (cell, row) => {
        if (!cell) {
            return '--';
        }
        return cell
    }

    constructor(props) {
        super(props);

        this.props.paginate();
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === 'search') {
            page = 1;
        }

        this.props.paginate(page, sizePerPage, { sortField, sortOrder }, searchText);
    }

    render() {
        let { data, meta, t } = this.props;

        return (<React.Fragment>
            <Helmet>
                <title>{t('radiology_tests')}</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">{t('radiology_tests')}</h4>
                </div>
            </div>
            <div className="content-wrapper">
                <DataGrid
                    columns={this.columns()}
                    data={data}
                    meta={meta}
                    handleTableChange={this.handleTableChange}
                />
            </div>
        </React.Fragment>);
    }
}

const mapStateToProps = (state) => ({
    ...state.price_list
});

const mapActionsToProps = ({
    paginate: PriceListAction.RadiologyTestList
})

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/pricing/RadiologyTestPriceListPage')(RadiologyTestPriceListPage));