import React from 'react';
import { getDateFormat } from '../../../../../utils/helpers'
import { withTranslation } from 'react-i18next';

class ViewSurgicalHistory extends React.Component {
    render() {
        let { item } = this.props;
        const { t } = this.props;
        return (
            <React.Fragment>
                <ul className="prescription-filed-details margin-bottom auto-width">
                    <li>{t('procedure')} :
                        <span>{item.procedure ? item.procedure : '--'}</span>
                    </li>
                    <li>{t('date')} :
                        <span>{item.date ? getDateFormat(item.date) : '--'}</span>
                    </li>
                    <li>{t('comment')}:
                        <span>{item.comment ? item.comment : '--'}</span>
                    </li>
                </ul>
            </React.Fragment>)
    }
}
export default (withTranslation('components/Prescription/History/components/SurgicalHistory/ViewSurgicalHistory')(ViewSurgicalHistory))
