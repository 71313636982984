import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';

export const Search = ({ filter, tags, removeFilter, onSerch, handleChange, validator, onReset, handleEvent = null, t }) => {
    return <div className="search-wrapper mt-3">
        <label>{t('search')}</label>
        <div className="search-box clearfix">
            {(Object.keys(tags)).map((tag) => {
                if (tags[tag] !== '' && !(isEmpty(tags[tag]))) {
                    return <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={removeFilter.bind(this)}> x </span> </span>
                }
                return <></>
            })
            }
            <span className="search-box-icon"></span>

            <div className="search-container">
                <form onSubmit={onSerch}>
                    <ul>
                        <li>
                            <span>{t('patient_name')}</span>
                            <input type="text" value={filter.patient_name} onChange={handleChange} name="patient_name"></input>
                        </li>
                        <li>
                            <span>{t('contact_number')}</span>
                            <input type="tel" value={filter.contact_number} onChange={handleChange} name="contact_number"></input>
                            {validator.message(t('contact_number'), filter.contact_number, 'size:10')}
                        </li>
                        {handleEvent && <li>
                            <span>{t('date')}</span>
                            <DateRangePicker startDate={filter.show_start_date} onApply={handleEvent} endDate={filter.show_end_date} >
                                <input type="text" className='search-input' value={filter.start_date} name="start_date" autoComplete='off'></input>
                                <input type="text" className='search-input' value={filter.end_date} name="end_date" autoComplete='off'></input>
                            </DateRangePicker>
                        </li>}
                        <li>
                            <button type="button" onClick={onReset} className="btn btn-secondary">{t('reset')}</button>
                            <button type="submit" className="btn btn-primary">{t('search')}</button>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    </div>
}

export default (withTranslation('components/PharmacyReminder/Search')(Search));