import React from 'react';

export const Blood = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>{t('blood.blood')}</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                <input type="checkbox" id='anemia' name='anemia' onChange={e => handleChange(e, 'blood')} checked={checkIfProblemExist('blood', 'anemia') ? 'checked' : ''} />
                <label htmlFor='anemia'>{t('blood.anemia')}</label>
                <input type="checkbox" id='clots' name='clots' onChange={e => handleChange(e, 'blood')} checked={checkIfProblemExist('blood', 'clots') ? 'checked' : ''} />
                <label htmlFor='clots'>{t('blood.clots')}</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('blood.comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('blood.type_here')} onChange={e => handleChange(e, 'blood')} value={getCategoryComment('blood') ? getCategoryComment('blood') : ''}></textarea>
    </React.Fragment>
}