import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ButtonToolbar } from 'react-bootstrap';
import AirlineService from '../../services/airlineService';
import DataGrid from '../../components/DataGrid';
import * as Helper from '../../utils/helpers';
import { connect } from 'react-redux';
import SearchFilter from '../../components/Airline/SearchFilter';
import { hasRole } from '../../utils/auth';
import { withTranslation } from 'react-i18next';

export class PastScanRecordPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filters: null,
            paginateData: null,
            isLoading: false,
        }
    }

    columns = () => {
        let { t } = this.props;
        return [{
            dataField: "timestamp",
            text: "Scanned Date",
            formatter: (cell) => {
                return Helper.getDateTimeFormat(cell);
            }
        },
        {
            dataField: 'patient_name',
            text: 'Patient Name',
            sort: false,
        },
        {
            dataField: 'user_name',
            text: 'Assistant Name',
            hidden: hasRole(this.props.selected_profile, ['airline-owner']) ? false : true,
            sort: false,
        },
        {
            dataField: 'action',
            text: 'Action',
            isDummyField: true,
            formatter: this.actionFormatter
        }];
    }

    actionFormatter = (cell, row) => {
        return <ButtonToolbar>
                <Link size="sm" to={"/authority-access/patients/" + row.patient_id} title="View"><span className="icon file"></span></Link>
            </ButtonToolbar>;
    }

    componentDidMount(){
        this.handleTableChange('search', {page: 1, sizePerPage: 25 });
    }

    handleTableChange = ( type, { page, sizePerPage, sortField, sortOrder, searchText } ) => {
		if (type === "search") {
			page = 1;
        }
        let filter = this.state.filters;

        this.setState({
            isLoading: true
        })

        AirlineService.getScannedPatients({ page, sizePerPage, sortField, sortOrder, searchText, filter })
        .then(response => {
            let paginate = response.data;

            this.setState({
                paginateData: paginate,
                isLoading: false
            }) 
        })
        .catch(error => {
            if (error.response && error.response.status) {
                Notification.show('error', error.response.data)
            }

            this.setState({
                isLoading: false
            })
        });
	};

    handleFilterSubmit = (filters) => {
        this.setState({
            filters
        }, () => {
            this.handleTableChange('search', {page: 1, sizePerPage: 25 });
        });
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Past Scan Record</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title">Past Scan Record</h4>
                        <SearchFilter 
                            selected_profile={this.props.selected_profile}
                            handleSubmit={this.handleFilterSubmit} 
                        />
                    </div>
                </div>
                <div className="content-wrapper">
                    <DataGrid
                        columns={this.columns()}
                        data={this.state.paginateData ? this.state.paginateData.data : []}
                        meta={this.state.paginateData ? this.state.paginateData.meta : {}}
                        handleTableChange={this.handleTableChange}
                        loading={this.state.isLoading}
                        noDataIndication="No Record Found"
                    />
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    selected_profile: state.selected_user_profile,
});

export default connect(mapStateToProps, null)(withTranslation('pages/airline/PastScanRecordPage')(PastScanRecordPage))
