import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Button, Tabs, Tab } from 'react-bootstrap';
import { getDateFormat, patientInsurance } from '../../utils/helpers';
import DataGrid from "../../components/DataGrid";
import * as CovidCenterActions from '../../actions/covidCenterAction';
import _ from 'lodash';
import CovidCenterService from '../../services/covidCenterService';
import * as loaderAction from '../../actions/loaderAction';
import * as QueueAction from '../../actions/queueAction';
import Notification from '../../utils/notification';
import PrintButton from '../../components/PrintButton';
import PrintConsentPage from '../../components/pdf/PrintConsentPage';
import { withTranslation } from 'react-i18next';

class CovidTestPatientsReportPage extends React.Component {
    constructor(props) {
        super(props);
        const { params: { type } } = this.props.match;
        this.state = {
            type: type,
            filter: {
                type: 'registered'
            }
        };
    }
    componentDidMount() {
        this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: this.state.filter });
    }
    columns = () => {
        let { t } = this.props;
        return [{
            dataField: 'created_at',
            text: t('request_date'),
            formatter: this.dateTimeFormat,
            style: { width: '120px' }
        }, {
            text: t('patient_name'),
            dataField: 'name'
        }, {
            text: t('gender'),
            dataField: 'gender',
            formatter: this.genderFormatter
        }, {
            text: t('date_of_birth'),
            dataField: 'date_of_birth',
            formatter: this.dateTimeFormat,
            style: { width: '120px' }
        }, {
            text: t('contact_number'),
            dataField: 'contact'
        }, {
            text: t('email'),
            dataField: 'email'
        }, {
            text: t('insurance_type'),
            dataField: 'insurance_type',
            formatter: this.insuranceTypeFormatter
        }, {
            dataField: 'actions',
            text: t('actions'),
            isDummyField: true,
            formatter: this.actionFormatter,
            style: { 'min-width': '350px' }
        }];
    };
    dateTimeFormat = (cell, row) => {
        return getDateFormat(cell)
    }

    genderFormatter = (cell, row) => {
        return _.capitalize(cell)
    }

    insuranceTypeFormatter = (cell, row) => {
        return cell ? patientInsurance(cell) : ""
    }

    actionFormatter = (cell, row) => {
        let { t } = this.props;
        return <React.Fragment className="pul-right">
            {this.state.filter.type === "registered" && <Button variant="primary" className='ml-2 height-30' onClick={e => this.handleQueueClick(row)}>
                {t('add_to_queue')}
            </Button>}
            {this.state.filter.type === "unregistered" && <Button variant="primary" className='ml-2 height-30' onClick={e => this.addPatientHandle(row)}>
                {t('add_patient')}
            </Button>}
            <PrintButton
                content={row}
                component={PrintConsentPage}
                title={t('print_consent')}
            />
            <Button variant="danger" className='ml-2 height-30' onClick={e => this.remove(row.id)}>
                {t('remove')}
            </Button>
        </React.Fragment>
    };

    _onSelect = (selectedKey) => {
        this.setState({
            filter: {
                type: selectedKey
            }
        }, () => {
            this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: this.state.filter });
        })
    };

    remove = (id) => {
        this.props.showLoader();
        CovidCenterService.deleteCovitTestPatient(id).then(resp => {
            this.props.hideLoader();
            Notification.show('success', { message: this.props.t('success') });
            this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: this.state.filter });
        }).catch(error => {
            this.props.hideLoader();
            if (error.response && error.response.status) {
                Notification.show('error', error.response.data);
            }
        });
    };

    handleQueueClick(row) {
        const queueData = {
            patient_id: row.patient_id,
            appointment_type: 'queue',
            prescription_id: '',
        };

        this.props.createQueue(queueData, this.props.history, 'covid-center');
        CovidCenterService.deleteCovitTestPatient(row.id)
    }

    addPatientHandle(row) {
        this.props.history.push({
            pathname: '/patients/create',
            state: row
        });
    }

    render() {
        let { data, meta } = this.props.covid_reports;
        const { t } = this.props;
        return <React.Fragment>
            <Helmet>
                <title>{t('patients_list')}</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">{t('patients_list')}</h4>
                </div>
            </div>
            <div className="content-wrapper">
                <Tabs defaultActiveKey={"registered"} onSelect={this._onSelect}>
                    <Tab eventKey="registered" title={t('registered_patient')} style={{ width: "100%" }}>
                        {data && <DataGrid
                            columns={this.columns()}
                            data={data}
                            meta={meta}
                            noDataIndication={t('no_record')}
                        />}
                    </Tab>
                    <Tab eventKey="unregistered" title={t('unregistered_patient')} style={{ width: "100%" }}>
                        {data && <DataGrid
                            columns={this.columns()}
                            data={data}
                            meta={meta}
                            noDataIndication={t('no_record')}
                        />}
                    </Tab>
                </Tabs>
            </div>
        </React.Fragment>
    }
}
const mapStateToProps = (state) => ({
    covid_reports: state.covid_reports,
});

const mapActionsToProps = {
    paginate: CovidCenterActions.paginateCovidTestForm,
    showLoader: loaderAction.loaderShow,
    hideLoader: loaderAction.loaderHide,
    createQueue: QueueAction.create
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/covid-center/CovidTestPatientsPage')(CovidTestPatientsReportPage));