import React from 'react';
import { withTranslation } from 'react-i18next';

const ShowRadiology = ({ radiology, t }) => {
    return (
        radiology &&
        <li>
            <div>{t('test_name')}: <span>{radiology.test_name} </span></div>
            <div>{t('test_category')}: <span>{radiology.test_category}</span></div>
        </li>)
}
export default (withTranslation('components/Prescription/ShowRadiologyBlock')(ShowRadiology))