import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataGrid from "../../components/DataGrid";
import * as MedicalCertificateAction from "../../actions/medicalCertificateAction";
import _ from 'lodash';
import { getDateFormat } from "../../utils/helpers";
import queryString from 'query-string';
import MedicalCertificateService from "../../services/medicalCertificateService";
import { withTranslation } from 'react-i18next';

const AddCertificateGrid = ({ handleCreateClick, t }) => {
    const columns = () => {
        return [
            {
                dataField: "title",
                text: t('certificate_name'),
                sort: false,
            },
            {
                dataField: "actions",
                text: t('action'),
                headerClasses: 'text-center',
                classes: 'text-center',
                isDummyField: true,
                formatter: (_cell, row) => {
                    return <button onClick={handleCreateClick.bind(this, row.route)} className="btn btn-outline-primary" >{t('create')}</button>
                }
            }
        ]
    }

    const certificates = [{
        title: t('return_to_school'),
        route: 'return_to_school'
    }, {
        title: t('return_to_work'),
        route: 'return_to_work'
    }, {
        title: t('work_excuse'),
        route: 'work_excuse'
    }, {
        title: t('follow_up'),
        route: 'follow_up'
    }, {
        title: t('referral_letter'),
        route: 'referral_letter'
    }
    ]

    return (
        <DataGrid
            pagination={false}
            columns={columns()}
            data={certificates}
        />
    )
}

class ListCertificatePage extends React.Component {
    constructor(props) {
        super(props);

        const params = this.props.match.params;
        const queryParams = queryString.parse(this.props.location.search)

        this.state = {
            patient_id: params.patientId,
            filter: {
                patient_id: params.patientId,
                status: "completed"
            },
            issue: _.has(queryParams, 'issue') ? true : false,
        }

        this.props.getPatientCertificate({ sortBy: { sortField: "updated_at", sortOrder: "desc" }, filter: this.state.filter });
    }

    columns = () => {
        let { t } = this.props;
        return [
            {
                dataField: "medical_certificate_type",
                text: t('certificate_name'),
                sort: true,
                formatter: (_cell, row) => {
                    return _.startCase(row.medical_certificate_type);
                }
            },
            {
                dataField: "updated_at",
                text: t('issued_date'),
                sort: true,
                formatter: (_cell, row) => {
                    return getDateFormat(row.updated_at);
                }
            },
            {
                dataField: "actions",
                text: t('download'),
                headerClasses: 'text-center',
                classes: 'text-center',
                isDummyField: true,
                formatter: (_cell, row) => {
                    return <Link className="" onClick={this.linkBtnClick.bind(this, row)} > </Link>
                }
            }
        ];
    };

    linkBtnClick(data) {
        MedicalCertificateService.downloadAndPrint(data.medical_certificate_id).then(resp => {
            this.setState({ isloading: false });
            window.open(resp.data.link);
        }).catch(error => {
            this.setState({ isloading: false });
            Notification.show('error', error.response.data);
        });
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }

        const filter = this.state.filter;
        this.props.getPatientCertificate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    };

    handleAddCeritifcate = (action) => {
        this.props.history.push('/patients/' + this.state.patient_id + '/medical-certificate/' + action);
    };

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('certificate')}</title>
                </Helmet>
                <section className="container-fluid">
                    <div className="row">
                        <div className="col-md-24 mt-3 requester-tabs">
                            <Tabs defaultActiveKey={this.state.issue ? 2 : 1} onSelect={k => k}>
                                <Tab eventKey={1} title={t('add_certificate')}>
                                    <div className="content-wrapper">
                                        <AddCertificateGrid
                                            handleCreateClick={this.handleAddCeritifcate}
                                            t={t}
                                        />
                                    </div>
                                </Tab>
                                <Tab eventKey={2} title={t('issued_certificate')}>
                                    <div className="content-wrapper issued-certi">
                                        {!_.isEmpty(this.props.medical_certificates) && <DataGrid
                                            columns={this.columns()}
                                            data={this.props.medical_certificates.data}
                                            meta={this.props.medical_certificates.meta}
                                            handleTableChange={this.handleTableChange}
                                            noDataIndication={t('certificate_not_issued')}
                                        />}
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        medical_certificates: state.medical_certificate,
    };
}

const mapActionsToProps = {
    getPatientCertificate: MedicalCertificateAction.list,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/certificate/ListCertificatePage')(ListCertificatePage));