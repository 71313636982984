import React from 'react';
import PatientService from '../services/patientService';
import * as loaderAction from '../actions/loaderAction';
import { connect } from 'react-redux';
import Notification from '../utils/notification';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next';

class AttachHistoricDataModal extends React.Component {
    state = {
        historic_data: '',
        selected_document: ''
    }
    componentDidMount() {
        if (!this.state.historic_data) {
            this._initialServce()
        }
    }

    columns = () => {
        return [{
            dataField: 'file_name',
            text: ''
        }, {
            dataField: 'actions',
            text: '',
            isDummyField: true,
            formatter: this.props.actionFormatter
        }]
    }

    _initialServce = () => {
        let { patient: { first_name, middle_name, last_name } } = this.props
        const filter = {
            search: `${first_name}_${middle_name}_${last_name}`
        }
        this.props.showLoader()
        PatientService.getUploadedHistoricData({ filter }).then(resp => {
            this.setState({
                historic_data: resp.data
            })
            this.props.hideLoader()
        }).catch(err => {
            Notification.show('error', err.response.data)
            this.props.hideLoader()
        })
    }

    handleSelectedFile = (document_id) => {
        this.setState({ selected_document: document_id })
    }

    attachPrescription = (e) => {
        e.preventDefault();
        let { t } = this.props;
        if (!this.state.selected_document) {
            Notification.show('error', { message: t('select_record_to_attach') });
            return false
        }
        this.props.showLoader()
        PatientService.attachPrescriptionHistory(this.props.patient.patient_id, this.state.selected_document).then(resp => {
            Notification.show('success', { message: t('record_attached') })
            this.props.hideLoader();
            this.props.handleShowHideModal();
        }).catch(err => {
            Notification.show('error', err.response.data)
            this.props.hideLoader()
        })

    }

    render() {
        const { t } = this.props;
        return <Modal size='lg' centered aria-labelledby="contained-modal-title-vcenter" show={this.props.show} onHide={this.props.handleShowHideModal} backdrop="static"
            keyboard={false}>
            <Modal.Header>
                <h6>{t('pdf_list')}</h6>
            </Modal.Header>
            <Modal.Body>
                <table className="table table-bordered">
                    <thead>
                    </thead>
                    <tbody>
                        {this.state.historic_data && !isEmpty(this.state.historic_data.data) ? this.state.historic_data.data.map(row =>
                            <tr>
                                <td><input className="form-check-input mb-3" type="radio" name="radioGroup" id={row.document_uuid} onClick={() => this.handleSelectedFile(row.document_uuid)} /><label className="form-check-label mb-3" for={row.document_uuid} /></td>
                                <td>{row.file_name}</td>
                                <td>{row.password_hash || '--'}</td>
                                <td>
                                    <Link to="#" onClick={this.props.actionFormatter.bind(this, row.document_uuid)} title="View in New Tab"><FontAwesomeIcon icon={faExternalLinkAlt} className="c-mb-2"/></Link>
                                </td>
                            </tr>
                        ) : <tr>{t('no_record')}</tr>}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={this.props.handleShowHideModal}>
                    {t('close')}
                </Button>
                {this.state.historic_data && !isEmpty(this.state.historic_data.data) && <Button variant="primary" onClick={this.attachPrescription}>
                    {t('attach')}
                </Button>}
            </Modal.Footer>
        </Modal>
    }

}

const mapActionsToProps = {
    showLoader: loaderAction.loaderShow,
    hideLoader: loaderAction.loaderHide
};

export default connect(null, mapActionsToProps)(withTranslation('components/AttachHistoricDataModal')(AttachHistoricDataModal));