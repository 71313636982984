import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getDateFormat, getAgeString } from '../../utils/helpers';
import DataGrid from "../../components/DataGrid";
import * as CovidCenterActions from '../../actions/covidCenterAction';
import { capitalize } from 'lodash';
import SearchComponent from '../../components/Covid/SearchComponent';
import CovidCenterService from '../../services/covidCenterService';
import * as loaderAction from '../../actions/loaderAction';
import { Link } from "react-router-dom";
import { hasRole } from '../../utils/auth';
import Notification from '../../utils/notification';
import { withTranslation } from 'react-i18next';

class CovidPatientDetailsPage extends React.Component {
    constructor(props) {
        super(props);
        const { params: { type } } = this.props.match;

        this.state = {
            type: type,
            exportCSV: hasRole(this.props.selected_user_profile, ['covid-center-owner']),
            filter: {
                status: 'fulfilled'
            },
        };
    }
    componentDidMount() {
        this.getCompletedReport()
    }

    getCompletedReport = () => {
        this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: this.state.filter });
    }

    columns = () => {
        let { t } = this.props;
        return [{
            text: t('patient_name'),
            dataField: 'patient_name',
        }, {
            text: t('gender'),
            dataField: 'gender',
            formatter: (cell, row) => capitalize(cell)
        }, {
            text: t('dob_age'),
            dataField: 'dob',
            formatter: this.dobFormatter
        }, {
            text: t('email'),
            dataField: 'patient_email',
        }, {
            dataField: 'collected_at',
            text: t('date_collected'),
            formatter: this.dateTimeFormat,
        }, {
            dataField: 'received_at',
            text: t('date_received'),
            formatter: this.dateTimeFormat,
        }, {
            dataField: 'reported_at',
            text: t('date_reported'),
            formatter: this.dateTimeFormat,
        }, {
            text: t('conducted_by'),
            dataField: 'conducted_by',
        }, {
            text: t('test_result'),
            dataField: 'result',
            formatter: (cell, row) => capitalize(cell)
        }, {
            text: t('reason_for_test'),
            dataField: 'test_reason',
            formatter: this.reasonFormatter
        }, {
            dataField: 'actions',
            text: t('actions'),
            isDummyField: true,
            formatter: this.actionFormatter
        }];
    };

    dobFormatter = (cell, row) => {
        if (cell) {
            return <React.Fragment>
                {getDateFormat(cell)} <span className='text-muted'>[{getAgeString(cell)}]</span>
            </React.Fragment>
        }
        return '-';
    }
    reasonFormatter = (cell, row) => {
        if (cell) {
            return <React.Fragment>
                {capitalize(cell)} <span className='text-muted'>: {row.test_reason_text}</span>
            </React.Fragment>
        }
        return '-';
    }
    dateTimeFormat = (cell, row) => {
        return getDateFormat(cell)
    }
    actionFormatter = (cell, row) => {
        return <React.Fragment>
            <Link to="#" className="ml-2" onClick={e => this.download(e, row.id)}><span className="icon file"></span></Link>
        </React.Fragment>
    };
    download = (e, id) => {
        e.preventDefault();
        this.props.loaderShow();
        CovidCenterService.downloadReports(id).then(resp => {
            if (resp.data && resp.data.download_url) {
                window.open(resp.data.download_url, "_blank")
            }
            this.props.loaderHide()
        }).catch(err => {
            this.props.loaderHide()
            Notification.show('error', err.response.data);
        })
    }
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: this.state.filter });
    }
    onSearch = (filter) => {
        let filterData = {
            ...filter,
            ...this.state.filter
        }
        this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: filterData });
    }

    render() {
        let { data, meta } = this.props.covid_reports;
        const { t } = this.props;
        return <React.Fragment>
            <Helmet>
                <title>{t('patient_details')}</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">{t('patient_details')}</h4>
                </div>
            </div>
            <SearchComponent onSearch={this.onSearch} list_type="completed" />
            <div className="content-wrapper">
                {data && <DataGrid
                    columns={this.columns()}
                    data={data}
                    meta={meta}
                    handleTableChange={this.handleTableChange}
                    noDataIndication={t("no_record")}
                    export_csv={this.state.exportCSV}
                    export_class="btn btn-default btn-outline-primary"
                />}
            </div>
        </React.Fragment>
    }
}
const mapStateToProps = (state) => ({
    covid_reports: state.covid_reports,
    selected_user_profile: state.selected_user_profile
});

const mapActionsToProps = {
    paginate: CovidCenterActions.paginate,
    loaderShow: loaderAction.loaderShow,
    loaderHide: loaderAction.loaderHide,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/covid-center/CovidPatientDetailsPage')(CovidPatientDetailsPage));