import React from 'react';
import ViewVital from './ViewVital';
import UrgentCareService from '../../../services/urgentCareService';
import VitalBlock from '../VitalBlock';
import * as ButtonComponents from '../Buttons';
import SimpleReactValidator from 'simple-react-validator';
import Notification from '../../../utils/notification';
import { hasRole } from '../../../utils/auth';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import * as HELPER from '../../../utils/helpers';

class VitalInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddVitalBlock: false,
            showAddButton: true,
            showSubmitButton: false,
            savedVitals: [],
            draftVital: {},
        }
        this.vitalValidator = new SimpleReactValidator({ locale: HELPER.getLanguage() });
    }

    componentDidMount() {
        this.getVitals();
    }

    getVitals = () => {
        UrgentCareService.getVital(this.props.prescriptionId).then(resp => {
            let savedVitals = resp.data.filter(vt => vt.status === "save")
            let draftVital = resp.data.filter(vt => vt.status === "draft")
            draftVital = draftVital.length ? draftVital[0] : {}
            this.setState({
                savedVitals: savedVitals,
                draftVital: draftVital
            }, () => {
                if (!_.isEmpty(draftVital))
                    this.handleVitalAdd()
            });

            this.props.hideLoader();
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    };

    handleRemoveVital = () => {
        this.setState({ showAddVitalBlock: false, showAddButton: true, showSubmitButton: false, draftVital: {} });
        this.props.handleIsPrescriptionEdited('vital', false);
    };

    handleVitalSubmit = (e, type = null) => {
        this.props.vitalSubmit(true, "Vital")
    }

    handleVitalAdd = (e, type = null) => {
        this.setState({ showAddVitalBlock: true, showAddButton: false, showSubmitButton: true });
    }

    handleVitalSaveAndNext = (e, type = null) => {
        if (_.isEmpty(this.state.vital) && (type === "save_and_next" || type === "draft_and_next")) {
            this.props.handleSaveAndNext();
            return false;
        }
        let status = ['save', 'save_and_next'].includes(type) ? "save" : "draft"

        this.setState(prevState => ({
            vital: {
                ...prevState.vital,
                status: status
            }
        }), () => {
            if (this.state.draftVital.id) {
                this.setState(prevState => ({
                    vital: {
                        ...prevState.vital,
                        id: this.state.draftVital.id
                    }
                }), () => {
                    this.addVital(type)
                })
            } else {
                this.addVital(type)
            }
        })
    }

    addVital = (type) => {
        if (!this.vitalValidator.allValid()) {
            this.vitalValidator.showMessages();
            this.forceUpdate();
            return false;
        }
        this.props.showLoader();
        UrgentCareService.createVital(this.props.prescriptionId, this.state.vital).then(resp => {
            this.props.hideLoader();
            if (type === "save_and_next" || type === "draft_and_next") {
                this.props.handleSaveAndNext();
            } else if (type === "save" || type === "draft") {
                this.handleRemoveVital();
                this.getVitals();
            }


        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }

    setVital = (vitalData) => {
        this.setState({ vital: vitalData })
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="content-wrapper lg-height">
                    <div className="content-wrapper padding-bottom-10">

                        {this.state.savedVitals && <ViewVital
                            vital={this.state.savedVitals}
                            patient_info={this.props.patient_info}
                        />}
                        {this.state.showAddVitalBlock && <VitalBlock
                            selected_user_profile={this.props.selected_user_profile}
                            patient_dob={this.props.patient_dob}
                            patient_gender={this.props.patient_gender}
                            showLoader={this.props.showLoader}
                            hideLoader={this.props.hideLoader}
                            handleSaveAndNext={this.props.handleSaveAndNext}
                            prescriptionId={this.props.prescriptionId}
                            patientId={this.props.patientId}
                            handleRedirect={this.props.handleRedirect}
                            handleIsPrescriptionEdited={this.props.handleIsPrescriptionEdited}
                            vitalSubmit={this.props.vitalSubmit}
                            handleRemoveVital={this.handleRemoveVital}
                            setVital={this.setVital}
                            draftVital={this.state.draftVital}
                        />}

                        {this.state.showSubmitButton && hasRole(this.props.selected_user_profile, ['junior-doctor', "associate-doctor", "doctor", "paramedic-doctor", "doctor-nurse"]) && <div className="bottom-btns">
                            <ButtonComponents.PrimaryButton
                                handleChange={(e) => this.handleVitalSaveAndNext(e, 'save')}
                                buttonType={"submit"}
                                buttonName={t("submit_vital")}
                            />

                            {hasRole(this.props.selected_user_profile, ["doctor-nurse"]) && <div className="bottom-btns">
                                <ButtonComponents.PrimaryButton
                                    handleChange={this.handleVitalSaveAndNext}
                                    buttonType={"draft"}
                                    buttonName={t('save_as_draft')}
                                    disabled={!this.state.showAddVitalBlock}
                                />
                            </div>}
                        </div>}

                        {this.state.showAddButton && hasRole(this.props.selected_user_profile, ['junior-doctor', "associate-doctor", "doctor", "paramedic-doctor", "doctor-nurse"]) && <div className="bottom-btns">
                            <ButtonComponents.PrimaryButton
                                handleChange={this.handleVitalAdd}
                                buttonType={"add"}
                                buttonName={t('Add_vital')}
                            />
                        </div>}
                    </div>
                </div>

                {hasRole(this.props.selected_user_profile, ['junior-doctor', "associate-doctor", "doctor", "paramedic-doctor"]) && <div className="bottom-btns">
                    <ButtonComponents.PrimaryButton
                        handleChange={this.handleVitalSaveAndNext}
                        buttonType={"save_and_next"}
                        buttonName={t('save_next')}
                    />
                </div>}
                {hasRole(this.props.selected_user_profile, ['junior-doctor', "associate-doctor", "doctor", "paramedic-doctor"]) && <div className="bottom-btns">
                    <ButtonComponents.PrimaryButton
                        handleChange={this.handleVitalSaveAndNext}
                        buttonType={"draft_and_next"}
                        buttonName={t('save_draft_next')}
                        disabled={!this.state.showAddVitalBlock}
                    />
                </div>}
                {hasRole(this.props.selected_user_profile, ['junior-doctor', "associate-doctor", "doctor", "paramedic-doctor"]) && <div className="bottom-btns">
                    <ButtonComponents.PrimaryButton
                        handleChange={this.handleVitalSaveAndNext}
                        buttonType={"draft"}
                        buttonName={t('save_as_draft')}
                        disabled={!this.state.showAddVitalBlock}
                    />
                </div>}
            </React.Fragment>
        )
    }
}
export default (withTranslation('components/UrgentCare/Vital/AddVital')(VitalInfo));
