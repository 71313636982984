import React from 'react';
import { getDosageType, getDurationType } from '../../utils/helpers';
import { capitalize } from 'lodash';
import { withTranslation } from 'react-i18next';

const ShowMedicineBlock = ({ medicine, type = null, t }) => {
    return (
        <React.Fragment>
            {medicine && <table className="table table table-responsive common-table">
                <thead>
                    <tr>
                        <th>{t('brand_name')}</th>
                        <th>{t('generic_name')}</th>
                        <th>Frequency</th>
                        <th>{t('meal')}</th>
                        <th>{t('duration')}</th>
                        <th>{t('dosage')}</th>
                        <th>Substitution</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td valign="top">{medicine.drug_name}</td>
                        <td valign="top">{medicine.drug_generic_name}</td>
                        <td valign="top">{medicine.dosage ? getDosageType(medicine.dosage) : '--'}</td>
                        <td valign="top">{medicine.meal ? capitalize(medicine.meal) : '--'}</td>
                        <td valign="top">{medicine.duration ? medicine.duration : '--'}
                            {medicine.duration_type ? getDurationType(medicine.duration_type) : '--'}</td>
                        <td valign="top">{medicine.quantity ? medicine.quantity : '--'}</td>
                        <td valign="top">{medicine.is_generic_allowed ? "Yes" : "No"} </td>
                    </tr>
                    <tr>
                        <td colSpan="7" className="text-left">
                            <span className="text-primary">{t('instructions')}: </span><span>{medicine.remarks ? medicine.remarks : "--"}</span>
                        </td>
                    </tr>
                    {type === 'current_medicine' && <tr>
                        <td colSpan="7" className="text-left">
                            <span className="text-primary">{t('side_effects_medication')}: </span><span>{medicine.side_effects ? medicine.side_effects : "--"}</span>
                        </td>
                    </tr>}
                </tbody>
            </table>}
        </React.Fragment>
    )
}
export default (withTranslation('components/Prescription/ShowMedicineBlock')(ShowMedicineBlock))