import React from 'react';

export const Eyes = ({ handleChange, checkIfProblemExist ,t }) => {
    return <React.Fragment>
        <h6>{t('eye.eyes')} </h6>
        <input type="checkbox" id='eyes-normal' name='eyes-normal' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'eyes-normal') ? 'checked' : ''} />
        <label htmlFor='eyes-normal'>{t('eye.normal')}</label>
    </React.Fragment>
}
export const EyesOptions = ({ handleChange, checkIfProblemExist ,t }) => {
    return <React.Fragment>
        <div className="options-details">
            {/* pupils light */}
            <input type="checkbox" id='eyes-pupils-reactive' name='eyes-pupils-reactive' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'eyes-pupils-reactive') ? 'checked' : ''} />
            <label htmlFor='eyes-pupils-reactive'>{t('eye.pupils_reactive_to_light')}</label>
            {/* redness */}
            <input type="checkbox" id='eyes-redness' name='eyes-redness' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'eyes-redness') ? 'checked' : ''} />
            <label htmlFor='eyes-redness'>{t('eye.redness')}</label>
            {/* discharge */}
            <input type="checkbox" id='eyes-discharge' name='eyes-discharge' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'eyes-discharge') ? 'checked' : ''} />
            <label htmlFor='eyes-discharge'>{t('eye.discharge')}</label>
            {/* swallen */}
            <input type="checkbox" id='eyes-swollen' name='eyes-swollen' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'eyes-swollen') ? 'checked' : ''} />
            <label htmlFor='eyes-swollen'>{t('eye.swollen')}</label>
            {/* lump */}
            <input type="checkbox" id='eyes-lump' name='eyes-lump' onChange={e => handleChange(e, 'eyes')} checked={checkIfProblemExist('eyes', 'eyes-lump') ? 'checked' : ''} />
            <label htmlFor='eyes-lump'>{t('eye.lump')}</label>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist ,t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('type_here')} onChange={e => handleChange(e, 'eyes')} disabled={checkIfProblemExist('eyes', 'eyes-normal') ? 'disabled' : ''} value={getCategoryComment('eyes') ? getCategoryComment('eyes') : ''}></textarea>
    </React.Fragment>
}