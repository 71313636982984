import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getDateFormat, getLanguage } from '../../utils/helpers';
import { hasRole } from '../../utils/auth';
import DataGrid from "../../components/DataGrid";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import _ from 'lodash';
import Notification from '../../utils/notification'
import * as PrescriptionAction from '../../actions/prescriptionAction';
import PrescriptionService from '../../services/prescriptionService';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import ViewFeeDetails from '../../components/ViewFeeDetails';
import { Tab, Tabs } from "react-bootstrap";
import PatientService from '../../services/patientService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next';

class ListPrescriptionFeesPage extends React.Component {
    columns = (selected_user_profile) => {
        let { t } = this.props;
        return [{
            text: t('date'),
            formatter: this.dateTimeFormat,
            dataField: 'date',
        }, {
            dataField: 'patient_id',
            text: t('patient_id'),
        }, {
            text: t('patient_name'),
            dataField: 'patient_name',
        }, {
            text: t('collected_by'),
            dataField: 'collected_by',
            formatter: this.displayValueFormatter,
            hidden: !hasRole(selected_user_profile, ['doctor', "associate-doctor"])
        }, {
            text: 'Currency',
            dataField: 'currency.name'
        }, {
            text: t('consultancy_fee'),
            dataField: 'consultancy_fee',
        }, {
            text: t('other_fee'),
            dataField: 'other_fee',
        }, {
            text: t('procedure_fee'),
            dataField: 'procedure_fee',
        }, {
            text: t('from_insurance'),
            dataField: 'from_insurance',
            hidden: !hasRole(selected_user_profile, ['doctor', "associate-doctor"])
        }, {
            text: t('cash_collected'),
            dataField: 'cash_collected',
            hidden: !hasRole(selected_user_profile, ['doctor', "associate-doctor"])
        }, {
            text: t('from_card'),
            dataField: 'from_card',
            hidden: !hasRole(selected_user_profile, ['doctor', "associate-doctor"])
        }, {
            text: t('from_online'),
            dataField: 'from_online',
            hidden: !hasRole(selected_user_profile, ['doctor', "associate-doctor"])
        }, {
            text: 'Balance',
            dataField: 'balance',
        }, {
            text: 'Received Balance',
            dataField: 'balance_received',
        }, {
            text: t('remark'),
            dataField: 'payment_remark',
            hidden: !hasRole(selected_user_profile, ['doctor', "associate-doctor"]),
            formatter: this.displayValueFormatter
        }, {
            text: t('total_amount'),
            formatter: this.totalAmountFormatter,
            csvFormatter: this.totalAmountFormatter,
            hidden: hasRole(selected_user_profile, ['doctor', "associate-doctor"]),
        }, {
            dataField: 'actions',
            text: t('actions'),
            isDummyField: true,
            formatter: this.actionFormatter,
            csvExport: false
        }]
    };

    displayValueFormatter = (cell, row) => {
        return cell ? cell : '--'
    }

    dateTimeFormat = (cell, row) => {
        return getDateFormat(row.date)
    }

    totalAmountFormatter = (cell, row) => {
        return (parseFloat(row.consultancy_fee) + parseFloat(row.other_fee) + (row.procedure_fee ? parseFloat(row.procedure_fee) : 0) + ((row && row.balance_received) ? parseFloat(row.balance_received) : 0)).toFixed(2)
    }

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({ locale: getLanguage() });
        this.state = {
            filter: {
                prescription_fee_status: "unpaid",
                start_date: "",
                end_date: "",
            },
            tags: {
                date_filter: "",
            },
            view_data: "",
            payment_remark: "",
            show_start_date: new Date(),
            show_end_date: new Date(),
            isloading: false,
        }
    }

    handleEvent = (_event, picker) => {
        let { show_start_date, show_end_date, filter, tags } = this.state;
        show_start_date = picker.startDate._d;
        show_end_date = picker.endDate._d;
        filter.start_date = (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '';
        filter.end_date = (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '';
        tags.date_filter = (picker.startDate._d && picker.endDate._d) ? moment(picker.startDate._d).format('DD-MM-YYYY') + ' to ' + moment(picker.endDate._d).format('DD-MM-YYYY') : '';
        this.setState({
            filter: filter,
            show_start_date: show_start_date,
            show_end_date: show_end_date,
            tags: tags,
        });
    }

    handleSearch = () => {
        window['searchToggle']();
        this.props.paginatePrescriptionFees({ sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: this.state.filter });
    }

    resetSearch = () => {
        const filter = this.state.filter;
        filter.start_date = "";
        filter.end_date = "";
        this.setState({
            filter: filter,
            show_start_date: new Date(),
            show_end_date: new Date(),
            tags: {
                date_filter: "",
            }
        });
        window['searchToggle']();
        this.props.paginatePrescriptionFees({ sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: this.state.filter });
    }

    removeFilter = (e) => {
        const filter = this.state.filter;
        const tags = this.state.tags;
        filter.type = ''
        if (e.target.dataset.tag === 'date_filter') {
            filter["start_date"] = '';
            filter["end_date"] = '';
        }
        tags[e.target.dataset.tag] = '';
        this.setState({ filter: filter, tags: tags });
        this.props.paginatePrescriptionFees({ sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: this.state.filter });
    }

    componentDidMount() {
        const filter = this.state.filter;
        this.props.paginatePrescriptionFees({ sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: filter });
    }

    getPdfLink = (feeId) => {
        this.setState({
            isloading: true
        });
        PatientService.getPatientFeeDownloadLink(feeId).then(resp => {
            this.setState({
                isloading: false
            });
            window.open(resp.data.link);
        }).catch(error => {
            this.setState({
                isloading: false
            });
            Notification.show('error', error.response.data);
        });

    }

    actionFormatter = (cell, row) => {
        if (row.status === "paid") {
            return (<React.Fragment>
                <a href onClick={e => this.getPdfLink(row.id)} title="View in New Tab">
                    <FontAwesomeIcon icon={faExternalLinkAlt} className="c-mb-2" />
                </a>
            </React.Fragment>);
        }
        return (
            <Link to="#" className="ml-2" onClick={e => this.getPatientFeeInfo(row.id)}><span className="icon file"></span></Link>
        );
    };

    getPatientFeeInfo = (fee_id) => {
        PrescriptionService.getPatientFeeInfoById(fee_id).then(resp => {
            this.setState({
                view_data: resp.data
            })
            const element = document.getElementById('view_fee_collection');
            element.scrollIntoView({ behavior: 'smooth' });
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        const filter = this.state.filter
        if (type === 'search') {
            page = 1;
        }
        this.props.paginatePrescriptionFees({ page, sizePerPage, sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: filter });
    }

    handleSubmit = (fee_id, data) => {
        this.setState({
            isloading: true
        });

        if (this.state.view_data.balance_received && this.state.view_data.balance_received > 0) { // to collect balance fees
            PrescriptionService.updatePatientBalanceFeeInfo(fee_id, data).then(resp => {
                Notification.show('success', { message: this.props.t('payment_done') });
                this.setState({
                    view_data: "",
                    payment_insurance_amount: "",
                    payment_cash_amount: "",
                    payment_online_amount: "",
                    status: "unpaid",
                    payment_remark: "",
                    isloading: false,
                })
                this.props.paginatePrescriptionFees({ sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: this.state.filter });
            }).catch(error => {
                this.setState({ isloading: false });
                Notification.show('error', error.response.data)
            })
        } else {
            PrescriptionService.updatePatientFeeInfo(fee_id, data).then(resp => {
                Notification.show('success', { message: 'Payment done.' });
                this.setState({
                    view_data: "",
                    payment_insurance_amount: "",
                    payment_cash_amount: "",
                    payment_online_amount: "",
                    status: "unpaid",
                    payment_remark: "",
                    isloading: false,
                })
                this.props.paginatePrescriptionFees({ sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: this.state.filter });
            }).catch(error => {
                this.setState({ isloading: false });
                Notification.show('error', error.response.data)
            })
        }

    }

    renderTags(tags) {
        let options;
        return (Object.keys(tags)).map((tag) => {
            if (tags[tag] !== '' && !(_.isEmpty(tags[tag]))) {
                options = <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
            } else {
                return false
            }
            return options
        })
    }

    onTabChange = key => {
        let { filter } = this.state;
        filter.prescription_fee_status = key === "pending_fees" ? 'unpaid' : 'paid'
        this.setState({
            activeKey: key,
            filter
        })
        this.props.paginatePrescriptionFees({ sortBy: { sortField: "created_at", sortOrder: "desc" }, filter });
    }

    clearViewData = () => {
        this.setState({ view_data: "" })
        const element = document.getElementById('list_fee_collection');
        element.scrollIntoView({ behavior: 'smooth' });
    }

    render() {
        let { data, meta } = this.props.prescription_fees.paginate;
        const { view_data, tags } = this.state
        const { t } = this.props;
        const dataTable = <DataGrid
            columns={this.columns(this.props.selected_user_profile)}
            data={data}
            noDataIndication={t('no_record')}
            handleTableChange={this.handleTableChange}
            meta={meta}
            pagination={true}
            export_csv
            export_class="btn btn-default btn-outline-primary"
        />
        return (<React.Fragment>
            {this.state.isloading && this.state.isloading && <div className="loading">Loading</div>}
            <Helmet>
                <title>{t('consultation_fee')}</title>
            </Helmet>
            <section className="container-fluid" id="list_fee_collection">
                <div class="row">
                    <div className="col-md-24 requester-tabs">
                        <h4 className="page-title">{t('consultation_fee')}</h4>
                    </div>
                    <div className="col-md-24 mt-3 request-pending">
                        <Tabs defaultActiveKey="pending_fees"
                            onSelect={this.onTabChange}>
                            <Tab eventKey="pending_fees" title={t('pendings')}>
                                {hasRole(this.props.selected_user_profile, ['doctor', 'associate-doctor']) &&
                                    <React.Fragment>
                                        <div className="search-wrapper mt-3">
                                            <label>{t('search')}</label>
                                            <div className="search-box clearfix">
                                                {tags && this.renderTags(tags)}
                                                <span className="search-box-icon"></span>
                                                <div className="search-container">
                                                    <ul>
                                                        <li>
                                                            <span>{t('select_date')}</span>
                                                            <DateRangePicker
                                                                startDate={this.state.show_start_date}
                                                                onApply={this.handleEvent}
                                                                endDate={this.state.show_end_date}
                                                            >
                                                                <input
                                                                    type="text"
                                                                    className="search-input"
                                                                    name="start_date"
                                                                    autoComplete="off"
                                                                    value={this.state.filter.start_date ? moment(this.state.filter.start_date).format('DD-MM-YYYY') : ''}

                                                                ></input>
                                                                <input
                                                                    type="text"
                                                                    className="search-input"
                                                                    name="end_date"
                                                                    autoComplete="off"
                                                                    value={this.state.filter.end_date ? moment(this.state.filter.end_date).format('DD-MM-YYYY') : ''}

                                                                ></input>
                                                            </DateRangePicker>
                                                        </li>
                                                        <li>
                                                            <button type="button" className="btn btn-secondary" onClick={this.resetSearch}>{t('reset')}</button>
                                                            <button type="submit" class="btn btn-primary" onClick={this.handleSearch}>{t('search')}</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                {data && <div className="content-wrapper">
                                    {dataTable}
                                </div>}
                                {view_data && <ViewFeeDetails view_data={view_data}
                                    handleSubmit={this.handleSubmit}
                                    totalAmountFormatter={this.totalAmountFormatter}
                                    clearData={this.clearViewData} />}
                            </Tab>
                            <Tab eventKey="past_record" title={t('paid')}>
                                {data && <div className="content-wrapper">
                                    {dataTable}
                                </div>}
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </section>
        </React.Fragment >)
    }
}

const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile,
    prescription_fees: state.prescription,
});

const mapActionsToProps = {
    paginatePrescriptionFees: PrescriptionAction.getPrescriptionFees
};
export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/prescription/ListPrescriptionFeesPage')(ListPrescriptionFeesPage))