import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import * as DataManagementAction from '../../actions/dataManagementAction';
import DataGrid from '../../components/DataGrid';
import { Link } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import DataManagementService from '../../services/dataManagementService';
import PathologyService from '../../services/pathologyService';
import Notification from '../../utils/notification';
import * as loaderAction from '../../actions/loaderAction';
import { getDateFormat, getLanguage } from '../../utils/helpers';
import { withTranslation } from 'react-i18next';


class DataManagement extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			addFormData: {
				test_name: '',
				test_price: '',
				test_text: '',
			},
			editFormData: {
				test_name: '',
				test_price: '',
				test_text: ''
			},
			filter: {
				user_location_id: undefined
			},
			isLoading: false,
			modelShow: false,
			selectedTest: '',
			covid_tests: [],
			covid_test_names: []
		}
		this.validator = new SimpleReactValidator({ locale: getLanguage() });
		this.validatorEdit = new SimpleReactValidator({ locale: getLanguage() });
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillMount() {
		this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" } });
		this.fetchCovidTests();
	}

	fetchCovidTests = () => {
		PathologyService.getAllCovidTests().then(resp => {
			this.setState({
				covid_tests: resp.data.data
			})
		}).catch(error => {
			Notification.show('error', error.response.data)
		})
	}

	columns = () => {
		let { t } = this.props;
		return [
			{
				dataField: 'created_at',
				text: t('date'),
				formatter: this.dateFormatter
			}, {
				dataField: 'name',
				text: t('test_name')
			}, {
				dataField: 'price',
				text: t('price'),
				formatter: this.priceFormatter
			}, {
				dataField: 'text',
				text: t('text'),
			}, {
				dataField: 'status',
				text: t('status'),
				formatter: this.statusFormatter
			}, {
				dataField: "actions",
				text: t("action"),
				isDummyField: true,
				formatter: this.actionFormatter
			}
		]
	};

	priceFormatter = (cell, row) => {
		return 'JMD $ ' + " " + cell;
	}

	dateFormatter = (cell, row) => {
		return getDateFormat(cell);
	}

	statusFormatter = (cell, row) => {
		return <Link to="#" className={"btn-toggle " + (row.status == 1 ? '' : 'off')} data-modal-id="confirm-status" onClick={e => this.selectTestHandler(row)}></Link>
	}

	actionFormatter = (_cell, row) => {
		return (<React.Fragment>
			<Link to="#" data-modal-id="edit-test" onClick={e => this.setEditFormHandler(row)}><span className="icon edit"></span></Link>
		</React.Fragment>);
	};

	handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
		if (type === 'search') {
			page = 1;
		}
		const filter = this.state.filter;

		this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, filter });
	}

	selectTestHandler = (test) => {
		this.setState({ selectedTest: test })
	}

	updateTestStatus = () => {
		let id = this.state.selectedTest.id;
		DataManagementService.updateTestStatus(id)
			.then(resp => {
				this.closeModal.click();
				this.props.hideLoader();
				this.setState({ selectedTest: '' })
				Notification.show("success", { message: this.props.t('test_status_updated_success') });
				this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" }, filter: this.state.filter });
			})
			.catch(error => {
				this.props.hideLoader();
				if (error.response && error.response.status) {
					Notification.show('error', error.response.data);
				}
			});

	}

	setEditFormHandler = (testData) => {
		console.log("Edit test ", testData);
		this.setState({
			editFormData: {
				test_id: testData.id,
				test_name: testData.name,
				test_price: testData.price,
				test_text: testData.text
			}
		});
	}

	handleChange(e) {
		const { addFormData } = this.state;
		addFormData[e.target.name] = e.target.value;

		this.setState({
			addFormData: addFormData
		});
	}

	onHandleChange = (e) => {
		const { editFormData } = this.state;
		editFormData[e.target.name] = e.target.value;

		this.setState({
			editFormData: editFormData
		});
	}

	handleAddTestClick = (e) => {
		e.preventDefault();
		const addFormData = {
			test_name: '',
			test_price: '',
			test_text: '',
		};

		this.setState({
			addFormData: addFormData
		});
	}

	handleResetAddTestForm = (e) => {
		e.preventDefault();
		this.addTestForm.reset();
		this.validator.hideMessages();
	}

	handleResetEditTestForm = (e) => {
		e.preventDefault();
		this.editTestForm.reset();
		this.validatorEdit.hideMessages();
	}

	handleSubmit = e => {
		e.preventDefault();
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
			return false;
		}
		this.setState({ isLoading: true })

		let data = {
			name: this.state.addFormData.test_name,
			price: this.state.addFormData.test_price,
			text: this.state.addFormData.test_text
		}

		this.props.showLoader();
		DataManagementService.create({ data }).then(resp => {
			let data = resp.data;
			this.props.hideLoader();
			Notification.show('success', data)
			this.closeModal.click();
			this.addTestForm.reset();
			this.validator.hideMessages();
			this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" } });
		}).catch(error => {
			this.props.hideLoader();
			if (error.response && error.response.status) {
				Notification.show('error', error.response.data);
			}
		});
	}

	handleEditSubmit = e => {
		e.preventDefault();
		if (!this.validatorEdit.allValid()) {
			this.validatorEdit.showMessages();
			this.forceUpdate();
			return false;
		}

		this.setState({ isLoading: true });

		let data = {
			id: this.state.editFormData.test_id,
			name: this.state.editFormData.test_name,
			price: this.state.editFormData.test_price,
			text: this.state.editFormData.test_text
		}
		this.props.showLoader();

		DataManagementService.update({ data }).then(resp => {
			this.props.hideLoader();
			Notification.show('success', { message: this.props.t('test_updated_success') });
			this.closeModal.click();
			this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" } });
		}).catch(error => {
			this.props.hideLoader();
			if (error.response && error.response.status) {
				Notification.show('error', error.response.data);
			}
		});
	}

	render() {
		let { data, meta, isLoading } = this.props.data_management;
		let covidTestNames = data.map(value => value.name);
		const { t } = this.props;
		return (<React.Fragment>
			<Helmet>
				<title>{t('data_management')}</title>
			</Helmet>
			<div className="row">
				<div className="col-md-24">
					<h4 className="page-title">{t('data_management')}</h4>
					<div className=" add-user-section">
						<a href data-modal-id="add-test" className="btn btn-primary mb-2 mr-n4" onClick={this.handleAddTestClick}>{t('add_test')}</a>
					</div>
					<div className="content-wrapper">
						{this.props.data_management && <DataGrid
							loading={isLoading}
							noDataIndication={t('no_record')}
							classes="data-management"
							columns={this.columns()}
							data={data}
							meta={meta}
							handleTableChange={this.handleTableChange}
						/>}
					</div>
				</div>
			</div>

			<div id="add-test">
				<div className="modal-box">
					<h4>{t('add_test')}</h4>
					<form ref={form => this.addTestForm = form}>
						<ul className="test-form-listing" >
							<li>
								<label className="required">{t('test_name')} *</label>
								<select className="form-control" name="test_name" onChange={this.handleChange} value={this.state.addFormData.test_name}>
									<option value=""> {t('select')} </option>
									{this.state.covid_tests && this.state.covid_tests.map((covid, idx) => (
										<option value={covid.test_name} disabled={covidTestNames.includes(covid.test_name) ? true : false}> {covid.test_name} </option>
									))}
								</select>
								<p className='ml-120'>{this.validator.message(t('test_name'), this.state.addFormData.test_name, 'required')}</p>
							</li>
							<li>
								<label className="required">{t('test_price')} ({t('in_jmd')}) *</label>
								<input type="number" className="form-control" name="test_price" value={this.state.addFormData.test_price} onChange={this.handleChange} />
								<p className='ml-120'>{this.validator.message(t('test_price'), this.state.addFormData.test_price, 'required|number')}</p>
							</li>
							<li>
								<label className="required">{t('text_report')} *</label>
								<input type="text" className="form-control" name="test_text" value={this.state.addFormData.test_text} onChange={this.handleChange} />
								<p className='ml-120'>{this.validator.message(t('report_text'), this.state.addFormData.test_text, 'required')}</p>
							</li>
							<li className="button-wrapper float-right">
								<button type="button" className="btn btn-primary" onClick={this.handleSubmit}>{t('submit')}</button>
								<button type="button" className="btn btn-secondary js-modal-close" onClick={this.handleResetAddTestForm} ref={input => this.closeModal = input}>{t('cancel')}</button>
							</li>
						</ul>
					</form>
				</div>
			</div>

			<div id="edit-test">
				<div className="modal-box">
					<h4>{t('edit_test')}</h4>
					<form onSubmit={this.handleEditSubmit} ref={form => this.editTestForm = form}>
						<ul className="test-form-listing">
							<li>
								<label className="required">{t('test_name')} *</label>
								<input type="text" className="form-control" name="test_name" value={this.state.editFormData.test_name ? this.state.editFormData.test_name : ""} readOnly />
							</li>
							<li>
								<label className="required">{t('test_price')} ({t('in_jmd')}) *</label>
								<input type="number" className="form-control" name="test_price" onChange={this.onHandleChange} value={this.state.editFormData.test_price} />
								<p className='ml-120'>{this.validatorEdit.message(t('test_price'), this.state.editFormData.test_price, 'required|number')}</p>
							</li>
							<li>
								<label className="required">{t('text_report')} *</label>
								<input type="text" className="form-control" name="test_text" onChange={this.onHandleChange} value={this.state.editFormData.test_text} />
								<p className='ml-120'>{this.validatorEdit.message(t('report_text'), this.state.editFormData.test_text, 'required')}</p>
							</li>
							<li className="button-wrapper float-right">
								<button type="submit" className="btn btn-primary">{t('submit')}</button>
								<button className="btn btn-secondary js-modal-close" onClick={this.handleResetEditTestForm} ref={input => this.closeModal = input}>{t('cancel')}</button>
							</li>
						</ul>
					</form>
				</div>
			</div>

			<div id="confirm-status">
				<div className="modal-box">
					<div className="user-popup-icon">
						<h3>{this.state.selectedTest.status == 0 ? t('activate') : t('deactivate')}  {t('test')}</h3>
					</div>
					<form >
						<p className='mt-3 mb-5 text-center'>{t('status_change_confirm', { status: this.state.selectedTest.status == 0 ? t("activate") : t("deactivate") })}</p>
						<ul className="form-listing">
							<li className="button-wrapper">
								<button className="btn btn-secondary js-modal-close custom-width-120" onClick={this.handleResetEditTestForm} ref={input => this.closeModal = input}>{t('no')}</button>
								<button type="button" className="btn btn-primary ml-4 custom-width-120" onClick={this.updateTestStatus}>{t('yes')}</button>

							</li>
						</ul>
					</form>
				</div>
			</div>

		</React.Fragment>);
	}
}

const mapStateToProps = (state) => ({
	data_management: state.data_management,
});

const mapActionsToProps = ({
	paginate: DataManagementAction.list,
	showLoader: loaderAction.loaderShow,
	hideLoader: loaderAction.loaderHide,
})

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/data-management/DataManagement')(DataManagement));
