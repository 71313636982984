import React from "react";
import UserManagementService from '../services/userManagementService';
import { withTranslation } from 'react-i18next';

class ServiceProviderLocations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
    }

    componentDidMount() {
        const service_provider_id = this.props.service_provider_id;
        UserManagementService.serviceProviderLocations(service_provider_id).then(resp => {
            this.setState({
                data: resp.data
            });

        }).catch(error => {
            console.error(error);
        })
    }

    handleChange = (evt) => {
        let location_id = evt.target.value;
        let index = evt.nativeEvent.target.selectedIndex;
        let location_name = evt.nativeEvent.target[index].text
        this.props.handleLocationChange(location_id, location_name)
    }

    render() {
        const { t } = this.props;
        return (
            <select className="form-control" name="user_location_id" onChange={this.handleChange} value={this.props.user_location_id !== null ? this.props.user_location_id : ''}>
                <option value=""> {t("select_location")} </option>
                {this.state.data && this.state.data.map((location, index) => (
                    <option value={location.user_location_id} key={index} name={location.name}> {location.name} </option>
                ))}
            </select>
        );
    }

}

export default (withTranslation('components/ServiceProviderLocations')(ServiceProviderLocations));