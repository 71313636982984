import React from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import * as PrescriptionAction from '../../actions/prescriptionAction';
import * as PrescriptionOrderAction from '../../actions/prescriptionOrderAction';
import _ from 'lodash';
import PharmacyMedicineBlock from '../../components/PharmacyMedicineBlock';
import Notification from '../../utils/notification';
import PatientInfo from '../../components/PatientInfo';
import DoctorInfo from '../../components/DoctorInfo';
import PrescriptionOrderService from '../../services/prescriptionOrderService';
import PrescriptionService from '../../services/prescriptionService';
import * as LoaderAction from "../../actions/loaderAction";
import PharmacistInfo from '../../components/PharmacistInfo';
import * as HELPER from "../../utils/helpers";
import { withTranslation } from 'react-i18next';
class TechnicianPrescriptionOrderPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            prescription_id: '',
            prescription: '',
            pos: null,
            madePayment: true,
            ref_number: null,
            pos_validation_msg: '',
            reference_validation_msg: '',
            is_pos_valid: false,
            is_ref_num_valid: false,
        };
        const params = this.props.match.params;
        const prescriptionId = params.prescriptionId;

        //this.props.resetPerscriptionOrder();
        this.props.fetchPrescription(prescriptionId);
        this.setState({
            prescription_id: prescriptionId
        });
        
        this.validator = new SimpleReactValidator({ locale: HELPER.getLanguage() });
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.removeFromQueue = this.removeFromQueue.bind(this);
        this.saveDraft = this.saveDraft.bind(this);
        
        this.props.acquirePrescription({ prescription_id: prescriptionId, assigned_to: 'pharmacy-technician', assigned_by: 'pharmacist' }, this.props.history);
    }

    // for checkbox
    handleClick() {
        this.setState({
            madePayment: !this.state.madePayment
        })
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleMedicineChange = (idx, data) => {

        const newMedicines = this.state.prescription.prescription_medicines.map((medicine, sidx) => {
            if (idx !== sidx) return medicine;

            return { ...medicine, ...data };
        });

        const prescription = {
            ...this.state.prescription,
            prescription_medicines: newMedicines
        }

        this.setState({ prescription: prescription });
    };

    removeAcquisition = () => {
        let postData = {
            prescription_id: this.state.prescription.prescription_id,
            assign_to: "pharmacy-technician"
        };

        PrescriptionService.assignPrecription(this.state.prescription.prescription_id, postData)
            .then(_resp => {
                this.props.history.push("/prescription/orders/queue");
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    Notification.show('error', error.response.data);
                }
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        const prescriptionOrder = this.state.prescription;
        let postData = {
            prescription_id: prescriptionOrder.prescription_id,
            pos_number: this.state.pos ? this.state.pos : null,
            reference_number: this.state.ref_number ? this.state.ref_number : null,
            assigned_to: 'pharmacist',
            order_status: 'draft',
            action: 'submit'
        };

        postData.order_items = [];

        prescriptionOrder.prescription_medicines.forEach((medicine) => {
            if (medicine.medicine_given) {
                let data = {
                    prescription_item_id: medicine.id,
                    drug_id: medicine.drug_id ? medicine.drug_id : '',
                    quantity: parseInt(medicine.quantity),
                    drug_sku_id: medicine.drug_sku_id,
                    package_size: medicine.package_size,
                    unit_given: parseInt(medicine.unit_given),
                    unit_dispatched: parseInt(medicine.unit_dispatched),
                    drug_coverage: medicine.selected_coverages,
                    is_given: medicine.is_given
                }
                postData.order_items = _.concat(postData.order_items, data);
            }
        });
        this.props.technicianPrescriptionOrder(postData, this.props.history);
    }

    saveDraft = (e) => {
        e.preventDefault();
        const prescriptionOrder = this.state.prescription;
        let postData = {
            prescription_id: prescriptionOrder.prescription_id,
            pos_number: this.state.pos ? this.state.pos : null,
            reference_number: this.state.ref_number ? this.state.ref_number : null,
            assigned_to: 'pharmacy-technician',
            order_status: 'draft',
            action: 'draft'
        };

        postData.order_items = [];

        prescriptionOrder.prescription_medicines.forEach((medicine) => {
            if (medicine.medicine_given) {
                let data = {
                    prescription_item_id: medicine.id,
                    drug_id: medicine.drug_id ? medicine.drug_id : '',
                    quantity: parseInt(medicine.quantity),
                    drug_sku_id: medicine.drug_sku_id,
                    package_size: medicine.package_size,
                    unit_given: parseInt(medicine.unit_given),
                    unit_dispatched: parseInt(medicine.unit_dispatched),
                    drug_coverage: medicine.selected_coverages,
                    is_given: medicine.is_given
                }
                postData.order_items = _.concat(postData.order_items, data);
            }
        });
        this.props.saveDraft(postData, this.props.history);
    }

    static getDerivedStateFromProps(props, state) {

        if (_.isEmpty(state.prescription)) {
            return {
                prescription: props.prescription,
                pos: props.prescription.pos_number,
                ref_number: props.prescription.reference_number,
            };
        }

        return null;
    }

    removeFromQueue = () => {
        this.props.history.push('/pharmacy/technician/prescription/list');
    }

    checkPosValidity = () => {
        if (!this.state.pos) {
            return;
        }
        return new Promise((resolve, reject) => {
            PrescriptionOrderService.checkPosNumber({ 'pos_number': this.state.pos }).then(res => {
                if (res.data.status === 'not_available') {
                    this.setState({
                        pos_validation_msg: this.props.t('pos_number_already_exists'),
                        is_pos_valid: false
                    });
                }

                if (res.data.status === 'available') {
                    this.setState({
                        pos_validation_msg: '',
                        is_pos_valid: true
                    });
                }
                return resolve(res.data.status)
            }).catch(error => {
                this.setState({
                    pos_validation_msg: '',
                    is_pos_valid: true
                });
                Notification.show('error', error.response.data)
                return reject(error);
            });
        })
    }

    checkReferenceNumberValidity = () => {
        if (!this.state.ref_number) {
            return;
        }
        return new Promise((resolve, reject) => {
            PrescriptionOrderService.checkReferenceNumber({ 'reference_number': this.state.ref_number }).then(res => {
                if (res.data.status === 'not_available') {
                    this.setState({
                        reference_validation_msg: this.props.t('reference_number_already_exists'),
                        is_ref_num_valid: false
                    });
                }

                if (res.data.status === 'available') {
                    this.setState({
                        reference_validation_msg: '',
                        is_ref_num_valid: true
                    });
                }
                return resolve(res.data.status)
            }).catch(error => {
                this.setState({
                    reference_validation_msg: '',
                    is_ref_num_valid: true
                });
                Notification.show('error', error.response.data)
                return reject(error);
            });
        })
    }

    render() {
        const { prescription } = this.state;
        const { t } = this.props;

        return (<React.Fragment>
            <Helmet>
                <title>{t('prescription_rx')}</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">{t('prescription_rx')}</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-md-24">
                    <PatientInfo patient_info={prescription.patient_info} />
                </div >
                <div className="col-md-24">
                    {prescription && prescription.prescription_type==='doctor-prescription' && <DoctorInfo
                        doctor_info={prescription.user_profile_info}
                        doctor_location={prescription.created_at_location}
                    />}
                    {prescription && prescription.prescription_type==='pharmacy-prescription' && <PharmacistInfo pharmacist_info={prescription.user_profile_info} pharmacy_info={prescription.service_provider_info} prescription_date={HELPER.getDateFormat(prescription && prescription.prescription_date)}/>}
                </div >
            </div>
            <h4 className="page-title font-size-16">{t('prescribed_medicine')}</h4>
            <form>
                <div className="content-wrapper">
                    <ul className="medicine-list">
                        {!_.isEmpty(prescription.prescription_medicines) && prescription.prescription_medicines.map((medicine, idx) => {
                            medicine.is_given = (medicine.is_given === undefined) ? medicine.is_given = 'no' : medicine.is_given;
                            
                            return <PharmacyMedicineBlock
                                medicine={medicine}
                                idx={idx}
                                handleChangeMedicine={this.handleMedicineChange}
                                validator={this.validator}
                                patientInsurance={prescription.patient_insurance}
                                role={this.props.role}
                            />
                        })}
                    </ul>
                </div>

                <div className="content-wrapper">
                    <ul className="payment-confirmed">
                        <li></li>
                        <li></li>
                        <li>
                            <div className="prescription-confirm">
                                <label>{t('pos')}:</label>
                                <div className="input-wrapper">
                                    <input type="text" name="pos" value={this.state.pos} onChange={this.handleChange} className="form-control" autoComplete="off" autoCorrect="off" />
                                    {this.validator.message(t('pos'), this.state.pos, 'alpha_num')}
                                    {this.state.pos_validation_msg && <lable className="text-danger">{this.state.pos_validation_msg}</lable>}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="prescription-confirm">
                                <label>{t('reference_number')}:</label>
                                <div className="input-wrapper">
                                    <input type="text" name="ref_number" value={this.state.ref_number} onChange={this.handleChange} className="form-control" />
                                    {this.validator.message(t('reference_number'), this.state.ref_number, 'alpha_num')}
                                    {this.state.reference_validation_msg && <lable className="text-danger">{this.state.reference_validation_msg}</lable>}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="button-wrapper align-right">
                    <button type="submit" onClick={this.handleSubmit} className="btn btn-primary">{t('send_to_pharmacist')}</button>
                    <button type="button" onClick={this.saveDraft} className="btn btn-primary">{t('save_as_draft')}</button>
                    <button type="button" className="btn btn-outline-primary" onClick={this.removeFromQueue}>{t('cancel')}</button>
                </div>
            </form>


        </React.Fragment>);
    }
}

const mapStateToProps = (state) => ({
    patient: state.patient,
    prescription: state.prescription.fetch,
    role: state.selected_user_profile
});

const mapActionsToProps = ({
    technicianPrescriptionOrder: PrescriptionOrderAction.technicianPrescriptionOrder,
    fetchPrescription: PrescriptionAction.getPrescriptionForOrder,
    acquirePrescription: PrescriptionAction.acquirePrescription,
    resetPerscriptionOrder: PrescriptionOrderAction.reset,
    saveDraft: PrescriptionOrderAction.saveDraft,
    loaderShow: LoaderAction.loaderShow,
    loaderHide: LoaderAction.loaderHide,
});

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/pharmacy/TechnicianPrescriptionOrderPage')(TechnicianPrescriptionOrderPage));
