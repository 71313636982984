import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet';
import DataGrid from "../../components/DataGrid";
import Card from '../../components/Dashboard/Card';
import { Link } from "react-router-dom";
import * as HospitalAction from '../../actions/hospitalAction'
import { getDateFormat, getLocatTimezone } from "../../utils/helpers";
import HospitalService from '../../services/hospitalService'
import Notification from '../../utils/notification';
import { withTranslation } from 'react-i18next';

export class ListPatientPage extends Component {
    constructor(props) {
        super(props);
        const params = this.props.match.params;
        this.state = {
            status: params.status,
            count: ''
        };
        this.props.paginateRecords({ sortBy: { sortField: params.status + "_at", sortOrder: "asc" } }, this.state.status);
        this.getCount(params.status);
    }

    getCount(status) {
        HospitalService.getCount(status)
            .then((resp) => {
                this.setState({
                    count: resp.data.record_count
                });
            })
            .catch((error) => {
                Notification.show("error", error.response.data);
            })
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.match.params.status !== prevProps.match.params.status) {
            this.setState({
                status: this.props.match.params.status
            });
            this.props.paginateRecords({ sortBy: { sortField: this.props.match.params.status + "_at", sortOrder: "asc" } }, this.props.match.params.status);
            this.getCount(this.props.match.params.status);
        };
    };

    columns = () => {
        let { t } = this.props;
        if (this.state.status === 'assisted') {
            return [{
                dataField: "patient_full_name",
                text: t('patient_name'),
            },
            {
                dataField: "assisted_user_full_name",
                text: t('ambulance_staff'),
            },
            {
                dataField: "assisted_at",
                text: t('request_date'),
                sort: true,
                formatter: (_cell, row) => {
                    return getDateFormat(row.assisted_at);
                }
            },
            {
                dataField: "assisted_at",
                text: t('request_time'),
                formatter: (_cell, row) => {
                    return getLocatTimezone(row.assisted_at);
                }
            },
            {
                dataField: "actions",
                text: t("actions"),
                isDummyField: true,
                formatter: this.actionFormatter
            }];
        } else if (this.state.status === 'confirmed') {
            return [{
                dataField: "patient_full_name",
                text: t('patient_name'),
            },
            {
                dataField: "confirmed_at",
                text: t('arrvied_date'),
                sort: true,
                formatter: (_cell, row) => {
                    return getDateFormat(row.confirmed_at);
                }
            },
            {
                dataField: "confirmed_at",
                text: t('arrived_time'),
                formatter: (_cell, row) => {
                    return getLocatTimezone(row.confirmed_at);
                }
            },
            {
                dataField: "confirmed_user_full_name",
                text: t('onboard_paramedic_staff'),
            },
            {
                dataField: "actions",
                text: t("actions"),
                isDummyField: true,
                formatter: this.actionFormatter
            }];
        } else if (this.state.status === 'discharged') {
            return [{
                dataField: "patient_full_name",
                text: t('patient_name'),
            },
            {
                dataField: "confirmed_at",
                text: t('arrvied_date'),
                sort: true,
                formatter: (_cell, row) => {
                    return getDateFormat(row.confirmed_at);
                }
            },
            {
                dataField: "confirmed_at",
                text: t('arrived_time'),
                formatter: (_cell, row) => {
                    return getLocatTimezone(row.confirmed_at);
                }
            },
            {
                dataField: "confirmed_user_full_name",
                text: t('onboard_paramedic_staff'),
            },
            {
                dataField: "discharged_user_full_name",
                text: t('discharge_staff'),
            },
            {
                dataField: "discharged_at",
                text: t('discharged_date'),
                sort: true,
                formatter: (_cell, row) => {
                    return getDateFormat(row.discharged_at);
                }
            },
            {
                dataField: "actions",
                text: t("actions"),
                isDummyField: true,
                formatter: this.actionFormatter
            }];
        }
    };

    actionFormatter = (_cell, row) => {
        return (
            <React.Fragment>
                <Link to={row.status === 'discharged' ? "/patients/" + row.patient_id : "/hospital/records/" + row.record_id} ><span className="icon file"></span></Link>
            </React.Fragment>
        )
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }
        this.props.paginateRecords({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText }, this.state.status);
    };

    render() {
        let { data, meta } = this.props.patients;
        const { t } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    {this.state.status === 'assisted' && <title>{t('patient_queue')}</title>}
                    {this.state.status === 'confirmed' && <title>{t('in_patient_list')}</title>}
                    {this.state.status === 'discharged' && <title>{t('discharged_patient_list')}</title>}
                </Helmet>
                {this.state.status === 'assisted' && <React.Fragment>
                    <h4 className="page-title">{t('patient_queue')}</h4>
                    <div className="col-md-8 mt-5">
                        <Card imgSrc="/images/approval-pending-doctor.png" label={t('num_patients_arrived_today')} count={this.state.count} />
                    </div>
                </React.Fragment>
                }
                {this.state.status === 'confirmed' && <React.Fragment>
                    <h4 className="page-title">{t('in_patient_list')}</h4>
                    <div className="col-md-8 mt-5">
                        <Card imgSrc="/images/approval-pending-doctor.png" label={t('total_in_patient_list')} count={meta.total} />
                    </div>
                </React.Fragment>
                }
                {this.state.status === 'discharged' && <React.Fragment>
                    <h4 className="page-title">{t('discharged_patient_list')}</h4>
                    <div className="col-md-8 mt-5">
                        <Card imgSrc="/images/approval-pending-doctor.png" label={t('total_discharged_patient_list')} count={meta.total} />
                    </div>
                </React.Fragment>
                }
                <div className="content-wrapper">
                    <DataGrid
                        columns={this.columns()}
                        data={data}
                        meta={meta}
                        handleTableChange={this.handleTableChange}
                    />
                </div>

            </React.Fragment>

        );
    }
}

function mapStateToProps(state) {
    return {
        patients: state.hospital_patient_list,
    };
}

const mapDispatchToProps = {
    paginateRecords: HospitalAction.list
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('pages/hospital/ListPatientPage')(ListPatientPage))
