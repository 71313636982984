import React from 'react';

export const Stomach = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>{t('stomach.gastrointestinal_systems')}</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                <input type="checkbox" id='nausea' name='nausea' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'nausea') ? 'checked' : ''} />
                <label htmlFor='nausea'>{t('stomach.nausea')}</label>
                <input type="checkbox" id='heartburn' name='heartburn' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'heartburn') ? 'checked' : ''} />
                <label htmlFor='heartburn'>{t('stomach.heartburn')}</label>
                <input type="checkbox" id='stomach-pain' name='stomach-pain' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'stomach-pain') ? 'checked' : ''} />
                <label htmlFor='stomach-pain'>{t('stomach.stomach_pain')}</label>
                <input type="checkbox" id='vomiting' name='vomiting' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'vomiting') ? 'checked' : ''} />
                <label htmlFor='vomiting'>{t('stomach.vomiting')}</label>
                <input type="checkbox" id='yellow-jaundice' name='yellow-jaundice' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'yellow-jaundice') ? 'checked' : ''} />
                <label htmlFor='yellow-jaundice'>{t('stomach.yellow_jaundice')}</label>
                <input type="checkbox" id='increasing-constipation' name='increasing-constipation' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'increasing-constipation') ? 'checked' : ''} />
                <label htmlFor='increasing-constipation'>{t('stomach.increasing_constipation')}</label>
                <input type="checkbox" id='persistent-diarrhea' name='persistent-diarrhea' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'persistent-diarrhea') ? 'checked' : ''} />
                <label htmlFor='persistent-diarrhea'>{t('stomach.persistent_diarrhea')}</label>
                <input type="checkbox" id='blood-in-stools' name='blood-in-stools' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'blood-in-stools') ? 'checked' : ''} />
                <label htmlFor='blood-in-stools'>{t('stomach.blood_in_stools')}</label>
                <input type="checkbox" id='black-stools' name='black-stools' onChange={e => handleChange(e, 'stomach-and-intestines')} checked={checkIfProblemExist('stomach-and-intestines', 'black-stools') ? 'checked' : ''} />
                <label htmlFor='black-stools'>{t('stomach.black_stools')}</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('stomach.comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('stomach.type_here')} onChange={e => handleChange(e, 'stomach-and-intestines')} value={getCategoryComment('stomach-and-intestines') ? getCategoryComment('stomach-and-intestines') : ''}></textarea>
    </React.Fragment>
}