import React from 'react';
import * as ConductedSubmittedBlock from '../ConductedSubmittedBlock';
import * as AddPresentingConditionComponents from './components/AddPresentingConditionComponents';
import { review_of_system_label_config } from '../../../config/add_prescription';
import { withTranslation } from 'react-i18next';

class ViewPresentingConditionInfo extends React.Component {
    render() {
        const { symptoms, review_of_system, t } = this.props;
        const getReviewOfSystemValueByKey = (key) => {
            return review_of_system_label_config[key]
        }
        return (<div className='form-group half'>
            <ConductedSubmittedBlock.ConductedBySubmittedAt
                name={review_of_system.submitted_by}
                date={symptoms.created_at}
                t={t}
            />
            <h6 className='mt-4'>{t('symptoms')}</h6>
            <React.Fragment>
                <p className='text-primary'>{symptoms.value}</p>
            </React.Fragment>
            <hr />

            <AddPresentingConditionComponents.ReviewOfSystem
                review_of_system={review_of_system.problems}
                getReviewOfSystemValueByKey={getReviewOfSystemValueByKey}
                t={t}
            />
            <hr />
        </div>)
    }
}
export default (withTranslation('components/UrgentCare/PresentingCondition/ViewPresentingCondition')(ViewPresentingConditionInfo));