import React from "react";
import { connect } from "react-redux";
import * as PendingSignatureAction from '../../actions/pendingSignaturePrescriptionAction';
import { Helmet } from "react-helmet";
import Notification from "../../utils/notification";
import { getDateFormat } from '../../utils/helpers';
import PinModal from "../../components/Signature/PinModal";
import _ from 'lodash';
import SimpleReactValidator from 'simple-react-validator';
import DataGrid from '../../components/DataGrid';
import { withTranslation } from 'react-i18next';
import * as HELPER from '../../utils/helpers';

class SignaturePendingPrescription extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: '',
            id: '',
            showPinModal: false,
            tags: {
                prescription_id: "",
                patient_name: "",
            },
            filter: {
                prescription_id: "",
                patient_name: "",
            }
        }
        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" }
        });
        this.onSuccessRedirect = this.onSuccessRedirect.bind(this);
        this.showPinModal = this.showPinModal.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.resetChecked = this.resetChecked.bind(this);
        this.bulkSign = this.bulkSign.bind(this);
        this.validator = new SimpleReactValidator({ locale: HELPER.getLanguage() });

    }

    handleChange = (e) => {
        const filter = this.state.filter;
        filter[e.target.name] = e.target.value ? e.target.value : undefined;
        this.setState({ filter });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.setState({
            tags: {
                name: this.state.filter.name,
                generic_name: this.state.filter.generic_name,
            }
        });

        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" },
            filter: this.state.filter
        });
    };

    selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: (row, isSelect) => {
            let array = [...this.state.id];
            let id = row.prescription_id;
            let index = this.state.id.indexOf(id);
            if (isSelect) {
                this.setState({
                    id: [...this.state.id, id]
                });
            } else {
                if (index !== -1) {
                    array.splice(index, 1);
                    this.setState({
                        id: array
                    });
                }
            }
        },
        onSelectAll: (isSelect, rows) => {
            let array = [];
            if (isSelect) {
                Object.keys(rows).map((row) => {
                    array = [...array, rows[row].prescription_id]
                    return array;
                });
            } else {
                array = [];
            }
            this.setState({
                id: array
            });
        }
    };

    columns = () => {
        let { t } = this.props;
        return [
            {
                dataField: "prescription_id",
                text: t('prescription_id'),
                sort: true
            },
            {
                dataField: "patient_name",
                text: t('patient_name')
            },
            {
                dataField: "prescription_date",
                text: t('prescription_date'),
                formatter: this.dateTimeFormat
            },
            {
                dataField: "prescription_status",
                text: t('prescription_status')
            },
            {
                dataField: "updated_at",
                text: t('updated_date'),
                formatter: this.dateTimeFormat
            },
        ]
    };

    dateTimeFormat = (cell, _row) => {
        return getDateFormat(cell)
    }

    bulkSign = () => {
        if (_.size(this.state.id) > 0) {
            this.showPinModal();

        } else {
            Notification.show('error', { 'message': this.props.t('select_prescription_validation') });
        }
    }

    resetChecked() {
        this.setState({
            id: ''
        });
    }

    showPinModal() {
        this.setState({ showPinModal: true });
    }
    handleClose() {
        this.setState({ showPinModal: false });
    }

    onSuccessRedirect() {
        this.setState({ showPinModal: false });
        this.resetChecked();
        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" }
        });
    }

    handleTableChange = (
        type,
        { page, sizePerPage, sortField, sortOrder, searchText }
    ) => {
        if (type === "search") {
            page = 1;
        }

        const filter = this.state.filter;
        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    };

    render() {
        let { data, meta, t } = this.props;

        return (
            <React.Fragment>
                <Helmet><title>{t('unsigned_prescription')}</title></Helmet>

                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title"><span>{t('unsigned_prescription')}</span>
                            <button className="btn btn-primary float-right" onClick={this.bulkSign} disabled={_.size(this.state.id) > 0 ? false : true}>{t('sign')} {_.size(this.state.id)} {t('checked_prescriptions')}</button>
                        </h4>
                        <div className="content-wrapper pending_signature">
                            <DataGrid
                                columns={this.columns()}
                                data={data}
                                meta={meta}
                                handleTableChange={this.handleTableChange}
                                keyField="prescription_id"
                                selectRow={this.selectRow}
                            />
                        </div>
                        <PinModal
                            id={this.state.id}
                            type="prescription"
                            onSuccess={this.onSuccessRedirect}
                            show={this.state.showPinModal}
                            handleClose={this.handleClose}
                        />
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.pending_signature_prescription_list
});

const mapActionsToProps = ({
    paginate: PendingSignatureAction.list
})

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/prescription/SignaturePendingPrescription')(SignaturePendingPrescription));