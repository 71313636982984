import { isEmpty } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';

class   ViewVitalInfo extends React.Component {
    render() {
        const { vital, patient_info, t } = this.props
        return (<div className="row">
            <div className="col-md-24">
                <ul className="prescription-patient-details">
                    <li>{t('height')}: <span>{(vital && vital.patient_height) ? parseFloat(vital.patient_height) : '--'} cms</span></li>
                    <li>{t('weight')}: <span>{(vital && vital.patient_weight) ? parseFloat(vital.patient_weight) : '--'} kgs</span></li>
                    <li>{t('blood_pressure')}: <span>{(vital && vital.patient_blood_pressure_max) ? parseFloat(vital.patient_blood_pressure_max) : '--'}/{(vital && vital.patient_blood_pressure_min) ? parseFloat(vital.patient_blood_pressure_min) : '--'} mm of Hg</span></li>
                    <li>{t('respiratory_rate')}: <span>{(vital && vital.patient_respiratory_rate) ? parseFloat(vital.patient_respiratory_rate) : '--'} {t('bpm')}</span></li>
                    <li>{t('pulse_rate')}: <span>{(vital && vital.patient_pulse_rate) ? parseFloat(vital.patient_pulse_rate) : '--'} {t('bpm')}</span></li>
                    <li>{t('pulse_ox')}: <span>{(vital && vital.patient_pulse_ox) ? parseFloat(vital.patient_pulse_ox) : '--'} SpO2</span></li>
                </ul>
                <ul className="prescription-patient-details">
                    <li>{t('gmr')}: <span>{(vital && vital.patient_rbg) ? parseFloat(vital.patient_rbg) : '--'}</span></li>
                    <li>{t('temperature')}: <span>{(vital && vital.patient_temperature) ? parseFloat(vital.patient_temperature) : '--'} F</span></li>
                    <li>{t('bmi')}: <span>{(vital && vital.patient_bmi) ? parseFloat(vital.patient_bmi) : '--'} kg/m²</span></li>
                    <li>{t('category')}: <span>
                        {(vital && vital.patient_bmi_category) ? 
                            ( patient_info.age.year <=19 && patient_info.gender === "other" ) ?
                            vital.patient_bmi_category &&
                                    vital.patient_gender_other === "male"?  <span>{vital.patient_bmi_category} ({t('boy')})</span> : 
                                    vital.patient_gender_other === "female" ? <span>{vital.patient_bmi_category} ({t('girl')})</span> : vital.patient_bmi_category 
                            :
                            vital.patient_bmi_category
                            : '--'}
                        </span>                    
                    </li>

                    <li>{t('blood_group')}: <span>{(vital && vital.patient_blood_group) ? vital.patient_blood_group : '--'}</span></li>
                    <li>{t('urinalysis')}: <span>{(vital && vital.patient_urinalysis) ? vital.patient_urinalysis : '--'}</span></li>
                    <li>{t('allergies')}: <span>{(vital && !isEmpty(vital.patient_allergy)) ? vital.patient_allergy.join(", ") : '--'}</span></li>
                    <li>{t('comorbidities')}: <span>{(vital && !isEmpty(vital.patient_comorbidities)) ? vital.patient_comorbidities.join(", ") : '--'}</span></li>
                </ul>
            </div>
        </div>)
    }
}
export default (withTranslation('components/Prescription/Vital/ViewVital')(ViewVitalInfo))