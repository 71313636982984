import * as types from './actionTypes';
import Notification from '../utils/notification';
import ProfileService from '../services/profileService';
import * as authUtils from '../utils/auth';
import { withTranslation } from 'react-i18next';

export function selectedProfile(user, history) {
    return dispatch => {
        Notification.clearAll();

        dispatch({ type: types.SHOW_PAGE_LOADER });
        dispatch({ type: types.PROFILE_REQUEST });

        ProfileService.getActiveProfile().then(resp => {
            dispatch({ type: types.RESET_ALL });
            if (!resp.data.twofa) {
                authUtils.setUserProfile(resp.data);
                authUtils.setUserLanguage(resp.data.user.language);
            }
            dispatch({ type: types.PROFILE_SUCCESS, payload: resp.data });
            dispatch({ type: types.HIDE_PAGE_LOADER });
            history.push('/redirect')
        }).catch(error => {
            if (error.response && error.response.status) {
                Notification.show('error', error.response.data);
                dispatch({ type: types.PROFILE_FAILURE });
                dispatch({ type: types.HIDE_PAGE_LOADER });
            }
        });
    }
}

export function editProfile(data, user, history) {
    return dispatch => {
        Notification.clearAll();
        dispatch({ type: types.SHOW_PAGE_LOADER });

        ProfileService.updateProfile(data, user.user_profile_id)
            .then(resp => {
                ProfileService.getActiveProfile().then(resp => {
                    authUtils.setUserProfile(resp.data);
                    authUtils.setUserLanguage(resp.data.user.language);

                    dispatch({ type: types.PROFILE_SUCCESS, payload: resp.data });
                    dispatch({ type: types.HIDE_PAGE_LOADER });

                    history.push('/redirect');
                }).catch(error => {
                    if (error.response && error.response.status) {
                        Notification.show('error', error.response.data);
                        dispatch({ type: types.PROFILE_FAILURE });
                        dispatch({ type: types.HIDE_PAGE_LOADER });
                    }
                });
            }).catch(error => {
                dispatch({ type: types.HIDE_PAGE_LOADER });
                if (error.response && error.response.status) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: types.HIDE_PAGE_LOADER });
                }
            });
    }
}

export function switchProfile(user, _history) {
    return dispatch => {
        const postData = {
            'user_profile_id': user.user_profile_id,
            'user_location_id': user.user_location_id
        }

        dispatch({ type: types.SHOW_PAGE_LOADER });
        dispatch({ type: types.PROFILE_REQUEST });

        ProfileService.userProfileToken(postData)
            .then(_resp => {
                ProfileService.getActiveProfile().then(resp => {
                    authUtils.setUserProfile(resp.data);
                    authUtils.setUserLanguage(resp.data.user.language);

                    dispatch({ type: types.RESET_ALL });
                    dispatch({ type: types.PROFILE_SUCCESS, payload: resp.data });
                    dispatch({ type: types.HIDE_PAGE_LOADER });
                }).catch(error => {
                    if (error.response && error.response.status) {
                        Notification.show('error', error.response.data);
                        dispatch({ type: types.PROFILE_FAILURE });
                        dispatch({ type: types.HIDE_PAGE_LOADER });
                    }
                });
            })
            .catch(error => {
                if (error.response && error.response.status) {
                    Notification.show('error', error.response.data);
                    dispatch({ type: types.PROFILE_FAILURE });
                    dispatch({ type: types.HIDE_PAGE_LOADER });
                }
            });
    }
}
