import React from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import * as AuthAction from '../../actions/authAction';
import queryString from 'query-string';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet';
import ReCAPTCHABlock from "../../components/ReCAPTCHABlock";
import { withTranslation } from 'react-i18next';
import * as HELPER from '../../utils/helpers';

class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        const queryParams = queryString.parse(this.props.location.search)

        this.state = {
            email: queryParams.email,
            token: queryParams.token,
            password: '',
            password_confirmation: ''
        };

        /* if (!this.state.username || !this.state.token) {
            this.props.history.push('/login');
        } */

        this.recaptchaRef = React.createRef();
        this.validator = new SimpleReactValidator({ locale: HELPER.getLanguage() });
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    verifySubmit = (evt) => {
        evt.preventDefault();
        this.recaptchaRef.execute();
    }

    verifiedSubmit = () => {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        this.props.resetPassword(this.state.email, this.state.token, this.state.password, this.state.password_confirmation, this.props.history);
        this.setState({
            password: '',
            password_confirmation: ''
        });
        this.validator.hideMessages();
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('title')}</title>
                </Helmet>
                <ReCAPTCHABlock ref={el => this.recaptchaRef = el} onSubmit={this.verifiedSubmit} />
                <div className="container login-register-wrapper">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-8 col-md-16 col-sm-24">
                            <img src="/images/logo1.png" className="logo" alt="" />
                            <h4>{t('title')}</h4>
                            <p>{t('title_message')}</p>
                            <Loader />
                            <form onSubmit={this.verifySubmit}>
                                < div className="form-group input-tooltip" >
                                    <input type="password" className="form-control" placeholder={t('password')} id="password" name="password" value={this.state.password} onChange={this.handleChange} autoComplete="new-password" />
                                    <span className="tooltip-custom">
                                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                                        <span className="tooltiptext">
                                        {t('password_help')}
                                    </span>
                                    </span>
                                    {this.validator.message(t('password'), this.state.password, 'required')}
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" placeholder={t('confirm_password')} id="password_confirmation" name="password_confirmation" value={this.state.password_confirmation} onChange={this.handleChange} autoComplete="new-password" />
                                    {this.validator.message(t('password_confirmation'), this.state.password_confirmation, 'required')}
                                </div>
                                <div className="form-group clearfix">
                                    <button type="submit" className="btn btn-primary float-left">{t('title')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    alert: state.alert
});

const mapActionsToProps = ({
    resetPassword: AuthAction.resetPassword
})

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/auth/ResetPasswordPage')(ResetPasswordPage));