import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import CovidCenterService from '../../services/covidCenterService';
import { find } from 'lodash';
import Notification from '../../utils/notification';
import { withTranslation } from 'react-i18next';

class SelectSignatureModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            doctors: '',
            nurses: '',
        }
    }
    componentDidMount() {
        this.props.loaderShow();
        CovidCenterService.getActiveUserProfiles().then(resp => {
            this.props.loaderHide();
            this.setState({
                ...resp.data
            })
        }).catch(err => {
            this.props.loaderHide();
            Notification.show('error', err.response.data)
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (!this.props.validator.allValid()) {
            this.props.validator.showMessages();
            this.forceUpdate();
            return false;
        } else {
            this.props.selectedProfiles(this.state.selected_doctor)
        }
    }
    onChange = (evt) => {
        const name = evt.target.name, value = evt.target.value
        const profiles = name === 'selected_doctor' ? this.state.doctors : this.state.nurses
        const profile = find(profiles, { 'user_profile_id': value });
        this.setState({ [name]: profile })
    }
    render() {
        const { validator, t } = this.props;
        validator.purgeFields()
        return <Modal show={this.props.show} backdrop="static" keyboard={false} onHide={this.props.onHide} centered size='md'>
            <Modal.Header closeButton>
                <Modal.Title>{t('select_signature')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form >
                    <div class="form-group row">
                        <label htmlFor="testtype" class="col-sm-8 col-form-label required-field">{t('select_doctor')}:</label>
                        <div class="col-sm-10">
                            <select className="form-control sm-3" onChange={this.onChange} name="selected_doctor">
                                <option>{t('select_doctor')}</option>
                                {this.state.doctors && this.state.doctors.map(doctor => (
                                    <option value={doctor.user_profile_id} key={doctor.user_profile_id}>{doctor.full_name}</option>
                                ))}
                            </select>
                        </div>
                        {validator.message(t('doctor'), this.state.selected_doctor, 'required')}
                    </div>
                </form >
            </Modal.Body >
            <Modal.Footer>
                <Button variant="outline-primary" onClick={this.props.onHide}>
                    {t('cancel')}
                </Button>
                <Button variant="primary" onClick={this.onSubmit}>
                    {t('submit')}
                </Button>
            </Modal.Footer>
        </Modal >
    }
}

export default (withTranslation('components/Covid/SelectSignatureModal')(SelectSignatureModal));