import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import * as NotificationAction from "../actions/notificationAction";
import { isEmpty, isNull } from 'lodash';
import Notification from '../components/Notification';
import NotificationPopup from '../components/NotificationPopup';
import OtherProfileNotificationPopup from '../components/OtherProfileNotificationPopup';
import ProfileService from '../services/profileService';
import UserNotificationService from '../services/userNotificationService';
import * as SelectedProfileAction from '../actions/selectedProfileAction';
import { redirectToChat } from '../utils/helpers';
import { withTranslation } from 'react-i18next';

class ApprovalPending extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            currentPage: '',
            dataPerPage: '',
            lastPage: '',
            profileData: null
        };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" }
        });
    }

    componentWillReceiveProps(props) {
        if (!isEmpty(props.user_notification.data) && !isEmpty(props.user_notification.meta)) {
            this.setState({
                data: props.user_notification.data,
                currentPage: props.user_notification.meta.current_page,
                dataPerPage: props.user_notification.meta.per_page,
                lastPage: Math.ceil(props.user_notification.meta.total / props.user_notification.meta.per_page),
            });
        }
    }

    componentWillUnmount() {
        this.props.clearNotifications();
    }

    handleClick = (pageId) => {
        this.setState({
            currentPage: Number(pageId)
        });

        this.props.paginate({
            page: Number(pageId),
            sortBy: { sortField: "updated_at", sortOrder: "desc" }
        });
    }

    previousPage = () => {
        if (this.state.currentPage > 1) {
            let currPage = this.state.currentPage;
            this.setState({
                currentPage: currPage - 1
            });

            this.props.paginate({
                page: currPage - 1,
                sortBy: { sortField: "updated_at", sortOrder: "desc" }
            });
        }
    }

    nextPage = () => {
        if (this.state.currentPage < this.state.lastPage) {
            let currPage = this.state.currentPage;
            this.setState({
                currentPage: currPage + 1
            });
            this.props.paginate({
                page: currPage + 1,
                sortBy: { sortField: "updated_at", sortOrder: "desc" }
            });
        }
    }

    handleReadAction = (data) => {
        if (data.user_profile_id === this.props.selected_profile.user_profile_id) {
            this.setState({ showNotificationPopup: true, notificationData: data });
        } else {
            let { profileData } = this.state;
            if (isNull(profileData)) {
                ProfileService.listOfProfiles().then(resp => {
                    profileData = resp.data.data;
                    this.setState({ showOtherProfileNotificationPopup: true, notificationData: data, profileData });
                }).catch(error => {
                    Notification.show('error', error.response.data);
                })
            } else {
                this.setState({ showOtherProfileNotificationPopup: true, notificationData: data });
            }
        }
    }

    shouldRedirect = (notificationData) => {
        this.handleCloseModal();
        this.sameProfileNotificationRedirect(notificationData);
    }

    sameProfileNotificationRedirect = (notificationData) => {
        UserNotificationService.readNotification(notificationData.id);

        if (notificationData.type === 'chat') {
            redirectToChat();
        }else if (notificationData.type === 'prescription_refill_reminder') {
            this.props.history.replace("/reminders");
        } else if (this.props.selected_profile.role_slug === "pharmacist") {
            this.props.history.replace("/prescription/orders/queue");
        } else if (this.props.selected_profile.role_slug === "pharmacy-technician") {
            this.props.history.replace("/pharmacy/technician/prescription/list");
        } else if (notificationData.type === 'prescription_approval') {
            if (this.props.selected_profile.role_slug !== "doctor") {
                this.props.history.replace("/prescriptions");
            } else {
                this.props.history.replace("/approve-prescription");
            }
        } else if (notificationData.type === 'prescription_queue') {
            this.props.history.replace("/doctor-queues");
        } else if (notificationData.type === 'permission_request') {
            this.props.history.replace("/request-pending");
        } else if (notificationData.type === 'immunization' && this.props.selected_profile.role_slug === "doctor") {
            this.props.history.replace("/immunization-queues");
        } else if (notificationData.type === 'prescription_concern') {
            notificationData.payload.map((d) => {
                if (d.label === 'Prescription Id') {
                    this.props.history.replace("/prescriptions/" + d.value);
                }
                return true;
            });
        } else if (notificationData.type === 'appointment_cancel') {
            this.props.history.replace("/appointments");
        } else if(notificationData.type === "pathology_report_upload" || notificationData.type === "radiology_report_upload") {
            this.props.history.replace('/patients/' + notificationData.payload.patient_id)
        } else if(notificationData.type === 'patient_fees') {
            this.props.history.replace("/prescriptions/fees");
        }
    }

    handleCloseModal = () => {
        this.setState({ showNotificationPopup: false })
    }

    handleOtherProfileCloseModal = () => {
        this.setState({ showOtherProfileNotificationPopup: false })
    }

    shouldSwitchProfile = (_notificationData, user) => {
        this.props.switchProfile(user[0]);
        this.handleOtherProfileCloseModal();
    }

    render() {
        const { data, dataPerPage } = this.state;
        const { t } = this.props;
        const renderdata = data.map((notification, _index) => {
            return <React.Fragment>
                <Notification notification={notification} handleReadAction={this.handleReadAction} />
                <NotificationPopup showModal={this.state.showNotificationPopup} handleClose={this.handleCloseModal} shouldRedirect={this.shouldRedirect} notificationData={this.state.notificationData} />
                <OtherProfileNotificationPopup showModal={this.state.showOtherProfileNotificationPopup} handleClose={this.handleOtherProfileCloseModal} shouldSwitchProfile={this.shouldSwitchProfile} notificationData={this.state.notificationData} profileData={this.state.profileData} />
            </React.Fragment>
        });

        // Logic for displaying page numbers
        const pageNumbers = [];
        if (!isEmpty(this.props.user_notification.meta)) {
            for (let i = 1; i <= Math.ceil(this.props.user_notification.meta.total / dataPerPage); i++) {
                pageNumbers.push(i);
            }
        }

        const renderPageNumbers = pageNumbers.map(number => {
            let active = this.state.currentPage === number ? 'active' : '';
            return (
                <li className={"page-item " + active} key={"page-item-" + number} id={"page-item-" + number}>
                    <Link className="page-link" onClick={this.handleClick.bind(this, number)}>{number}</Link>
                </li>
            );
        });

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('notification_list')}</title>
                </Helmet>
                <section className="container-fluid">
                    <div className="row">
                        <div className="col-md-24 notification-list">
                            <h4 className="page-title">{t('notification_list')}</h4>
                            <div className="content-wrapper">
                                <div className="notification-list-wrapper">
                                    {renderdata}
                                </div>
                                <nav aria-label="Page navigation clearfix">
                                    <ul className="pagination float-right">
                                        <li className="page-item">
                                            <Link className="page-link" onClick={this.previousPage.bind(this)} aria-label={t('previous')} title={t('previous')}>
                                                <span aria-hidden="true">&laquo;</span>
                                                <span className="sr-only">{t('previous')}</span>
                                            </Link>
                                        </li>
                                        {renderPageNumbers}
                                        <li className="page-item">
                                            <Link className="page-link" onClick={this.nextPage.bind(this)} aria-label={t('next')} title={t('next')}>
                                                <span aria-hidden="true">&raquo;</span>
                                                <span className="sr-only">{t('next')}</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.session.user,
    selected_profile: state.selected_user_profile,
    user_notification: state.user_notification
});

const mapActionsToProps = ({
    clearNotifications: NotificationAction.clearNotifications,
    paginate: NotificationAction.userNotification,
    switchProfile: SelectedProfileAction.switchProfile,
})

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/Notification-list')(ApprovalPending));
