import React from "react";
import * as Helper from "../../utils/helpers";
import { Helmet } from 'react-helmet';
import PatientInfo from './../PatientInfo';
import DoctorInfo from './../DoctorInfo';
import { withTranslation } from 'react-i18next';
import PrescriptionOrderService from '../../services/prescriptionOrderService';
import * as LoaderAction from "../../actions/loaderAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class ViewOrder extends React.Component {
    componentDidMount() {
        if (this.printBtnRef && this.props.autoPrint === true) {
            this.printBtnRef.click();
        }
    }

    downloadOrderPdf = () => {
        this.props.loaderShow();
        PrescriptionOrderService.getOrderPdf(this.props.prescription_order.order_id).then(res => {
            this.props.loaderHide();
            let data = res.data;
            window.open(data.temporaryUrl)
        }).catch(error => {
            this.props.loaderHide();
            Notification.show('error', error.response.data);
        });
    }

    render() {
        const { prescription_order, t, user_role } = this.props;
        return (<React.Fragment>
            <Helmet>
                <title>{t('view_rx')}</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title no-heading">
                        <span className="prescription-details">{t('rx_id')}: <i>{prescription_order && prescription_order.order_id}</i></span>
                        <span className="date">{t('rx_date')} <i>{prescription_order && Helper.getDateFormat(prescription_order.order_date)}</i></span>
                    </h4>
                    <div className="row">
                        <div className="col-md-24">
                            <div className="row">
                                <h4 className="page-title font-size-16 col-md-12">{t('rx_summary')}</h4>
                                <div className="text-right mb-2 mt-2 col-md-12">
                                    {prescription_order.has_signed && user_role && (user_role === 'pharmacist') && prescription_order && prescription_order.order_id && <button className="btn btn-primary ml-2" onClick={this.downloadOrderPdf}>{t('print')}</button>}
                                    {!prescription_order.has_signed && user_role === 'pharmacist' && <span class="sign_prescription float-right">{t('sign_order_print')} <Link to={{ pathname: "/orders/pending-signature" }}>{t('click_here')}</Link></span>}
                                </div>
                            </div>
                        </div>
                    </div>

                    {(this.props.user_role !== 'pharmacy') && <PatientInfo patient_info={prescription_order.patient_info} orderDate={prescription_order.order_date} />}

                    {(this.props.user_role !== 'pharmacy') && this.props.show_doctor_info && prescription_order.prescription_type !== 'pharmacy-prescription' && <DoctorInfo doctor_info={prescription_order.doctor_info}
                        doctor_location={prescription_order.doctor_location_info} />}

                    <div className="content-wrapper padding-bottom-10">
                        <h6>{t('pharmacy_info')}</h6>
                        <ul className="prescription-patient-details">
                            <li>{t('pharmacy_name')}: <span>{prescription_order.service_provider_info && prescription_order.service_provider_info.service_provider_name} </span></li>
                            <li>{t('order_date')}: <span>{prescription_order && prescription_order.order_date && Helper.getDateFormat(prescription_order.order_date)}</span></li>
                            <li>{t('pharmacist_name')}: <span>{prescription_order && prescription_order.created_by_user_info && prescription_order.created_by_user_info.full_name}</span></li>
                            <li>{t('pharmacist_registration_number')}: <span>{prescription_order && prescription_order.created_by_user_info && prescription_order.created_by_user_info.license_number ? prescription_order.created_by_user_info.license_number : '-'}</span></li>
                        </ul>
                    </div>

                    <h4 className="page-title font-size-16">{t('dispensed_medication')}</h4>
                    <div className="content-wrapper">
                        <table className="table table table-responsive common-table">
                            <thead>
                                <tr>
                                    <th>{t('brand_name')}</th>
                                    <th>{t('generic_name')}</th>
                                    <th>Frequency</th>
                                    <th>{t('duration')}</th>
                                    <th>{t('unit_required')}</th>
                                    <th>{t('unit_dispensed')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {prescription_order && prescription_order.prescription_order_items && (prescription_order.prescription_order_items).map((medicine, idx) => (
                                    <React.Fragment>
                                        <tr>
                                            <td>{medicine.drug_name}</td>
                                            <td>{medicine.drug_generic_name}</td>
                                            <td>{medicine.dosage}</td>
                                            <td>{medicine.duration}{Helper.getDurationType(medicine.duration_type)}</td>
                                            <td>{medicine.required_unit} {medicine.drug_type === "tablespoon" ? "ml" : "pc"}</td>
                                            <td>{medicine.fulfilled_unit} {medicine.drug_type === "tablespoon" ? "ml" : "pc"}</td>
                                        </tr>
                                        {medicine.remark && <tr>
                                            <td colSpan={6} className='remark-table-cell'>
                                                <div className="fourth">
                                                    <label>{t('pharmacist_remark')}:</label>
                                                    <span>{medicine.remark}</span>
                                                </div>
                                            </td>
                                        </tr>}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>)
    }
}

const mapActionsToProps = {
    loaderShow: LoaderAction.loaderShow,
    loaderHide: LoaderAction.loaderHide,
};

export default connect(null, mapActionsToProps)(withTranslation('components/Order/ViewOrder')(ViewOrder));
