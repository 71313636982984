import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import PatientInfoSection from '../../components/AmbulanceRecord/PatientInfoSection'
import MedicalInfoSection from '../../components/AmbulanceRecord/MedicalInfoSection'
import MedicationSection from '../../components/AmbulanceRecord/MedicationSection'
import CurrentMedicationSection from "../../components/AmbulanceRecord/CurrentMedicationSection"
import { PatientStatusSection, PatientConditionSection, NoteSection, SelectHospitalSection } from "../../components/AmbulanceRecord/AddEditFormSection"
import * as LoaderAction from '../../actions/loaderAction'
import AmbulanceService from '../../services/ambulanceService'
import Notification from '../../utils/notification'
import SimpleReactValidator from 'simple-react-validator';
import { withTranslation } from 'react-i18next';
import * as HELPER from '../../utils/helpers';

export class AddRecordPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            patientId: null,
            isLoading: true,
            patient: null,
            prescriptions: null,
            form: {
                patient_status: null,
                patient_condition: null,
                notes: null,
                medicines: [],
                hospital: []
            }
        };

        const params = this.props.match.params;
        this.state.patientId = params.patientId;
        this.validator = new SimpleReactValidator({ locale: HELPER.getLanguage() });
    }

    componentDidMount() {
        this.props.showLoader();

        Promise.all([
            AmbulanceService.getPatientInfo(this.state.patientId).then(function (response) { return response.data }),
            AmbulanceService.getPatientPrescription(this.state.patientId).then(function (response) { return response.data })
        ]).then(([patient, prescriptions]) => {
            this.setState({
                patient: patient,
                prescriptions: prescriptions,
                isLoading: false
            })
            this.props.hideLoader();
        })
            .catch(error => {
                this.setState({
                    isLoading: false
                })
                this.props.hideLoader();
                Notification.show('error', error.response.data)
                this.props.history.push('/ambulance/patients/search');
            });
    }

    onChange = (key, value) => {
        this.setState({
            form: {
                ...this.state.form,
                [key]: value
            }
        })
    }

    // Start Medicine Block Events
    handleMedicineChange = (idx, data) => {
        let { form } = this.state;
        const newMedicines = form.medicines.map((medicine, sidx) => {
            if (idx !== sidx) return medicine;

            return { ...medicine, ...data };
        });
        form['medicines'] = newMedicines;
        this.setState({ form });
    };
    handleAddMedicine = () => {
        let { form } = this.state;
        form['medicines'] = form.medicines.concat([{
            drug_id: '',
            drug_name: '',
            drug_generic_id: '',
            drug_generic_name: '',
            remarks: '',
        }]);
        this.setState({ form });
    };
    handleRemoveMedicine = (event) => {
        let { form } = this.state;
        const idx = Number(event.target.dataset['id']);
        form['medicines'] = form.medicines.filter((s, sidx) => idx !== sidx);
        this.setState({ form });
    };
    //End medicine block

    handleSearchHospital = (data) => {
        let form = this.state.form;
        form['hospital'] = {
            service_provider_id: data.service_provider_id,
            service_provider_name: data.service_provider_name,
            user_location_id: data.user_location_id,
            user_location_name: data.user_location_name
        };
        this.setState({ form });
    }

    handleSubmit = e => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let postData = {
            patient_id: this.state.patientId,
            patient_status: this.state.form.patient_status,
            patient_condition: this.state.form.patient_condition,
            medicines: this.state.form.medicines,
            notes: this.state.form.notes,
            hospital_service_provider_id: this.state.form.hospital.service_provider_id,
            hospital_user_location_id: this.state.form.hospital.user_location_id,
        }

        this.props.showLoader();
        AmbulanceService.createRecord(postData)
            .then(response => {
                this.props.hideLoader();
                Notification.show('success', { message: this.props.t('record_created_success') })
                this.props.history.push('/ambulance/records');
            })
            .catch(error => {
                this.props.hideLoader();
                if (error.response) {
                    Notification.show('error', error.response.data)
                }
            });
    }
    render() {
        const { t } = this.props;
        this.validator.purgeFields();
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('ambulance_patient_profile')}</title>
                </Helmet>
                <section className="container-fluid">
                    <div className="row">
                        <div className="col-md-24">
                            <h4 className="page-title"><span>{t('patient_profile')}</span></h4>
                        </div>
                    </div>
                    <PatientInfoSection patient={this.state.patient} t={t} />
                    <MedicalInfoSection patient={this.state.patient} t={t} />
                    <CurrentMedicationSection prescriptions={this.state.prescriptions} />
                    <div className="row">
                        <div className="col-24">
                            <PatientStatusSection validator={this.validator} value={this.state.form.patient_status} onChange={this.onChange} t={t}/>
                            <PatientConditionSection value={this.state.form.patient_condition} onChange={this.onChange} t={t}/>
                            <div className="content-wrapper pb-4">
                                <h4 className="wrapper-heading">{t('ambulance_treatment')}</h4>
                                <ul className="prescription-patient-details medicine-details">
                                    {this.state.form.medicines && this.state.form.medicines.map((medicine, idx) => (
                                        <MedicationSection
                                            key={idx}
                                            medicine={medicine}
                                            idx={idx}
                                            handleChangeMedicine={this.handleMedicineChange}
                                            handleRemoveMedicine={this.handleRemoveMedicine}
                                            validator={this.validator}
                                        />
                                    ))}
                                </ul>
                                <button onClick={this.handleAddMedicine} className="btn btn-primary float-right">{t('add')}</button>
                            </div>
                            <NoteSection value={this.state.form.notes} onChange={this.onChange} t={t}/>
                            <SelectHospitalSection hospital={this.state.form.hospital} validator={this.validator} handleSearchHospital={this.handleSearchHospital} t={t}/>
                            <div className="text-right">
                                <button className="btn btn-primary" onClick={e => this.handleSubmit(e)}>{t('submit')}</button>
                                <button className="btn btn-secondary" onClick={() => this.props.history.goBack()}>{t('cancel')}</button>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('pages/ambulance/AddRecordPage')(AddRecordPage))
