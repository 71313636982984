import React from 'react';

export const General = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <h6>{t('general.general')}</h6>
        <input type="checkbox" id='general-normal' name='general-normal' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'general-normal') ? 'checked' : ''} />
        <label htmlFor='general-normal'>{t('general.normal')}</label>
    </React.Fragment>
}
export const MM = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <label className="heading">{t('general.mm')} </label>
        <div className="options-details">
            {/* pale */}
            <input type="checkbox" id='general-mm-pale' name='general-mm-pale' value='general-mm-pale' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'general-mm-pale') ? 'checked' : ''} />
            <label htmlFor='general-mm-pale'>{t('general.pale')}</label>
            {/* icteric */}
            <input type="checkbox" id='general-mm-icteric' value='general-mm-icteric' name='general-mm-icteric' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'general-mm-icteric') ? 'checked' : ''} />
            <label htmlFor='general-mm-icteric'>{t('general.icteric')}</label>
            {/* dry */}
            <input type="checkbox" id='general-mm-dry' value='general-mm-dry' name='general-mm-dry' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'general-mm-dry') ? 'checked' : ''} />
            <label htmlFor='general-mm-dry'>{t('general.dry')}</label>
            {/* cyanotic */}
            <input type="checkbox" id='general-mm-cyanotic' name='general-mm-cyanotic' value='general-mm-cyanotic' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'general-mm-cyanotic') ? 'checked' : ''} />
            <label htmlFor='general-mm-cyanotic'>{t('general.cyanotic')}</label>
            {/* normal */}
            <input type="checkbox" id="general-mm-normal" value="general-mm-normal" name="general-mm-normal" onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', "general-mm-normal") ? 'checked' : ''} />
            <label htmlFor="general-mm-normal">{t('general.normal')}</label>
        </div>
    </React.Fragment>
}
export const LN = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <label className="heading">{t('general.ln')} </label>
        <input type="checkbox" id='general-ln-present' name='general-ln-present' value='general-ln-present' checked={checkIfProblemExist('general', 'general-ln-present') ? 'checked' : ''} onChange={e => handleChange(e, 'general')} />
        <label htmlFor='general-ln-present' >{t('general.present')}</label>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('type_here')} disabled={checkIfProblemExist('general', 'general-normal') ? 'disabled' : ''} onChange={e => handleChange(e, 'general')} value={getCategoryComment('general') ? getCategoryComment('general') : ''} ></textarea>
    </React.Fragment>
}