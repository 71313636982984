import React from "react";
import { Modal } from 'react-bootstrap';
import PrescriptionService from '../services/prescriptionService';
import * as HELPER from "../utils/helpers";
import { withTranslation } from 'react-i18next';

class ViewOriginalPrescription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            prescription_medicine_items: ''
        }
    }

    componentDidMount() {

        PrescriptionService.showOriginalPrescription(this.props.prescriptionId).then(res => {
            let data = res.data;
            this.setState({
                prescription_medicine_items: data.data.prescription_medicine_item
            });
        }).catch(error => {
            console.error(error);
        });
    }

    render() {
        let prescription_medicine_items = this.state.prescription_medicine_items;
        const { t } = this.props;
        return (
            <>
                <Modal size='lg' centered aria-labelledby="contained-modal-title-vcenter" show={this.props.show} onHide={this.props.handleClose} className="original-prescription">
                    <Modal.Header closeButton>
                        <h6>{t('original_medication')}</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="content-wrapper">
                            <table className="table table table-responsive common-table">
                                <thead>
                                    <tr>
                                        <th>{t('brand_name')}</th>
                                        <th>{t('generic_name')}</th>
                                        <th>Frequency</th>
                                        <th>{t('duration')}</th>
                                        <th>{t('dosage')}</th>
                                        <th>{t('type')}</th>
                                        <th>{t('meal')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {prescription_medicine_items && prescription_medicine_items.map((medicine, idx) => (
                                        <React.Fragment>
                                            <tr>
                                                <td>{medicine.drug_name}</td>
                                                <td>{medicine.drug_generic_name}</td>
                                                <td>{medicine.dosage}</td>
                                                <td>{medicine.duration}{HELPER.getDurationType(medicine.duration_type)}</td>
                                                <td>{parseInt(medicine.quantity)}</td>
                                                <td>{medicine.dosage_form}</td>
                                                <td>{medicine.meal}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={7}>
                                                    <label>{t('doctors_instructions')}:</label>
                                                    <span>{medicine && medicine.remarks}</span>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default (withTranslation('components/ViewOriginalPrescription')(ViewOriginalPrescription));