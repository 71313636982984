import React from 'react';
import BarGraph from '../Graph/BarGraph';
import { withTranslation } from 'react-i18next';

class UniquePatientGraph extends React.Component {
    handleUniquePatientView = (view) => {
        this.props.renderDashboardGraph('unique_patient', view)
    }

    render() {
        const { t } = this.props;
        return (
            <BarGraph
                barColor="#EFB9E9"
                barLabel={t('unique_patients')}
                yScaleLabel={t('no_of_patients')}
                datasetLabels={this.props.unique_patient_graph_data.x_axis_label}
                datasetCounts={this.props.unique_patient_graph_data.counts}
                handleViewChange={this.handleUniquePatientView}
                t={t}
            />
        )
    }
}
export default (withTranslation('components/Dashboard/UniquePatientGraph')(UniquePatientGraph))