import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as UrgentCareAction from "../../actions/urgentCareAction";
import DataGrid from "../../components/DataGrid";
import * as Helper from "../../utils/helpers";
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import RoleSerivce from '../../services/roleService';
import { withTranslation } from 'react-i18next';

class ApprovalUrgentCarePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: {
                patient_code: "",
                patient_name: "",
                prescription_id: "",
                start_date: "",
                end_date: "",
                show_start_date: new Date(),
                show_end_date: new Date(),
            },
            tags: {
                patient_code: "",
                patient_name: "",
                prescription_id: "",
            }
        };

        this.validator = new SimpleReactValidator({ locale: Helper.getLanguage() });
        this.handleEvent = this.handleEvent.bind(this);
        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" }
        });
    }

    columns = () => {
        let { t } = this.props;
        return [
            {
                dataField: "prescription_id",
                text: t('urgent_care_id'),
                sort: true
            },
            {
                dataField: "patient_code",
                text: t('patient_id'),
            },
            {
                dataField: "patient_name",
                text: t('patient_name')
            },
            {
                dataField: "prescription_date",
                text: t('created_on'),
                sort: true,
                formatter: this.dateFormatter
            },
            {
                dataField: "status",
                text: t('status'),
                sort: true,
                formatter: this.statusFormatter
            },
            {
                dataField: "actions",
                text: t('actions'),
                isDummyField: true,
                formatter: this.actionFormatter
            }
        ];
    };

    statusFormatter = (cell, row) => {
        return _.capitalize(row.status);
    }

    handleChange = (e) => {
        const filter = this.state.filter;
        filter[e.target.name] = e.target.value ? e.target.value : undefined;
        this.setState({ filter });
    }

    actionFormatter = (_cell, row) => {
        return (
            row && (row.status === 'draft') ? <Link to={"/patients/" + row.patient_id + "/urgent-care/" + row.prescription_id}>
                <span className="icon edit"></span>
            </Link> : ''
        );
    };

    dateFormatter = (cell, row) => {
        return Helper.getDateTimeFormat(row.updated_at);
    };

    handleTableChange = (
        type,
        { page, sizePerPage, sortField, sortOrder, searchText }
    ) => {
        if (type === "search") {
            page = 1;
        }

        const filter = this.state.filter;
        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        /* External javascript function  */
        window['searchToggle']();

        this.setState({
            tags: {
                patient_code: this.state.filter.patient_code,
                patient_name: this.state.filter.patient_name,
                prescription_id: this.state.filter.prescription_id,
                date_filter: (this.state.filter.start_date && this.state.filter.end_date) ? this.state.filter.start_date + ' to ' + this.state.filter.end_date : ''
            }
        });

        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" },
            filter: this.state.filter
        });
    }

    resetSearch = (e) => {
        e.preventDefault()
        this.setState({
            filter: {
                patient_code: "",
                patient_name: "",
                prescription_id: "",
                start_date: "",
                end_date: "",
                show_start_date: new Date(),
                show_end_date: new Date(),
            },
            tags: {
                patient_code: "",
                patient_name: "",
                prescription_id: "",
                date_filter: ""
            }
        }, () => {
            window['searchToggle']();
            this.props.paginate({
                sortBy: { sortField: "updated_at", sortOrder: "desc" },
                filter: this.state.filter
            });
        });
    }

    handleEvent = (event, picker) => {
        this.setState({
            filter: {
                patient_code: this.state.filter.patient_code,
                patient_name: this.state.filter.patient_name,
                prescription_id: this.state.filter.prescription_id,
                start_date: (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '',
                end_date: (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '',
                show_start_date: picker.startDate._d,
                show_end_date: picker.endDate._d,
            },
            tags: {
                patient_code: this.state.filter.patient_code,
                patient_name: this.state.filter.patient_name,
                prescription_id: this.state.filter.prescription_id,
                date_filter: (picker.startDate._d && picker.endDate._d) ? moment(picker.startDate._d).format('DD-MM-YYYY') + ' to ' + moment(picker.endDate._d).format('DD-MM-YYYY') : ''
            }
        });

        this.props.paginate({ filter: this.state.filter });
    }

    removeFilter = (e) => {

        const filter = this.state.filter;
        const tags = this.state.tags;

        if (e.target.dataset.tag === 'date_filter') {
            filter["start_date"] = '';
            filter["end_date"] = '';
        } else {
            filter[e.target.dataset.tag] = '';
        }
        tags[e.target.dataset.tag] = '';

        this.setState({ filter: filter, tags: tags });

        this.props.paginate({ filter: this.state.filter });
    }

    componentWillUnmount() {
        this.props.resetList();
    }

    render() {
        let { data, meta } = this.props.urgent_care_approval;
        let tags = this.state.tags;
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-24 mt-2">
                        <div className="search-wrapper">
                            <label>Search</label>
                            <div className="search-box clearfix">
                                {(Object.keys(tags)).map((tag) => {
                                    if (tags[tag] !== '' && !_.isEmpty(tags[tag])) {
                                        return <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
                                    }
                                    return <></>
                                })
                                }
                                <span className="search-box-icon"></span>

                                <div className="search-container">
                                    <form>
                                        <ul>
                                            <li>
                                                <span>{t('patient_id')}</span>
                                                <input type="text" value={this.state.filter.patient_code} onChange={this.handleChange} name="patient_code"></input>
                                                {this.validator.message(t('patient_code'), this.state.filter.patient_code, 'alpha_num')}
                                            </li>
                                            <li>
                                                <span>{t('patient_name')}</span>
                                                <input type="text" value={this.state.filter.patient_name} onChange={this.handleChange} name="patient_name"></input>
                                                {this.validator.message(t('patient_name'), this.state.filter.patient_name, 'alpha_num_space')}
                                            </li>
                                            <li>
                                                <span>{t('urgent_care_id')}</span>
                                                <input type="text" value={this.state.filter.prescription_id} onChange={this.handleChange} name="prescription_id"></input>
                                                {this.validator.message(t('urgent_care_id'), this.state.filter.prescription_id, 'alpha_num')}
                                            </li>
                                            <li>
                                                <span>{t('date')}</span>
                                                <DateRangePicker
                                                    startDate={this.state.filter.show_start_date}
                                                    onApply={this.handleEvent}
                                                    endDate={this.state.filter.show_end_date}
                                                >
                                                    <input type="text" className='search-input' value={this.state.filter.start_date ? moment(this.state.filter.start_date).format('DD-MM-YYYY') : ''} name="start_date" autoComplete='off'></input>
                                                    <input type="text" className='search-input' value={this.state.filter.end_date ? moment(this.state.filter.end_date).format('DD-MM-YYYY') : ''} name="end_date" autoComplete='off'></input>
                                                </DateRangePicker>
                                            </li>
                                            <li>
                                                <button onClick={this.resetSearch} className="btn btn-secondary">{t('reset')}</button>
                                                <button onClick={this.handleSubmit} className="btn btn-primary">{t('search')}</button>
                                            </li>
                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-wrapper">
                    {data && (
                        <DataGrid
                            columns={this.columns()}
                            data={data}
                            meta={meta}
                            handleTableChange={this.handleTableChange}
                            noDataIndication={t('no_record')}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        user: state.session.user,
        urgent_care_approval: state.urgent_care_approval.paginate,
        selected_user_profile: state.selected_user_profile,
    };
}

const mapActionsToProps = {
    paginate: UrgentCareAction.listApproval,
    resetList: UrgentCareAction.resetListApproval,
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(withTranslation('pages/urgent-care/ApprovalUrgentCarePage')(ApprovalUrgentCarePage));
