import React from 'react';
import { Helmet } from "react-helmet";
import DataGrid from "../../components/DataGrid";
import _ from 'lodash';
import { getImmunizationList } from '../../actions/patientAction';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { getDateFormat } from '../../utils/helpers'
import { withTranslation } from 'react-i18next';

class ListImmunizationPage extends React.Component {
    componentDidMount() {
        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "asc" } });
    }

    columns = () => {
        let { t } = this.props;
        return [
            {
                dataField: "immunization_id",
                text: t('immunization_id'),
                hidden: true,
            },
            {
                dataField: "patient_code",
                text: t('patient_id'),
            },
            {
                dataField: "patient_name",
                text: t('patient_name')
            },
            {
                dataField: "immunization_date",
                text: t('date_of_immunization'),
                formatter: (cell, row) => getDateFormat(cell)
            },
            {
                dataField: "status",
                text: t('status'),
                formatter: (cell, row) => _.capitalize(cell)
            },
            {
                dataField: "actions",
                text: t('actions'),
                isDummyField: true,
                formatter: this.actionFormatter
            }
        ];
    };

    actionFormatter = (cell, row) => {
        if (row.status === 'conducted') {
            return row.immunization_type === 'child-vaccine' ? <Link to={"patients/" + row.patient_id + "/immunizations"}>
                <span className="icon edit"></span>
            </Link> : <Link to={"patients/" + row.patient_id + "/other-immunizations"}>
                <span className="icon edit"></span>
            </Link>
        }
        return ''
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField: "created_at", sortOrder: "asc" }, });
    }

    render() {
        const { t } = this.props;
        let { data, meta } = this.props.immunizations
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('immunization_queue')}</title>
                </Helmet>
                <div className="row">
                    <div className="col-sm-24">
                        <h4 className="page-title">{t('immunization_queue')}</h4>
                        <div className="content-wrapper">
                            <DataGrid
                                columns={this.columns()}
                                data={data}
                                meta={meta}
                                handleTableChange={this.handleTableChange}
                                noDataIndication={t('no_record_found')}
                                pagination={true}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    immunizations: state.immunization,
});

const mapActionsToProps = {
    paginate: getImmunizationList
};
export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/immunization/ListImmunizationPage')(ListImmunizationPage))