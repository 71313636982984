import React from 'react';
import { Form, Button } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from 'moment';
import AppointmentService from '../../services/appointmentService';
import { getDateFormat } from '../../utils/helpers'
import Notification from '../../utils/notification';
import { Pagination } from 'react-bootstrap';
import DeleteSlotModal from './DeleteSlotModal';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const SelectDoctor = ({ doctors, doctor_profile_id, handleChange, handleSearch, t }) => {
    return <Form inline>
        <Form.Label className="mr-2 color-blue">{t('select_doctor')}</Form.Label>
        <Form.Control as="select" className="w-50 mr-4" onChange={handleChange} name="doctor_profile_id" value={doctor_profile_id}>
            <option value="">{t('select_doctor')}</option>
            {doctors && doctors.map(doctor => (
                <option value={doctor.user_profile_id} key={doctor.user_profile_id}>{doctor.full_name}</option>
            ))}
        </Form.Control>
        <Button variant="primary" onClick={handleSearch}>{t('search')}</Button>
    </Form>
}
export const Search = ({ selected_location, filter, locations, handleEvent, handleSearch, handleLocationChange, resetSearch, t }) => {
    return <form onSubmit={handleSearch}>
        <ul className="slot-search">
            <li>
                <span>{t('location')}</span>
                <select className="form-control" name="user_location_id" onChange={(e) => handleLocationChange(e)} value={selected_location}>
                    <option value=""> {t('select_location')}</option>
                    {locations && locations.map((location) => (
                        <option key={location.user_location_id} value={location.user_location_id} name={location.name}> {location.name} </option>
                    ))}
                </select>
            </li>
            <li>
                <span>{t('date')}</span>
                <DateRangePicker
                    startDate={filter.show_start_date}
                    onApply={handleEvent}
                    endDate={filter.show_end_date}
                >
                    <input type="text" className='search-input' defaultValue={filter.from ? moment(filter.from).format('DD-MM-YYYY') : ''} name="from" autoComplete='off'></input>
                    <input type="text" className='search-input' defaultValue={filter.to ? moment(filter.to).format('DD-MM-YYYY') : ''} name="to" autoComplete='off'></input>
                </DateRangePicker>
            </li>
            <li>
                <button type="button" className="btn btn-secondary" onClick={resetSearch}>{t('reset')}</button>
                <button type="submit" className="btn btn-primary">{t('search')}</button>
            </li>
        </ul>
    </form >
}

export class Slots extends React.Component {
    state = {
        data: '',
        meta: '',
        show_delete_modal: false,
        delete_slot_date: '',
    }
    componentDidMount() {
        this.getSlot({})
    }
    getSlot = ({ page = 1, sizePerPage = 25 }) => {
        let { location: { user_location_id }, filter } = this.props;
        this.props.showLoader();
        const queryParams = {
            doctor_profile_id: filter.doctor_profile_id,
            user_location_id: user_location_id,
            from: filter.from,
            to: filter.to,
        }
        AppointmentService.getSlot({ page: page, sizePerPage: sizePerPage, filter: queryParams }).then(resp => {
            let { meta } = resp.data;
            this.setState({
                data: resp.data.data,
                meta: {
                    current_page: parseInt(meta.current_page),
                    from: parseInt(meta.from),
                    last_page: parseInt(meta.last_page),
                    per_page: parseInt(meta.per_page),
                    to: parseInt(meta.to),
                    total: parseInt(meta.total),
                }

            });
            this.props.hideLoader()
        }).catch(err => {
            this.props.hideLoader()
            Notification.show('error', { message: 'Something went wrong.' })
        });
    }
    componentDidUpdate(prevProps) {
        let { is_filter, location: { user_location_id }, } = this.props;

        if ((is_filter !== prevProps.is_filter) || (user_location_id !== prevProps.location.user_location_id)) {
            this.getSlot({});
            this.props.resetFilterType();
        }
    }
    getDay = (date) => {
        return moment(date, 'YYYY-MM-DD').format("dddd");
    }
    getTime = (time) => {
        return moment(time, 'HH:mm:ss').format('hh:mm A');
    }
    deleteSlot = () => {
        let { location: { user_location_id }, filter } = this.props;
        let params = {
            type: 'date',
            from: this.state.delete_slot_date,
            to: this.state.delete_slot_date,
            doctor_profile_id: filter.doctor_profile_id,
            location_id: user_location_id,
        };
        AppointmentService.deleteSlot(params).then(_resp => {
            Notification.show('success', { message: 'Current slot deleted successfully.' });
            this.handleDeleteSlotModal()
        }).catch(err => {
            this.props.hideLoader();
            Notification.show('error', err.response.data)
        })
    }
    renderPaginationItem = () => {
        let { meta } = this.state;
        let items = []
        for (let index = 1; index <= meta.last_page; index++) {
            items.push(<Pagination.Item onClick={() => this.onPageChange(index)} active={meta.current_page === index ? 'active' : ''}>{index}</Pagination.Item>)
        }
        return items;
    }
    onPageChange = (page) => {
        this.getSlot({ page });
    }
    onPrevPage = () => {
        let { meta } = this.state;
        this.getSlot({ page: meta.current_page - 1 });
    }
    onNextPage = () => {
        let { meta } = this.state;
        this.getSlot({ page: meta.current_page + 1 });
    }
    getHolidays = (location) => {
        this.props.history.push({
            pathname: '/slots/locations/' + location.user_location_id + '/holidays',
            state: {
                location: location,
                doctor: this.props.selected_doctor
            }
        })
    }
    handleDeleteSlotModal = (delete_slot_date = '') => {
        this.setState({ show_delete_modal: !this.state.show_delete_modal, delete_slot_date })
    }
    handleSizePerPage = (sizePerPage) => {
        this.getSlot({ sizePerPage });
    }
    render() {
        const { data, meta } = this.state;
        const { t } = this.props;
        return <div className="content-wrapper pt-4 pb-4">
            <button className="btn btn-outline-primary float-right" onClick={() => this.getHolidays(this.props.location)}>{t('view_holiday')}</button>
            <h6><span className="color-grey font-regular">{t('location')}:</span> {this.props.location.name}
            </h6>
            {data.length > 0 ? data.map(slot => (
                <div className="slot-list">
                    <div className="title d-flex justify-content-between align-items-center">
                        <span>{getDateFormat(slot.date)} | {this.getDay(slot.date)}</span>
                        <button onClick={() => this.handleDeleteSlotModal(slot.date)} className="btn btn-primary">{t('delete_slot')}</button>
                    </div>
                    <div className="appmnt-slots">
                        <div className="appmnt-slots-data">
                            <ul className="row list-inline">
                                {slot.slots.map(data => (
                                    <li className="col-md-3"><a href="#">{this.getTime(data.start_time)} To {this.getTime(data.end_time)}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )) : <div className="no-slot-wrapper">
                <div className="no-slot">
                    <h3 className="mb-4">{t('no_slot_to_display')}</h3>
                    <Link to="/slots/create" className="btn btn-primary">{t('create_slot')}</Link>
                </div>
            </div>}

            {meta && meta.total > 0 && <div>
                <span className="react-bs-table-sizePerPage-dropdown dropdown" style={{ visibility: 'visible' }}>
                    <Dropdown >
                        <Dropdown.Toggle id="pageDropDown" className="btn btn-default btn-secondary dropdown-toggle">
                            {this.state.meta.per_page}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                            {[10, 25, 30, 50].map(item => (
                                <Dropdown.Item className="dropdown-item" onClick={() => this.handleSizePerPage(item)}>{item}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </span>
                <span className="float-right">
                    <Pagination>
                        <Pagination.Prev onClick={this.onPrevPage} disabled={meta.current_page === 1 ? 'disabled' : ''} />
                        {this.renderPaginationItem()}
                        <Pagination.Next onClick={this.onNextPage} disabled={meta.current_page === meta.last_page ? 'disabled' : ''} />
                    </Pagination>
                </span>
            </div>
            }
            <DeleteSlotModal showModal={this.state.show_delete_modal}
                handleDeleteSlot={this.deleteSlot}
                handleClose={this.handleDeleteSlotModal} />
        </div>
    }
}