import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const sectionSeperator = {
    borderBottom: "1px solid #e9ecef",
    marginTop: "15px",
    marginBotton: "15px",
  };

const SurgicalHistoryItem = ({ index, item, changeHandler, removeSurgicalHistory, t }) => {
    return <div className="row cancel_section" style={sectionSeperator}>
        <div className="col-sm-10 form-group">
            <label htmlFor="procedure">{t('procedure')}:</label>
                <input type="text" id="procedure" className="form-control" name="procedure" onChange={(e) => changeHandler(e, index)} value={item.procedure ? item.procedure : ''} />
            </div>
        <div className="col-sm-4 form-group">
            <label htmlFor="date">{t('date')}:</label>
                <input type="date" id="date" className="form-control" name="date" onChange={(e) => changeHandler(e, index)} max={moment().format('YYYY-MM-DD')} value={item.date ? item.date : ''} />
            </div>
        <div className="col-sm-10 form-group">
            <label htmlFor="comment">{t('comment')}:</label>
            <textarea className="form-control" id="comment" name="comment" onChange={(e) => changeHandler(e, index)} value={item.comment ? item.comment : ''}></textarea>
            </div>
            <span className="cancel" data-id={index} onClick={removeSurgicalHistory}></span>
        </div>
}

class SurgicalHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            surgical_history: this.props.surgical_history,
        }
    }
    addBlock = () => {
        let { surgical_history } = this.props;
        this.props.handleSurgicalHistory(surgical_history.concat({}))
    }
    removeSurgicalHistory = (event) => {
        let { surgical_history } = this.props;
        const idx = Number(event.target.dataset['id']);
        surgical_history = surgical_history.filter((s, sidx) => idx !== sidx);
        this.props.handleSurgicalHistory(surgical_history)
    };
    changeHandler = (e, index) => {
        let surgical_history = this.props.surgical_history;
        surgical_history[index][e.target.name] = e.target.value;
        this.props.handleSurgicalHistory(surgical_history)
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="examination-wrapper clearfix">
                    {this.props.surgical_history && this.props.surgical_history.map((item, index) => (
                        <SurgicalHistoryItem 
                            index={index} 
                            item={item}
                            changeHandler={this.changeHandler}
                            removeSurgicalHistory={this.removeSurgicalHistory}
                            t={t}
                        />
                    ))}
                    <button onClick={this.addBlock} className="add-new btn btn-default internal btn-outline-primary">{t('add_more')}</button>
                </div>
            </React.Fragment>
        )
    }
}
export default (withTranslation('components/Prescription/History/SurgicalHistory')(SurgicalHistory));