import React from "react";
import * as HELPER from "../../utils/helpers";
import { Modal } from 'react-bootstrap';
import DoctorInfo from './../DoctorInfo';
import PharmacistInfo from './../PharmacistInfo';
import { withTranslation } from 'react-i18next';
class ViewPrescription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            prescription: '',
            prescription_medicine_items: '',
            prescription_edited_medicine_items: ''
        }
    }

    render() {
        let prescription = this.props.prescription;
        let prescription_medicine_items = prescription.prescription_medicine_items;
        let prescription_edited_medicine_items = prescription.prescription_edited_medicine_items;
        let doctor = prescription ? (prescription.user_profile_info) : null;
        let doctor_location = prescription ? (prescription.user_location_info) : null;
        const { t } = this.props;
        return (  
            <React.Fragment>
                {<Modal size='lg' centered aria-labelledby="contained-modal-title-vcenter" show={this.props.show} onHide={this.props.handleClose} className="original-prescription">
                        <Modal.Header closeButton>
                        <h6>{t('prescription')}</h6>
                        </Modal.Header>
                    <Modal.Body>
                     <div className="row">
                        <div className="col-md-24">
                            {prescription && prescription.prescription_type === 'doctor-prescription' && doctor && <DoctorInfo doctor_info={doctor} doctor_location={doctor_location} />}
                            {prescription && prescription.prescription_type === 'pharmacy-prescription' && <PharmacistInfo
                                    pharmacist_info={prescription.user_profile_info}
                                    pharmacy_info={prescription.service_provider_info} 
                                    prescription_date={HELPER.getDateFormat(prescription && prescription.prescription_date)}/>}
                        </div>    
                     </div>   
                     <div className="row">
                        <div className="col-md-24">
                            <h4 className="page-title font-size-16">{t('prescription_medication')}</h4>   
                        <div className="content-wrapper">
                        <table className="table table table-responsive common-table">
                                        <thead>
                                            <tr>
                                                <th>{t('brand_name')}</th>
                                                <th>{t('generic_name')}</th>
                                                <th>Frequency</th>
                                                <th>{t('duration')}</th>
                                                <th>{t('dosage')}</th>
                                                <th>{t('type')}</th>
                                                <th>{t('meal')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {prescription_medicine_items && prescription_medicine_items.map((medicine, idx) => (
                                                <React.Fragment>
                                                <tr>
                                                    <td>{medicine.drug_name}</td>
                                                    <td>{medicine.drug_generic_name}</td>
                                                    <td>{medicine.dosage}</td>
                                                    <td>{medicine.duration}{HELPER.getDurationType(medicine.duration_type)}</td>
                                                    <td>{parseInt(medicine.quantity)}</td>
                                                    <td>{medicine.dosage_form}</td>
                                                    <td>{medicine.meal}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={7}>
                                                        <label>{t('instructions')}:</label>
                                                        <span>{medicine && medicine.remarks}</span>
                                                    </td>  
                                                </tr>
                                                </React.Fragment>
                                            ))} 
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                        {prescription_edited_medicine_items && <div className="col-md-24">
                                <h4 className="page-title font-size-16">Modified Prescription Medication</h4>   
                            <div className="content-wrapper">
                                <table className="table table table-responsive common-table">
                                                <thead>
                                                    <tr>
                                                        <th>{t('brand_name')}</th>
                                                        <th>{t('generic_name')}</th>
                                                        <th>Frequency</th>
                                                        <th>{t('duration')}</th>
                                                        <th>{t('dosage')}</th>
                                                        <th>{t('type')}</th>
                                                        <th>{t('meal')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {prescription_edited_medicine_items && prescription_edited_medicine_items.map((medicine, idx) => (
                                                        <React.Fragment>
                                                        <tr>
                                                            <td>{medicine.drug_name}</td>
                                                            <td>{medicine.drug_generic_name}</td>
                                                            <td>{medicine.dosage}</td>
                                                            <td>{medicine.duration}{HELPER.getDurationType(medicine.duration_type)}</td>
                                                            <td>{parseInt(medicine.quantity)}</td>
                                                            <td>{medicine.dosage_form}</td>
                                                            <td>{medicine.meal}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={7}>
                                                                <label>{t('instructions')}:</label>
                                                                <span>{medicine && medicine.remarks}</span>
                                                            </td>  
                                                        </tr>
                                                        </React.Fragment>
                                                    ))} 
                                                </tbody>
                                        </table>
                                </div>
                            </div>}
                      </div>
                      </Modal.Body>
                </Modal>}
            </React.Fragment>
        )
    }
}
    

export default (withTranslation('components/Prescription/ViewPrescription')(ViewPrescription));