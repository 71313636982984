import React from "react";
import { hasRole } from '../../utils/auth';
import * as ButtonComponents from './Buttons';
import ViewRecommendation from '@Components/UrgentCare/Recommendation/ViewRecommendation';
import ShowPathologyTest from '../Prescription/PathologyTestBlock'
import AddPathologyBlock from "./AddPathologyBlock";
import UrgentCareService from "../../services/urgentCareService";
import PathologyService from '../../services/pathologyService';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

class PathologyBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            savedPrescriptionPathologyTests: [],
            draftPrescriptionPathologyTests: {},
            show_add_pathology_block: false,
            showSubmitButton: false,
            covid_tests: []
        }
        this.pathologyValidator = props.validator
        this.addPathologyBlockRef = React.createRef();
    }

    componentDidMount() {
        this.getPathology()
        this.fetchCovidTests();
    }

    componentDidUpdate(prevProps) {
        if (this.props.prescription_pathology_tests !== prevProps.prescription_pathology_tests) {
            this.getPathology()
        }
    }

    fetchCovidTests = () => {
        PathologyService.getAllCovidTests().then(resp => {
            this.setState({
                covid_tests: resp.data.data
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    getPathology() {
        let draftPrescriptionPathologyTests = this.props.prescription_pathology_tests ? this.props.prescription_pathology_tests.filter(ppt => ppt.status === "draft") : []
        draftPrescriptionPathologyTests = draftPrescriptionPathologyTests.length ? draftPrescriptionPathologyTests[0] : { prescription_pathology_test_items: [] }
        this.setState({
            savedPrescriptionPathologyTests: this.props.prescription_pathology_tests ? this.props.prescription_pathology_tests.filter(ppt => ppt.status === "save") : [],
            draftPrescriptionPathologyTests: draftPrescriptionPathologyTests,
        })
        if (!_.isEmpty(draftPrescriptionPathologyTests.prescription_pathology_test_items))
            this.handleAddButton()
    }

    handleRemoveComponent = () => {
        this.setState({ showSubmitButton: false, pathologyTests: {}, draftPrescriptionPathologyTests: { prescription_pathology_test_items: [] } });
        this.props.handleIsPrescriptionEdited('recommendation_pathology', false);
    };

    handleAddButton = () => {
        this.setState({ showSubmitButton: true });
    }

    handlePathologySaveAndNext = (e, type = null) => {
        if (_.isEmpty(this.state.draftPrescriptionPathologyTests) && (type === "save_and_next" || type === "draft_and_next")) {
            this.props.handleSaveAndNext();

            return false;
        }
        let status = ['save', 'save_and_next'].includes(type) ? "save" : "draft"

        this.setState(prevState => ({
            pathologyTests: {
                prescription_pathology_tests: prevState.draftPrescriptionPathologyTests.prescription_pathology_test_items,
                status: status,
                patient_id: this.props.patientId
            }
        }), () => {
            if (this.state.draftPrescriptionPathologyTests.prescription_pathology_test_items.id) {
                this.setState(prevState => ({
                    pathologyTests: {
                        prescription_pathology_tests: prevState.draftPrescriptionPathologyTests.prescription_pathology_test_items,
                        id: this.state.draftPrescriptionPathologyTests.prescription_pathology_test_items.id
                    }
                }), () => {
                    this.addPathology(type)
                })
            } else {
                this.addPathology(type)
            }
        })
    }

    addPathology = (type) => {
        if (!this.pathologyValidator.allValid()) {
            this.pathologyValidator.showMessages();
            this.forceUpdate();
            return false;
        }
        this.props.showLoader();
        UrgentCareService.createLabTest(this.props.prescriptionId, this.state.pathologyTests).then(resp => {
            this.props.hideLoader();
            this.handleRemoveComponent();
            this.props.getPrescriptionRecommendation();
            if (type === "save_and_next" || type === "draft_and_next") {
                this.props.handleSaveAndNext();
            }
            this.addPathologyBlockRef.current.getAllPathologyTests()

        }).catch(error => {
            this.props.hideLoader();
            console.log("error =", error)
            // Notification.show('error', error.response.data)
        })
    }

    // Start Pathology Block Events
    handlePathologyChange = (idx, data) => {
        let { draftPrescriptionPathologyTests } = this.state;
        let newPathology = [];
        if (data.test_category === "COVID-19") {
            newPathology = draftPrescriptionPathologyTests.prescription_pathology_test_items.map((pathology) => {
                if (data.test_id !== pathology.test_id) return pathology;

                return { ...pathology, ...data };
            });
        } else {
            newPathology = draftPrescriptionPathologyTests.prescription_pathology_test_items.map((pathology, sidx) => {
                if (idx !== sidx) return pathology;

                return { ...pathology, ...data };
            });
        }

        draftPrescriptionPathologyTests.prescription_pathology_test_items = newPathology;
        this.setState({ draftPrescriptionPathologyTests });
        this.props.handleIsPrescriptionEdited('recommendation_pathology')
    };
    handleAddPathologyBlock = () => {
        this.setState({
            show_add_pathology_block: !this.state.show_add_pathology_block
        })
    }
    handleRemovePathologyTest = (event) => {
        let { draftPrescriptionPathologyTests } = this.state;
        const idx = Number(event.target.dataset["id"]);
        const type = event.target.dataset["type"];
        let pathology = [];
        if (type == "COVID-19") {
            pathology = draftPrescriptionPathologyTests.prescription_pathology_test_items.filter((s, sidx) => idx !== s.test_id);
        } else {
            pathology = draftPrescriptionPathologyTests.prescription_pathology_test_items.filter((s, sidx) => idx !== sidx);
        }

        draftPrescriptionPathologyTests.prescription_pathology_test_items = pathology;
        this.setState({ draftPrescriptionPathologyTests }, () => {
            if (this.state.draftPrescriptionPathologyTests.prescription_pathology_test_items.length === 0) {
                this.setState({ showSubmitButton: false });
                this.props.handleIsPrescriptionEdited('recommendation_pathology', false)
            }
        });
    };


    handlePathology = (data) => {
        this.handleAddButton()
        let { draftPrescriptionPathologyTests } = this.state;
        draftPrescriptionPathologyTests.prescription_pathology_test_items = data;
        this.setState({ draftPrescriptionPathologyTests });
        this.props.handleIsPrescriptionEdited('recommendation_pathology')
    }
    // End Pathology Block Events

    handleCovidTestChange = (e) => {
        let { draftPrescriptionPathologyTests } = this.state;
        const test_id = e.target.value;
        const pathology = draftPrescriptionPathologyTests.prescription_pathology_test_items.filter((s) => s.test_category !== "COVID-19");
        draftPrescriptionPathologyTests.prescription_pathology_test_items = pathology;
        this.setState({ draftPrescriptionPathologyTests }, () => {
            if (test_id != "") {
                let covid_test = this.state.covid_tests.filter((s) => s.test_id == test_id);
                if (covid_test.length > 0) {
                    let data = {
                        test_id: covid_test[0].test_id,
                        test_type: covid_test[0].test_type,
                        test_category: covid_test[0].test_category,
                        test_name: covid_test[0].test_name,
                        test_description: covid_test[0].test_description,
                        is_other: false
                    }

                    let { draftPrescriptionPathologyTests } = this.state;
                    draftPrescriptionPathologyTests['prescription_pathology_test_items'].push(data);

                    this.setState({ draftPrescriptionPathologyTests });
                }
            }
        });
        this.setState({ showSubmitButton: true });
        this.props.handleIsPrescriptionEdited('recommendation_pathology')
    }
    render() {
        let { draftPrescriptionPathologyTests } = this.state;
        const { t } = this.props;
        let selectedCovidTest = "";
        selectedCovidTest = draftPrescriptionPathologyTests.prescription_pathology_test_items && draftPrescriptionPathologyTests.prescription_pathology_test_items.filter((test) => test.test_category == "COVID-19")
        selectedCovidTest = selectedCovidTest && selectedCovidTest.length > 0 ? selectedCovidTest[0].test_id : "";
        return (
            <div>
                <div className="content-wrapper padding-bottom-10">
                    {this.state.savedPrescriptionPathologyTests && this.state.savedPrescriptionPathologyTests.map((item, idx) => (
                        <ViewRecommendation
                            type={"pathology"}
                            prescription_pathology_test={item}
                            idx={idx}
                        />
                    ))}
                    <ul className="prescription-patient-details medicine-details clear-both">
                        {this.state.showSubmitButton && this.state.draftPrescriptionPathologyTests.prescription_pathology_test_items.filter((test) => test.test_category !== "COVID-19").map((pathology, idx) => (
                            <ShowPathologyTest
                                pathology={pathology}
                                idx={idx}
                                key={idx}
                                handleChangePathology={this.handlePathologyChange}
                                handleRemovePathologyTest={this.handleRemovePathologyTest}
                                validator={this.pathologyValidator}
                                disabled={this.props.recordId ? true : false}
                            />
                        ))}
                        <button onClick={this.handleAddPathologyBlock} className="add-new btn btn-primary" disabled={hasRole(this.props.selected_user_profile, ["paramedic-doctor"]) ? 'disabled' : ''}>{t('add')}</button>
                        <AddPathologyBlock
                            show={this.state.show_add_pathology_block}
                            prescriptionId={this.state.prescriptionId}
                            handleAddPathologyBlock={this.handleAddPathologyBlock}
                            handlePathology={this.handlePathology}
                            ref={this.addPathologyBlockRef}
                        />
                    </ul>
                    <div className="content-wrapper padding-bottom-10">
                        <h6>{t('covid_test')}</h6>
                        <select className="form-control margin-bottom col-sm-6" value={selectedCovidTest} onChange={this.handleCovidTestChange} name="covid_test" >
                            <option value="">{t('select_test')}</option>
                            {this.state.covid_tests && this.state.covid_tests.map((covid, idx) => (
                                <option value={covid.test_id}> {covid.test_name} </option>
                            ))}
                        </select>
                        <ul className="prescription-patient-details medicine-details">
                            {this.state.draftPrescriptionPathologyTests.prescription_pathology_test_items && this.state.draftPrescriptionPathologyTests.prescription_pathology_test_items.filter((test) => test.test_category === "COVID-19").map((pathology, idx) => (
                                <ShowPathologyTest
                                    pathology={pathology}
                                    idx={idx}
                                    key={idx}
                                    handleChangePathology={this.handlePathologyChange}
                                    handleRemovePathologyTest={this.handleRemovePathologyTest}
                                    validator={this.pathologyValidator}
                                    disabled={this.props.recordId ? true : false}
                                />
                            ))}
                        </ul>
                    </div>
                    <br /><br /><br /><br />
                    {this.state.showSubmitButton && <div className="bottom-btns">
                        <ButtonComponents.PrimaryButton
                            handleChange={this.handlePathologySaveAndNext}
                            buttonType={"save"}
                            buttonName={t('submit_test')}

                        />
                    </div>}
                </div>

                <div className="bottom-btns">
                    {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor", "paramedic-doctor"]) &&
                        <ButtonComponents.PrimaryButton
                            handleChange={this.handlePathologySaveAndNext}
                            buttonType={"save_and_next"}
                            buttonName={t('save_next')}
                        />}
                    <ButtonComponents.OutlineButton
                        handleChange={this.props.handlePrevious}
                        buttonName={t('previous')}
                    />
                    <ButtonComponents.PrimaryButton
                        handleChange={this.handlePathologySaveAndNext}
                        buttonType={"draft_and_next"}
                        buttonName={t('save_draft_next')}
                        disabled={!this.state.showSubmitButton}
                    />
                    <ButtonComponents.PrimaryButton
                        handleChange={this.handlePathologySaveAndNext}
                        buttonType={"draft"}
                        buttonName={t('save_draft')}
                        disabled={!this.state.showSubmitButton}
                    />
                </div>

            </div>
        );
    }
}

export default (withTranslation('components/UrgentCare/PathologyBlock')(PathologyBlock));