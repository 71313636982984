import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { fullNameStr, getStatus, getDateFormat } from '../../utils/helpers';
import DataGrid from "../../components/DataGrid";
import SearchPatient from "../../components/SearchPatient";
import * as PathoRadioAction from '../../actions/PathoRadioAction';
import { withTranslation } from 'react-i18next';

class SearchPatientRadiologyTest extends React.Component {
    columns = () => {
        let { t } = this.props;
        return [{
            dataField: 'prescription_id',
            text: t('prescription_id'),
        }, {
            dataField: 'patient_id',
            text: t('patient_id'),
            hidden: true
        }, {
            text: t('patient_name'),
            dataField: 'patient_info.full_name',
        }, {
            dataField: 'doctor_info.full_name',
            text: t('doctor_name')
        }, {
            formatter: this.dateTimeFormat,
            text: t('prescription_date')
        }, {
            formatter: this.statusFormatter,
            text: t('status')
        }, {
            dataField: 'actions',
            text: t('actions'),
            isDummyField: true,
            formatter: this.actionFormatter
        }
        ];
    };

    constructor(props) {
        super(props);
        this.state = { show: true }
        this.props.resetPaginate()
    }

    nameFormatter = (cell, row) => {
        return fullNameStr(row.patient_info);

    };

    dateTimeFormat = (cell, row) => {
        return getDateFormat(row.prescription_date)
    }

    actionFormatter = (cell, row) => {
        return (<React.Fragment>
            <Link to={"/patient/radiology-test/" + row.prescription_id}><span className="icon file"></span></Link>
        </React.Fragment>);
    };

    statusFormatter = (cell, row) => {
        if (row.prescription_radiology_tests)
            return getStatus(row.prescription_radiology_tests.status)
        else
            return;
    }

    handleHideBlock = () => this.setState({ show: !this.state.show })

    handlePatientChange = (patient_id) => {
        !this.state.show && this.handleHideBlock()
        this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "desc" } }, patient_id);
        this.handleHideBlock()
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder } });
    }

    render() {
        let { data, meta, is_requested } = this.props.prescription_patho_radio_test;
        const { t } = this.props;
        return <div>
            <Helmet>
                <title>{t('search_referral')}</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">{t('search_referral')}</h4>
                    <div className="content-wrapper">
                        <div className="row">
                            <SearchPatient
                                handlePatientSearch={this.handlePatientChange}
                                history={this.props.history}
                                show={this.state.show}
                            ></SearchPatient>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {data && is_requested === true && <div className="content-wrapper">
                    <DataGrid
                        columns={this.columns()}
                        data={data}
                        handleTableChange={this.handleTableChange}
                        meta={meta}
                        pagination={false}
                        noDataIndication={t('no_record')}
                    />
                </div>}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => ({
    prescription_patho_radio_test: state.prescription_patho_radio_test,
    user: state.session.user,
});

const mapActionsToProps = {
    paginate: PathoRadioAction.patientRadiolgyTestList,
    resetPaginate: PathoRadioAction.reset
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/radiology/SearchPatientRadiologyTest')(SearchPatientRadiologyTest));