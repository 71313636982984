import React from 'react';
import { Modal, Button, Row } from 'react-bootstrap';
import * as loaderAction from '../actions/loaderAction';
import { connect } from 'react-redux';
import { groupBy, filter } from 'lodash';
import Notification from '../utils/notification';
import PathoRadioService from '../services/pathoRadioService';
import { FormGroup, FormControl } from "react-bootstrap";
import RadiologyService from '../services/radiologyService';
import * as authUtils from '../utils/auth';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
const selectedProfile = authUtils.getUserProfile();


class UploadExternalRadiologyReportModal extends React.Component {
    state = {
        tests: [],
        categories: [],
        file: '',
        report_conducted_on: "",
        test_name: "",
        selectedCategory: ""
    }
    componentDidMount() {
        this.getAllRadiologyTests();
    }

    getAllRadiologyTests = () => {
        RadiologyService.getAllRadiologyTests().then(resp => {
            this.setState({
                tests: groupBy(resp.data.data, 'test_category')
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    handleFileUpload = (e) => {
        this.setState({ file: e.target.files })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChangeCategory = (e) => {
        this.state.test_name && this.setState({ test_name: "" })
        let data = filter(this.state.tests, function (test, index) {
            return index === e.target.value
        });
        this.setState({
            categories: data[0],
            selectedCategory: e.target.value
        })
    }

    handleUploadSubmit = (e) => {
        e.preventDefault();
        let { t } = this.props;
        if (!this.state.report_conducted_on) {
            Notification.show('error', { message: t('select_conducted_date') })
            return false;
        } else if (!this.state.selectedCategory) {
            Notification.show('error', { message: t('select_test_category') })
            return false;
        } else if (!this.state.test_name) {
            Notification.show('error', { message: t('select_test_validation') })
            return false;
        } else if (!this.state.file) {
            Notification.show('error', { message: t('upload_file_validation') })
            return false;
        } else if (this.state.file[0].size > 102400000) {
            Notification.show('error', { message: t('file_size_validation') });
            return false;
        } else {
            const { test_name, file, report_conducted_on, selectedCategory } = this.state;
            const fd = new FormData();
            const test_id = ( selectedCategory && selectedCategory === "other" ) ? null : test_name ;
            fd.append('file', file[0]);
            fd.append('test_id', test_id)
            fd.append('report_conducted_on', report_conducted_on)
            fd.append('patient_id', this.props.patient.patient_id);
            fd.append('test_name', test_name)
            this.props.showLoader();
            PathoRadioService.uplodDoctorRadiologyOrder(fd).then(resp => {
                Notification.show('success', { message: t('report_uploaded') })
                this.props.refreshList();
                this.props.hideLoader();
                this.props.handleModal();
            }).catch(err => {
                this.props.hideLoader();
                Notification.show('error', err.response.data)
            })
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <br />
                <form>

                    <FormGroup className="col-sm-12 float-left">
                        <label>{t('doctor_name')}:</label>
                        <FormControl
                            name='doctor_name'
                            type="text"
                            value={selectedProfile.user ? selectedProfile.user.full_name : ""}
                            autoComplete="none"
                            readOnly
                        />
                    </FormGroup>

                    <FormGroup className="col-sm-12 float-left">
                        <label>{t('report_conducted_on')}:</label>
                        <FormControl
                            name='report_conducted_on'
                            type="date"
                            value={this.state.report_conducted_on}
                            max={moment().format('YYYY-MM-DD')}
                            onChange={this.handleChange}
                        />
                    </FormGroup>

                    <FormGroup className="col-sm-12 float-left">
                        <label>{t('test_category')}:</label>

                        <select className="form-control" name="test_category" id="test_category" onChange={e => this.handleChangeCategory(e)}>
                            <option>{t('select')}</option>
                            {this.state.tests && Object.keys(this.state.tests).map((key, index) => (
                                <option value={key}>
                                    {key !== "null" ? key : "Profiles"}
                                </option>
                            ))}
                            <option value="other">Other</option>
                        </select>
                    </FormGroup>

                    <FormGroup className="col-sm-12 float-left">
                        <label>{t('test_name')}:</label>

                        {this.state.selectedCategory && this.state.selectedCategory === "other" ?
                            <FormControl
                                name='test_name'
                                type="text"
                                value={this.state.test_name}
                                onChange={this.handleChange}
                                placeHolder={"Test Name"}
                            />
                        :
                        <select className="form-control" name="test_name" id="test_name" onChange={this.handleChange}>
                            <option>Select</option>
                            {this.state.categories && this.state.categories.map((test, index) => (
                                <option value={test.test_id}>
                                    {test.test_name}
                                </option>
                            ))}
                        </select>
                        }
                    </FormGroup>

                    <br />
                    <div class="custom-file">
                        <input id="input-b2" name="input-file-upload" type="file" onChange={this.handleFileUpload} accept='.jpeg,.png,.jpg,.pdf,.webm,.mp4,.mpeg' />
                    </div>
                    <span className="text-info">{t('file_note')}</span>

                </form>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={this.props.handleModal}>
                        {t('cancel')}
                    </Button>
                    <Button variant="primary" onClick={this.handleUploadSubmit}>
                        {t('upload')}
                    </Button>
                </Modal.Footer>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
});

const mapActionsToProps = {
    showLoader: loaderAction.loaderShow,
    hideLoader: loaderAction.loaderHide
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('components/UploadExternalRadiologyReportModal')(UploadExternalRadiologyReportModal));
