import React from 'react';
import { withTranslation } from 'react-i18next';

class PharmacistInfo extends React.Component {
    render() {
        const { pharmacist_info, pharmacy_info, t } = this.props;

        return (
            <React.Fragment>
                <div className="content-wrapper">
                    <h6>{t('pharmacist_info')}</h6>
                    <ul className="prescription-patient-details">
                        <li>{t('pharmacist_name')}: <span>{(pharmacist_info) ? pharmacist_info.full_name : ''}</span></li>
                        <li>{t('pharmacist_registration_number')}: <span>{(pharmacy_info) ? pharmacy_info.license_number : ''}</span></li>
                        <li>{t('pharmacy_contact')}: <span>{(pharmacy_info) ? pharmacy_info.contact_number : ''}</span></li>
                    </ul>
                </div>
            </React.Fragment>
        )
    }
}

export default (withTranslation('components/PharmacistInfo')(PharmacistInfo));
