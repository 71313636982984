import React from 'react';
import BarGraph from '../Graph/MultiBarGraph';
import { withTranslation } from 'react-i18next';

class CovidTestGraph extends React.Component {

    handleCovidTestView = (view) => {
        this.props.renderDashboardGraph(view)
    }

    render() {
        const { t } = this.props;
        return (
            <BarGraph
                yScaleLabel={t("test_count")}
                datasetLabels={this.props.covid_test_graph_data.x_axis_label}
                datasetCounts={this.props.covid_test_graph_data.counts}
                handleViewChange={this.handleCovidTestView}
                t={t}
            />
        )
    }
}
export default (withTranslation('components/Dashboard/CovidTestGraph')(CovidTestGraph));