import React from 'react';
import * as Helper from "../../utils/helpers";

const ContactDetails = ({ patient, t }) => {
    return <div className="content-wrapper padding-none">
        <h5>
        {t('profile.contact.contact_detail')}
        </h5>
        <ul className="details-listing clearfix full-width">
            <li>
                <span>{t('profile.contact.contact_number')}:</span>
                {patient.contact_number}
            </li>
            <li>
                <span>{t('profile.contact.alternate_number')}:</span>
                {patient.alt_contact_number}
            </li>
            <li>
                <span>{t('profile.contact.email')}:</span>
                {patient.email}
            </li>
            <li>
                <span>{t('profile.contact.address')}:</span>
                <span className="address"> {patient.address && Helper.fullAddressStr(patient.address)
                }</span>
            </li>
        </ul>
    </div>
}
export default ContactDetails