import React from 'react';
import { hasRole } from '../../utils/auth';
import { Form, Table, Button } from "react-bootstrap";
import ServiceProviderLocations from '../ServiceProviderLocations';
import moment from 'moment';
import { getDateFormat } from '../../utils/helpers';

export const SelectLocation = ({ selected_profile, handleLocationChange, t }) => {
    let location = null;
    const { user_location } = selected_profile;

    location = hasRole(selected_profile, ['doctor', 'associate-doctor']) ?
        <ServiceProviderLocations
            service_provider_id={selected_profile.service_provider_id}
            handleLocationChange={handleLocationChange} /> :
        <select className="form-control" name="location_id" onChange={(e) => handleLocationChange(e.target.value)}>
            <option>{t('select_location')}</option>
            <option value={user_location.user_location_id} name={user_location.name}> {user_location.name} </option>
        </select>

    return <Form.Group className="mr-5">
        <Form.Label className="mr-2">{t('select_location')}</Form.Label>
        {location}
    </Form.Group>;
}

export const SelectDuration = ({ from, to, handleDuration, t }) => {
    return <Form.Group className="mr-5">
        <Form.Label className="mr-2">{t('select_duration')}:</Form.Label>
        <Form.Control
            required
            type="date"
            className="mr-3 i-cale"
            name="from"
            min={moment().add(1, 'days').format('YYYY-MM-DD')}
            max={to}
            onChange={handleDuration}
            value={from} />
        <Form.Label>To</Form.Label>
        <Form.Control
            required
            type="date"
            className="mx-sm-3 i-cale"
            name="to"
            min={from ? from : moment().format('YYYY-MM-DD')}
            onChange={handleDuration}
            value={to} />
    </Form.Group>
}

export const SelectDoctor = ({ doctors, doctor_profile_id, handleSelectDoctor, t }) => {
    let doctor = doctors && doctors.map(doctor => (
        <option value={doctor.user_profile_id} key={doctor.user_profile_id}>{doctor.full_name}</option>
    ));

    return <Form.Group className="mr-5">
        <Form.Label className="mr-2">{t('select_doctor')}</Form.Label>
        <Form.Control as="select" onChange={handleSelectDoctor} name="doctor_profile_id" value={doctor_profile_id}>
            <option>{t('select_doctor')}</option>
            {doctor}
        </Form.Control>
    </Form.Group>
}

export const AddHoliday = ({ holiday, handleHolidayChange, addHoliday, validator, t }) => {
    return <Form inline>
        <div className="row w-100 mb-3">
            <div className="pb-sm-1 pt-sm-1 v-line col-4">
                <Form.Group>
                    <Form.Label className="mr-2">{t('begin_date')}</Form.Label>
                    <Form.Control
                        required
                        type="date"
                        className="mr-3 i-cale"
                        name="begin_date"
                        onChange={(e) => handleHolidayChange(e)}
                        min={moment().format('YYYY-MM-DD')}
                        max={holiday.end_date}
                        value={holiday.begin_date || ''} />
                </Form.Group>
                {validator.message(t("begin_date"), holiday.begin_date, "required")}
            </div>
            <div className="pb-sm-1 pt-sm-1 v-line col-4">
                <Form.Group>
                    <Form.Label className="mr-2">{t('end_date')}</Form.Label>
                    <Form.Control
                        required
                        type="date"
                        className="mr-3 i-cale"
                        name="end_date"
                        value={holiday.end_date || ''}
                        onChange={(e) => handleHolidayChange(e)}
                        min={holiday.begin_date ? holiday.begin_date : moment().format('YYYY-MM-DD')} />
                </Form.Group>
                {validator.message(t("end_date"), holiday.end_date, "required")}
            </div>
            <div className="pb-sm-1 pt-sm-1 v-line p-0 col">
                <Form.Group>
                    <Form.Label className="mr-2" >{t('description')}</Form.Label>
                    <Form.Control type="text" className="slot-desc" value={holiday.description || ''} name="description" onChange={(e) => handleHolidayChange(e)} />
                </Form.Group>
                {validator.message(t("description"), holiday.description, "required")}
            </div>
        </div>
        <div className="row w-100 border-bottom-0">
            <div className="col-24 text-right">
                <Button variant="outline-primary" onClick={addHoliday}>{t('add_holiday')}</Button>
            </div>
        </div>
    </Form>
}

export const HolidayList = ({ holidays, deleteHoliday }) => {
    return <div className="slot-list-holiday pb-4">
        <Table hover className="slot-datatable">
            <thead>
                <tr>
                    <th>Begin Date</th>
                    <th>End Date</th>
                    <th>Description</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {holidays.map((holiday, index) => (
                    <tr>
                        <td>{getDateFormat(holiday.begin_date)}</td>
                        <td>{getDateFormat(holiday.end_date)}</td>
                        <td>{(holiday.description)}</td>
                        <td><span onClick={() => deleteHoliday(index)} className="delete"></span></td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </div>
}

export const AppointmentDuration = ({ appointment_duration, appointment_duration_in, changeHandler, t }) => {
    return <Form inline>
        <Form.Label>{t('from')}</Form.Label>
        <Form.Control type="number" className="mb-2 ml-sm-3 mt-sm-3" name="appointment_duration" onChange={changeHandler} value={parseInt(appointment_duration)} />
        <Form.Control as="select" name="appointment_duration_in" onChange={changeHandler} className="ml-sm-3 mt-sm-2" value={appointment_duration_in}>
            <option value="minutes">{t('minutes')}</option>
            <option value="hours">{t('hours')}</option>
        </Form.Control>
    </Form>
}
