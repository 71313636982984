import React from 'react';
import _ from "lodash";
import { withTranslation } from 'react-i18next';

class DoctorInfo extends React.Component {
    render() {
        const { doctor_info, doctor_location, t } = this.props;

        return (
            <React.Fragment>
                <div className="content-wrapper">
                    <h6>{t('doctor_info')}</h6>
                    <ul className="prescription-patient-details">
                        <li>{t('doctor_name')}: <span>{(doctor_info) ? doctor_info.full_name : ''}</span></li>
                        <li>{t('specialization')}: <span>{(doctor_info) ? doctor_info.specialization : ''}</span></li>
                        <li>{t('registration_number')}: <span>{(doctor_info) ? doctor_info.license_number : ''}</span></li>
                    </ul>
                    <ul className="prescription-patient-details">
                        <li>{t('email')}: <span>{(doctor_info) ? doctor_info.email : ''}</span></li>
                        <li>{t('contact_number')}: <span>{(doctor_info) ? doctor_info.contact_number : ''}</span></li>
                    </ul>
                    <ul className="prescription-patient-details">
                        <li>{t('address')}: <span>{(doctor_location && doctor_location.address) ? (_.isNull(doctor_location.address.address_line_1) ? '' : doctor_location.address.address_line_1) + ' ' + (_.isNull(doctor_location.address.address_line_2) ? '' : doctor_location.address.address_line_2) + ' ' + (_.isNull(doctor_location.address.address_line_3) ? '' : doctor_location.address.address_line_3) : ''}</span></li>
                        <li>{t('city')}: <span>{(doctor_location && doctor_location.address) ? doctor_location.address.city : ''}</span></li>
                        <li>{t('parish')}: <span>{(doctor_location && doctor_location.address) ? doctor_location.address.parish : ''}</span></li>
                        <li>{t('pincode')}: <span>{(doctor_location && doctor_location.address) ? doctor_location.address.pincode : ''}</span></li>
                    </ul>
                </div>
            </React.Fragment>
        )
    }
}

export default (withTranslation('components/DoctorInfo')(DoctorInfo));