import React from 'react';
import { withTranslation } from 'react-i18next';

const ShowPathology = ({ pathology, t }) => {
    return (
        pathology &&
        <li>
            <div>{t('test_name')}: <span>{pathology.test_name} - {pathology.test_category}</span></div>
            <div className="no-border">{t('remark')}: <span>{pathology.remark}</span></div>
        </li>
    )
}
export default (withTranslation('components/Prescription/ShowPathologyBlock')(ShowPathology))