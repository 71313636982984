import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Tab, Tabs } from "react-bootstrap";
import { ButtonToolbar } from 'react-bootstrap';
import * as UserAccountAction from "../../actions/userAccountAction";
import _ from 'lodash';
import DataGrid from "../../components/DataGrid";
import UserAccountService from '../../services/userAccountService';
import Notification from '../../utils/notification';
import { getDateFormat } from "../../utils/helpers";
import { withTranslation } from 'react-i18next';

class UserAccounts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            'service_provider_id': this.props.selected_user_profile.service_provider_id,
            'key': 'invoices'
        }
        this.props.invoiceList(this.state.service_provider_id, {
            sortBy: { sortField: "created_at", sortOrder: "desc" },
        })
    }

    componentWillReceiveProps(props) {
        this.setState({
            list: props.user_account.data,
        });
    }

    requestList = key => {
        this.setState({
            'key': key
        })
        const { service_provider_id } = this.state
        if (key === 'invoices') {
            this.props.invoiceList(service_provider_id, {
                sortBy: { sortField: "created_at", sortOrder: "desc" },
            })
        }
        if (key === 'statements') {
            this.props.statementList(service_provider_id, {
                sortBy: { sortField: "created_at", sortOrder: "desc" },
            })
        }
    }

    columns = () => {
        let { t } = this.props;
        if (this.state.key === "invoices") {
            return [
                {
                    dataField: "invoice_number",
                    text: t('invoice_number'),
                    sort: false
                },
                {
                    dataField: 'from_date',
                    text: t('from'),
                    formatter: this.dateFormatter,
                },
                {
                    dataField: "to_date",
                    text: t('to'),
                    formatter: this.dateFormatter,
                },
                {
                    dataField: 'actions',
                    text: t('actions'),
                    formatter: this.invoiceActionFormatter
                }
            ];
        }
        else {
            return [
                {
                    dataField: "statement_number",
                    text: t('statement_number'),
                    sort: false
                },
                {
                    dataField: 'from_date',
                    formatter: this.dateFormatter,
                    text: t('from'),
                },
                {
                    dataField: "to_date",
                    formatter: this.dateFormatter,
                    text: t('to')
                },
                {
                    dataField: 'actions',
                    text: t('actions'),
                    formatter: this.statementActionFormatter
                }
            ];
        }
    }

    dateFormatter = (cell, row) => {
        return getDateFormat(cell)
    }

    download = id => {
        if (this.state.key === "statements") {
            UserAccountService.viewServiceProviderStatements(this.state.service_provider_id, id).then(resp => {
                window.open(resp.data.url, "_blank")
            }).catch(error => {
                Notification.show('error', error.response.data)
            })
        }

        if (this.state.key === "invoices") {
            UserAccountService.viewServiceProviderInvoices(this.state.service_provider_id, id).then(resp => {
                window.open(resp.data.url, "_blank")
            }).catch(error => {
                Notification.show('error', error.response.data)
            })
        }
    }

    statementActionFormatter = (cell, row) => {
        return <ButtonToolbar>
            {<a href rel="noopener noreferrer" onClick={e => this.download(row.statement_id)} traget="_blank"><span className="icon file" /></a>}
        </ButtonToolbar>;
    }

    invoiceActionFormatter = (cell, row) => {
        return <ButtonToolbar>
            {<a href rel="noopener noreferrer" onClick={e => this.download(row.invoice_id)} traget="_blank"><span className="icon file" /></a>}
        </ButtonToolbar>;
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }

        if (this.state.key === "invoices") {
            this.props.invoiceList(this.state.service_provider_id, { page, sizePerPage, sortBy: { sortField: "created_at", sortOrder: "desc" }, searchText });
        }
        if (this.state.key === "statements") {
            this.props.statementList(this.state.service_provider_id, { page, sizePerPage, sortBy: { sortField: "created_at", sortOrder: "desc" }, searchText });
        }

    };

    render() {
        const { data, meta } = this.props.user_account
        const { t } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('patient')}</title>
                </Helmet>
                <section className="container-fluid">
                    <div className="row">
                        <div className="col-md-24 requester-tabs">
                            <h4 className="page-title">{t('user_accounts')}</h4>
                        </div>
                        <div className="col-md-24 mt-3 request-pending">
                            <Tabs
                                defaultActiveKey="invoices"
                                onSelect={this.requestList.bind(this)}
                            >
                                <Tab eventKey="invoices" title={t('invoices')}>
                                    <div className="content-wrapper lg-height">
                                        {_.isEmpty(this.state.list) && (
                                            <div>{t('no_invoice')}</div>
                                        )}
                                        {!_.isEmpty(this.state.list) &&
                                            this.state.key === "invoices" && <DataGrid
                                                columns={this.columns()}
                                                data={data}
                                                meta={meta}
                                                handleTableChange={this.handleTableChange}
                                            //export_csv={false}
                                            />}
                                    </div>
                                </Tab>
                                <Tab eventKey="statements" title={t('bill_statements')}>
                                    <div className="content-wrapper lg-height">
                                        {_.isEmpty(this.state.list) && (
                                            <div>{t('no_statement')}</div>
                                        )}
                                        {!_.isEmpty(this.state.list) &&
                                            this.state.key === "statements" && <DataGrid
                                                columns={this.columns()}
                                                data={data}
                                                meta={meta}
                                                handleTableChange={this.handleTableChange}
                                            //export_csv={false}
                                            />}
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        selected_user_profile: state.selected_user_profile,
        user_account: state.user_account,
    }
};

const mapActionsToProps = {
    invoiceList: UserAccountAction.invoiceList,
    statementList: UserAccountAction.statementList,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/account_and_billing/UserAccounts')(UserAccounts))