import React from 'react';
import { Helmet } from "react-helmet";
import DataGrid from "../../components/DataGrid";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from 'react-redux';
import * as AppointmentAction from '../../actions/appointmentAction'
import AppointmentFilter from '../../components/Slot/AppointmentFilter';
import { Link } from 'react-router-dom';
import { includes, isEmpty } from 'lodash';
import AppointmentService from '../../services/appointmentService';
import * as LoaderAction from "../../actions/loaderAction";
import moment from 'moment';
import CancelSlotButton from '../../components/Slot/CancelSlotModal';
import { getDateFormat } from '../../utils/helpers'
import * as ServiceProviderLocationAction from '../../actions/serviceProviderLocationAction';
import ScheduleAppointmentModal from '../../components/Slot/ScheduleAppointmentModal';
import Notification from '../../utils/notification'
import { withTranslation } from 'react-i18next';

class ListAppointmentManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {},
            tags: {},
            doctors: '',
            cancel_slot_modal: false,
            schedule_appointment_modal: false,
            selected_patient: ''
        };
    }
    componentDidMount() {
        this.props.paginate({})
    }
    componentDidUpdate(_prevProps, prevState) {
        const { selected_user_profile } = this.props;
        const { locations } = this.props.service_provider_locations;
        if (!this.state.doctors) {
            this.getDoctorByLocation()
        }
        if (prevState.cancel_slot_modal !== this.state.cancel_slot_modal && isEmpty(locations)) {
            this.props.getLocations(selected_user_profile)
        }
    }
    getDoctorByLocation = () => {
        this.props.loaderShow();
        let { role_slug, user_location_id } = this.props.selected_user_profile;
        let location_id = includes(['doctor', 'associate-doctor'], role_slug) ? user_location_id : ''
        AppointmentService.getDoctorByLocation(location_id).then(resp => {
            this.setState({
                doctors: resp.data,
            });
            this.props.loaderHide();
        }).catch(_error => {
            this.props.loaderHide();
            Notification.show('error', { message: this.props.t('something_wrong') })
        })
    }
    columns = () => {
        let { t } = this.props;
        return [{
            dataField: "patient_code",
            text: t('patient_id'),
        },
        {
            dataField: 'patient_name',
            text: t('patient_name'),
        },
        {
            dataField: "doctor_name",
            text: t('doctor_name')
        },
        {
            dataField: "patient_contact",
            text: t('contact_number')
        },
        {
            dataField: "date",
            text: t('date'),
            formatter: (cell, _row) => {
                return getDateFormat(cell)
            }
        },
        {
            dataField: "slot",
            text: t('time'),
            formatter: this.slotFormatter
        },
        {
            dataField: "actions",
            text: t('actions'),
            formatter: this.actionFormatter
        }];
    };
    slotFormatter = (_cell, row) => {
        if (row.start_time && row.end_time) {
            return moment(row.start_time, 'HH:mm:ss').format('hh:mm A') + " to " + moment(row.end_time, 'HH:mm:ss').format('hh:mm a')
        }
    }
    actionFormatter = (_cell, row) => {
        return moment(row.date, "YYYY-MM-DD").isSameOrAfter(moment().startOf('day')) ? <React.Fragment>
            <button className="btn btn-primary ml-2" onClick={() => this.handleModalShowHide(row)}>{this.props.t('reschedule_appointment')}</button>
            <button className="btn btn-default btn-outline-primary" onClick={() => this.deleteAppointment(row)}>{this.props.t('delete')}</button>
        </React.Fragment> : ''
    }
    handleTableChange = (_type, { page, sizePerPage }) => {
        this.props.paginate({ page, sizePerPage, filter: this.state.filter });
    }
    handleFilterChange = (evt) => {
        let { filter } = this.state;
        filter[evt.target.name] = evt.target.value;
        if (evt.target.name === "doctor_profile_id") {
            let index = evt.nativeEvent.target.selectedIndex;
            filter['doctor_name'] = evt.nativeEvent.target[index].text
        }
        this.setState({ filter })
    }
    handleSearch = (e) => {
        e.preventDefault();
        let { tags, filter } = this.state
        Object.assign(tags, filter);
        this.setState({ tags });
        this.props.paginate({ filter: this.state.filter });
        window['searchToggle']();
    }
    handleResetFilter = (e) => {
        e.preventDefault();
        let { filter, tags } = this.state;
        (Object.keys(filter)).map((tag) => {
            delete filter[tag];
            delete tags[tag];
            return true
        });
        this.setState({ filter, tags });
        this.props.paginate({ filter: filter });
        window['searchToggle']();
    }
    removeFilter = (tag) => {
        let { filter, tags } = this.state;
        if (tag === 'doctor_name') {
            delete filter['doctor_profile_id']
        }
        delete filter[tag];
        delete tags[tag]
        this.setState({ filter, tags })
        this.props.paginate({ filter: filter });
    }
    handleCancelSlotModal = (status = null) => {
        this.setState({ cancel_slot_modal: !this.state.cancel_slot_modal });
        if (status) {
            this.props.history.go(0);
            Notification.show('success', { message: this.props.t('current_slot_deleted') });
        }
    }
    handleModalShowHide = (patient) => {
        this.setState({
            schedule_appointment_modal: !this.state.schedule_appointment_modal, selected_patient: patient
        })
    }
    handleRescheduleAppointmentSubmit = (data) => {
        const { selected_patient } = this.state
        this.props.loaderShow();
        let appointmentData = {
            "from": data.from,
            "to": data.to,
            "patient_name": selected_patient.patient_name,
            "patient_contact": selected_patient.patient_contact,
            "slot_id": data.slot_id,
            "doctor_id": selected_patient.doctor_id,
            "type": selected_patient.patient_id ? "card" : "other"
        }
        if (selected_patient.patient_id) {
            appointmentData["patient_id"] = selected_patient.patient_id
        }
        AppointmentService.rescheduleAppointment(selected_patient.id, appointmentData).then(resp => {
            this.props.loaderHide();
            Notification.show('success', { 'message': this.props.t('appointment_rescheduled') });
            this.setState({ schedule_appointment_modal: false });
            this.props.paginate({ filter: this.state.filter });
        }).catch(_error => {
            this.props.loaderHide();
            Notification.show('error', { message: this.props.t('unable_reschedule_appointment') })
        })
    }
    deleteAppointment = (appointment) => {
        this.props.loaderShow();
        AppointmentService.deleteAppointmentById(appointment.id).then(resp => {
            this.props.loaderHide();
            Notification.show('success', { 'message': this.props.t('appointment_deleted') });
            this.props.paginate({ filter: this.state.filter });
        }).catch(_error => {
            this.props.loaderHide();
            Notification.show('error', _error.response.data)
        })
    }
    render() {
        let { data, meta, isLoading } = this.props.appointment
        const { locations } = this.props.service_provider_locations;
        const { selected_patient, schedule_appointment_modal } = this.state
        const { t } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('appointment')}</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title">
                            <span>{t('appointment_management')}</span>
                            <Link to="/slots" className="btn btn-primary ml-2">{t('slot_management')}</Link>
                            <button onClick={() => this.handleCancelSlotModal()} className="btn btn-default btn-outline-primary">{t('cancel_slot')}</button>
                            <Link to="/slots/create" className="btn btn-default btn-outline-primary ml-2">{t('create_slot')}</Link>
                        </h4>
                    </div>
                    <div className="col-md-24 mt-3 request-pending">
                        <Tabs defaultActiveKey="booked" id="uncontrolled-tab-example">
                            <Tab eventKey="booked" title={t('appointment_list')}>
                                <AppointmentFilter
                                    tags={this.state.tags}
                                    filter={this.state.filter}
                                    handleSearch={this.handleSearch}
                                    handleFilterChange={this.handleFilterChange}
                                    doctors={this.state.doctors}
                                    handleResetSearch={this.handleResetFilter}
                                    removeFilter={this.removeFilter}
                                    t={t} />
                                <div className="content-wrapper">
                                    <DataGrid
                                        columns={this.columns()}
                                        data={data}
                                        meta={meta}
                                        handleTableChange={this.handleTableChange}
                                        loading={isLoading}
                                        noDataIndication={t('no_record')}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                {this.state.cancel_slot_modal && !isEmpty(locations) && <CancelSlotButton
                    showHide={this.state.cancel_slot_modal}
                    handleModalShowHide={this.handleCancelSlotModal}
                    selected_doctor={this.state.doctors}
                    doctor_disabled={false}
                    locations={locations} />}
                {schedule_appointment_modal && selected_patient && <ScheduleAppointmentModal
                    handleModalShowHide={this.handleModalShowHide}
                    showHide={schedule_appointment_modal}
                    patient_name={selected_patient.patient_name}
                    patient_id={selected_patient.patient_id}
                    patient_contact={selected_patient.patient_contact}
                    patient_code={selected_patient.patient_code}
                    title="Reschedule Appointment"
                    doctor_profile_id={selected_patient.doctor_id}
                    handleSubmit={this.handleRescheduleAppointmentSubmit} />}
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    appointment: state.appointment,
    selected_user_profile: state.selected_user_profile,
    service_provider_locations: state.service_provider_locations
});

const mapActionsToProps = {
    paginate: AppointmentAction.list,
    loaderShow: LoaderAction.loaderShow,
    loaderHide: LoaderAction.loaderHide,
    getLocations: ServiceProviderLocationAction.getLocations
};
export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/appointment/ListAppointmentPage')(ListAppointmentManagement));
