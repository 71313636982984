import React from 'react';
import * as Helper from "../../utils/helpers";
import * as AddAssessmentComponents from '../../components/Prescription/Assessment/components/AddAssessmentComponent';
import MedicineBlock from '../../components/Prescription/MedicineBlock';
import SearchDoctor from './SearchDoctor';
import moment from 'moment';

const WorkExcuse = ({validator, form_attributes, handleChangeCertificate, patient,
    handleRemoveCptData, handleRemoveIcdData, handleCptBlock, handleCptData, handleIcdBlock, 
    handleIcdData, cpt_block_show, icd_block_show, selected_user_profile, handleMedicineChange, 
    handleRemoveMedicine, handleAddMedicine, handleSearchDoctor, t}) => {
    return(
        <React.Fragment>
            <div className="row mb-3">
                <div className="col-md-24">
                    <h6 className="blue-main-heading">{t('follow_up.title')}</h6>
                </div>
            </div>
            <div class="mb-3 row">
                <label htmlFor="staticEmail" class="col-auto col-form-label"><b>{t('follow_up.subject')}:</b></label>
                <div class="col-sm-10">
                    <input type="text" className="form-control" name="follow_up_subject" value={form_attributes.follow_up_subject} onChange={handleChangeCertificate} placeholder={t('follow_up.type_subject')}/>
                    {validator.message(t('follow_up.subject'), form_attributes.follow_up_subject, 'required|string')}
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-8 follow-up">
                    <div className="row">
                    <div className="col-md-4">{t('follow_up.dear')}</div> 
                <div className="col-md-20">
                <SearchDoctor 
                        doctor={form_attributes.doctor}
                        validator={validator}
                        handleSearchDoctor={handleSearchDoctor}
                    />
                </div>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-24 follow-up">
                    <form className="form-inline">
                        <span>
                        {t('follow_up.text1')} <strong>{ patient.full_name }</strong>,  {t('follow_up.a')} <strong>{Helper.getAgeString(patient.date_of_birth)}</strong>  {t('follow_up.old')},   
                            <input type="text" className="form-control" name="follow_up_advice" value={form_attributes.follow_up_advice} onChange={handleChangeCertificate}/> 
                            {validator.message(t('follow_up.reason'), form_attributes.follow_up_advice, 'required|string')} {t('follow_up.text2')}
                        </span>
                    </form>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-24 follow-up">
                    <form className="form-inline">
                        <span>
                        {t('follow_up.text3')}
                            <input type="date" className="form-control i-cale w-auto" name="visit_date" value={form_attributes.visit_date} onChange={handleChangeCertificate} max={moment().format('YYYY-MM-DD ')}/> {t('follow_up.for')},
                            {validator.message(t('follow_up.visit_date'), form_attributes.visit_date, 'required')}
                        </span>
                    </form>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-24 follow-up">
                {t('follow_up.text4')}
                <input type="text" className="form-control" cols="50" name="follow_up_procedure" value={form_attributes.follow_up_procedure} onChange={handleChangeCertificate} placeholder={t('follow_up.type_procedure')}/>
                {validator.message(t('follow_up.procedure'), form_attributes.follow_up_procedure, 'required|string')}
                </div>
            </div>
            <div className="row">
                <div className="col-md-24">
                    <h6 className="mt-4 mb-4"><b>{t('follow_up.text5')}</b></h6>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-24">
                {t('follow_up.text6')}
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-24">
                    <h6 >ICD CPT</h6>
                    <AddAssessmentComponents.IcdCpt
                        cpt_data={form_attributes.cpt_data}
                        icd_data={form_attributes.icd_data}
                        handleRemoveCptData={handleRemoveCptData}
                        handleRemoveIcdData={handleRemoveIcdData}
                        handleCptBlock={handleCptBlock}
                        handleCptData={handleCptData}
                        handleIcdBlock={handleIcdBlock}
                        handleIcdData={handleIcdData}
                        cpt_block_show={cpt_block_show}
                        icd_block_show={icd_block_show}
                    />
                </div>
            </div>

            <div class="mb-3 row">
                <label class="col-2 col-form-label color-grey">{t('follow_up.assessment')}:</label>
                <div class="col-sm-22">
                    <textarea className="form-control remark p-3" rows="2" name="follow_up_assessment" value={form_attributes.follow_up_assessment} onChange={handleChangeCertificate} placeholder={t('follow_up.type_assessment')}></textarea>
                    {validator.message(t('follow_up.assessment'), form_attributes.follow_up_assessment, 'required|string')}
                </div>
            </div>
            <div class="mb-3 row">
                <label class="col-2 col-form-label color-grey">{t('follow_up.plan')}:</label>
                <div class="col-sm-22">
                    <textarea className="form-control remark p-3" rows="2" name="follow_up_plan" value={form_attributes.follow_up_plan} onChange={handleChangeCertificate} placeholder={t('follow_up.type_plan')}></textarea>
                    {validator.message(t('follow_up.plan'), form_attributes.follow_up_plan, 'required|string')}
                </div>
            </div>
            <div className="row mb-3 mt-5">
                <div className="col-md-24">
                    {t('follow_up.text7')}
                </div>
            </div>
            <div className="row">
                <div className="col-md-24">
                    <h6><b>{t('follow_up.medication')}</b></h6>
                    <ul className="prescription-patient-details medicine-details">
                        {form_attributes.medicines.map((medicine, idx) => (
                            <MedicineBlock
                                key={idx}
                                medicine={medicine}
                                idx={idx}
                                handleChangeMedicine={handleMedicineChange}
                                handleRemoveMedicine={handleRemoveMedicine}
                                validator={validator}
                                selected_user_profile={selected_user_profile}
                                disabled={false}
                                not_required={false}
                                show_cancel={true}
                            />
                        ))}
                    </ul>
                    <button onClick={handleAddMedicine} className="add-new btn btn-primary">{t('follow_up.add')}</button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default WorkExcuse;