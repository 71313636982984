import React from 'react';
import { overall_examination_label_config, overall_examination_problem_config, review_of_system_label_config, overall_examination_config } from '../../../config/add_prescription';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';

class ViewExamination extends React.Component {
    getExaminationValueByKey = (key) => {
        return overall_examination_label_config[key]
    }

    hasNormal = (examinationProblems) => {
        let problems = ['general-normal', 'respiratory-normal', 'abdomen-normal', 'cardiovascular-normal', 'muscle-joints-bones-normal', 'nervous-system-normal', 'eyes-normal', 'ears-normal', 'nose-normal', 'throat-normal', 'skin-normal', 'genitalia-normal', 'prostate-normal', 'breast-normal'];
        return examinationProblems.some(problem => problems.includes(problem));
    }

    getLabelByProblems = (problem) => {
        for (const [key, value] of Object.entries(overall_examination_problem_config)) {
            if (value.includes(problem)) {
                return this.getExaminationValueByKey(key);
            }
        }
    }

    getOverallExaminationLabelByProblem = (category, problem) => {
        var arr = {};
        var singleItem = [];
        var multiItem = [];
        if (category !== undefined) {
            for (const [key, value] of Object.entries(overall_examination_config[category])) {
                if (!Array.isArray(value) && problem.includes(value)) {
                    singleItem.push(this.getExaminationValueByKey(value));
                }
                if (Array.isArray(value)) {
                    var item = []
                    // eslint-disable-next-line no-unused-vars
                    for (const [_k, v] of Object.entries(value)) {
                        if (problem.includes(v)) {
                            item.push(this.getExaminationValueByKey(v));
                        }
                    }
                    if (!isEmpty(item)) {
                        let problem_key = this.getExaminationValueByKey(key);
                        if (category === key) {
                            problem_key = category;
                        }
                        multiItem[problem_key] = item.join(', ');
                    }
                }
            }
            if (!isEmpty(singleItem)) {
                arr[category] = singleItem.join(', ');

            }

            for (const [key, value] of Object.entries(multiItem)) {
                arr[key] = value;
            }
        }
        return arr;
    }
    getReviewOfSystemValueByKey = (key) => {
        return review_of_system_label_config[key]
    }

    render() {
        let { review_of_system, examination, investigation } = this.props.examination;
        const { t } = this.props;
        return (
            <>
                {!isEmpty(examination) && <div className="content-wrapper padding-bottom-10">
                    <h6 className="inner_heading">{t('overall_examination')}</h6>
                    {examination.map((item, index) => (
                        <React.Fragment key={index}>
                            <ul className="prescription-filed-details">
                                <li>{this.getExaminationValueByKey(item.category)}
                                    {this.hasNormal(item.problems) && <i>(Normal)</i>} :

                                    <span>{!isEmpty(item.problems) ? Object.entries(this.getOverallExaminationLabelByProblem(item.category, item.problems)).map(([idx, problem]) => (
                                        !this.hasNormal([item.problems[idx]]) && <React.Fragment key={idx}>
                                            {(item.category === idx) ? <span key={idx}>{problem} </span> : <React.Fragment key={idx}> {idx}  : <span key={idx}>{problem} </span> </React.Fragment>}
                                        </React.Fragment>
                                    ), item.category) : '--'}
                                    </span>
                                </li>

                                {item.comments && <li>{t('comment')} :<span className='c-text-pre-wrap'>{item.comments}</span></li>}
                            </ul>
                        </React.Fragment>
                    ))}
                </div>}
                <div className="content-wrapper padding-bottom-10 margin-bottom-none">
                    <h6 className="inner_heading">{t('examination_note')}</h6>
                    <p className='c-text-pre-wrap'>{this.props.examination_note ? this.props.examination_note : '--'}</p>
                </div>
                {!isEmpty(investigation) && <div className="content-wrapper padding-bottom-10 margin-bottom-none">
                    <h6 className="inner_heading">Investigation</h6>
                    <p className='c-text-pre-wrap'>{investigation ? investigation : '--'}</p>
                </div>}
                {!isEmpty(review_of_system) && <div className="content-wrapper padding-bottom-10">
                    <h6 className="inner_heading">{t('review_of_system')} </h6>
                    {review_of_system.map((item, index) => (
                        <React.Fragment key={index}>
                            <ul className="prescription-filed-details" key={index}>
                                <li>{this.getReviewOfSystemValueByKey(item.category)} :
                                    <span>{item.problems.map((problem, idx) => (
                                        <span key={idx}>{(idx ? ', ' : '') + this.getReviewOfSystemValueByKey(problem)}</span>
                                    ))}</span>
                                </li>

                                {item.comments && <li>{t('comment')} :<span className='c-text-pre-wrap'>{item.comments}</span></li>}
                            </ul>
                        </React.Fragment>
                    ))}
                </div>}
            </>)
    }
}
export default (withTranslation('components/Prescription/Examination/ViewExamination')(ViewExamination));