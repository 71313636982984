import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

const CancelModal = ({ showModal, handleRedirect, handleClose, t }) => {
    return (
        <Modal show={showModal} backdrop="static" keyboard={false} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('alert')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t('confirm_text')}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleRedirect}>
                    {t('ok')}
                </Button>
                <Button variant="danger" onClick={handleClose}>
                    {t('cancel')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default (withTranslation('components/CancelModal')(CancelModal))
