import React from 'react';

export default function MedicalInfoSection({ isLoading = false, patient, t }) {
    return (
        <div className="row">
            <div className="col-24">
                <div className="content-wrapper">
                    <h4 className="wrapper-heading">{t('medical_info')}</h4>
                    <ul className="list-inline diff-tags with-v-line mt-2 mb-2">
                        <li className="list-inline-item">{t('blood_group')}:<span className="ml-2">{patient && patient.blood_group}</span></li>
                        <li className="list-inline-item">{t('allergy')}:<span className="ml-2">{patient && patient.allergy && patient.allergy.join(", ")}</span></li>
                        <li className="list-inline-item">{t('comorbidities')}:<span className="ml-2">{patient && patient.comorbidities && patient.comorbidities.join(", ")}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
