import React from 'react';
import * as Helper from "../../utils/helpers";
import { withTranslation } from 'react-i18next';

class PermissionSections extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            btn_disabled: true,
        }
    }

    componentDidMount() {
        if (document.querySelectorAll('.checkbox' + this.props.request.id + ':checked').length > 0) {
            this.setState({
                btn_disabled: false
            })
        }
    }

    handleChange = (evt) => {
        const { request } = this.props;

        let fieldName = evt.target.name;
        if (evt.target.checked) {
            request[fieldName] = 'allow';
        } else {
            request[fieldName] = 'deny';
        }

        this.props.handleSharePermission(this.props.idx, this.props.request);

        if (document.querySelectorAll('.checkbox' + this.props.request.id + ':checked').length > 0) {
            this.setState({
                btn_disabled: false
            })
        } else {
            this.setState({
                btn_disabled: true
            })
        }
    };

    sharePermissions = (idx) => {
        if (document.querySelector('.checkbox' + this.props.request.id + ':checked') === null) {
            this.setState({
                btn_disabled: true
            });
        } else {
            this.props.handleSubmitPermission(this.props.idx, this.props.request);
        }
    };


    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="request-pending-wrapper">
                    <div className="left-content">
                        <ul >
                            <li>
                                <span>{t('requester_doctor_name')}:</span> <span className="blue">
                                    {this.props.request.requested_by_full_name}
                                </span>
                            </li>
                            <li>
                                <span>{t('specialization')}:</span> <span className="blue">{this.props.request.requested_by_specialization}</span>
                            </li>
                            <li>
                                <span>{t('patient_name')}:</span> <span className="blue">{this.props.request.patient_full_name}</span>
                            </li>
                            <li>
                                <span>{t('patient_id')}:</span> <span className="blue">{this.props.request.patient_code}</span>
                            </li>
                            <li>
                                <span>{t('prescription_id')}:</span> <span className="blue">{this.props.request.prescription_id}</span>
                            </li>
                            <li>
                                <span>{t('requested_date')}:</span> <span className="blue">
                                    {Helper.getDateFormat(this.props.request.requested_at)}</span>
                            </li>
                            {this.props.status === 'approved' && <li>
                                <span>{t('responded_date')}:</span> <span className="blue">
                                    {Helper.getDateFormat(this.props.request.responded_at)}</span>
                            </li>}
                        </ul>
                    </div>
                    <div className="right-content">
                        <div className="checkbox-container">
                            <span className="section">{t('section')}</span>
                            <div className="checbox-wrapper">
                                <div className="check">
                                    <input type="checkbox" id={"symptomps" + this.props.request.id} name="section_symptoms" value="" checked={this.props.request.section_symptoms === 'allow' ? 'checked' : ''} onChange={this.handleChange} disabled={this.props.request.status === 'approved' ? 'disabled' : ''} className={"checkbox" + this.props.request.id} />
                                    <label htmlFor={"symptomps" + this.props.request.id}>{t('present_condition')}</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id={"examination" + this.props.request.id} name="section_examination" value="" checked={this.props.request.section_examination === 'allow' ? 'checked' : ''} onChange={this.handleChange} disabled={this.props.request.status === 'approved' ? 'disabled' : ''} className={"checkbox" + this.props.request.id} />
                                    <label htmlFor={"examination" + this.props.request.id}>{t('examination')}</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id={"history" + this.props.request.id} name="section_history" value="" checked={this.props.request.section_history === 'allow' ? 'checked' : ''} onChange={this.handleChange} disabled={this.props.request.status === 'approved' ? 'disabled' : ''} className={"checkbox" + this.props.request.id} />
                                    <label htmlFor={"history" + this.props.request.id}>{t('history')}</label>
                                </div>
                                {/* <div className="check">
                                    <input type="checkbox" id={"diagnosis" + this.props.request.id} name="section_diagnosis" value="" checked={this.props.request.section_diagnosis === 'allow' ? 'checked' : ''} onChange={this.handleChange} disabled={this.props.request.status === 'approved' ? 'disabled' : ''} className={"checkbox" + this.props.request.id} />
                                    <label htmlFor={"diagnosis" + this.props.request.id}>Diagnosis</label>
                                </div> */}
                            </div>
                            <div className="checbox-wrapper">
                                <div className="check">
                                    <input type="checkbox" id={"icd" + this.props.request.id} name="section_icd" value="" checked={this.props.request.section_icd === 'allow' ? 'checked' : ''} onChange={this.handleChange} disabled={this.props.request.status === 'approved' ? 'disabled' : ''} className={"checkbox" + this.props.request.id} />
                                    <label htmlFor={"icd" + this.props.request.id}>{t('assessment')}</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id={"medicines" + this.props.request.id} name="section_medicines" value="" checked={this.props.request.section_medicines === 'allow' ? 'checked' : ''} onChange={this.handleChange} disabled={this.props.request.status === 'approved' ? 'disabled' : ''} className={"checkbox" + this.props.request.id} />
                                    <label htmlFor={"medicines" + this.props.request.id}>{t('medication')}</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id={"pathology" + this.props.request.id} name="section_pathology_tests" value="" checked={this.props.request.section_pathology_tests === 'allow' ? 'checked' : ''} onChange={this.handleChange} disabled={this.props.request.status === 'approved' ? 'disabled' : ''} className={"checkbox" + this.props.request.id} />
                                    <label htmlFor={"pathology" + this.props.request.id}>{t('pathology_test')}</label>
                                </div>
                            </div>
                            <div className="checbox-wrapper">
                                <div className="check">
                                    <input type="checkbox" id={"radiology" + this.props.request.id} name="section_radiology_tests" value="" checked={this.props.request.section_radiology_tests === 'allow' ? 'checked' : ''} onChange={this.handleChange} disabled={this.props.request.status === 'approved' ? 'disabled' : ''} className={"checkbox" + this.props.request.id} />
                                    <label htmlFor={"radiology" + this.props.request.id}>{t('radiology_test')}</label>
                                </div>
                                <div className="check">
                                    <input type="checkbox" id={"remark" + this.props.request.id} name="section_remarks" value="" checked={this.props.request.section_remarks === 'allow' ? 'checked' : ''} onChange={this.handleChange} disabled={this.props.request.status === 'approved' ? 'disabled' : ''} className={"checkbox" + this.props.request.id} />
                                    <label htmlFor={"remark" + this.props.request.id}>{t('remark')}</label>
                                </div>
                            </div>
                        </div>
                        {this.props.request.status === 'requested' && <div className="text-right">
                            <button className="btn btn-primary" onClick={this.sharePermissions} disabled={this.state.btn_disabled}>{t('share')}</button>
                        </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default (withTranslation('components/RequestPermission/PermissionSections')(PermissionSections))