import React from 'react';
import { Helmet } from 'react-helmet';
import SimpleReactValidator from "simple-react-validator";
import moment from 'moment';
import CardSwipe from 'react-card-swipe';
import * as HELPER from "../../utils/helpers";
import Notification from "../../utils/notification";
import CardService from '../../services/cardService';
import { withTranslation } from 'react-i18next';

class AssignCardPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            card: '',
            first_name: '',
            last_name: '',
            maiden_name: '',
            date_of_birth: '',
            place_of_birth: '',
            contact: '',
            received_by: ''
        };

        CardSwipe.init({
            success: this.handleSwipeSuccess,
            debug: false
        });

        this.searchByCardBtn = React.createRef();
        this.validator = new SimpleReactValidator({ locale: HELPER.getLanguage() });
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSwipeSuccess = (swipeData) => {
        this.setState({
            card: swipeData.line1
        });

        this.searchByCardBtn.current.click();
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let cardObj = HELPER.getCardObj(this.state.card);
        if (cardObj) {
            this.setState({
                card: cardObj.user_code
            });

            let postData = {
                card: {
                    user_code: cardObj.user_code,
                    card_number: cardObj.card_number,
                    hash: cardObj.hash,
                },
                patient: {
                    first_name: this.state.first_name,
                    // middle_name: this.state.middle_name,
                    last_name: this.state.last_name,
                    maiden_name: this.state.maiden_name,
                    date_of_birth: this.state.date_of_birth,
                    place_of_birth: this.state.place_of_birth,
                    contact: this.state.contact,
                },
                card_reissue: {
                    received_by: this.state.received_by
                }
            };
            CardService.attachCardToPatient(postData)
                .then(resp => {
                    this.reset();
                    Notification.show("success", { message: resp.data.message });
                    this.props.history.push('/reissue-cards');
                })
                .catch(error => {
                    if (error.response && error.response.status) {
                        Notification.show("error", error.response.data);
                    } else {
                        Notification.show("error", {message: 'Try Again!'});
                    }
                });
        } else {
            this.setState({
                card: ""
            });
            Notification.show("error", { message: this.props.t('invalid_card') });
        }
    }

    reset = () => {
        this.setState({
            card: '',
            first_name: '',
            // middle_name: '',
            last_name: '',
            maiden_name: '',
            date_of_birth: '',
            received_by: ''
        });
        this.assignCardForm.reset();
    }

    componentDidMount() {
        const { request_id } = this.props.match.params;
        CardService.getReissueCardInfo(request_id).then(resp => {
            const data = resp.data
            this.setState({
                first_name: data.patient_info.first_name,
                last_name: data.patient_info.last_name,
            })
        })
    }

    render() {
        const { t } = this.props;
        return <div>
            <Helmet>
                <title>{t('patient_card_reissue')}</title>
            </Helmet>
            <div className="row">
                <div className="col-sm-24">
                    <h4 className="page-title">{t('verification_details')}</h4>
                    <form onSubmit={this.handleSubmit} name="card_assign_form" className="card-assign-form" ref={form => this.assignCardForm = form}>
                        <div className="content-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="col-sm-24"><span class="text-primary">{t('card')}</span><span class="text-danger">*</span>
                                        <fieldset className="form-group">
                                            {HELPER.config('CARD_DEBUG') === 'false' ?
                                            <input type="text" className="form-control" id="card" name="card" value={this.state.card} onChange={this.handleChange} placeholder={t('search_by_card')} readOnly/> : 
                                            <input type="text" className="form-control" id="card" name="card" value={this.state.card} onChange={this.handleChange} placeholder={t('search_by_card')} /> }
                                            {this.validator.message(t('card'), this.state.card, 'required')}
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="col-sm-24"><span class="text-primary">{t('first_name')}</span><span class="text-danger">*</span>
                                        <fieldset className="form-group">
                                            <input type="text" className="form-control" id="first_name" name="first_name" value={this.state.first_name} onChange={this.handleChange} placeholder={t('enter_first_name')} />
                                            {this.validator.message(t('first_name'), this.state.first_name, 'required')}
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {/* <div className="col-sm-12">
                                    <div className="col-sm-24"><span class="text-primary">Middle Name</span><span class="text-danger">*</span>
                                        <fieldset className="form-group">
                                            <input type="text" className="form-control" id="middle_name" name="middle_name" value={this.state.middle_name} onChange={this.handleChange} placeholder="Enter middle name" />
                                            {this.validator.message('middle_name', this.state.middle_name, 'required')}
                                        </fieldset>
                                    </div>
                                </div> */}
                                <div className="col-sm-12">
                                    <div className="col-sm-24"><span class="text-primary">{t('last_name')}</span><span class="text-danger">*</span>
                                        <fieldset className="form-group">
                                            <input type="text" className="form-control" id="last_name" name="last_name" value={this.state.last_name} onChange={this.handleChange} placeholder={t('enter_last_name')} />
                                            {this.validator.message(t('last_name'), this.state.last_name, 'required')}
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="col-sm-24"><span class="text-primary">{t('mothers_maiden_name')}</span><span class="text-danger">*</span>
                                        <fieldset className="form-group">
                                            <input type="text" className="form-control" id="maiden_name" name="maiden_name" value={this.state.maiden_name} onChange={this.handleChange} placeholder={t('enter_maiden_name')} />
                                            {this.validator.message(t('maiden_name'), this.state.maiden_name, 'required')}
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="col-sm-24"><span class="text-primary">{t('dob')}</span><span class="text-danger">*</span>
                                        <fieldset className="form-group">
                                            <input type="date" className="form-control" value={this.state.date_of_birth} id="date_of_birth" name="date_of_birth" onChange={this.handleChange} max={moment().format('YYYY-MM-DD')} />
                                            {this.validator.message(t('date_of_birth'), this.state.date_of_birth, 'required')}
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="col-sm-24"><span class="text-primary">{t('place_of_birth')}</span><span class="text-danger">*</span>
                                        <fieldset className="form-group">
                                            <input type="text" className="form-control" value={this.state.place_of_birth} id="place_of_birth" name="place_of_birth" onChange={this.handleChange} placeholder={t('enter_place_of_birth')} />
                                            {this.validator.message(t('place_of_birth'), this.state.place_of_birth, 'required')}
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="col-sm-24"><span class="text-primary">{t('contact_number')}</span><span class="text-danger">*</span>
                                        <fieldset className="form-group">
                                            <input type="text" className="form-control" value={this.state.contact} id="contact" name="contact" onChange={this.handleChange} placeholder={t('enter_contact_number')} />
                                            {this.validator.message(t('contact_number'), this.state.contact, 'required|numeric')}
                                        </fieldset>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="col-sm-24"><span class="text-primary">{t('received_by')}</span><span class="text-danger">*</span>
                                        <fieldset className="form-group">
                                            <input type="text" className="form-control" value={this.state.received_by} id="received_by" name="received_by" onChange={this.handleChange} placeholder={t('enter_receiver_name')}/>
                                            {this.validator.message(t('received_by'), this.state.received_by, 'required|string')}
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4 pb-4">
                                <div className="col-sm-24 text-center">
                                    <button type="submit" className="btn btn-primary">{t('verify_activate')}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    }
}

export default (withTranslation('pages/patient/AssignCardPage')(AssignCardPage));