import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Notification from '../../../../utils/notification';
import PrescriptionService from '../../../../services/prescriptionService';
import { withTranslation } from 'react-i18next';

class PersonalNoteModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            personal_note: '',
            buttonText: 'Save',
            isButtonDisabled: false,
            show: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onShow = () => {
        this.setState({
            personal_note: this.props.personal_note
        })
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit = (prescription_id) => {
        const postData = {
            personal_note: this.state.personal_note
        }

        this.setState({
            isButtonDisabled: true,
            buttonText: 'Please wait....'
        });       
        PrescriptionService.updatePrescriptionPersonalNote(prescription_id, postData).then(resp => {
            Notification.show('success', { message: this.props.t('note_updated') });
            this.props.setPrescriptionPersonalNote(this.state.personal_note);
            this.props.onSuccess(this.props);

            PrescriptionService.getUpdatedPrescriptionsDownloadLink(prescription_id).then(resp => {
            }).catch(error => {
                this.setState({
                    isloading: false
                });
                Notification.show('error', error.response.data);
            });

            window.location.reload(); 

            
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
            .finally(() => {
                this.setState({
                    isButtonDisabled: false,
                    buttonText: 'Save'
                });
            });
    }

    render() {
        const { t } = this.props;
        return (
            <Modal
                show={this.props.show}
                onShow={this.onShow}
                onHide={this.props.handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <Form>
                        <Form.Control as="textarea" rows={3} name="personal_note" value={this.state.personal_note} onChange={this.onChange} placeholder={t('enter_personal_note')} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" disabled={this.state.isButtonDisabled} onClick={this.props.handleClose}>
                        {t('cancel')}
                    </Button>
                    <Button variant="primary" disabled={this.state.isButtonDisabled} onClick={e => this.handleSubmit(this.props.id)}>
                        {t(this.state.buttonText)}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default (withTranslation('components/Prescription/Recommendation/components/PersonalNoteModal')(PersonalNoteModal))
