import React from 'react';
import BarGraph from '../Graph/BarGraph';
import { withTranslation } from 'react-i18next';

class PatientVisitedGraph extends React.Component {

    handlePatientVisitedView = (view) => {
        this.props.renderDashboardGraph('patient_visited', view)
    }

    render() {
        const { t } = this.props;
        return (
            <BarGraph
                barColor="#7BE874"
                barLabel={t('patient_visits')}
                yScaleLabel={t('no_of_patients')}
                datasetLabels={this.props.patient_added_graph_data.x_axis_label}
                datasetCounts={this.props.patient_added_graph_data.counts}
                handleViewChange={this.handlePatientVisitedView}
                t={t}
            />
        )
    }
}
export default (withTranslation('components/Dashboard/PatientVisitedGraph')(PatientVisitedGraph))