import React from 'react';
import { Helmet } from "react-helmet";
import { Form, Table, Button } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import AppointmentService from '../../services/appointmentService';
import Notification from '../../utils/notification';
import * as loaderAction from '../../actions/loaderAction';
import { capitalize, has, isEmpty } from 'lodash';
import * as SlotComponents from '../../components/Slot/CreateSlotComponents';
import SimpleReactValidator from "simple-react-validator";
import TimePicker from 'react-bootstrap-time-picker';
import { withTranslation } from 'react-i18next';

class CreateSlotPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            doctors: '',
            doctor_profile_id: '',
            from: '',
            to: '',
            location_id: '',
            days: '',
            appointment_duration: '',
            appointment_duration_in: 'minutes',
            holiday: {},
            holidays: [],
        }
        this.validator = new SimpleReactValidator({ locale: localStorage.getItem('i18nextLng') });
        this.holidayValidator = new SimpleReactValidator({ locale: localStorage.getItem('i18nextLng') });
    }

    handleLocationFilter = (user_location_id) => {
        this.props.showLoader();
        AppointmentService.getDoctorByLocation(user_location_id).then(resp => {
            this.setState({
                doctors: resp.data,
                location_id: user_location_id,
                doctor_profile_id: '',
                from: '',
                to: '',
                days: ''
            });
            this.props.hideLoader();
        }).catch(_error => {
            this.props.hideLoader();
            Notification.show('error', { message: this.props.t('something_wrong_location_change') })
        })
    }

    handleSelectDoctor = (evt) => {
        this.setState({
            [evt.target.name]: evt.target.value,
            from: '',
            to: '',
            days: ''
        })
    }

    handleDuration = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    componentDidUpdate(_prevProps, prevState) {
        if ((prevState.from && prevState.to) && (this.state.from && this.state.to) && ((this.state.from !== prevState.from) || (this.state.to !== prevState.to))) {
            this.renderSelectDayChart();
        }
    }
    renderSelectDayChart = () => {
        const { from, to, location_id, doctor_profile_id } = this.state
        let startDate = moment(from), endDate = moment(to), days = {}, now = startDate.clone(), slot_days = [];
        while (now.isSameOrBefore(endDate)) {
            slot_days.push(now.format('YYYY-MM-DD'));
            now.add(1, 'days');
        }
        this.props.showLoader();
        AppointmentService.getSlotdates(location_id, doctor_profile_id).then(resp => {
            let existing_slot_days = [...resp.data.data];
            slot_days.forEach(function (day) {
                let found = existing_slot_days.find(item => { return item === day })
                if (!found) {
                    let weekday = (moment(day)).format('dddd')
                    days[weekday.toLowerCase()] = { slots: [{ opening_time: moment('00:00', 'HH:mm'), closing_time: '' }], checked: false, selected: false, closed: false }
                }
            });
            this.setState({ days })
            this.props.hideLoader();
        }).catch(err => {
            this.props.hideLoader();
            Notification.show('error', err.response.data)
        });
    }

    handleTimeChange = (value, day, index, name) => {
        let { days } = this.state;
        let time = new Date(value * 1000).toISOString().substr(11, 5);
        time = moment(time, 'HH:mm');
        days[day]['slots'][index][name] = time;
        this.setState({ days })
    }

    handleRadioChange = (day, type) => {
        let { days } = this.state
        if (type === 'select') {
            days[day].selected = true;
            days[day].closed = false;;
        } else if (type === 'closed') {
            days[day]['slots'] = [{}];
            days[day].selected = false;
            days[day].closed = true;
        }
        this.setState({ days })
    }
    handleCheckboxChange = (day, type, evt) => {
        let { days } = this.state;
        if (type === 'check') {
            days[day].checked = evt.target.checked;
        } else if (type === 'checkAll') {
            Object.keys(days).map((day) => (
                days[day].checked = evt.target.checked
            ))
        }
        this.setState({ days })
    }
    addSlots = (day) => {
        let { days } = this.state;
        days[day]['slots'] = [...days[day]['slots'], { opening_time: '', closing_time: '' }];
        this.setState({ days })
    }
    removeSlot = (day, index) => {
        let { days } = this.state;
        days[day]['slots'].splice(index, 1)
        this.setState({ days })
    }

    handleHolidayChange = (evt) => {
        let { holiday } = this.state;
        holiday[evt.target.name] = evt.target.value;
        this.setState({ holiday })
    }

    addHoliday = () => {
        if (!this.holidayValidator.allValid()) {
            this.holidayValidator.showMessages();
            this.forceUpdate();
            return false;
        }
        let { holidays, holiday } = this.state;
        if (!isEmpty(holiday)) {
            holidays = [...this.state.holidays, holiday];
            this.setState({ holidays, holiday: {} })
        }
    }

    deleteHoliday = (index) => {
        let { holidays } = this.state;
        holidays = holidays.filter((_val, idx) => idx !== index);
        this.setState({ holidays })
    }

    renderBlock = (key, index) => {
        let { days } = this.state;
        return <tr>
            {index === 0 && <React.Fragment>
                <td>
                    {['checkbox'].map((type) => (
                        <Form.Check
                            type={type}
                            id={`default-${type}-${key}-${index}`}
                            label={``}
                            onChange={(e) => this.handleCheckboxChange(key, 'check', e)}
                            checked={days[key].checked ? 'checked' : ''}
                        />
                    ))}
                </td>
                <td>{capitalize(key)}</td>
                <td>
                    {['radio'].map((type) => (
                        <Form.Check
                            type={type}
                            id={`select-day-${type}-${key}-${index}`}
                            label={``}
                            onChange={() => this.handleRadioChange(key, 'select')}
                            checked={days[key].selected ? 'checked' : ''}
                        />
                    ))}
                </td>
                <td>
                    {['radio'].map((type) => (
                        <Form.Check
                            type={type}
                            id={`closed-day-${type}-${key}-${index}`}
                            label={``}
                            onChange={() => this.handleRadioChange(key, 'closed')}
                            checked={days[key].closed ? 'checked' : ''}
                        />
                    ))}
                </td>
            </React.Fragment>}
            {index > 0 && <td colSpan="4"></td>}
            {days[key].closed && <td colSpan="3">
                <div className="slot-cls">Closed</div>
            </td>}
            {!days[key].closed && <React.Fragment>
                <td>
                    <TimePicker
                        format={24}
                        name="opening_time"
                        value={days[key]['slots'][index].opening_time ? days[key]['slots'][index].opening_time.format("HH:mm") : null}
                        onChange={(value) => this.handleTimeChange(value, key, index, 'opening_time')}
                    />
                    {days[key].selected && this.validator.message("opening_time", days[key]['slots'][index].opening_time, "required")}
                </td>
                <td>
                    <TimePicker
                        format={24}
                        name="closing_time"
                        value={days[key]['slots'][index].closing_time ? days[key]['slots'][index].closing_time.format("HH:mm") : null}
                        onChange={(value) => this.handleTimeChange(value, key, index, 'closing_time')}
                    />
                    {days[key].selected && this.validator.message("closing_time", days[key]['slots'][index].closing_time, "required")}
                </td>
                {index === 0 && <td className="last">
                    <span onClick={() => this.addSlots(key)} className="add"></span>
                </td>}
                {index > 0 && <td className="last">
                    <span onClick={() => this.removeSlot(key, index)} className="remove"></span>
                </td>}
            </React.Fragment>}
        </tr>
    }

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    createSlot = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let { appointment_duration, appointment_duration_in } = this.state, slots = {};
        let days = { ...this.state.days }

        Object.keys(days).map((key, _idx) => {
            let day = days[key];
            return day['slots'].map(time => {
                if (day.selected) {
                    let opening_time = time.opening_time.clone()
                    let closing_time = time.closing_time.clone();
                    while (opening_time.isBefore(closing_time)) {
                        let slot = has(slots, key) ? slots[key] : []
                        slots[key] = [...slot, { "start_time": opening_time.format("HH:mm"), "end_time": opening_time.add(appointment_duration, appointment_duration_in).format("HH:mm") }];
                    }

                } else if (day.closed) {
                    slots[key] = []
                }
                return true;
            })
        });
        if (isEmpty(slots)) {
            Notification.show('error', { message: this.props.t('select_status_of_marked_day') });
            return false;
        }
        let slotData = {
            "from": this.state.from,
            "to": this.state.to,
            "location_id": this.state.location_id,
            "doctor_profile_id": this.state.doctor_profile_id,
            "holidays": this.state.holidays,
            "slots": slots
        }
        this.props.showLoader();
        AppointmentService.createSlot(slotData).then(resp => {
            this.props.hideLoader();
            Notification.show('success', resp.data);
            this.props.history.push("/slots")
        }).catch(err => {
            this.props.hideLoader();
            Notification.show('error', err.response.data)
        });
    }

    isAllChecked = () => {
        let { days } = this.state
        const { t } = this.props;
        let checkedDays = Object.keys(days).map((day) => {
            return days[day].checked;
        });
        return checkedDays.includes(false) ? '' : 'checked'
    }

    render() {
        const { selected_user_profile, t } = this.props;
        const { days } = this.state;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('create_slot')}</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title no-heading">
                            <Link to='/slots'>‹ {t('back')}</Link>
                            <span className="page-title-txt">{t('create_slot')}</span>
                        </h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-24">
                        <div className="content-wrapper">
                            <Form inline className="row create-slot-form">
                                {selected_user_profile && <SlotComponents.SelectLocation
                                    selected_profile={selected_user_profile}
                                    handleLocationChange={this.handleLocationFilter}
                                    t={t} />}
                                <SlotComponents.SelectDoctor doctors={this.state.doctors}
                                    doctor_profile_id={this.state.doctor_profile_id}
                                    handleSelectDoctor={this.handleSelectDoctor}
                                    t={t} />
                                <SlotComponents.SelectDuration
                                    from={this.state.from}
                                    to={this.state.to}
                                    handleDuration={this.handleDuration}
                                    t={t} />
                            </Form>
                            <Form inline>
                                {this.validator.message(t("location_id"), this.state.location_id, "required")}
                                {this.validator.message(t("doctor_profile_id"), this.state.doctor_profile_id, "required")}
                                {this.validator.message(t("from_date"), this.state.from, "required")}
                                {this.validator.message(t("to_date"), this.state.to, "required")}
                            </Form>
                        </div>
                    </div>
                    {this.state.doctor_profile_id && this.state.location_id && this.state.from && this.state.to && isEmpty(this.state.days) && this.renderSelectDayChart()}
                    <div className="col-md-24">
                        <div className="content-wrapper">
                            <h6>{t('select_days_which_doctor_available')}:</h6>
                            {isEmpty(this.state.days) && <Form><Form.Label>{t('no_data_selected')}</Form.Label></Form>}
                            {this.state.days &&
                                <Form>
                                    <Table hover className="slot-datatable">
                                        <thead>
                                            <tr>
                                                <th>
                                                    {['checkbox'].map((type) => (
                                                        <Form.Check
                                                            type={type}
                                                            id={`default-${type}`}
                                                            key={`default-${type}`}
                                                            label={``}
                                                            onChange={(e) => this.handleCheckboxChange(null, 'checkAll', e)}
                                                            checked={this.isAllChecked()}
                                                        />
                                                    ))}
                                                </th>
                                                <th>{t('days')}</th>
                                                <th>{t('select_day')}</th>
                                                <th>{t('closed')}</th>
                                                <th>{t('opening_time')}</th>
                                                <th>{t('closing_time')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(days).map((key, _idx) => (
                                                days[key]['slots'].map((_value, index) => (
                                                    this.renderBlock(key, index)
                                                ))
                                            ))}
                                        </tbody>
                                    </Table>
                                </Form >}
                        </div>
                    </div>

                    <div className="col-md-24">
                        <div className="content-wrapper">
                            <h6>{t('appointment_duration')}</h6>
                            <SlotComponents.AppointmentDuration
                                appointment_duration={this.state.appointment_duration}
                                appointment_duration_in={this.state.appointment_duration_in}
                                changeHandler={this.changeHandler}
                                t={t} />
                            {this.validator.message(t('appointment_duration'), this.state.appointment_duration, "required")}
                        </div>
                    </div>
                    <div className="col-md-24">
                        <div className="content-wrapper">
                            <h6>{t('add_holiday')}</h6>
                            <div className="slot-add-holiday mt-3">
                                <SlotComponents.AddHoliday holiday={this.state.holiday}
                                    handleHolidayChange={this.handleHolidayChange}
                                    addHoliday={this.addHoliday}
                                    validator={this.holidayValidator}
                                    t={t} />
                            </div>

                            {this.state.holidays.length > 0 && <React.Fragment>
                                <h6 className="mt-5">{t('list_holiday')}</h6>
                                <SlotComponents.HolidayList holidays={this.state.holidays}
                                    deleteHoliday={this.deleteHoliday} />
                            </React.Fragment>}
                        </div>
                    </div>
                    <div className="col-24 text-right mb-5 mt-4">
                        <Button variant="primary mr-3" onClick={this.createSlot}>{t('create_slot')}</Button>
                        <Button variant="outline-primary bg-dh-white" onClick={() => {
                            this.props.history.push('/appointments');
                        }}>{t('cancel')}</Button>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile
});
const mapActionsToProps = ({
    showLoader: loaderAction.loaderShow,
    hideLoader: loaderAction.loaderHide
})
export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/slot-management/CreateSlotPage')(CreateSlotPage));