import React from 'react'
import SearchHospital from '../../components/AmbulanceRecord/SearchHospital'

export function PatientStatusSection({ value = null, onChange, validator, t }) {
    const status = [
        { 'key': 'alive', 'label': t('alive') },
        { 'key': 'dead', 'label': t('dead') },
        { 'key': 'dead-in-transit', 'label': t('dead_in_transit') }
    ]

    const handleChange = (e) => {
        if (e.target.checked) {
            onChange('patient_status', e.target.value)
        } else {
            onChange('patient_status', null)
        }
    }

    return (
        <div className="content-wrapper pb-4">
            <h4 className="wrapper-heading">{t('patient_status')}</h4>
            <form>
                {status.map((obj) => {
                    return (
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={obj.key}
                                id={"status_" + obj.key}
                                checked={value === obj.key ? true : false}
                                onChange={handleChange}
                            />
                            <label className="form-check-label color-blue" htmlFor={"status_" + obj.key}>
                                {obj.label}
                            </label>
                        </div>
                    )
                })}
                {validator.message(t("patient_status"), value, "required")}
            </form>
        </div>
    )
}

export function PatientConditionSection({ value = null, onChange, t }) {
    const handleChange = (e) => {
        onChange('patient_condition', e.target.value)
    }

    return (
        <div className="content-wrapper pb-4">
            <h4 className="wrapper-heading">{t('current_condition_patient')}</h4>
            <div className="form-group">
                <textarea className="form-control" rows="3" placeholder={t('type_condition_here')} onChange={handleChange} value={value ? value : ''}>{value}</textarea>
            </div>
        </div>
    )
}

export function MedicationSection() {
    return (
        <div className="content-wrapper pb-4">
            <h4 className="wrapper-heading">Ambulance Treatment (Medicine Details)</h4>
        </div>
    )
}

export function NoteSection({ value = null, onChange, t }) {
    const handleChange = (e) => {
        onChange('notes', e.target.value)
    }

    return (
        <div className="content-wrapper pb-4">
            <h4 className="wrapper-heading">{t('note')}</h4>
            <div className="form-group">
                <textarea className="form-control" rows="3" placeholder={t("type_here")} onChange={handleChange} value={value ? value : ''}>{value}</textarea>
            </div>
        </div>
    )
}

export function SelectHospitalSection({ validator, handleSearchHospital, hospital, t }) {
    return (
        <div className="content-wrapper pb-4">
            <h4 className="wrapper-heading">{t('hospital_detail')}</h4>
            <SearchHospital
                hospital={hospital}
                validator={validator}
                handleSearchHospital={handleSearchHospital}
            />
        </div>
    )
}
