import React from "react";
import PrescriptionService from '../services/prescriptionService';
import Notification from '../utils/notification';
import { withTranslation } from 'react-i18next';

class RepeatPrescriptionButton extends React.Component {
    state = {
        isloading: false
    }
    repeatPrescription = () => {
        this.setState({
            isloading: true
        });
        PrescriptionService.repeatPrescription(this.props.prescriptionId).then(resp => {
            this.setState({
                isloading: false
            });
            const { prescription_id } = resp.data
            this.props.history.push({ pathname: `/patients/${this.props.patientId}/prescriptions/${prescription_id}`, state: 'repeated' })
        }).catch(error => {
            this.setState({
                isloading: false
            });
            Notification.show('error', error.response.data);
        });

    }
    render() {
        const { t } = this.props;
        return (<React.Fragment>
            {this.state.isloading && this.state.isloading && <div className="loading">{t('loading')}</div>}
            <button className="btn btn-outline-primary ml-2" onClick={this.repeatPrescription}>
                {t('repeat_prescription')}
            </button>
        </React.Fragment >)
    }
}

export default (withTranslation('components/RepeatPrescriptionButton')(RepeatPrescriptionButton));