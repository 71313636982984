import React from 'react';

export const ReproductiveHistory = ({ gynaecology_history, handleChange ,t }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>{t('reproductive_history')}</h6>
        </div>
        <div className="form-group col-33">
            <label>{t('number_of_pregnancies')} :</label>
            <input type="text" id="pregnancies_number" name="pregnancies_number" className="form-control" onChange={handleChange} value={gynaecology_history.pregnancies_number ? gynaecology_history.pregnancies_number : ''} />
        </div>
        <div className="form-group col-33">
            <label>{t('number_of_miscarriage')} :</label>
            <input type="text" id="miscarrages_number" name="miscarrages_number" className="form-control" onChange={handleChange} value={gynaecology_history.miscarrages_number ? gynaecology_history.miscarrages_number : ''} />
        </div>
        <div className="form-group col-33 padding-none">
            <label>{t('number_of_abortions')} :</label>
            <input type="text" id="abortions_number" name="abortions_number" className="form-control" onChange={handleChange} value={gynaecology_history.abortions_number ? gynaecology_history.abortions_number : ''} />
        </div>
    </React.Fragment>
}

export const GynaecologyHistory = ({ gynaecology_history, handleChange, getValue, t }) => {
    let { age_of_first_period, bleeding_between_periods, is_menopause, menopause_age, contraception, pap_smear, is_regular_periods, pap_smear_test_date, menopause_comment } = gynaecology_history;
    return <React.Fragment>
        <div className="heading">
            <h6>{t('gynaecological_history')}</h6>
        </div>
        <div className="form-group first">
            <label>{t('age_first_period')} :</label>
            <input type="text" id="age_of_period" name="age_of_first_period" className="form-control" onChange={handleChange} value={age_of_first_period ? age_of_first_period : ''} />
        </div>
        <div className="form-group second">
            <label>{t('regular_period')} : </label>
            <select className="form-control" name="is_regular_periods" onChange={handleChange} value={getValue(is_regular_periods)}>
                <option value='' defaultValue>---{t('select')}---</option>
                <option value={true}>{t('yes')}</option>
                <option value={false}>{t('no')}</option>
            </select>
        </div>
        <div className="form-group third no-padding">
            <label>Duration of Period :</label>
            <input type="number" id="bleeding_between_periods" name="bleeding_between_periods" className="form-control" onChange={handleChange} value={bleeding_between_periods ? bleeding_between_periods : ""} />
            <span> Days</span>
        </div>
        <div className="form-group fourth">
            <label>{t('menopause')} : </label>
            <select className="form-control" name="is_menopause" onChange={handleChange} value={getValue(is_menopause)}>
                <option value='' defaultValue>---{t('select')}---</option>
                <option value={true}>{t('yes')}</option>
                <option value={false}>{t('no')}</option>
            </select>
        </div>
        <div className="form-group fifth">
            <label> Menopause start date :</label>
            <input type="date" id="menopause_age" name="menopause_age" className="form-control" onChange={handleChange} value={menopause_age ? menopause_age : ''} 
            />
        </div>
        {
            is_menopause && <div className='form-group fifth'>
            <label style={{marginRight: '8px'}}>Menopause Comment :</label>
            <textarea
                  className="form-control"
                  name="menopause_comment"
                  id="menopause_comment"
                  onChange={handleChange}
                  style={{marginBottom: '-15px'}}
                >{menopause_comment ? menopause_comment : ''}</textarea>
        </div>
        }
        <div className="form-group sixth">
            <label>{t('contraception')} : </label>
            <select className="form-control" name="contraception" onChange={handleChange} value={contraception ? contraception : ''}>
                <option value='' defaultValue>---{t('select')}---</option>
                <option value='ocp'>{t('ocp')}</option>
                <option value="injectable">{t('injectable')}</option>
                <option value="subcutaneous">{t('subcutaneous')}</option>
                <option value="iud">{t('iud')}</option>
            </select>
        </div>
        <div className="form-group seventh no-padding">
            <label>{t('pap_smear')} : </label>
            <select className="form-control" name="pap_smear" onChange={handleChange} value={pap_smear ? pap_smear : ''}>
                <option value='' defaultValue>---{t('select')} ---</option>
                <option value="normal">{t('normal')} </option>
                <option value="abnormal">{t('abnormal')} </option>
                <option value="never-did-papsmear">{t('never_did_pap_smear')} </option>
            </select>
        </div>
        <div className="form-group fifth no-padding">
            <label>Pap Smear Test Date: </label>
            <input type="date" name="pap_smear_test_date" className="form-control" onChange={handleChange} value={pap_smear_test_date ? pap_smear_test_date : ''} />
        </div>
    </React.Fragment>
}
export const Allergy = ({ gynaecology_history, handleChange, getValue, t }) => {
    let { allergies, is_hospitalized_allergic_reaction, allergies_comment } = gynaecology_history;
    return <React.Fragment>
        <div className="heading">
            <h6>{t('allergies')}</h6>
        </div>
        <div className="form-group full">
            <label>{t('allergies_to_medications')} :</label>
            <input type="text" id="allergies" name="allergies" className="form-control" onChange={handleChange} value={allergies ? allergies : ''} />
        </div>
        <div className="form-group full">
            <label>{t('ever_hospitalized_for_allergic_reaction')} : </label>
            <select className="form-control" name="is_hospitalized_allergic_reaction" onChange={handleChange} value={getValue(is_hospitalized_allergic_reaction)}>
                <option value='' defaultValue>---{t('select')}---</option>
                <option value={true}>{t('yes')}</option>
                <option value={false}>{t('no')}</option>
            </select>
        </div>
        <div className="form-group full">
            <label htmlFor="comment">Comment : </label>
            <input type="text" className="form-control" name="allergies_comment" onChange={handleChange} value={allergies_comment ? allergies_comment : ""}/>
        </div>
    </React.Fragment>
}