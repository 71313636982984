import React from 'react';

export const General = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>{t('general.general')}</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                {/* weight gain */}
                <input type="checkbox" id='recent-weight-gain' name='recent-weight-gain' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'recent-weight-gain') ? 'checked' : ''} />
                <label htmlFor='recent-weight-gain'>{t('general.recent_weight_gain')}</label>
                {/* weight loss */}
                <input type="checkbox" id='recent-weight-loss' name='recent-weight-loss' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'recent-weight-loss') ? 'checked' : ''} />
                <label htmlFor='recent-weight-loss'>{t('general.recent_weight_loss')}</label>
                {/* fatigue */}
                <input type="checkbox" id='fatigue' name='fatigue' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'fatigue') ? 'checked' : ''} />
                <label htmlFor='fatigue'>{t('general.fatigue')}</label>
                {/* weakness */}
                <input type="checkbox" id='weakness' name='weakness' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'weakness') ? 'checked' : ''} />
                <label htmlFor='weakness'>{t('general.weakness')}</label>
                {/* fever */}
                <input type="checkbox" id='fever' name='fever' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'fever') ? 'checked' : ''} />
                <label htmlFor='fever'>{t('general.fever')}</label>
                {/* night sweats */}
                <input type="checkbox" id='night-sweats' name='night-sweats' onChange={e => handleChange(e, 'general')} checked={checkIfProblemExist('general', 'night-sweats') ? 'checked' : ''} />
                <label htmlFor='night-sweats'>{t('general.night_sweats')}</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('general.comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('general.type_here')} onChange={e => handleChange(e, 'general')} value={getCategoryComment('general') ? getCategoryComment('general') : ''}></textarea>
    </React.Fragment>
}