import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet';
import DataGrid from "../../components/DataGrid";
import { Link } from "react-router-dom";
import * as AmbulanceAction from '../../actions/ambulanceAction'
import { getDateFormat, getLocatTimezone } from "../../utils/helpers";
import { withTranslation } from 'react-i18next';

export class ListRecordPage extends Component {
    constructor(props) {
        super(props);
        this.props.paginateRecords({ sortBy: { sortField: "assisted_at", sortOrder: "asc" } });
    }

    columns = () => {
        let { t } = this.props;
        return [{
            dataField: "patient_full_name",
            text: t('patient_name'),
        },
        {
            dataField: "assisted_at",
            text: t("date"),
            sort: true,
            formatter: (_cell, row) => {
                return getDateFormat(row.assisted_at);
            }
        },
        {
            dataField: "Time",
            text: t("Time"),
            formatter: (_cell, row) => {
                return getLocatTimezone(row.assisted_at);
            }
        },
        {
            dataField: "actions",
            text: t("actions"),
            isDummyField: true,
            formatter: this.actionFormatter
        }
        ];
    };

    actionFormatter = (_cell, row) => {
        return (
            <React.Fragment>
                <Link to={"/ambulance/records/" + row.record_id + "/view"} ><span className="icon file"></span></Link>
                {row.status === 'assisted' && <Link to={"/ambulance/records/" + row.record_id}><span className="icon edit"></span></Link>}
            </React.Fragment>
        )
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }

        this.props.paginateRecords({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText });
    };

    render() {
        let { data, meta } = this.props.ambulance;
        const { t } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('prescription_details')}</title>
                </Helmet>
                <h4 className="page-title">{t('prescriptions')}</h4>
                <div className="content-wrapper">
                    <DataGrid
                        columns={this.columns()}
                        data={data}
                        meta={meta}
                        handleTableChange={this.handleTableChange}
                    />
                </div>

            </React.Fragment>

        );
    }
}

function mapStateToProps(state) {
    return {
        ambulance: state.ambulance_list,
    };
}

const mapDispatchToProps = {
    paginateRecords: AmbulanceAction.list
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('pages/ambulance/ListRecordPage')(ListRecordPage))
