import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import PersonalHistory from './components/PersonalHistory/AddPersonalHistory';
import FamilyHistory from './components/FamilyHistory/AddFamilyHistory';
import SurgicalHistory from './components/SurgicalHistory/AddSurgicalHistory';
import GynaecologyHistory from './components/GynaecologyHistory/AddGynaecologyHistory';
import SimpleReactValidator from 'simple-react-validator';
import PrescriptionService from '../../../services/prescriptionService';
import Notification from '../../../utils/notification';
import CurrentMedicineBlock from '../CurrentMedicineBlock';
import { removeAutoSaveWithInterval, setAutoSaveWithInterval } from '../../../utils/helpers';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import * as HELPER from '../../../utils/helpers';

class HistoryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            medicines: [],
            patient_history: {
                personal_history: {
                    substance: []
                },
                family_history: {},
                obstetrics_gynaecology: {},
                surgical_history: [{}],
                current_medicine: []
            },
            include_personal_history: false,
            include_family_history: false,
            include_surgical_history: false,
            include_gynaecology_history: false,
            include_medication_history: false
        }
        this.historyValidator = new SimpleReactValidator({ locale: HELPER.getLanguage() });
    }
    handleCheck = (e) => {
        this.setState({ [e.target.name]: !this.state[e.target.name] });
        this.props.handleIsPrescriptionEdited('history');
    }
    componentDidMount() {
        // let patient_history, prescripton_history;
        this.props.showLoader();
        this.getPatientHistory();
        setAutoSaveWithInterval(this.props.selected_user_profile, this.handleHistorySaveAndNext)
    }

    componentWillUnmount() {
        removeAutoSaveWithInterval()
    }

    getPatientHistory = () => {
        let patient_history, prescripton_history;
        PrescriptionService.getPatientHistory(this.props.patientId).then(resp => {
            patient_history = resp.data;
            PrescriptionService.getHistory(this.props.prescriptionId).then(resp => {
                prescripton_history = resp.data
                this.setState({
                    patient_history: {
                        personal_history: prescripton_history.personal_history ? prescripton_history.personal_history : (patient_history.personal_history ? patient_history.personal_history : this.state.patient_history.personal_history),

                        family_history: prescripton_history.family_history ? prescripton_history.family_history : (patient_history.family_history ? patient_history.family_history : this.state.patient_history.family_history),

                        obstetrics_gynaecology: prescripton_history.obstetrics_gynaecology ? prescripton_history.obstetrics_gynaecology : (patient_history.obstetrics_gynaecology ? patient_history.obstetrics_gynaecology : this.state.patient_history.obstetrics_gynaecology),
                        surgical_history: prescripton_history.surgical_history ? prescripton_history.surgical_history : (patient_history.surgical_history ? patient_history.surgical_history : this.state.patient_history.surgical_history),
                        current_medicine: prescripton_history.current_medicine ? prescripton_history.current_medicine.prescription_drugs : this.state.patient_history.current_medicine,
                    },
                    include_personal_history: prescripton_history.personal_history ? true : true,
                    include_family_history: prescripton_history.family_history ? true : true,
                    include_surgical_history: prescripton_history.surgical_history ? true : true,
                    include_gynaecology_history: prescripton_history.obstetrics_gynaecology ? true : true,
                    include_medication_history: prescripton_history.current_medicine ? true : true,
                })
                this.props.hideLoader();
            }).catch(error => {
                this.props.hideLoader();
                Notification.show('error', error.response.data)
            })
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    // Start Medicine Block Events
    handleMedicineChange = (idx, data) => {
        let { patient_history } = this.state;
        const newMedicines = patient_history.current_medicine.map((medicine, sidx) => {
            if (idx !== sidx) return medicine;

            return { ...medicine, ...data };
        });
        patient_history['current_medicine'] = newMedicines;
        if(this.state.patient_history['current_medicine'].filter((item) => item.is_print === true).length <= 0 && newMedicines) {
            this.setState({
                include_medication_history: false
            })
        } else if(this.state.patient_history['current_medicine'].filter((item) => item.is_print === true).length > 0) {
            this.setState({
                include_medication_history: true
            })
        }
        this.setState({ patient_history: patient_history })
        this.props.handleIsPrescriptionEdited('history');
    };
    handleAddMedicine = () => {
        let { patient_history } = this.state;
        let current_medicine = patient_history['current_medicine'] ? patient_history['current_medicine'] : [];
        let medicines = current_medicine.concat([{
            drug_id: '',
            drug_name: '',
            drug_generic_id: '',
            drug_generic_name: '',
            dosage: '',
            meal: '',
            duration: '',
            duration_type: '',
            is_generic_allowed: false,
            quantity: '',
            drug_type: '',
            is_other: false,
            over_dosage: false,
            is_generic_allowed_disable: false,
            is_print: true
        }]);
        patient_history['current_medicine'] = medicines;
        this.setState({ patient_history })
        this.props.handleIsPrescriptionEdited('history');
    };
    handleRemoveMedicine = (event) => {
        let { patient_history } = this.state;
        const idx = Number(event.target.dataset['id']);
        const medicines = patient_history.current_medicine.filter((s, sidx) => idx !== sidx);
        patient_history['current_medicine'] = medicines;
        this.setState({ patient_history: patient_history })
        this.props.handleIsPrescriptionEdited('history');
    };
    //End medicine block
    handlePersonalHistory = (personal_history) => {
        let { patient_history } = this.state;
        patient_history['personal_history'] = personal_history;
        this.setState({ patient_history: patient_history })
        this.props.handleIsPrescriptionEdited('history');
    }
    handleFamilyHistory = (family_history) => {
        let { patient_history } = this.state;
        patient_history['family_history'] = family_history;
        this.setState({ patient_history: patient_history })
        this.props.handleIsPrescriptionEdited('history');
    }
    handleSurgicalHistory = (surgical_history) => {
        let { patient_history } = this.state;
        patient_history['surgical_history'] = surgical_history;
        this.setState({ patient_history: patient_history })
        this.props.handleIsPrescriptionEdited('history');
    }
    handleGynacHistory = (gynaecology_history) => {
        let { patient_history } = this.state;
        patient_history['obstetrics_gynaecology'] = gynaecology_history;
        this.setState({ patient_history: patient_history })
        this.props.handleIsPrescriptionEdited('history');
    }
    handleHistorySaveAndNext = (e = null, type = null) => {
        e && e.preventDefault();
        type = e ? type : "auto_save";
        let { patient_history } = this.state;
        if (!this.historyValidator.allValid()) {
            this.historyValidator.showMessages();
            this.forceUpdate();
            return false;
        }
        let personal_history = this.state.include_personal_history ? patient_history.personal_history : null;
        let gynaecology_history = this.state.include_gynaecology_history ? patient_history.obstetrics_gynaecology : null;
        let family_history = this.state.include_family_history ? patient_history.family_history : null;
        let surgical_history = this.state.include_surgical_history ? patient_history.surgical_history : null;
        let medication_history = this.state.include_medication_history ? patient_history.current_medicine : null;
        let sendData = {
            'patient_id': this.props.patientId,
            'gynaecology_history': gynaecology_history,
            'personal_history': personal_history,
            'family_history': family_history,
            'prescription_drugs': medication_history,
            'surgical_history': surgical_history,
        }
        e && this.props.showLoader();
        PrescriptionService.CreateHistory(this.props.prescriptionId, sendData).then(resp => {
            e && this.props.hideLoader();
            type !== "auto_save" && this.props.handleSaveAndNext();
        }).catch(error => {
            e && this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    getValue = (value) => {
        return (value !== undefined && value !== null) ? value : ''
    }

    render() {
        let { patient_history } = this.state;
        this.historyValidator.purgeFields();
        let { t } = this.props;
        return (
            <React.Fragment>
                <div className="lg-height">
                    <Accordion>
                        <Card>
                            <Card.Header>
                                <div className="include_personal">
                                    <input type="checkbox" id="include_personal_history" name="include_personal_history" onChange={this.handleCheck} checked={this.state.include_personal_history ? 'checked' : ''} />
                                    <label htmlFor="include_personal_history">{t('include_print_file')}</label>
                                </div>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    {t('personal_history')}</Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <PersonalHistory
                                        handlePersonalHistory={this.handlePersonalHistory}
                                        personal_history={patient_history.personal_history}
                                        getValue={this.getValue}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <div className="include_personal">
                                    <input type="checkbox" id="include_family_history" name="include_family_history" onChange={this.handleCheck} checked={this.state.include_family_history ? 'checked' : ''} />
                                    <label htmlFor="include_family_history">{t('include_print_file')}</label>
                                </div>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">{t('family_history')}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <FamilyHistory
                                        handleFamilyHistory={this.handleFamilyHistory}
                                        family_history={patient_history.family_history}
                                        getValue={this.getValue}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <div className="include_personal">
                                    <input type="checkbox" id="include_surgical_history" name="include_surgical_history" onChange={this.handleCheck} checked={this.state.include_surgical_history ? 'checked' : ''} />
                                    <label htmlFor="include_surgical_history">{t('include_print_file')}</label>
                                </div>
                                <Accordion.Toggle as={Button} variant="link" eventKey="2">{t('surgical_history')}</Accordion.Toggle>

                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    <SurgicalHistory
                                        handleSurgicalHistory={this.handleSurgicalHistory}
                                        surgical_history={patient_history.surgical_history}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        {this.props.patient_gender !== "male" && <Card>
                            <Card.Header>
                                <div className="include_personal">
                                    <input type="checkbox" id="include_gynaecology_history" name="include_gynaecology_history" onChange={this.handleCheck} checked={this.state.include_gynaecology_history ? 'checked' : ''} />
                                    <label htmlFor="include_gynaecology_history">{t('include_print_file')}</label>
                                </div>
                                <Accordion.Toggle as={Button} variant="link" eventKey="3">{t('obstetrics_gynaecology')}</Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    <GynaecologyHistory
                                        handleGynacHistory={this.handleGynacHistory}
                                        gynaecology_history={patient_history.obstetrics_gynaecology}
                                        getValue={this.getValue}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>}
                        <Card>
                            <Card.Header>
                                <div className="include_personal">
                                    <input type="checkbox" id="include_medication_history" name="include_medication_history" onChange={this.handleCheck} checked={this.state.include_medication_history ? 'checked' : ''} />
                                    <label htmlFor="include_medication_history">Include in Printable Patient File</label>
                                </div>
                                <Accordion.Toggle as={Button} variant="link" eventKey="4">{t('current_medicine')}</Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                    <div className="examination-wrapper clearfix">
                                        <div className="heading">
                                            <h6>{t('medication')}</h6>
                                        </div>
                                        <ul className="prescription-patient-details medicine-details test">
                                            {patient_history.current_medicine.map((medicine, idx) => (
                                                <CurrentMedicineBlock
                                                    key={idx}
                                                    medicine={medicine}
                                                    idx={idx}
                                                    handleChangeMedicine={this.handleMedicineChange}
                                                    handleRemoveMedicine={this.handleRemoveMedicine}
                                                    validator={this.historyValidator}
                                                    selected_user_profile={this.props.selected_user_profile}
                                                    show_cancel
                                                    not_required={true}
                                                />
                                            ))}
                                        </ul>
                                        <button onClick={this.handleAddMedicine} className="add-new btn btn-default btn-outline-primary">{t('add_more')}</button>
                                    </div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion >
                </div>
                <div className="bottom-btns">
                    <button className="btn btn-primary" onClick={(e) => this.handleHistorySaveAndNext(e, 'save_and_next')}>{t('save_next')}</button>
                    <button className="btn btn-default btn-outline-primary" onClick={this.props.handlePrevious}>{t('previous')}</button>
                </div>
            </React.Fragment>
        )
    }
}
export default (withTranslation('components/Prescription/History/HistoryComponent')(HistoryComponent));
