import React from 'react';
import BarGraph from '../Graph/BarGraph';
import { withTranslation } from 'react-i18next';

class FulfilledOrderGraph extends React.Component {
    handleOrderFulfilledView = (view) => {
        this.props.renderOrderGraph('order', view)
    }

    render() {
        const { t } = this.props;
        return (
            <BarGraph
                barColor="#7BE874"
                barLabel={t('number_of_order_fulfilled')}
                yScaleLabel={t('orders_fulfilled')}
                datasetLabels={this.props.order_graph_data.x_axis_label}
                datasetCounts={this.props.order_graph_data.counts}
                handleViewChange={this.handleOrderFulfilledView}
                t={t}
            />
        )
    }
}
export default (withTranslation('components/Dashboard/FulfilledOrderGraph')(FulfilledOrderGraph))