import React from "react";
import { Helmet } from "react-helmet";
import { Tab, Tabs } from "react-bootstrap";
import OverviewUrgentCarePage from "./OverviewUrgentCarePage";
import ApprovalUrgentCarePage from "./ApprovalUrgentCarePage";
import { withTranslation } from 'react-i18next';

class ListUrgentCarePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: "overview"
        };
    }

    componentWillUnmount() {
    }

    listPrescription = key => {
        this.setState({
            key: key
        });
    };
    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('urgent_care')}</title>
                </Helmet>
                <section className="container-fluid">
                    <div className="row">
                        <div className="col-md-24 requester-tabs">
                            <h4 className="page-title">{t('urgent_care')}</h4>
                        </div>
                        <div className="col-md-24 mt-3 request-pending">
                            <Tabs
                                activeKey={this.state.key}
                                onSelect={this.listPrescription.bind(this)}
                            >
                                <Tab eventKey="overview" title={t('overview')}>
                                    {<OverviewUrgentCarePage />}
                                </Tab>
                                <Tab eventKey="approval" title={t('for_approval')}>
                                    {<ApprovalUrgentCarePage />}
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default (withTranslation('pages/urgent-care/ListUrgentCarePage')(ListUrgentCarePage));
