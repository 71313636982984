import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import SimpleReactValidator from 'simple-react-validator';
import CovidCenterService from '../../services/covidCenterService';
import Notification from '../../utils/notification';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import * as HELPER from '../../utils/helpers';

class UploadExistingReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'patient_id': '',
            'doctor_name': '',
            'laboratory_name': '',
            'file': '',
            'conducted_at': '',
            'test_result': '',
            'test_name': '',
        }
        this.validator = new SimpleReactValidator({ locale: HELPER.getLanguage() })
    }
    handleFilUpload = (e) => {
        this.setState({ file: e.target.files[0] });
        e.target.value = null;
    }

    removeFile = () => this.setState({ file: '' });

    handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

    componentWillUnmount() {
        this.setState({
            'patient_id': '',
            'doctor_name': '',
            'laboratory_name': '',
            'file': '',
            'conducted_at': '',
            'test_result': '',
            'test_name': '',
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('patient_id', this.props.patient.patient_id);
        formData.append('patient_name', this.props.patient.full_name);
        formData.append('doctor_name', this.state.doctor_name);
        formData.append('laboratory_name', this.state.laboratory_name);
        formData.append('conducted_at', this.state.conducted_at);
        formData.append('test_result', this.state.test_result);
        formData.append('test_name', this.state.test_name);

        this.props.loaderShow()
        CovidCenterService.uploadExistingReport(formData).then(resp => {
            this.props.loaderHide();
            this.props.handleUploadReportModal()
        }).catch(err => {
            this.props.loaderHide();
            Notification.show('error', err.response.data);
        })
    }
    render() {
        const { t } = this.props;
        return (
            <Modal show={this.props.show} centered onHide={this.props.handleUploadReportModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('upload_existing_report')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="fee_collection_form">
                        <div className="form-group row">
                            <label className="col-sm-8">{t('date_of_conducted')}<i>*</i></label>
                            <div className="col-sm-15">
                                <input type="date" className="form-control" id="conducted_at" name="conducted_at" onChange={this.handleChange} max={moment().format('YYYY-MM-DD')} />
                                {this.validator.message(t('date_of_conducted'), this.state.conducted_at, 'required')}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-8">{t('laboratory_name')}<i>*</i></label>
                            <div className="col-sm-15">
                                <input type="text" className="form-control" id="laboratory_name" name="laboratory_name" onChange={this.handleChange} />
                                {this.validator.message(t('laboratory_name'), this.state.laboratory_name, 'required')}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-8">{t('doctor_name')}<i>*</i></label>
                            <div className="col-sm-15">
                                <input type="text" className="form-control" id="doctor_name" name="doctor_name" onChange={this.handleChange} />
                                {this.validator.message(t('doctor_name'), this.state.doctor_name, 'required')}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-8">{t('test_name')}<i>*</i></label>
                            <div className="col-sm-15">
                                <select className="form-control" id="test_name" name="test_name" onChange={this.handleChange} >
                                    <option value="">{t('select_test')}</option>
                                    <option value='ANTIGEN COVID-19 RAPID TEST'>ANTIGEN COVID-19 RAPID TEST</option>
                                    <option value="RT-PCR">RT-PCR</option>
                                </select>
                                {this.validator.message(t('test_name'), this.state.test_name, 'required')}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-8">{t('test_result')}<i>*</i></label>
                            <div className="col-sm-15">
                                <select className="form-control" id="test_result" name="test_result" onChange={this.handleChange} >
                                    <option value="">{t('select_result')}</option>
                                    <option value='positive'>{t('positive')}</option>
                                    <option value="negative">{t('negative')}</option>
                                </select>
                                {this.validator.message(t('test_result'), this.state.test_result, 'required')}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-8">{t('upload_report')}<i>*</i></label>
                            {this.state.file && <span className='upload completed'>
                                <a href>{this.state.file.name}</a>
                                <a href className="close" onClick={this.removeFile}>x </a>
                            </span>}
                            <input type="file" ref={signatureFile => this.fileInput1 = signatureFile} onChange={this.handleFilUpload} name="file" id="file" hidden accept='.pdf' />
                            <div className="col-sm-4">
                                <button onClick={() => this.fileInput1.click()} className="btn btn-outline-primary" name="file" id="file">{t('upload')}</button>
                            </div>
                        </div>
                        {this.validator.message(t('report'), this.state.file, 'required')}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.handleUploadReportModal} >
                    {t('cancel')}
                    </Button>
                    <Button variant="primary" onClick={this.handleSubmit} >
                        {t('submit')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default (withTranslation('components/Covid/UploadExistingReports')(UploadExistingReports));
