import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import UserSignatureService from '../../services/UserSignatureService';
import Notification from '../../utils/notification';
import { withTranslation } from 'react-i18next';

class PinModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            signature_pin: '',
            buttonText: 'Verify',
            isVerifyDisabled: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            buttonText: 'Please wait....',
            isVerifyDisabled: true
        })

        let id = !Array.isArray(this.props.id) ? [this.props.id] : this.props.id;

        UserSignatureService.verifyPin(this.state.signature_pin, id, this.props.type)
            .then((_resp) => {
                Notification.show('success', { message: this.props.t('pin_verified') });
                this.setState({
                    buttonText: 'Verify',
                    signature_pin: '',
                    isVerifyDisabled: false
                })
                this.props.onSuccess(this.props);
            })
            .catch((error) => {
                Notification.show('error', error.response.data);
                this.setState({
                    buttonText: 'Verify',
                    signature_pin: '',
                    isVerifyDisabled: false
                })
            })
    }

    onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { t } = this.props;
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    {t('signature_pin')}
                </Modal.Header>
                <Modal.Body>
                    <Form onKeyDown={this.onKeyDown}>
                        <Form.Control
                            size="lg"
                            type="password"
                            placeholder={t('enter_signature_pin')}
                            value={this.state.signature_pin}
                            id="signature_pin"
                            name="signature_pin"
                            onChange={this.handleChange}
                            maxLength="6"
                            disabled={this.state.isVerifyDisabled}
                            autoComplete="one-time-code"
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" disabled={this.state.isVerifyDisabled} onClick={this.props.handleClose}>
                        {t('close')}
                    </Button>
                    <Button variant="primary" disabled={this.state.isVerifyDisabled} onClick={this.handleSubmit}>
                        {t(this.state.buttonText)}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default (withTranslation('components/Signature/PinModal')(PinModal))
