import React from 'react';
import DashboardService from '../../services/dashboardService';
import Notification from '../../utils/notification';
import Card from './Card';
import { withTranslation } from 'react-i18next';

class CovidCenterReportCount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            testCompleted: '',
            reportPending: ''
        }
    }

    componentDidMount() {
        Promise.all([this.getCovidCenterReportCounts("completed"), this.getCovidCenterReportCounts("pending")])
        this.interval = setInterval(() => { Promise.all([this.getCovidCenterReportCounts("completed"), this.getCovidCenterReportCounts("pending")]) }, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    getCovidCenterReportCounts = (type) => {
        return new Promise((resolve, reject) => {
            return resolve(
                DashboardService.renderCovidCenterReportCountInfo(type)
                    .then((resp) => {
                        if (type === "completed") {
                            this.setState({
                                testCompleted: resp.data.completed,
                            });
                        } else if (type === "pending") {
                            this.setState({
                                reportPending: resp.data.pending,
                            });
                        }
                    })
                    .catch((error) => {
                        Notification.show("error", error.response.data);
                        return false;
                    })
            );
        })
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="col-md-12">
                    <Card
                        imgSrc="/images/clockwise-rotation.png"
                        label={t('test_completed')}
                        count={this.state.testCompleted}
                    />
                </div>
                <div className="col-md-12">
                    <Card
                        imgSrc="/images/clockwise-rotation.png"
                        label={t('report_pending')}
                        count={this.state.reportPending}
                    />
                </div>
            </React.Fragment>
        )
    }
}
export default (withTranslation('components/Dashboard/CovidCenterReportCount')(CovidCenterReportCount));