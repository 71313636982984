import React from 'react';

export const Heart = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>{t('heart.heart')}</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                <input type="checkbox" id='chest-pain' name='chest-pain' onChange={e => handleChange(e, 'heart-and-lungs')} checked={checkIfProblemExist('heart-and-lungs', 'chest-pain') ? 'checked' : ''} />
                <label htmlFor='chest-pain'>{t('heart.chest_pain')}</label>
                <input type="checkbox" id='palpitations' name='palpitations' onChange={e => handleChange(e, 'heart-and-lungs')} checked={checkIfProblemExist('heart-and-lungs', 'palpitations') ? 'checked' : ''} />
                <label htmlFor='palpitations'>{t('heart.palpitations')}</label>
                <input type="checkbox" id='shortness-of-breath' name='shortness-of-breath' onChange={e => handleChange(e, 'heart-and-lungs')} checked={checkIfProblemExist('heart-and-lungs', 'shortness-of-breath') ? 'checked' : ''} />
                <label htmlFor='shortness-of-breath'>{t('heart.shortness_of_breath')}</label>
                <input type="checkbox" id='fainting' name='fainting' onChange={e => handleChange(e, 'heart-and-lungs')} checked={checkIfProblemExist('heart-and-lungs', 'fainting') ? 'checked' : ''} />
                <label htmlFor='fainting'>{t('heart.fainting')}</label>
                <input type="checkbox" id='swollen-legs-or-feet' name='swollen-legs-or-feet' onChange={e => handleChange(e, 'heart-and-lungs')} checked={checkIfProblemExist('heart-and-lungs', 'swollen-legs-or-feet') ? 'checked' : ''} />
                <label htmlFor='swollen-legs-or-feet'>{t('heart.swollen_legs_feet')}</label>
                <input type="checkbox" id='cough' name='cough' onChange={e => handleChange(e, 'heart-and-lungs')} checked={checkIfProblemExist('heart-and-lungs', 'cough') ? 'checked' : ''} />
                <label htmlFor='cough'>{t('heart.cough')}</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('heart.comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('heart.type_here')} onChange={e => handleChange(e, 'heart-and-lungs')} value={getCategoryComment('heart-and-lungs') ? getCategoryComment('heart-and-lungs') : ''}></textarea>
    </React.Fragment>
}