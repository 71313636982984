import React from 'react';
import DoctorService from '../../services/doctorService';
import _ from 'lodash';
import AutoSuggest from "../AutoSuggest";
import { withTranslation } from 'react-i18next';

class DoctorSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            options: [],
            filter:{
                specialization: ''
            }
        }
    }

    _handleSearch = (query) => {

        if(_.isEmpty(this.props.refer_to.doctor_specialization)){
            return false;
        }

        this.setState({ 
            isLoading: true,
            filter:{
                role_slug:'doctor',
                specialization: this.props.refer_to.doctor_specialization
            }
        });
        const filter = {
            role_slug: 'doctor',
            specialization: this.props.refer_to.doctor_specialization
        };
        DoctorService.searchDoctor({ searchText: query, filter})
            .then(resp => {
                const items = resp.data.data;
                
                const options = items.map(i => ({
                    doctor_id: i.user_profile_id,
                    doctor_name: i.full_name,
                    specialization: i.specialization
                }));
                
                this.setState({
                    isLoading: false,
                    options: options
                });
            });
    }

    _onChange = (option) => {
        let { refer_to } = this.props;
        
        if (option) {
            if (option.customOption === true) {
                refer_to.doctor_id = "";
                refer_to.doctor_name = option.doctor_name;
            } else {
                refer_to.doctor_id = option.doctor_id;
                refer_to.doctor_name = option.doctor_name;
            }
        } else {
            refer_to.doctor_id = "";
            refer_to.doctor_name = "";
        }

        this.props.handleDoctorSearch(refer_to);
    }

    render() {
        const { t } = this.props;
        return (<React.Fragment>
                <AutoSuggest
                    disabled={this.props.disabled}
                    allowNew={true}
                    isLoading={this.state.isLoading}
                    options={this.state.options}
                    newSelectionPrefix={t('new_doctor')}
                    labelKey="doctor_name"
                    placeholder={t('search_doctor')}
                    defaultValue={this.props.refer_to.doctor_name}
                    onSearch={this._handleSearch}
                    onChange={this._onChange}
                    renderMenuItem={(option) => {
                        return <div key={option.doctor_id}>
                            <div>{option.doctor_name}</div>
                            <small>{t('specialization')}: {option.specialization}</small>
                        </div>
                    }}
                />
                {!this.props.disabled && this.props.validator.message(t('doctor_name'),this.props.refer_to.doctor_name,"required")}
        </React.Fragment>
        );
    }
}

export default (withTranslation('components/Prescription/DoctorSearch')(DoctorSearch));