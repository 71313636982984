import React from "react";
import RadiologyService from "../../services/radiologyService";
import AutoSuggest from "../AutoSuggest";
import { withTranslation } from 'react-i18next';

class RadiologyTestBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            options: []
        }
    }

    _handleChange = (evt) => {
        const { radiology } = this.props;

        let fieldName = evt.target.name;
        let fieldValue = evt.target.value;

        radiology[fieldName] = fieldValue;

        this.props.handleChangeRadiology(this.props.idx, radiology);
    }

    _handleSearch = (query) => {
        this.setState({ isLoading: true });
        RadiologyService.list({ searchText: query })
            .then(resp => {
                let data = resp.data;
                const items = data.data;
                const options = items.map(i => ({
                    test_id: i.test_id,
                    test_name: i.test_name,
                    test_category: i.test_category,
                    test_description: i.test_description,
                }));

                this.setState({
                    isLoading: false,
                    options: options
                });
            });
    }

    _onChange = (option) => {
        let { radiology } = this.props;

        if (option) {
            if (option.customOption === true) {
                radiology.test_name = option.test_name;
                radiology.test_category = "Others";
                radiology.is_other = true;
            } else {
                radiology.test_id = option.test_id;
                radiology.test_name = option.test_name;
                radiology.test_category = option.test_category;
                radiology.test_description = option.test_description;
                radiology.is_other = false;
            }
        } else {
            radiology.test_id = "";
            radiology.test_name = "";
            radiology.test_category = "";
            radiology.test_description = "";
            radiology.is_other = false;
        }

        this.props.handleChangeRadiology(this.props.idx, radiology);
    }

    render() {
        const { t } = this.props;
        return (
            <li data-id={"radiology" + this.props.idx}>
                <div className="form-group half">
                    <label>{t('search_test')}</label>
                    <AutoSuggest
                        disabled={true}
                        allowNew={true}
                        isLoading={this.state.isLoading}
                        options={this.state.options}
                        newSelectionPrefix={t('new_test')}
                        labelKey="test_name"
                        placeholder={t('search_test')}
                        defaultValue={this.props.radiology.test_name}
                        onSearch={this._handleSearch}
                        onChange={this._onChange}
                        renderMenuItem={(option) => {
                            return <div key={option.id}>
                                <div>{option.test_name}</div>
                                <small>Test Type: {option.test_category}</small>
                            </div>
                        }}
                    />
                    {this.props.validator.message(t('test_name'), this.props.radiology.test_name, 'required')}
                </div>
                <div className="form-group half">
                    <label>{t('type')}</label>
                    <input type="text" name="test_category" className="form-control" value={this.props.radiology.test_category} onChange={this._handleChange} readOnly="readOnly" />
                </div>
                <div className="form-group full">
                    <label>{t('test_remark')}</label>
                    <textarea className="form-control" name="remark" value={this.props.radiology.remark ? this.props.radiology.remark : ''} onChange={this._handleChange} placeholder={t('type_test_remark')} disabled={this.props.disabled}></textarea>
                </div>
                {this.props.disabled === false && <a href className="cancel" data-id={this.props.idx} onClick={this.props.handleRemoveRadiologyTest} > </a>}
            </li>
        )
    }
}
export default (withTranslation('components/Prescription/RadiologyTestBlock')(RadiologyTestBlock));