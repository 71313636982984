import React from "react";
import Notification from '../../utils/notification';
import { hasRole } from '../../utils/auth';
import _ from 'lodash';
import MedicineBlock from '../Prescription/MedicineBlock';
import * as ButtonComponents from './Buttons';
import ViewRecommendation from '@Components/UrgentCare/Recommendation/ViewRecommendation';
import UrgentCareService from "../../services/urgentCareService";
import { withTranslation } from 'react-i18next';

class MedicationBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            savedPrescriptionDrugs: [],
            draftPrescriptionDrugs: {},
            showSubmitButton: false,
        }
        this.medicationValidator = props.validator
    }

    componentDidMount() {
        this.getMedication()
    }

    componentDidUpdate(prevProps) {
        if (this.props.prescription_drugs !== prevProps.prescription_drugs) {
            this.getMedication()
        }
    }

    getMedication() {
        let draftPrescriptionDrugs = this.props.prescription_drugs ? this.props.prescription_drugs.filter(pd => pd.status === "draft") : []
        draftPrescriptionDrugs = draftPrescriptionDrugs.length ? draftPrescriptionDrugs[0] : { prescription_medicine_item: [] }
        this.setState({
            savedPrescriptionDrugs: this.props.prescription_drugs ? this.props.prescription_drugs.filter(pd => pd.status === "save") : [],
            draftPrescriptionDrugs: draftPrescriptionDrugs,
        })
        if (!_.isEmpty(draftPrescriptionDrugs.prescription_medicine_item))
            this.handleAddButton()
    }

    handleRemoveMedication = () => {
        this.setState({ showSubmitButton: false, drugs: {}, draftPrescriptionDrugs: { prescription_medicine_item: [] } });
        this.props.handleIsPrescriptionEdited('recommendation_medication', false);
    };

    handleAddButton = () => {
        this.setState({ showSubmitButton: true });
    }

    handleMedicationSaveAndNext = (e, type = null) => {
        if (_.isEmpty(this.state.draftPrescriptionDrugs) && (type === "save_and_next" || type === "draft_and_next")) {
            this.props.handleSaveAndNext();
            return false;
        }
        let status = ['save', 'save_and_next'].includes(type) ? "save" : "draft"

        this.setState(prevState => ({
            drugs: {
                prescription_drugs: prevState.draftPrescriptionDrugs.prescription_medicine_item,
                status: status,
                patient_id: this.props.patientId
            }
        }), () => {
            if (this.state.draftPrescriptionDrugs.prescription_medicine_item.id) {
                this.setState(prevState => ({
                    drugs: {
                        prescription_drugs: prevState.draftPrescriptionDrugs.prescription_medicine_item,
                        id: this.state.draftPrescriptionDrugs.prescription_medicine_item.id
                    }
                }), () => {
                    this.addMedication(type)
                })
            } else {
                this.addMedication(type)
            }
        })
    }

    addMedication = (type) => {
        if (!this.medicationValidator.allValid()) {
            this.medicationValidator.showMessages();
            this.forceUpdate();
            return false;
        }
        this.props.showLoader();
        UrgentCareService.createMedication(this.props.prescriptionId, this.state.drugs).then(resp => {
            this.props.hideLoader();
            this.handleRemoveMedication();
            this.props.getPrescriptionRecommendation();
            if (type === "save_and_next" || type === "draft_and_next") {
                this.props.handleSaveAndNext();
            }

        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }

    // Start Medicine Block Events
    handleMedicineChange = (idx, data) => {
        let { draftPrescriptionDrugs } = this.state;
        const newMedicines = draftPrescriptionDrugs.prescription_medicine_item.map((medicine, sidx) => {
            if (idx !== sidx) return medicine;

            return { ...medicine, ...data };
        });
        draftPrescriptionDrugs.prescription_medicine_item = newMedicines;
        this.setState({ draftPrescriptionDrugs });
        this.props.handleIsPrescriptionEdited('recommendation_medication');
    };
    handleAddMedicine = () => {
        this.handleAddButton()
        let { draftPrescriptionDrugs } = this.state;
        draftPrescriptionDrugs.prescription_medicine_item = draftPrescriptionDrugs.prescription_medicine_item.concat([{
            drug_id: '',
            drug_name: '',
            drug_generic_id: '',
            drug_generic_name: '',
            dosage: '',
            meal: '',
            duration: '',
            duration_type: '',
            is_generic_allowed: false,
            quantity: '',
            drug_type: '',
            is_other: false,
            over_dosage: false,
            is_generic_allowed_disable: false
        }]);
        this.setState({ draftPrescriptionDrugs });
        this.props.handleIsPrescriptionEdited('recommendation')
    };
    handleRemoveMedicine = (event) => {
        let { draftPrescriptionDrugs } = this.state;
        const idx = Number(event.target.dataset['id']);
        draftPrescriptionDrugs.prescription_medicine_item = draftPrescriptionDrugs.prescription_medicine_item.filter((s, sidx) => idx !== sidx);
        this.setState({ draftPrescriptionDrugs }, () => {
            if (this.state.draftPrescriptionDrugs.prescription_medicine_item.length === 0) {
                this.setState({ showSubmitButton: false });
                this.props.handleIsPrescriptionEdited('recommendation_medication', false);
            }
        });
    };
    //End medicine block
    reloadPrescriptionMedicine = (type) => {
        this.props.getPrescriptionRecommendation();
    }
    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="content-wrapper padding-bottom-10">
                    {this.state.savedPrescriptionDrugs && this.state.savedPrescriptionDrugs.map((item, idx) => (
                        <ViewRecommendation
                            type={"medication"}
                            prescription={this.state.prescription}
                            prescription_medicine={item}
                            idx={idx}
                            reloadPrescriptionMedicine={this.reloadPrescriptionMedicine}
                        />
                    ))}
                    {this.state.showSubmitButton && <ul className="prescription-patient-details medicine-details clear-both">
                        {this.state.draftPrescriptionDrugs && this.state.draftPrescriptionDrugs.prescription_medicine_item.map((medicine, idx) => (
                            <MedicineBlock
                                key={idx}
                                medicine={medicine}
                                idx={idx}
                                handleChangeMedicine={this.handleMedicineChange}
                                handleRemoveMedicine={this.handleRemoveMedicine}
                                validator={this.medicationValidator}
                                selected_user_profile={this.props.selected_user_profile}
                                disabled={this.props.recordId ? true : false}
                                not_required={false}
                            />
                        ))}
                    </ul>}
                    <button onClick={this.handleAddMedicine} className="add-new btn btn-primary" disabled={hasRole(this.props.selected_user_profile, ["paramedic-doctor", "doctor-nurse", "junior-doctor"]) ? 'disabled' : ''}>{t('add')}</button>
                    <br /><br /><br /><br />
                    {this.state.showSubmitButton && <div className="bottom-btns">
                        <ButtonComponents.PrimaryButton
                            handleChange={this.handleMedicationSaveAndNext}
                            buttonType={"save"}
                            buttonName={t('submit_medication')}
                        />
                    </div>}
                </div>
                <div className="bottom-btns">
                    {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor", "paramedic-doctor"]) &&
                        <ButtonComponents.PrimaryButton
                            handleChange={this.handleMedicationSaveAndNext}
                            buttonType={"save_and_next"}
                            buttonName={t('save_next')}
                        />}
                    {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor", "paramedic-doctor"]) && <ButtonComponents.OutlineButton
                        handleChange={this.props.handlePrevious}
                        buttonName={t('previous')}
                    />}
                    {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor", "paramedic-doctor"]) && <ButtonComponents.PrimaryButton
                        handleChange={this.handleMedicationSaveAndNext}
                        buttonType={"draft_and_next"}
                        buttonName={t('save_draft_next')}
                        disabled={!this.state.showSubmitButton}
                    />}
                    {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor", "paramedic-doctor"]) && <ButtonComponents.PrimaryButton
                        handleChange={this.handleMedicationSaveAndNext}
                        buttonType={"draft"}
                        buttonName={t('save_draft')}
                        disabled={!this.state.showSubmitButton}
                    />}
                </div>
            </div>
        );
    }
}

export default (withTranslation('components/UrgentCare/MedicationBlock')(MedicationBlock));