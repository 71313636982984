import React from 'react';
import ViewPersonalHistoryBlock from './components/PersonalHistory/ViewPersonalHistory';
import ViewFamilyHistory from './components/FamilyHistory/ViewFamilyHistory';
import ViewSurgicalHistory from './components/SurgicalHistory/ViewSurgicalHistory';
import * as ViewGynaecologyHistory from './components/GynaecologyHistory/ViewGynaecologyHistory';
import ShowMedicineBlock from '../ShowMedicineBlock';
import { isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';

class ViewHistoryComponent extends React.Component {
    render() {
        let { personal_history, family_history, surgical_history, obstetrics_gynaecology, current_medicine, t } = this.props;
        return (
            <React.Fragment>
                {/* personal history */}
                {personal_history &&
                    <React.Fragment>
                        <div className="content-wrapper padding-top margin-bottom-none">
                            <h6 className="inner_heading">{t('personal_history')}</h6>
                            <ViewPersonalHistoryBlock personal_history={personal_history} />
                        </div>
                    </React.Fragment>}
                {/* family history */}
                {family_history &&
                    <React.Fragment>
                        <h6 className="inner_heading">{t('family_history')}</h6>
                        <ViewFamilyHistory family_history={family_history} />
                    </React.Fragment>}
                {/* surgical_history */}
                {!isEmpty(surgical_history) &&
                    <React.Fragment>
                        <h6>{t('surgical_history')}</h6>
                        {surgical_history.map((item, key) => (
                            <ViewSurgicalHistory item={item} key={key} />
                        ))}
                    </React.Fragment>}
                {/* O & g history */}
                {this.props.patient_gender !== "male" && obstetrics_gynaecology &&
                    <React.Fragment>
                        <h6>{t('obstetrics_gynaecology')}</h6>
                        <ViewGynaecologyHistory.ReproductionHistory reproductive_history={obstetrics_gynaecology.reproductive_history} />
                        <ViewGynaecologyHistory.GynaecologyHistory gynaecological_history={obstetrics_gynaecology.gynaecological_history} />
                        <ViewGynaecologyHistory.Allergy allergies={obstetrics_gynaecology.allergies} />
                    </React.Fragment>}
                {/* current history */}
                {current_medicine && !isEmpty(current_medicine.prescription_drugs) &&
                    <React.Fragment>
                        <h6 className="pt-3">{t('current_medicine')}</h6>
                        {current_medicine.prescription_drugs.map((item, idx) => (
                            <ShowMedicineBlock medicine={item} key={idx} type='current_medicine' />
                        ))}
                    </React.Fragment>}
            </React.Fragment>
        )
    }
}
export default (withTranslation('components/Prescription/History/ViewHistoryAccordion')(ViewHistoryComponent))