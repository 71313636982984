import React from "react";
import { connect } from "react-redux";
import * as PrescriptionOrderAction from "../../actions/prescriptionOrderAction";
import _ from "lodash";
import queryString from 'query-string';
import ViewOrder from '../../components/Order/ViewOrder';
import { withTranslation } from 'react-i18next';
import ViewPrescription from '../../components/Prescription/ViewPrescription';
import PrescriptionService from '../../services/prescriptionService';
import PrescriptionOrderService from '../../services/prescriptionOrderService';
import Notification from '../../utils/notification';
import * as LoaderAction from "../../actions/loaderAction";

class ViewPrescriptionOrderPage extends React.Component {
  constructor(props) {
    super(props);

    const {
      match: { params }
    } = this.props;
    const id = params.id;
    const queryParams = queryString.parse(this.props.location.search)
    this.state = {
      autoPrint: (_.has(queryParams, 'print') && queryParams.print === 1) ? true : false,
      prescriptionView: false,
      prescriptionPrint: false,
      prescription: '',
      prescriptionOrder: '',
      order_id: id
    };

    this.getData(id);
    this.showOriginalPrescription = this.showOriginalPrescription.bind(this);
    this.hideOriginalPrescription = this.hideOriginalPrescription.bind(this);
    this.printPrescription = this.printPrescription.bind(this);
  }

  showOriginalPrescription = () => {
    this.setState({
      show: true
    });
  }

  printPrescription = () => {
    this.setState({
      printPrescription: true
    });
  }

  hideOriginalPrescription = () => {
    this.setState({
      show: false
    });
  }

  getPrescription = (prescriptionId) => {
    return new Promise((resolve, reject) => {
      PrescriptionService.getprescriptionForPrint(prescriptionId).then(res => {
        let data = res.data;
        return resolve(data);
      }).catch(error => {
        this.props.loaderHide();
        Notification.show('error', error.response.data)
        return reject(error);
      });
    })
  }

  getOrder = (orderId) => {
    return new Promise((resolve, reject) => {
      PrescriptionOrderService.get(orderId).then(res => {
        let data = res.data;
        return resolve(data);
      }).catch(error => {
        this.props.loaderHide();
        Notification.show('error', error.response.data)
        return reject(error);
      });
    })
  }

  async getData(orderId) {
    try {
      this.props.loaderShow();
      const order = await this.getOrder(orderId);
      const prescription = await this.getPrescription(order.prescription_id);
      this.setState({
        prescription: prescription,
        prescriptionOrder: order,
      });
      this.props.loaderHide();
    } catch (error) {
      this.props.loaderHide();
      Notification.show('error', error.response.data)
    }
  }

  download = () => {
    this.props.loaderShow()
    PrescriptionOrderService.getOrderPrescriptionPdf(this.state.prescriptionOrder.order_id).then(resp => {
      this.props.loaderHide()
      window.open(resp.data.url)
    }).catch(error => {
      this.props.loaderHide()
      Notification.show('error', error.response.data)
    })
  }

  render() {
    const { t } = this.props;
    const prescription_order = this.state.prescriptionOrder;
    return (
      <React.Fragment>
        <ViewOrder
          autoPrint={this.state.autoPrint}
          prescription_order={prescription_order}
          show_doctor_info={true}
          user_role={this.props.user_role}
        ></ViewOrder>

        <div className="row mb-3">
          {this.state.prescription && <button className="btn btn-primary ml-2" onClick={this.download}>{t('print_prescription')}</button>}
          {!_.isEmpty(prescription_order.prescription_id) && <React.Fragment>
            <button className="btn btn-primary ml-2" onClick={this.showOriginalPrescription}>{t('view_prescription')}</button>
            <ViewPrescription
              prescription={this.state.prescription}
              show={this.state.show}
              handleShow={this.showOriginalPrescription}
              handleClose={this.hideOriginalPrescription}
            /></React.Fragment>}
        </div>

        <div class="mb-3 text-center">
          <span className="text-muted">
            {t('prescription_note')}
          </span>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    prescription_order: state.prescription_order.fetch,
    pharmacy: state.selected_user_profile.service_provider,
    user_role: state.selected_user_profile.role_slug
  };
}

const mapActionsToProps = {
  fetchDetails: PrescriptionOrderAction.fetch,
  loaderShow: LoaderAction.loaderShow,
  loaderHide: LoaderAction.loaderHide,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/prescription/ViewPrescriptionOrderPage')(ViewPrescriptionOrderPage));
