import React from 'react';

export const MentionSymptons = ({ changeHandler, value, t }) => {
    return (
        <React.Fragment>            
                <label className="heading-comment">{t('mention_symptoms')} </label>
                <textarea className="form-control height" name="symptoms" placeholder={t('type_here')} onChange={changeHandler} value={value}></textarea>
        </React.Fragment>
    )
}

export const ReviewOfSystem = ({ review_of_system, getReviewOfSystemValueByKey, t }) => {
    return (
        <div>
            <h6 className="inner_heading">{t('review_of_system')}</h6>
            {review_of_system && review_of_system.map((item, index) => (
                <React.Fragment key={index}>
                    <ul className="prescription-filed-details" key={index}>
                        <li>{getReviewOfSystemValueByKey(item.category)} :
                            <span>{item.problems.map((problem, idx) => (
                                <span key={idx}>{(idx ? ', ' : '') + getReviewOfSystemValueByKey(problem)}</span>
                            ))}</span>
                        </li>

                        {item.comments && <li>{t('comment')} :<span>{item.comments}</span></li>}
                    </ul>
                </React.Fragment>
            ))}
        </div>
    )
}