import React from 'react';
import DashboardService from '../../services/dashboardService';
import Notification from '../../utils/notification';
import { hasProvider } from '../../utils/auth';
import moment from "moment";
import Card from './Card';
import { withTranslation } from 'react-i18next';

class ReportsCountInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            upload_pending_count: '',
            uploaded_report_count: '',
            amountPayable: '',
        }
    }

    componentDidMount() {
        Promise.all([this.getDashboardCounts("upload-pendings"), this.getDashboardCounts("uploaded-reports"), this.getDashboardCounts("amount-payables")])
        this.interval = setInterval(() => { Promise.all([this.getDashboardCounts("upload-pendings"), this.getDashboardCounts("uploaded-reports"), this.getDashboardCounts("amount-payables")]) }, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    getDashboardCounts = (type) => {
        const { selected_user_profile } = this.props
        return new Promise((resolve, _reject) => {
            let filter = {}
            if (type === "upload-pendings") {
                filter = {
                    status: 'conducted'
                }
            } else if (type === "uploaded-reports") {
                const date = new Date()
                filter = {
                    status: 'fulfilled',
                    uploaded_at: moment(date).format('YYYY-MM-DD')
                }
            }
            return hasProvider(selected_user_profile.service_provider, ['pathology']) ? resolve(this.getPathologyDashboardCounts(type, filter)) : resolve(this.getRadiologyDashboardCounts(type, filter))
        })
    }

    getPathologyDashboardCounts = (type, filter) => {
        DashboardService.renderPathologyCountInfo(type, filter).then(resp => {
            if (type === "upload-pendings" && resp.data.upload_pending_count) {
                this.setState({
                    upload_pending_count: resp.data.upload_pending_count
                })
            } else if (type === "uploaded-reports" && resp.data.uploaded_report_count) {
                this.setState({
                    uploaded_report_count: resp.data.uploaded_report_count
                })
            } else if (type === "amount-payables" && resp.data.amountPayable) {
                this.setState({
                    amountPayable: resp.data.amountPayable
                })
            }
        }).catch(error => {
            Notification.show('error', error.response.data)
            return false;
        })
    }

    getRadiologyDashboardCounts = (type, filter) => {
        DashboardService.renderRadiologyCountInfo(type, filter).then(resp => {
            if (type === "upload-pendings" && resp.data.upload_pending_count) {
                this.setState({
                    upload_pending_count: resp.data.upload_pending_count
                })
            } else if (type === "uploaded-reports" && resp.data.uploaded_report_count) {
                this.setState({
                    uploaded_report_count: resp.data.uploaded_report_count
                })
            } else if (type === "amount-payables" && resp.data.amountPayable) {
                this.setState({
                    amountPayable: resp.data.amountPayable
                })
            }
        }).catch(error => {
            Notification.show('error', error.response.data)
            return false;
        })
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="col-md-8">
                    <Card
                        label={t('amount_payable')}
                        count={this.state.amountPayable}
                    />
                </div>
                <div className="col-md-8">
                    <Card
                        imgSrc="/images/report-upload-pending.png"
                        label={t('report_upload_pending')}
                        count={this.state.upload_pending_count}
                    />
                </div>
                <div className="col-md-8">
                    <Card
                        imgSrc="/images/reports-upload-today.png"
                        label={t('reports_uploaded_today')}
                        count={this.state.uploaded_report_count}
                    />
                </div>
            </React.Fragment>
        )
    }
}
export default (withTranslation('components/Dashboard/ReportsCountInfo')(ReportsCountInfo))