import React from 'react';
import * as AddPersonalHistoryComponent from './AddPersonalHistoryComponents'
import { withTranslation } from 'react-i18next';

class PersonalHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            substance: '',
        }
    }
    handleSubstanceAbuseChange = (e, index) => {
        let { personal_history } = this.props;
        let substance = personal_history.substance ? personal_history.substance : [{}];
        if (e.target.name === "substance_type" && e.target.value === '') {
            substance[index]['is_currently_use'] = '';
            substance[index]['is_previously_used'] = ''
            substance[index]['type_quantity'] = ''
            substance[index]['how_long_years'] = ''
            substance[index]['stopped_when_reason'] = ''
        }
        if (e.target.name === "is_currently_use" || e.target.name === "is_previously_used") {
            substance[index][e.target.name] = e.target.value === "" ? '' : JSON.parse(e.target.value);
        } else {
            substance[index][e.target.name] = e.target.value;
        }
        personal_history['substance'] = substance;
        this.props.handlePersonalHistory(personal_history);
    }
    addSubstanceAbuseBlock = () => {
        let { personal_history } = this.props;
        let substance = personal_history.substance.concat({
            substance_type: ''
        });
        personal_history['substance'] = substance;
        this.props.handlePersonalHistory(personal_history);
    }
    handlePersonalHistoryChange = (e) => {
        let { personal_history } = this.props;
        let targets = ["is_sexually_active", "is_hiv_tested", "is_abuse", "is_exercise", "is_sleep_snore"];
        if (targets.includes(e.target.name)) {
            if (e.target.name === "is_hiv_tested" && personal_history.is_hiv_tested) {
                personal_history['last_hiv_test'] = ''
                personal_history['hiv_test_result'] = ''
                personal_history['hiv_test_comment'] = ''
            }
            personal_history[e.target.name] = e.target.value === "" ? '' : JSON.parse(e.target.value)
        } else {
            personal_history[e.target.name] = e.target.value;
        }
        this.props.handlePersonalHistory(personal_history);
    }
    removeSubstanceAbuse = (event) => {
        let { personal_history } = this.props;
        const idx = Number(event.target.dataset['id']);
        personal_history['substance'] = personal_history['substance'].filter((s, sidx) => idx !== sidx);
        this.props.handlePersonalHistory(personal_history);
    };
    render() {
        let { personal_history, t } = this.props;
        return (
            <React.Fragment>
                <div className="examination-wrapper">
                    <div className="select-wrapper">
                        <label>{t('education')}</label>
                        <select className="form-control" name="education" onChange={this.handlePersonalHistoryChange} value={personal_history.education ? personal_history.education : ''}>
                            <option value="">{t('select_education')}</option>
                            <option value="secondary">{t('secondary')}</option>
                            <option value="higher-secondary">{t('higher_secondary')}</option>
                            <option value="graduation">{t('graduation')}</option>
                            <option value="post-graduation">{t('post_graduation')}</option>
                        </select>
                    </div>
                </div>
                <div className="examination-wrapper clearfix">
                    <AddPersonalHistoryComponent.Abuse
                        handleChange={this.handlePersonalHistoryChange}
                        is_abuse={personal_history.is_abuse}
                        abuse_reason={personal_history.abuse_reason}
                        getValue={this.props.getValue}
                        t={t}
                    />
                </div>
                <div className="examination-wrapper clearfix">
                    <AddPersonalHistoryComponent.SubstanceAbuse
                        substance={personal_history.substance ? personal_history.substance : []}
                        addSubstanceAbuseBlock={this.addSubstanceAbuseBlock}
                        handleChange={this.handleSubstanceAbuseChange}
                        removeSubstanceAbuse={this.removeSubstanceAbuse}
                        getValue={this.props.getValue}
                        t={t}
                    />
                </div>
                <div className="examination-wrapper clearfix">
                    <AddPersonalHistoryComponent.Excercise
                        handleChange={this.handlePersonalHistoryChange}
                        is_exercise={personal_history.is_exercise}
                        exercise_frequency={personal_history.exercise_frequency}
                        is_sleep_snore={personal_history.is_sleep_snore}
                        getValue={this.props.getValue}
                        t={t}
                    />
                </div>
                <div className="examination-wrapper clearfix">
                    <AddPersonalHistoryComponent.Sexual
                        personal_history={personal_history}
                        handleChange={this.handlePersonalHistoryChange}
                        getValue={this.props.getValue}
                        t={t}
                    />
                </div>
                <div className="examination-wrapper clearfix">
                    <AddPersonalHistoryComponent.Vaccine
                        tetanus_vaccacines={personal_history.tetanus_vaccacines}
                        influenza_vaccines={personal_history.influenza_vaccines}
                        hiv_vaccines={personal_history.hiv_vaccines}
                        handleChange={this.handlePersonalHistoryChange}
                        t={t}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default (withTranslation('components/Prescription/History/PersonalHistory')(PersonalHistory));