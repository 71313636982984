import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import SearchPatientWithoutCode from '../../components/SearchPatientWithoutCode'
import { withTranslation } from 'react-i18next';

export class SearchPatientPage extends Component {

    handlePatientChange = (patient_id) => {
        this.props.history.push('/ambulance/records/patients/' + patient_id);
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('search_patient')}</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title">{t('search_patient')}</h4>
                        <div className="content-wrapper">
                            <SearchPatientWithoutCode 
                                handlePatientSearch={this.handlePatientChange}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default (withTranslation('pages/ambulance/SearchPatientPage')(SearchPatientPage));
