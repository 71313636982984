import React from 'react'
import { Modal, Button } from 'react-bootstrap';

const DeleteProcedureModal = ({ showModal, handleDelete, id, handleClose, t }) => {
    return (
        <Modal show={showModal} backdrop="static" keyboard={false} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered size="md">
            <div className="delete-slots m-0 p-0">
                <img src="/images/icons/Alert-Icon.svg" alt="" className="alert-icon" />
                <p className="deleteslot del-msg-1">{t('alert')}</p>
                <p className="deleteslot del-msg-2">{t('confirm_delete')}</p>
            </div>

            <div className="delete-footer">
                <Button className="btn btn-primary delete-popup-btn" size="sm" onClick={handleClose}>
                    {t('no')}
                </Button>
                <Button className="btn btn-secondary delete-popup-btn" size="sm" onClick={() => handleDelete(id)}>
                    {t('yes')}
                </Button>
            </div>
        </Modal>
    )
}
export default DeleteProcedureModal
