import React from 'react';
import { Helmet } from "react-helmet";
import DataGrid from "../../components/DataGrid";
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import * as PatientConfirmEditAction from '../../actions/patientConfirmEditAction';
import { withTranslation } from 'react-i18next';

class ListPatientConfirmEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditModal: false,
            showAddModal: false,
            procedure: "",
            showAddEditModal: false,
            showDeleteModal: false,
            id: 0
        }
    }
    componentDidMount() {
        this.getProcedureList();
    }

    getProcedureList = () => {
        this.props.paginate({ sortBy: { sortField: "created_at", sortOrder: "desc" }});
    }

    columns = () => {
        let { t } = this.props;
        return [
            {
                dataField: "id",
                text: t('id'),
                hidden: true
            },
            {
                dataField: "",
                text: t('patient_name'),
                formatter: (i, row) => {
                    return `${row.patient_name['name_title']} ${row.patient_name['first_name']} ${row.patient_name['last_name']}`;
                },
            },
            {
                dataField: "service_provider_name.name",
                text: t('service_provider')
            },
            {
                dataField: "service_location_name.name",
                text: t('location')
            },
            {
                dataField: "user_profile_name.user_id",
                text: t('user_profile')
            },
            {
                dataField: "action",
                text: t('action'),
                isDummyField: true,
                formatter: this.actionFormatter
            }
        ];
    };

    actionFormatter = (cell, row) => {
        if(row.id){
            return <div><a title="Confirm">
                    <Button variant="primary" className='ml-2 height-30' onClick={e => this.addPatientHandle(row)}>
                        {this.props.t('confirm_profile')}
                    </Button>
                    </a> 
               </div>
                
        }
        return ''
    }

        addPatientHandle(row) {
        this.props.history.push({
            pathname: '/patient-confirm-edit/' + row.patient_id + '/' + row.id
        });
    }

    handleModal = (row) => {
        this.handleShow()
        if(row === "add"){
            this.setState({ showAddModal: true});
        }else{
            this.setState({ procedure: row, showEditModal: true});
        }
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField: "created_at", sortOrder: "desc" }, });
    }

	onSuccessRedirect = () => {
        this.getProcedureList()
        this.handleClose()
	}
	
	handleClose = () => {
        this.setState({ showAddEditModal: false, procedure: "" });
        this.handleHideModal()
	}
	
	handleShow = () => {
        this.setState({ showAddEditModal: true });
	}

    handleHideModal = () => {
        this.setState({ showAddModal: false, showEditModal: false});
    }

    render() {
        let { data, meta } = this.props.profileEditQueue
        const { t } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('confirm_patient_edit_list')}</title>
                </Helmet>
                <div className="row">
                    <div className="col-sm-24">
                        <div className='page-title'>
                            <span>{t('confirm_patient_edit_list')}</span>
                        </div>
                        <div className="content-wrapper">
                            <DataGrid
                                columns={this.columns()}
                                data={data}
                                meta={meta}
                                handleTableChange={this.handleTableChange}
                                noDataIndication={t('no_record')}
                                pagination={true}
                            />
                        </div>
                    </div>
                </div>

                
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    profileEditQueue: state.profile_edit_queue,
});

const mapActionsToProps = {
    paginate: PatientConfirmEditAction.patientConfirmEditList
};
export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/patient-confirm-edit/ListPatientConfirmEditPage')(ListPatientConfirmEditPage))
