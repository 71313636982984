import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import _ from 'lodash';
import { connect } from "react-redux";
import PinModal from "../../components/Signature/PinModal";
import Notification from '../../utils/notification';
import * as PatientAction from '../../actions/patientAction';
import * as Helper from "../../utils/helpers";
import SimpleReactValidator from 'simple-react-validator';
import ReturnToSchool from '../../components/Certificate/ReturnToSchool';
import ReturnToWork from '../../components/Certificate/ReturnToWork';
import WorkExcuse from '../../components/Certificate/WorkExcuse';
import FollowUp from '../../components/Certificate/FollowUp';
import ReferralLetter from '../../components/Certificate/ReferralLetter';
import MedicalCertificateService from "../../services/medicalCertificateService";
import { withTranslation } from 'react-i18next';

class AddCertificatePage extends React.Component {
    constructor(props) {
        super(props);

        const params = this.props.match.params;

        if(params.type === 'follow_up'){
            this.state = {
                icd_block_show: false,
                cpt_block_show: false,
                certificate_type: params.type,
                patient_id: params.patientId,
                form_attributes:{
                    cpt_data: [],
                    icd_data: [],
                    medicines:[],
                    doctor:{}
                },
            }
        } else if(params.type === 'referral_letter') {
            this.state = {
                certificate_type: params.type,
                patient_id: params.patientId,
                form_attributes:{
                    doctor:{}
                },
            }
        } else{
            this.state = {
                certificate_type: params.type,
                patient_id: params.patientId,
                form_attributes:{}
            }
        }
        

        this.props.getPatient(params.patientId);
        this.validator = new SimpleReactValidator({ locale: Helper.getLanguage() });
    }

    handleSubmitPrint = () => {

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }

        if(this.state.certificate_type === 'follow_up'){
            let icd_cpts = [];
            !_.isEmpty(this.state.form_attributes.cpt_data) && this.state.form_attributes.cpt_data.map(cpt => (
                (!_.isEmpty(cpt.cptCrosswalks)) ?
                    cpt.cptCrosswalks.map((crosswalk, _idx) => (
                        icd_cpts = icd_cpts.concat({
                            cpt_code: cpt.selectedCpt.cpt_code,
                            cpt_description: crosswalk.full_description,
                            icd_description: cpt.selectedCpt.full_description,
                            icd_code: crosswalk.icd_code
                        })
                    ))
                    :
                    icd_cpts = icd_cpts.concat({ cpt_code: cpt.selectedCpt.cpt_code, 
                        cpt_description: cpt.selectedCpt.full_description,
                    })
            ))
            !_.isEmpty(this.state.form_attributes.icd_data) && this.state.form_attributes.icd_data.map(icd => (
                (!_.isEmpty(icd.icdCrosswalks)) ?
                    icd.icdCrosswalks.map((crosswalk, idx) => (
                        icd_cpts = icd_cpts.concat({
                            icd_code: icd.selectedIcd.icd_code,
                            icd_description: crosswalk.full_description,
                            cpt_code: crosswalk.cpt_code,
                            cpt_description: icd.selectedIcd.full_description,
                        })
                    ))
                    :
                    icd_cpts = icd_cpts.concat({ icd_code: icd.selectedIcd.icd_code, 
                        icd_description: icd.selectedIcd.full_description,
                    })
            ));

            const form_attributes = this.state.form_attributes;
            form_attributes['icd_cpts'] = icd_cpts;
            this.setState({ form_attributes });
        }
        const data = {
            medical_certificate_type: this.state.certificate_type,
            patient_id: this.state.patient_id,
            form_attributes: this.state.form_attributes
        };
        
        MedicalCertificateService.create({data})
        .then((resp) => {
            this.setState({certificate_id : resp.data.medical_certificate_id});
            this.showPinModal();
        })
        .catch((error) => {
            Notification.show('error', error.response.data);
        })
    }

    handleCptBlock = () => {
        this.setState({
            cpt_block_show: !this.state.cpt_block_show
        });
    }

    handleCptData = cptData => {
        let { form_attributes } = this.state;
        form_attributes['cpt_data'] = form_attributes.cpt_data.concat([{
            selectedCpt: cptData.selectedCpt,
            cptCrosswalks: cptData.selectedCrosswalk,
        }])
        this.setState({ form_attributes });
    }

    handleIcdBlock = () => {
        this.setState({
            icd_block_show: !this.state.icd_block_show
        });
    }

    handleIcdData = icdData => {
        let { form_attributes } = this.state
        let icd_data = form_attributes.icd_data.concat([{
            selectedIcd: icdData.selectedIcd,
            icdCrosswalks: icdData.selectedCrosswalk,
        }])
        form_attributes['icd_data'] = icd_data
        this.setState({ form_attributes });
    }

    handleRemoveCptData = (event) => {
        let { form_attributes } = this.state;
        const idx = Number(event.target.dataset["id"]);
        form_attributes['cpt_data'] = form_attributes.cpt_data.filter((s, sidx) => idx !== sidx)
        this.setState({ form_attributes });
    };

    handleRemoveIcdData = (event) => {
        let { form_attributes } = this.state;
        const idx = Number(event.target.dataset["id"]);
        form_attributes['icd_data'] = form_attributes.icd_data.filter((s, sidx) => idx !== sidx)
        this.setState({ form_attributes });
    };

    // Start Medicine Block Events
    handleMedicineChange = (idx, data) => {
        let { form_attributes } = this.state;
        const newMedicines = form_attributes.medicines.map((medicine, sidx) => {
            if (idx !== sidx) return medicine;

            return { ...medicine, ...data };
        });
        form_attributes['medicines'] = newMedicines;
        this.setState({ form_attributes });
    };
    handleAddMedicine = () => {
        let { form_attributes } = this.state;
        form_attributes['medicines'] = form_attributes.medicines.concat([{
            drug_id: '',
            drug_name: '',
            drug_generic_id: '',
            drug_generic_name: '',
            dosage: '',
            meal: '',
            duration: '',
            duration_type: '',
            substitution_not_allowed: false,
            quantity: '',
            drug_type: '',
            is_other: false,
            over_dosage: false,
            substitution_not_allowed_disable: false
        }]);
        this.setState({ form_attributes });
    };
    handleRemoveMedicine = (event) => {
        let { form_attributes } = this.state;
        const idx = Number(event.target.dataset['id']);
        form_attributes['medicines'] = form_attributes.medicines.filter((s, sidx) => idx !== sidx);
        this.setState({ form_attributes });
    };

    showPinModal = () => {
        this.setState({ showPinModal: true });
    }

    handleClose = () => {
        this.setState({ showPinModal: false });
    }

    onSuccessRedirect = () => {
        MedicalCertificateService.downloadAndPrint(this.state.certificate_id)
        .then((resp) => {
            window.open(resp.data.link);
            this.props.history.push('/patients/' + this.state.patient_id +'/medical-certificate?issue=1');
        })
        .catch((error) => {
            Notification.show('error', error.response.data);
        })
    }

    handleCertificateChange = (e) => {
        let form_attributes = this.state.form_attributes;
        form_attributes[e.target.name] = e.target.value;
        this.setState({ form_attributes });
    }

    handleSearchDoctor = (data) => {
        let form_attributes = this.state.form_attributes;
        form_attributes['doctor'] = {
            doctor_id: data.doctor_id,
            doctor_name: data.doctor_name,
            doctor_specialization: data.specialization,
            service_provider_id: data.service_provider_id
        };
        this.setState({ form_attributes });
    }

    render() {
        const { t } = this.props;
        this.validator.purgeFields();
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('certificate')}</title>
                </Helmet>
                <section className="container-fluid">
                    <div className="row">
                        <div className="col-sm-24">
                            <h4 className="page-title no-heading">
                                <Link className="mb-1" onClick={() => this.props.history.goBack()}>&#8249; {t('back')}</Link>
                                {t('create')} {_.startCase(this.state.certificate_type)} {t('certificate')}
                            </h4>
                            <h4 className="page-title">{_.startCase(this.state.certificate_type)}</h4>
                            <div className="content-wrapper p-4">
                                <h6>{t('patient_info')}</h6>
                                <ul className="prescription-patient-details">
                                    <li>{t('name')}: <span>{this.props.patient.full_name}</span></li>
                                    <li>{t('gender')}: <span>{_.capitalize(this.props.patient.gender)}</span></li>
                                    <li>{t('date_of_birth')}: <span>{ Helper.getDateFormat(this.props.patient.date_of_birth) } ({Helper.getAgeString(this.props.patient.date_of_birth)})</span></li>
                                    <li>{t('contact_number')}: <span>{this.props.patient.contact_number}</span></li>
                                </ul>
                            </div>


                            <div className="content-wrapper">
                                    {this.state.certificate_type === 'return_to_school' && <ReturnToSchool
                                    validator = {this.validator}
                                    form_attributes = {this.state.form_attributes}
                                    patient={this.props.patient}
                                    handleChangeCertificate={this.handleCertificateChange}
                                    t={t}
                                    ></ReturnToSchool>}
                                <div className="row">
                                    <div className="mt-3 col-sm-24">
                                        
                                        {this.state.certificate_type === 'return_to_work' && <ReturnToWork
                                            validator = {this.validator}
                                            form_attributes = {this.state.form_attributes}
                                            patient={this.props.patient}
                                            handleChangeCertificate={this.handleCertificateChange}
                                            t={t}
                                        ></ReturnToWork>}
                                        {this.state.certificate_type === 'work_excuse' && <WorkExcuse
                                            validator = {this.validator}
                                            form_attributes = {this.state.form_attributes}
                                            patient={this.props.patient}
                                            handleChangeCertificate={this.handleCertificateChange}
                                            t={t}
                                        ></WorkExcuse>}
                                        {this.state.certificate_type === 'follow_up' && <FollowUp
                                            validator = {this.validator}
                                            form_attributes = {this.state.form_attributes}
                                            patient={this.props.patient}
                                            selected_user_profile={this.props.selected_user_profile}
                                            icd_block_show={this.state.icd_block_show}
                                            cpt_block_show={this.state.cpt_block_show}
                                            handleRemoveCptData={this.handleRemoveCptData}
                                            handleRemoveIcdData={this.handleRemoveIcdData}
                                            handleCptBlock={this.handleCptBlock}
                                            handleCptData={this.handleCptData}
                                            handleIcdBlock={this.handleIcdBlock}
                                            handleIcdData={this.handleIcdData}
                                            handleAddMedicine={this.handleAddMedicine}
                                            handleMedicineChange={this.handleMedicineChange}
                                            handleRemoveMedicine={this.handleRemoveMedicine}
                                            handleChangeCertificate={this.handleCertificateChange}
                                            handleSearchDoctor={this.handleSearchDoctor}
                                            t={t}
                                        ></FollowUp>}
                                        {this.state.certificate_type === 'referral_letter' && <ReferralLetter
                                            validator = {this.validator}
                                            form_attributes = {this.state.form_attributes}
                                            patient={this.props.patient}
                                            handleChangeCertificate={this.handleCertificateChange}
                                            handleSearchDoctor={this.handleSearchDoctor}
                                            t={t}
                                        ></ReferralLetter>}
                                    </div>
                                    {this.state.showPinModal && 
                                        <PinModal show={this.state.showPinModal} handleClose={this.handleClose} type="certificate" id={this.state.certificate_id} onSuccess={this.onSuccessRedirect} />
                                    }
                                </div>
                            </div>
                            <div className="mt-3 text-right mb-3">
                                <button class="btn btn-primary mr-2" onClick={this.handleSubmitPrint}>{t('submit_print')}</button>
                                <button class="btn btn-outline-primary">{t('cancel')}</button>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        patient: state.patient,
        selected_user_profile: state.selected_user_profile,
    };
}

const mapActionsToProps = ({
    getPatient: PatientAction.get,
})

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/certificate/AddCertificatePage')(AddCertificatePage));