import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import SimpleReactValidator from 'simple-react-validator';
import Alert from '../../components/Alert';
import * as PatientAction from '../../actions/patientAction';
import PatientService from '../../services/patientService';
import CommunityService from '../../services/communityService';
import { Helmet } from 'react-helmet';
import * as Config from '../../config';
import _ from 'lodash';
import CardSwipe from 'react-card-swipe';
import * as HELPER from '../../utils/helpers';
import moment from 'moment';
import Notification from '../../utils/notification';
import AdditionalInformation from "../../components/Patient/AdditionalInformation";
import { hasProvider } from "../../utils/auth";
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';

class AddPatientPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            check_card_number: {
                hide: false,
                status: "default",
                message: ""
            },
            card: {
                user_code: '',
                card_number: '',
                hash: ''
            },
            card_number: "",
            title: '',
            first_name: "",
            middle_name: "",
            last_name: "",
            father_name: "",
            mother_name: "",
            mother_maiden_name: "",
            date_of_birth: "",
            gender: "",
            email: "",
            contact_number: "",
            alt_contact_number: "",
            blood_group: "",
            address_line_1: "",
            address_line_2: "",
            address_line_3: "",
            city: "",
            pincode: "",
            parish: "",
            insurance_type: [],
            is_no: false,
            is_other: false,
            is_private_sagicore: false,
            is_private_medicus: false,
            is_private_canopy: false,
            is_jadep: false,
            is_nhf: false,
            is_goj: false,
            jadep_number: "",
            nhf_number: "",
            goj_number: "",
            isNoChecked: false,
            isPrivateChecked: false,
            isJadepChecked: false,
            isNHFChecked: false,
            isGOJChecked: false,
            isJadepDisabled: true,
            place_of_birth: "",
            allergy: [],
            comorbidities: [],
            height: null,
            kin_name: '',
            kin_number: '',
            kin_relation: '',
            nearest_police_station: '',
            occupation: '',
            private_insurance_sagicore_number: '',
            private_insurance_medicus_number: '',
            private_insurance_canopy_number: '',
            profilePicId: '',
            profilePicUrl: '',
            disableSubmit: false,
            showModal: false,
            health_information: null,
            communities: [],
            selectedParisCommunity: [],
            isOnboard: false,
            isOpen: false,
            showDatePicker: false,
            minor: false,
            parent: "",
            relation: "",
            national_id: "",
        };
        this.ref = React.createRef();
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleVisibleClick = this.handleVisibleClick.bind(this);

        CardSwipe.init({
            success: this.handleSwipeSuccess,
            debug: true
        });

        this.validator = new SimpleReactValidator({
            locale: localStorage.getItem('i18nextLng'),
            validators: {
                insurance_type: {
                    message: this.props.t('insurance_type_validation'),
                    rule: (val) => {
                        return val.length > 0;
                    },
                    required: true
                },
                validation_sigle_space: {
                    message: 'The field should contain only single spaces',
                    rule: (val, params, validator) => {
                        // Check if the value is not empty and contains only single spaces
                        return val.trim().length > 0 && !val.includes('  ');
                    },
                    required: true
                },
                validation_sigle_space_nonmanditory: {
                    message: 'The field should contain only single spaces',
                    rule: (val, params, validator) => {
                        // Check if the value is not empty and contains only single spaces
                        return val.trim().length > 0 && !val.includes('  ');
                    },
                    required: false
                },
            }
        });
        this.handleChange = this.handleChange.bind(this);
        this.handleInsuranceTypeChange = this.handleInsuranceTypeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.getCommunity();
        this.setSelectedParisCommunity(this.state.parish)

    }

    componentDidMount() {
        if (this.props.location.state != undefined && this.props.location.state.name != undefined) {
            let data = this.props.location.state;
            let name = data.name ? data.name.split(" ") : "";
            let first_name = "";
            let last_name = "";
            if (name.length > 1) {
                first_name = name.slice(0, -1).join(' ');
                last_name = name.slice(-1).join(' ');
            } else {
                first_name = name[0];
                last_name = "";
            }

            const currentDate = moment().format('YYYY-MM-DD');
            const jadepEligibleDate = moment(data.date_of_birth).add(60, 'y').format('YYYY-MM-DD');
            this.setState({
                first_name: first_name,
                last_name: last_name,
                gender: data.gender,
                date_of_birth: data.date_of_birth,
                contact_number: data.contact,
                email: data.email,
                mother_maiden_name: data.maiden_name,
                place_of_birth: data.place_of_birth,
                alt_contact_number: data.alt_contact,
                address_line_1: data.address_line_1,
                parish: data.parish,
                city: data.city,
                insurance_type: data.insurance_type ? data.insurance_type : [],

                jadep_number: data.jadep_number,
                nhf_number: data.nhf_number,
                goj_number: data.goj_number,

                private_insurance_sagicore_number: data.sagicore_number,
                private_insurance_medicus_number: data.medicus_number,
                private_insurance_canopy_number: data.canopy_number,

                is_jadep: data.insurance_type && data.insurance_type.includes('jadep') ? true : false,
                is_nhf: data.insurance_type && data.insurance_type.includes('nhf') ? true : false,
                is_goj: data.insurance_type && data.insurance_type.includes('goj') ? true : false,

                is_private_sagicore: data.insurance_type && data.insurance_type.includes('private-insurance-sagicore') ? true : false,
                is_private_medicus: data.insurance_type && data.insurance_type.includes('private-insurance-medicus') ? true : false,
                is_private_canopy: data.insurance_type && data.insurance_type.includes('private-insurance-canopy') ? true : false,

                is_other: data.insurance_type && data.insurance_type.includes('other') ? true : false,
                is_no: data.insurance_type && data.insurance_type.includes('no') ? true : false,
                isJadepDisabled: (jadepEligibleDate <= currentDate) ? false : true,
            });
        } else {
            if (this.props.location.preRegisteredPatient != undefined && this.props.location.preRegisteredPatient.first_name != undefined) {

                this.fetchPreRegisteredPatient(this.props.location.preRegisteredPatient);
            }
        }
    }

    getCommunity = () => {
        CommunityService.listAll().then(resp => {
            this.setState({
                communities: resp.data.data,
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    fetchPreRegisteredPatient(data) {
        this.setState({
            title: data.name_title,
            first_name: data.first_name,
            middle_name: data.middle_name ? data.middle_name : "",
            last_name: data.last_name,
            father_name: data.father_name ? data.father_name : "",
            mother_name: data.mother_name ? data.mother_name : "",
            mother_maiden_name: data.mother_maiden_name ? data.mother_maiden_name : "",
            date_of_birth: data.date_of_birth,
            gender: data.gender,
            email: data.email ? data.email : '',
            contact_number: data.contact_number ? data.contact_number : "",
            alt_contact_number: data.alt_contact_number ? data.alt_contact_number : "",
            blood_group: data.blood_group ? data.blood_group : "",
            address_line_1: data.address_line_1 ? data.address_line_1 : "",
            address_line_2: data.address_line_2 ? data.address_line_2 : "",
            address_line_3: data.address_line_3 ? data.address_line_3 : "",
            city: data.city,
            pincode: data.pincode ? data.pincode : "",
            parish: data.parish,
            insurance_type: data.insurance_type ? data.insurance_type : [],
            is_no: data.is_no ? true : false,
            is_other: data.is_other ? true : false,
            is_private_sagicore: data.is_private_sagicore ? true : false,
            is_private_medicus: data.is_private_medicus ? true : false,
            is_private_canopy: data.is_private_canopy ? true : false,
            is_jadep: data.is_jadep ? true : false,
            is_nhf: data.is_nhf ? true : false,
            is_goj: data.is_goj ? true : false,
            jadep_number: data.jadep_number ? data.jadep_number : "",
            nhf_number: data.nhf_number ? data.nhf_number : "",
            goj_number: data.goj_number ? data.goj_number : "",
            place_of_birth: data.place_of_birth ? data.place_of_birth : "",
            allergy: data.allergies ? data.allergies : [],
            comorbidities: data.comorbidities ? data.comorbidities : [],
            height: data.height ? data.height : null,
            kin_name: data.kin_name ? data.kin_name : "",
            kin_number: data.kin_number ? data.kin_number : "",
            kin_relation: data.kin_relation ? data.kin_relation : "",
            nearest_police_station: data.nearest_police_station ? data.nearest_police_station : "",
            occupation: data.occupation ? data.occupation : "",
            private_insurance_sagicore_number: data.private_insurance_sagicore_number ? data.private_insurance_sagicore_number : "",
            private_insurance_medicus_number: data.private_insurance_medicus_number ? data.private_insurance_medicus_number : "",
            private_insurance_canopy_number: data.private_insurance_canopy_number ? data.private_insurance_canopy_number : "",
            profilePicId: data.profilePicId ? data.profilePicId : "",
            profilePicUrl: data.profilePicUrl ? data.profilePicUrl : "",
            minor: data.minor,
            national_id: data.national_id,
        }, () => {
            this.setSelectedParisCommunity(this.state.parish)
        });
    }

    handleDebugCard = (e) => {

        if (e.target.value) {
            let swipeData = HELPER.debugCard(e.target.value);

            this.verifyCard(swipeData);

        } else {
            this.setState({
                card_number: '',
                card: {
                    user_code: '',
                    card_number: '',
                    hash: '',
                },
                check_card_number: {
                    hide: false,
                    status: "default",
                    message: ''
                }
            });
        }
    }

    handleSwipeSuccess = (swipeData) => {
        this.verifyCard(swipeData);
    }

    verifyCard = (swipeCardObj) => {
        let cardObj = HELPER.getCardObj(swipeCardObj.line1);

        this.setState({
            card_number: cardObj.user_code,
            card: {
                user_code: cardObj.user_code,
                card_number: cardObj.card_number,
                hash: cardObj.hash,
            },
            check_card_number: {
                hide: true,
                status: 'default',
                message: this.props.t('checking')
            }
        });

        PatientService.verifyCard(cardObj)
            .then(resp => {
                let data = resp.data;

                this.setState({
                    check_card_number: {
                        hide: true,
                        status: data.card_status === 'success' ? 'success' : 'danger',
                        message: data.message
                    }
                });
            }).catch(error => {
                this.setState({
                    check_card_number: {
                        hide: true,
                        status: 'info',
                        message: this.props.t('try_again')
                    }
                });
            });
    }

    searchPatientInPPL = (email, contact_number) => {
        let reqData = { email, contact_number };
        PatientService.searchPatientInPPL(reqData)
            .then(resp => {
                if (resp.data) {
                    this.setState({
                        isOnboard: true,
                    })
                }
            }).catch(error => {
                if (error.response.data.message) {
                    this.setState({
                        isOnboard: false,
                        isOpen: true
                    })
                }
            });
    }

    modalClose = () => {
        this.setState({
            isOnboard: false,
            isOpen: false
        })
    }

    createPatient = () => {
        this.setState({
            isOnboard: true,
            isOpen: false
        });
    }

    handleChange(e) {
        if (e.target.name === 'date_of_birth') {
            const patientAge = moment().diff(e.target.value, 'years');

            if (patientAge >= 60) {
                this.setState({ [e.target.name]: e.target.value, isJadepDisabled: false });
            } else {
                this.setState({
                    [e.target.name]: e.target.value,
                    isJadepDisabled: true,
                    jadep_number: '',
                    is_jadep: false
                });
            }
            return true;
        }

        if (e.target.name === 'parish') {
            this.setSelectedParisCommunity(e.target.value)
            this.setCommunity(null)
        }

        if (e.target.name === 'contact_number' || 'email' && this.state.isOnboard) {
            this.setState({
                isOnboard: false
            })
        }
        this.setState({ [e.target.name]: e.target.value });
    }

    handleMinorChange = (value) => {
        this.setState({ 'minor': value });
    }

    handleParentChange = (parent) => {
        if (parent) {
            this.setState({ parent: parent, contact_number: parent.contact_number });
        } else {
            this.setState({ parent: '', contact_number: '' });
        }
    }

    setSelectedParisCommunity = (parish) => {
        let selectedCommunities = _.filter(this.state.communities, (items) => items.parish_name == parish)
        selectedCommunities = selectedCommunities.map((items) => {
            return { 'label': items.community_name, 'value': items.community_name };
        })
        this.setState({
            selectedParisCommunity: selectedCommunities
        });
    }

    setCommunity = (community) => {
        this.setState({ city: community ? community.value : '' });
    }

    createCommunity = (community) => {
        community = community.replace(/"/g, "'");
        community = community.replace(/''/g, "'");
        let selectedParisCommunity = this.state.selectedParisCommunity;
        selectedParisCommunity.push({ label: community, value: community });
        this.setState({ city: community, selectedParisCommunity: selectedParisCommunity });
    }

    handleTagsChange = (e) => {
        let value = e.target.value ? (e.target.value).split(",") : [];
        this.setState({ [e.target.name]: value });
    }

    handleInsuranceTypeChange(e) {
        let insurance_type = this.state.insurance_type;

        const { is_no, is_private_sagicore, is_private_medicus, is_private_canopy, is_other } = this.state;

        if (e.target.checked) {
            insurance_type.push(e.target.value);
        } else {
            if (e.target.value === 'jadep') {
                this.setState({
                    jadep_number: ''
                });
            }
            if (e.target.value === 'nhf') {
                this.setState({
                    nhf_number: ''
                });
            }
            if (e.target.value === 'goj') {
                this.setState({
                    goj_number: ''
                });
            }
            if (e.target.value === 'private-insurance-sagicore') {
                this.setState({
                    private_insurance_sagicore_number: ''
                });
            }
            if (e.target.value === 'private-insurance-medicus') {
                this.setState({
                    private_insurance_medicus_number: ''
                });
            }
            if (e.target.value === 'private-insurance-canopy') {
                this.setState({
                    private_insurance_canopy_number: ''
                });
            }
            _.pull(insurance_type, e.target.value);
        }

        if (e.target.value === 'no') {
            if (e.target.checked) {
                insurance_type = ["no"];
            }
            this.setState({
                is_no: !is_no,
                is_private_sagicore: e.target.unchecked,
                is_private_medicus: e.target.unchecked,
                is_private_canopy: e.target.unchecked,
                is_jadep: e.target.unchecked,
                is_nhf: e.target.unchecked,
                is_goj: e.target.unchecked,
                is_other: e.target.unchecked,
                jadep_number: "",
                nhf_number: "",
                goj_number: "",
                private_insurance_sagicore_number: "",
                private_insurance_medicus_number: "",
                private_insurance_canopy_number: "",
            });
        } else {
            _.pull(insurance_type, "no");
        }

        if (e.target.value === 'private-insurance-sagicore') {
            this.setState({
                is_private_sagicore: !is_private_sagicore,
                is_no: false
            });
        }

        if (e.target.value === 'other') {
            this.setState({
                is_other: !is_other,
                is_no: false
            });
        }

        if (e.target.value === 'private-insurance-medicus') {
            this.setState({
                is_private_medicus: !is_private_medicus,
                is_no: false
            });
        }

        if (e.target.value === 'private-insurance-canopy') {
            this.setState({
                is_private_canopy: !is_private_canopy,
                is_no: false
            });
        }

        if (e.target.value === 'jadep') {
            this.setState({
                is_no: false,
                is_jadep: e.target.checked
            });
        }

        if (e.target.value === 'nhf') {
            this.setState({
                is_no: false,
                is_nhf: e.target.checked
            });
        }

        if (e.target.value === 'goj') {
            this.setState({
                is_no: false,
                is_goj: e.target.checked
            });
        }

        this.setState({ insurance_type: insurance_type });
    }

    resetNumberBox = (e) => {
        this.setState({
            check_card_number: {
                hide: false,
                status: 'default',
                message: ''
            }
        });
    }

    async handleSubmit() {
        // e.preventDefault();
        if (this.state.check_card_number.status !== 'success') {
            return false;
        }
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        let data = {
            code_number: this.state.card.user_code,
            card_number: this.state.card.card_number,
            healthcard_number: this.state.card_number,
            name_title: this.state.title,
            first_name: this.state.first_name,
            middle_name: this.state.middle_name,
            last_name: this.state.last_name,
            father_name: this.state.father_name,
            mother_name: this.state.mother_name,
            maiden_name: this.state.mother_maiden_name,
            date_of_birth: this.state.date_of_birth,
            gender: this.state.gender,
            email: this.state.email,
            contact_number: this.state.contact_number,
            alt_contact_number: this.state.alt_contact_number,
            blood_group: this.state.blood_group,
            insurance_type: this.state.insurance_type,
            jadep_number: this.state.jadep_number,
            nhf_number: this.state.nhf_number,
            goj_number: this.state.goj_number,
            address: {
                address_line_1: this.state.address_line_1,
                address_line_2: this.state.address_line_2,
                address_line_3: this.state.address_line_3,
                city: this.state.city,
                pincode: this.state.pincode,
                parish: this.state.parish
            },
            place_of_birth: this.state.place_of_birth,
            allergy: this.state.allergy,
            comorbidities: this.state.comorbidities,
            height: this.state.height,
            kin_name: this.state.kin_name ? this.state.kin_name : '',
            kin_number: this.state.kin_number ? this.state.kin_number : '',
            kin_relation: this.state.kin_relation ? this.state.kin_relation : '',
            nearest_police_station: this.state.nearest_police_station ? this.state.nearest_police_station : '',
            occupation: this.state.occupation ? this.state.occupation : '',
            sagicore_number: this.state.private_insurance_sagicore_number ? this.state.private_insurance_sagicore_number : '',
            medicus_number: this.state.private_insurance_medicus_number ? this.state.private_insurance_medicus_number : '',
            canopy_number: this.state.private_insurance_canopy_number ? this.state.private_insurance_canopy_number : '',
            profile_picture_id: this.state.profilePicId ? this.state.profilePicId : '',
            pre_registered_id: this.props.location.preRegisteredPatient ? this.props.location.preRegisteredPatient.id : '',
            health_information: this.state.health_information,
            minor: this.state.minor,
            parent_patient_id: this.state.parent ? this.state.parent.value : null,
            parent_relation: this.state.relation,
            national_id: this.state.national_id,
        };

        if (this.state.minor == true) {
            this.props.addPatient(data, this.props.selected_user_profile, this.props.history);
        } else {
            !this.state.isOnboard && this.searchPatientInPPL(data.email, data.contact_number);
            if (this.state.isOnboard) {
                this.props.addPatient(data, this.props.selected_user_profile, this.props.history);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isOnboard) {
            this.handleSubmit();
        }
    }

    // On file select upload the file
    onFileChange = event => {

        let file = event.target.files[0];
        let fileSize = file.size / 1024;
        if (fileSize > 5120) {
            Notification.show('error', { "message": "The file may not be greater than 5 MB." });
            return false;
        }
        this.setState({
            disableSubmit: true
        });
        const formData = new FormData();
        formData.append('file', file);
        PatientService.uploadProfilePicture(formData).then(resp => {
            this.setState({
                profilePicId: resp.data.image_id,
                profilePicUrl: resp.data.thumbnail_url,
                disableSubmit: false
            })
        }).catch(error => {
            this.setState({
                disableSubmit: false
            });
            Notification.show('error', error.response.data)
        })
    };

    deleteProfile = () => {
        this.setState({
            disableSubmit: true
        });
        PatientService.deleteProfilePicture(this.state.profilePicId).then(resp => {
            this.setState({
                profilePicId: '',
                profilePicUrl: '',
                disableSubmit: false
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    saveAdditionalInfo = (additionalData) => {
        // Update component state with additionalData
        this.setState({ health_information: additionalData }, () => {
            console.log("Additional Data Received: ", this.state.health_information);
        });
    };

    // Function to handle modal visibility
    toggleModal = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal
        }));
    };

    ////////////////dob changes///////////////
    handleDateChange = (date) => {
        this.setState({ date_of_birth: date, showDatePicker: false });
    };
    handleClick = (e) => {
        this.ref.current.focus();
    };
    handleVisibleClick() {
        this.ref.current.focus();
    }

    loadOptions = (inputValue, callback) => {
        if (inputValue.length > 2) {
            let reqData = { search: inputValue };
            PatientService.searchPatientByName(reqData)
                .then(resp => {
                    if (resp.data) {
                        let options = resp.data.data.map((items) => {
                            return { 'label': items.full_name, 'value': items.patient_id, 'contact_number': items.contact_number };
                        })
                        callback(options)
                    }
                }).catch(error => {
                    if (error.response.data.message) {
                    }
                });
        } else {
            callback([]);
        }
    };

    render() {
        this.validator.purgeFields();
        const { is_no, is_private_sagicore, is_private_medicus, is_private_canopy, is_jadep, is_nhf, is_goj, is_other } = this.state;
        let { t } = this.props;
        return (<div>
            <Helmet>
                <title>{t('add_new_patients')}</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">{t('add_patient')}</h4>
                    <div className="content-wrapper edit-patient">
                        <Alert />
                        <form onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="card_number">{t('card_number')}*</label>
                                        <input type="text" className="form-control" value={this.state.card_number} id="card_number" name="card_number" onChange={this.handleChange} onFocus={this.resetNumberBox} readOnly />
                                        {this.state.check_card_number && <small className={'text-' + this.state.check_card_number.status}>{this.state.check_card_number.message}</small>}
                                        {this.validator.message(t('card_number'), this.state.card_number, 'required|alpha_num', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>
                                {HELPER.config('CARD_DEBUG') === 'true' ? <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="card_number">{t('card_debug')}*</label>
                                        <input type="text" className="form-control" id="card" name="card" onBlur={this.handleDebugCard} />
                                    </fieldset>
                                </div> : ""}
                            </div>

                            <div className="row minor-patient">

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="minor">Minor</label>
                                        <div className="checkbox-option-list">
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id='minor_no' name="minor" onChange={e => this.handleMinorChange(false)} checked={this.state.minor == false ? true : false} />
                                                <label htmlFor='minor_no'>NO</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id='minor_yes' name="minor" onChange={e => this.handleMinorChange(true)} checked={this.state.minor == true ? true : false} />
                                                <label htmlFor='minor_yes'>YES</label>
                                            </div>
                                        </div>
                                        {this.validator.message('minor', this.state.minor, 'required', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="parent" className={!this.state.minor == true ? "text-muted" : ""}>Parent/Guardian</label>

                                        <AsyncSelect
                                            name="parent"
                                            cacheOptions
                                            isClearable
                                            value={this.state.parent}
                                            loadOptions={this.loadOptions}
                                            onChange={value => this.handleParentChange(value)}
                                            placeholder="Search"
                                            isDisabled={this.state.minor ? false : true}
                                            loadingMessage={(e) => e.inputValue.length > 2 ? "Searching..." : ""}
                                        />

                                    </fieldset>
                                </div>
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="relation" className={!this.state.minor == true ? "text-muted" : ""}>Relation</label>
                                        <input type="text" disabled={this.state.minor ? false : true} className="form-control input-relation" value={this.state.relation} id="relation" name="relation" onChange={this.handleChange} />
                                        {this.validator.message('relation', this.state.relation, 'alpha_space', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>
                            </div>


                            <div className="row">

                                <div className="col-sm-3">
                                    <fieldset className="form-group">
                                        <label htmlFor="title">{t('title')}*</label>
                                        <select className="form-control" value={this.state.title} id="title" name="title" onChange={this.handleChange}>
                                            <option value="" disabled>{t('select_title')}</option>
                                            {!this.state.minor && Config.name_title.map(function (key) {
                                                return <option value={key}>
                                                    {key}
                                                </option>;
                                            })}

                                            {this.state.minor && Config.minor_name_title.map(function (key) {
                                                return <option value={key}>
                                                    {key}
                                                </option>;
                                            })}
                                        </select>
                                        {this.validator.message(t('title'), this.state.title, 'required', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>

                                <div className="col-sm-7">
                                    <fieldset className="form-group">
                                        <label htmlFor="first_name">{t('first_name')}*</label>
                                        <input type="text" className="form-control" value={this.state.first_name} id="first_name" name="first_name" onChange={this.handleChange} />
                                        {this.validator.message(t('first_name'), this.state.first_name, ['required', 'string','validation_sigle_space', { regex: HELPER.personNameRegex() }], { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>

                                <div className="col-sm-7">
                                    <fieldset className="form-group">
                                        <label htmlFor="middle_name">{t('middle_name')}</label>
                                        <input type="text" className="form-control" value={this.state.middle_name} id="middle_name" name="middle_name" onChange={this.handleChange} />
                                        {this.validator.message(t('middle_name'), this.state.middle_name, ['string','validation_sigle_space_nonmanditory', { regex: HELPER.personNameRegex() }], { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>

                                <div className="col-sm-7">
                                    <fieldset className="form-group">
                                        <label htmlFor="last_name">{t('last_name')}*</label>
                                        <input type="text" className="form-control" value={this.state.last_name} id="last_name" name="last_name" onChange={this.handleChange} />
                                        {this.validator.message(t('last_name'), this.state.last_name, ['required', 'string', 'validation_sigle_space',{ regex: HELPER.personNameRegex() }], { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="father_name">{t('father_name')}</label>
                                        <input type="text" className="form-control" value={this.state.father_name} id="father_name" name="father_name" onChange={this.handleChange} />
                                        {this.validator.message(t('father_name'), this.state.father_name, ['string', 'validation_sigle_space_nonmanditory', { regex: HELPER.personNameRegex() }], { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="mother_name">{t('mother_name')}</label>
                                        <input type="text" className="form-control" value={this.state.mother_name} id="mother_name" name="mother_name" onChange={this.handleChange} />
                                        {this.validator.message(t('mother_name'), this.state.mother_name, ['string', 'validation_sigle_space_nonmanditory', { regex: HELPER.personNameRegex() }], { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="mother_maiden_name">{t('mother_maiden_name')}*</label>
                                        <input type="text" className="form-control" value={this.state.mother_maiden_name} id="mother_maiden_name" name="mother_maiden_name" onChange={this.handleChange} />
                                        {this.validator.message(t('mother_maiden_name'), this.state.mother_maiden_name, ['required', 'string', 'validation_sigle_space', { regex: HELPER.personNameRegex() }], { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="gender">{t('gender')}*</label>
                                        <select className="form-control" id="control" name="gender" value={this.state.gender} onChange={this.handleChange}>
                                            <option value="">{t('select_gender')}</option>
                                            <option value="male">{t('male')}</option>
                                            <option value="female">{t('female')}</option>
                                        </select>
                                        {this.validator.message(t('gender'), this.state.gender, 'required')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="date_of_birth">{t('date_of_birth')}*</label>
                                        <div style={{ position: 'relative' }}>
                                            <input
                                                type="date"
                                                ref={this.ref}
                                                className="form-control"
                                                value={this.state.date_of_birth ? moment(this.state.date_of_birth).format('YYYY-MM-DD') : ''}
                                                id="date_of_birth"
                                                name="date_of_birth"
                                                onChange={(e) => this.handleDateChange(e.target.value)}
                                                onClick={this.handleClick}
                                                max={moment().format('YYYY-MM-DD')}
                                                placeholder=""
                                            />
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                className="edit-icon datepickericon"
                                                onClick={this.handleVisibleClick}
                                            />
                                        </div>
                                        {this.validator.message(t('date_of_birth'), this.state.date_of_birth, 'required')}
                                    </fieldset>
                                </div>


                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="place_of_birth">{t('place_of_birth')}*</label>
                                        <input type="text" className="form-control" value={this.state.place_of_birth} id="place_of_birth" name="place_of_birth" onChange={this.handleChange} />
                                        {this.validator.message(t('place_of_birth'), this.state.place_of_birth, ['required','validation_sigle_space'])}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="email">{t('email')}</label>
                                        <input type="text" className="form-control" value={this.state.email} id="email" name="email" onChange={this.handleChange} />
                                        {this.validator.message(t('email'), this.state.email, 'email', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="contact_number">{t('contact_number')}*</label>
                                        <input type="text" className="form-control" value={this.state.contact_number} id="contact_number" name="contact_number" onChange={this.handleChange} />
                                        {this.validator.message(t('contact_number'), this.state.contact_number, 'required|phone|max:10', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="alt_contact_number">{t('alternate_number')}</label>
                                        <input type="text" className="form-control" value={this.state.alt_contact_number} id="alt_contact_number" name="alt_contact_number" onChange={this.handleChange} />
                                        {this.validator.message(t('alternate_number'), this.state.alt_contact_number, 'phone|max:10', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="blood_group">{t('blood_group')}</label>
                                        <select className="form-control" value={this.state.blood_group} id="blood_group" name="blood_group" onChange={this.handleChange}>
                                            <option value="" disabled>{t('unknown')}</option>
                                            {Object.keys(Config.BLOODGROUP).map(function (key) {
                                                return <option className="travelcompany-input" value={Config.BLOODGROUP[key]}>
                                                    {Config.BLOODGROUP[key]}
                                                </option>;
                                            })}
                                        </select>
                                        {this.validator.message(t('blood_group'), this.state.blood_group, 'string')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="height">{t('height')}</label>
                                        <input type="number" name="height" className="form-control" value={this.state.height ? parseFloat(this.state.height) : ""} onChange={this.handleChange} min="0" autoComplete="off" placeholder="in cm" />
                                        {this.validator.message(t('height'), this.state.height, 'numeric|min:0,num|max:300,num')}
                                    </fieldset>
                                </div>

                                < div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="national_id">National ID</label>
                                        <input type="text" className="form-control" name="national_id" value={this.state.national_id} id="national_id" onChange={this.handleChange} />
                                        {this.validator.message('national_id', this.state.national_id, ['string', { regex: HELPER.nationalIdRegex() }])}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-24">
                                    <fieldset className="form-group">
                                        <label htmlFor="insurance_type">{t('insurance_type')}*</label>
                                        <div className="checkbox-option-list">
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-no'} name="insurance_type" value="no" onChange={this.handleInsuranceTypeChange} checked={is_no} />
                                                <label htmlFor={'insurance_type-no'}>{t('no')}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-jadep'} name="insurance_type" value="jadep" disabled={this.state.isJadepDisabled} onChange={this.handleInsuranceTypeChange} checked={is_jadep} />
                                                <label htmlFor={'insurance_type-jadep'}>{t('jadep')}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-nhf'} name="insurance_type" value="nhf" onChange={this.handleInsuranceTypeChange} checked={is_nhf} />
                                                <label htmlFor={'insurance_type-nhf'}>{t('nhf')}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-goj'} name="insurance_type" value="goj" onChange={this.handleInsuranceTypeChange} checked={is_goj} />
                                                <label htmlFor={'insurance_type-goj'}>{t('goj')}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-private-insurance-sagicore'} name="insurance_type" value="private-insurance-sagicore" onChange={this.handleInsuranceTypeChange} checked={is_private_sagicore} />
                                                <label htmlFor={'insurance_type-private-insurance-sagicore'}>{t('private_insurance_sagicor')}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-private-insurance-medicus'} name="insurance_type" value="private-insurance-medicus" onChange={this.handleInsuranceTypeChange} checked={is_private_medicus} />
                                                <label htmlFor={'insurance_type-private-insurance-medicus'}>{t('private_insurance_medicus')}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-private-insurance-canopy'} name="insurance_type" value="private-insurance-canopy" onChange={this.handleInsuranceTypeChange} checked={is_private_canopy} />
                                                <label htmlFor={'insurance_type-private-insurance-canopy'}>{t('private_insurance_canopy')}</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input type="checkbox" id={'insurance_type-other'} name="insurance_type" value="other" onChange={this.handleInsuranceTypeChange} checked={is_other} />
                                                <label htmlFor={'insurance_type-other'}>{t('other')}</label>
                                            </div>
                                        </div>
                                        {this.validator.message(t('insurance_type'), this.state.insurance_type, 'insurance_type', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                {this.state.is_jadep && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="jadep_number">{t('jadep_number')}*</label>
                                        <input type="text" className="form-control" value={this.state.jadep_number} id="jadep_number" name="jadep_number" onChange={this.handleChange} />
                                        {this.validator.message(t('jadep_number'), this.state.jadep_number, 'required|string|max:100', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>}

                                {this.state.is_nhf && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="nhf_number">{t('nhf_number')}*</label>
                                        <input type="text" className="form-control" value={this.state.nhf_number} id="nhf_number" name="nhf_number" onChange={this.handleChange} />
                                        {this.validator.message(t('nhf_number'), this.state.nhf_number, 'required|string|max:100', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>}

                                {this.state.is_goj && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="goj_number">{t('goj_number')}*</label>
                                        <input type="text" className="form-control" value={this.state.goj_number} id="goj_number" name="goj_number" onChange={this.handleChange} />
                                        {this.validator.message(t('goj_number'), this.state.goj_number, 'required|string|max:100', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>}

                                
                                {this.state.is_private_sagicore && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="goj_number">{t('sagicore_number')}*</label>
                                        <input type="text" className="form-control" value={this.state.private_insurance_sagicore_number} id="sagicore_number" name="private_insurance_sagicore_number" onChange={this.handleChange} />
                                        {this.validator.message(t('sagicore_number'), this.state.private_insurance_sagicore_number, 'required|string|max:100', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>}

                                {this.state.is_private_medicus && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="goj_number">{t('medicus_number')}*</label>
                                        <input type="text" className="form-control" value={this.state.private_insurance_medicus_number} id="medicus_number" name="private_insurance_medicus_number" onChange={this.handleChange} />
                                        {this.validator.message(t('medicus_number'), this.state.private_insurance_medicus_number, 'required|string|max:100', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>}

                                {this.state.is_private_canopy && <div className="col-sm-6">
                                    <fieldset className="form-group">
                                        <label htmlFor="goj_number">{t('canopy_number')}*</label>
                                        <input type="text" className="form-control" value={this.state.private_insurance_canopy_number} id="canopy_number" name="private_insurance_canopy_number" onChange={this.handleChange} />
                                        {this.validator.message(t('canopy_number'), this.state.private_insurance_canopy_number, 'required|string|max:100', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>}
                            </div>

                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="address_line_1">{t('address')} 1*</label>
                                        <textarea maxLength="255" type="text" rows="2" className="form-control" value={this.state.address_line_1} id="address_line_1" name="address_line_1" onChange={this.handleChange} />
                                        {this.validator.message(t('address') + " 1", this.state.address_line_1, 'required|string|max:100')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="address_line_2">{t('address')} 2</label>
                                        <textarea maxLength="255" type="text" className="form-control" value={this.state.address_line_2} rows="2" id="address_line_2" name="address_line_2" onChange={this.handleChange} />
                                        {this.validator.message(t('address') + " 2", this.state.address_line_2, 'string|max:100')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="address_line_3">{t('address')} 3</label>
                                        <textarea type="text" maxLength="255" className="form-control" value={this.state.address_line_3} rows="2" id="address_line_3" name="address_line_3" onChange={this.handleChange} />
                                        {this.validator.message(t('address') + " 3", this.state.address_line_3, 'string|max:100')}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="parish">{t('parish')}*</label>
                                        <select className="form-control" value={this.state.parish} id="parish" name="parish" onChange={this.handleChange}>
                                            <option value="" disabled>{t('select_parish')}</option>
                                            {Object.keys(Config.parish).map(function (key) {
                                                return <option className="travelcompany-input" value={key}>
                                                    {Config.parish[key]}
                                                </option>;
                                            })}
                                        </select>
                                        {this.validator.message(t('parish'), this.state.parish, 'required')}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="city">{t('city')}*</label>
                                        <CreatableSelect isClearable options={this.state.selectedParisCommunity} value={{ value: this.state.city, label: this.state.city }} onChange={(newValue) => this.setCommunity(newValue)} onCreateOption={this.createCommunity} />
                                        {this.validator.message(t('city'), this.state.city, 'required|string|max:100')}


                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="pincode">{t('zip_code')}</label>
                                        <input type="text" className="form-control" value={this.state.pincode} id="pincode" name="pincode" onChange={this.handleChange} />
                                        {this.validator.message(t('zip_code'), this.state.pincode, 'alpha_num|max:7')}
                                    </fieldset>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="kin_name">{t('next_of_kin')}</label>
                                        <input type="text" className="form-control" value={this.state.kin_name} id="kin_name" name="kin_name" onChange={this.handleChange} />
                                        {this.validator.message('kin_name', this.state.kin_name, 'alpha_space', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="kin_relation">{t('relationship_with_kin')}</label>
                                        <input type="text" className="form-control" value={this.state.kin_relation} id="kin_relation" name="kin_relation" onChange={this.handleChange} />
                                        {this.validator.message('kin_relation', this.state.kin_relation, 'alpha_space', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="kin_number">{t('kin_contact_number')}</label>
                                        <input type="text" className="form-control" value={this.state.kin_number} id="kin_number" name="kin_number" onChange={this.handleChange} />
                                        {this.validator.message(t('kin_contact_number'), this.state.kin_number, 'phone|max:10', { className: "srv-validation-message custom-width" })}
                                    </fieldset>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="nearest_police_station">{t('nearest_police_station')}</label>
                                        <input type="text" className="form-control" value={this.state.nearest_police_station} id="nearest_police_station" name="nearest_police_station" onChange={this.handleChange} />
                                    </fieldset>
                                </div>
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="occupation">{t('occupation')}</label>
                                        <input type="text" className="form-control" value={this.state.occupation} id="occupation" name="occupation" onChange={this.handleChange} />
                                    </fieldset>
                                </div>

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="allery">{t('any_known_allergies')}</label>
                                        <textarea className="form-control" name="allergy" value={this.state.allergy} onChange={this.handleTagsChange} placeholder={t('type_allergy')}></textarea>
                                    </fieldset>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="allery">{t('comorbidities')}</label>
                                        <textarea className="form-control" name="comorbidities" value={this.state.comorbidities} onChange={this.handleTagsChange} placeholder={t('type_comorbidities')}></textarea>
                                    </fieldset>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-8">
                                    <fieldset className="form-group">
                                        <label htmlFor="profile_pic">{t('profile_picture')}</label>
                                        {_.isEmpty(this.state.profilePicUrl) && <div className="profile-pic">
                                            <img src="/images/user.svg" alt="" />
                                        </div>}
                                        {!_.isEmpty(this.state.profilePicUrl) && <div className="profile-pic">
                                            <img src={this.state.profilePicUrl} alt="" />
                                        </div>}

                                        <input className="hidden" id="profile-pic-upload" type="file" name="file" onChange={this.onFileChange} accept='.jpeg,.jpg,.png' />
                                        {_.isEmpty(this.state.profilePicUrl) && <label htmlFor="profile-pic-upload" className="profile-pic-upload ml-2">{t('choose_file')}</label>}

                                        {!_.isEmpty(this.state.profilePicUrl) && <>
                                            <button type="button" className="btn btn-secondry profile-pic-btn" onClick={this.deleteProfile}>{t('remove')}</button>
                                            <label htmlFor="profile-pic-upload" className="btn btn-secondry profile-pic-btn mb-0">{t('change_image')}</label>
                                        </>}
                                    </fieldset>
                                </div>
                                <div className="col-sm-8">
                                    <fieldset>
                                        {/* Button to trigger the modal */}
                                        {hasProvider(this.props.selected_user_profile.service_provider, ['pathology']) &&
                                            <button type="button" className="btn btn-primary" onClick={this.toggleModal}>Additional Information</button>
                                        }
                                        {/* Render the AdditionalInformation component */}
                                        {this.state.showModal && (
                                            <AdditionalInformation
                                                show={this.state.showModal}
                                                handleAdditionalInformation={this.toggleModal}
                                                getData={this.state.health_information}
                                                saveAdditionalInfo={this.saveAdditionalInfo} // Pass the saveAdditionalInfo function
                                                handleSubmit={this.handleSubmit} // Pass the modified handleSubmit function
                                            />
                                        )}
                                    </fieldset>
                                </div>
                            </div>
                            <>
                                <Modal centered aria-labelledby="contained-modal-title-vcenter" show={this.state.isOpen}>
                                    <Modal.Header>
                                        <h6>Patient Onboarding Confirmation</h6>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div>
                                            <p>A patient with same information already exists in PPL, Do you wish to onboard this patient on DH system ?</p>
                                        </div>
                                        <div className="pt-3">
                                            <Button
                                                variant="primary"
                                                className="btn-md mr-3 cbtn"
                                                type="submit"
                                                onClick={this.createPatient}
                                            >
                                                Yes
                                            </Button>

                                            <Button
                                                variant="danger"
                                                className="btn-md cbtn"
                                                onClick={this.modalClose}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </>
                            <div className="row">
                                <div className="col-sm-24">
                                    <button type="button" className="btn btn-primary" disabled={this.state.disableSubmit} onClick={this.handleSubmit}>{t('submit')}</button>
                                    <Link to="/patients/search" className="btn btn-secondry">{t('cancel')}</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
        );
    }
}

const mapStateToProps = state => ({
    // patient_list: state.patient_list,
    selected_user_profile: state.selected_user_profile,
});

const mapActionsToProps = ({
    addPatient: PatientAction.add,
})

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/patient/AddPatientPage')(AddPatientPage));