import React from 'react';
import Card from './Card';
import { withTranslation } from 'react-i18next';

class PrescriptionCount extends React.Component {

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="col-md-8">
                    <Card 
                        imgSrc="/images/prescribed-prescription.png" 
                        label={t("unfilled_prescription")}
                        count={this.props.prescription_count.prescribed_prescription}
                    />
                </div>
                <div className="col-md-8">
                    <Card
                        imgSrc="/images/fulfilled-prescription.png"
                        label={t("filled_prescription")}
                        count={this.props.prescription_count.fulfilled_prescription}
                    />
                </div>
                <div className="col-md-8">
                    <Card
                        imgSrc="/images/partially-fulfilled-prescription.png"
                        label={t("partially_filled_prescription")}
                        count={this.props.prescription_count.partially_fulfilled_prescription}
                    />
                </div>
            </React.Fragment>
        )
    }
}
export default withTranslation('components/Dashboard/PrescriptionCount')(PrescriptionCount)