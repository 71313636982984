import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import PatientInfoSection from '../../components/AmbulanceRecord/PatientInfoSection'
import MedicalInfoSection from '../../components/AmbulanceRecord/MedicalInfoSection'
import CurrentMedicationSection from "../../components/AmbulanceRecord/CurrentMedicationSection"
import * as HospitalAction from '../../actions/hospitalAction'
import HospitalService from '../../services/hospitalService'
import { patientStatus } from '../../utils/helpers';
import * as LoaderAction from '../../actions/loaderAction'
import Notification from '../../utils/notification'
import { Link } from "react-router-dom"
import PrescriptionService from '../../services/prescriptionService'
import { withTranslation } from 'react-i18next';

export class ViewPatientRecordPage extends Component {
    constructor(props) {
        super(props);

        const params = this.props.match.params;
        this.state = {
            record_id: params.recordId
        }

        this.props.getRecord(params.recordId, { log: true });
    }

    handleConfirm = () => {
        this.props.showLoader();
        HospitalService.confirmRecord(this.state.record_id)
            .then(resp => {
                this.props.hideLoader();
                Notification.show('success', { message: "Record confirmed successfully" });
                this.props.history.goBack();
            })
            .catch(error => {
                this.props.hideLoader();
                if (error.response) {
                    Notification.show('error', error.response.data)
                }
            })
    }

    handleDischarge = () => {
        PrescriptionService.createDraftPrescription({ "patient_id": this.props.ambulance.data.patient_id }).then(resp => {
            this.props.history.push('/hospital/records/' + this.state.record_id + '/prescription/' + resp.data.prescription_id);
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    render() {
        let { data } = this.props.ambulance;
        const { t } = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('ambulance_patient_profile')}</title>
                </Helmet>
                <section className="container-fluid">
                    <div className="row">
                        <div className="col-md-24">
                            <h4 className="page-title no-heading">
                                <Link onClick={() => this.props.history.goBack()} to="#">&#8249; {t('back')}</Link>
                                {data.status === 'assisted' && <span>{t('ambulance_patient_profile')}</span>}
                                {data.status === 'confirmed' && <span>{t('in_patient_profile')}</span>}
                            </h4>
                        </div>
                    </div>
                    <PatientInfoSection patient={data.patient_info} t={t}/>
                    <MedicalInfoSection patient={data.patient_info} t={t}/>
                    <div className="content-wrapper padding-bottom-10">
                        <h6>{t('patient_status')}</h6>
                        <p>{patientStatus(data.patient_status)}</p>
                    </div>
                    <CurrentMedicationSection prescriptions={data.current_prescriptions} />
                    <div className="content-wrapper padding-bottom-10">
                        <h6>{t('current_condition_patient')}</h6>
                        <p>{data.patient_condition}</p>
                    </div>
                    <div className="row">
                        <div className="col-24">
                            <div className="content-wrapper pb-4">
                                <h4 className="wrapper-heading">{t('ambulance_treatment')}</h4>
                                {data.medicines && data.medicines.map((medicine) => {
                                    return <React.Fragment>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr className="font-size-12">
                                                        <th>{t('brand_name')}</th>
                                                        <th>{t('generic_name')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="color-grey font-size-12">
                                                        <td>{medicine.drug_name}</td>
                                                        <td>{medicine.drug_generic_name}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {<div className="row">
                                            <div className="col-3">
                                                <span className="font-size-12 color-blue">{t('instructions')}:</span>
                                            </div>
                                            <div className="col-19">
                                                <p className="color-grey font-size-12">{medicine.remarks}</p>
                                            </div>
                                        </div>}
                                    </React.Fragment>
                                })}
                            </div>
                            <div className="content-wrapper padding-bottom-10">
                                <h6>{t('note')}</h6>
                                <p>{data.notes}</p>
                            </div>
                            <div className="content-wrapper padding-bottom-10">
                                <h6>{t('ambulance_assistant_details')}</h6>
                                <ul className="list-inline diff-tags with-v-line mt-2 mb-2">
                                    <li className="list-inline-item">{t('staff_name')}:<span className="ml-2">{data.assisted_user_profile_name}</span></li>
                                    <li className="list-inline-item">{t('contact_number')}:<span className="ml-2">{data.assisted_contact_number}</span></li>
                                    <li className="list-inline-item">{t('ambulance_company')}:<span className="ml-2">{data.assisted_service_provider_name}</span></li>
                                </ul>
                            </div>
                            {data.status === 'assisted' && <div className="text-right mt-3 mb-3">
                                <button className="btn btn-primary" onClick={e => this.handleConfirm(e)}>{t('confirmed')}</button>
                                <button className="btn btn-secondary" onClick={() => this.props.history.goBack()}>{t('back_to_list')}</button>
                            </div>}
                            {data.status === 'confirmed' && <div className="text-right mt-3 mb-3">
                                <button className="btn btn-primary" onClick={e => this.handleDischarge(e)}>{t('proceed_to_discharged')}</button>
                            </div>}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        ambulance: state.ambulance_fetch,
    };
}

const mapDispatchToProps = {
    hideLoader: LoaderAction.loaderHide,
    showLoader: LoaderAction.loaderShow,
    getRecord: HospitalAction.get,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('pages/hospital/ViewPatientRecordPage')(ViewPatientRecordPage))
