import React from "react";
import { connect } from "react-redux";
import { getSignaturePendingOrders } from '../../actions/prescriptionOrderAction';
import { Helmet } from "react-helmet";
import Notification from "../../utils/notification";
import { getDateFormat, getStatus } from '../../utils/helpers';
import PinModal from "../../components/Signature/PinModal";
import _ from 'lodash';
import DataGrid from '../../components/DataGrid';
import { withTranslation } from 'react-i18next';

class SignaturePendingOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isloading: false,
            data: '',
            id: [],
            showPinModal: false,
        }
        this.onSuccessRedirect = this.onSuccessRedirect.bind(this);
        this.showPinModal = this.showPinModal.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.resetChecked = this.resetChecked.bind(this);
    }

    componentDidMount() {
        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" },
        });
    }

    selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            let array = [...this.state.id];
            let id = row.order_id;
            let index = this.state.id.indexOf(id);
            if (isSelect) {
                this.setState({
                    id: [...this.state.id, id]
                });
            } else {
                if (index !== -1) {
                    array.splice(index, 1);
                    this.setState({
                        id: array
                    });
                }
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            let array = [];
            if (isSelect) {
                Object.keys(rows).forEach((row) => {
                    array = [...array, rows[row].order_id]
                });
            }

            this.setState({
                id: array
            });
        }
    };

    columns = () => {
        let { t } = this.props;
        return [
            {
                dataField: "order_id",
                text: t('rx_id'),
                sort: true
            },
            {
                dataField: "prescription_id",
                text: t('prescription_id'),
                sort: true
            },
            {
                dataField: "patient_full_name",
                text: t('patient_name'),
            },
            {
                dataField: "created_by_full_name",
                text: t('created_by'),
                sort: false,
            },
            {
                dataField: "status",
                text: t("status"),
                sort: true,
                formatter: (cell, row) => {
                    return getStatus(cell)
                }
            },
            {
                dataField: "order_date",
                text: t('rx_date'),
                sort: true,
                formatter: this.dateTimeFormat
            }
        ]
    };

    dateTimeFormat = (cell, row) => {
        return getDateFormat(cell)
    }

    setLoading = (value) => {
        this.setState({
            isloading: value
        });
    }

    bulkSign = () => {
        if (_.size(this.state.id) > 0) {
            this.showPinModal();

        } else {
            Notification.show('error', { 'message': this.props.t('select_atleast_one_order') });
        }
    }

    resetChecked = () => {
        this.setState({
            id: []
        });
    }

    showPinModal = () => {
        this.setState({ showPinModal: true });
    }
    handleClose = () => {
        this.setState({ showPinModal: false });
    }

    onSuccessRedirect = () => {
        this.setState({ showPinModal: false });
        this.resetChecked();
        this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "desc" }, });
    }

    handleTableChange = (
        type,
        { page, sizePerPage, sortField, sortOrder, searchText }
    ) => {
        if (type === "search") {
            page = 1;
        }

        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText });
    };

    render() {
        let { data, meta } = this.props.signature_pending_orders_list;
        const { t } = this.props;
        return (
            <React.Fragment>
                {this.state.isloading && this.state.isloading && <div className="loading">{t('loading')}</div>}
                <Helmet>
                    <title>{t('unsigned_rx')}</title>
                </Helmet>
                <h4 className="page-title">
                    <span>{t('unsigned_rx')}</span>
                    <button className="btn btn-primary float-right" onClick={this.bulkSign.bind()} disabled={_.size(this.state.id) > 0 ? false : true}>{t('sign')} {_.size(this.state.id)} {t('checked_orders')}</button>
                </h4>

                <div className="content-wrapper pending_signature">
                    <DataGrid
                        columns={this.columns()}
                        data={data}
                        meta={meta}
                        handleTableChange={this.handleTableChange}
                        keyField="order_id"
                        selectRow={this.selectRow}
                        noDataIndication={t('no_record')}
                    />
                </div>
                {this.state.showPinModal &&
                    <PinModal show={this.state.showPinModal} handleClose={this.handleClose} id={this.state.id} type="orders" onSuccess={this.onSuccessRedirect} setLoading={this.setLoading} />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    signature_pending_orders_list: state.prescription_order.paginate,
});

const mapActionsToProps = ({
    paginate: getSignaturePendingOrders
})

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/pharmacy/SignaturePendingOrders')(SignaturePendingOrders));