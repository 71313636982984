import React from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import DataGrid from "../../components/DataGrid";
import * as AppointmentService from "../../actions/appointmentAction";
import { getDateFormat } from '../../utils/helpers';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class HolidayListPage extends React.Component {
    constructor(props) {
        super(props);
        const { params: { locationId } } = this.props.match;
        let { doctor, location } = this.props.history.location.state;
        this.state = {
            user_location_id: locationId,
            sortBy: { sortField: "created_at", sortOrder: "desc" },
            isLoading: true,
            location: location,
            doctor: doctor[0],
            filter: {
                doctor_profile_id: doctor[0].user_profile_id
            }
        }
    }
    componentDidMount() {
        let { user_location_id } = this.state
        this.props.holidaylist(user_location_id, { sortBy: this.state.sortBy, filter: this.state.filter })
    }
    columns = () => {
        let { t } = this.props;
        return [
            {
                dataField: "begin_date",
                text: t('begin_date'),
                formatter: this.getDateFormat
            },
            {
                dataField: 'end_date',
                text: t('end_date'),
                formatter: this.getDateFormat
            },
            {
                dataField: "description",
                text: t('description')
            }
        ];
    };
    getDateFormat = (row) => {
        if (row) {
            return getDateFormat(row);
        }
        return null
    }
    handleTableChange = (_type, { page, sizePerPage, sortBy }) => {
        this.props.holidaylist(this.state.user_location_id, { page, sizePerPage, sortBy: this.state.sortBy, filter: this.state.filter });
    }
    render() {
        let { holidays, isLoading } = this.props.appointment;
        const { t } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('holiday_list')}</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-24">
                        <h4 className="page-title no-heading">
                            <Link to="/slots">‹ {t('back')}</Link>
                            <span className="page-title-txt">{t('holiday_list')}</span>
                        </h4>
                    </div>
                </div>
                <div className="row holiday-list">
                    <div className="col-md-24">
                        {this.state.doctor && <h3>{this.state.doctor.full_name}</h3>}
                        <div className="content-wrapper">
                            {this.state.location && <h6><span className="color-grey font-regular">{t('location')}:</span> {this.state.location.name}</h6>}
                            {holidays && <DataGrid
                                columns={this.columns()}
                                loading={isLoading}
                                noDataIndication={t('no_record')}
                                data={holidays}
                                pagination={false}
                                handleTableChange={this.handleTableChange}
                            />}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile,
    service_provider_locations: state.service_provider_locations,
    appointment: state.appointment
});
const mapActionsToProps = ({
    holidaylist: AppointmentService.holidaylist
})
export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/slot-management/HolidayListPage')(HolidayListPage));