import React from 'react';
import moment from 'moment';

const sectionSeperator = {
    borderBottom: "1px solid #e9ecef",
    marginTop: "15px",
    marginBotton: "15px",
};

export const Abuse = ({ is_abuse, abuse_reason, handleChange, getValue, t }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>{t('abuse.abuse')}</h6>
        </div>
        <div className="form-group full form-left">
            <label>{t('abuse.text')} </label>
            <select className="form-control" name="is_abuse" onChange={handleChange} value={getValue(is_abuse)}>
                <option value='' defaultValue>---{t('abuse.select')}---</option>
                <option value={true}>{t('abuse.yes')}</option>
                <option value={false}>{t('abuse.no')}</option>
            </select>
            {/* <input type="checkbox" id="is_abuse" name="is_abuse" onChange={handleChange} value={is_abuse ? is_abuse : ''} checked={is_abuse ? 'checked' : ''} />
            <label htmlFor="is_abuse"></label> */}
        </div>
        <div className="form-group full">
            <label className="blue-label">{t('abuse.details')} </label>
            <textarea className="form-control" name="abuse_reason" placeholder={t('abuse.type_here')} onChange={handleChange} value={abuse_reason ? abuse_reason : ''}></textarea>
        </div>
    </React.Fragment>
}
export const Excercise = ({ is_exercise, exercise_frequency, is_sleep_snore, handleChange, getValue, t }) => {
    return (
        <React.Fragment>
            <div className="heading">
                <h6>{t('excercise.exercise_and_other')}</h6>
            </div>
            <div className="form-group form-left padding-right excercise-and-other-first">
                <label>{t('excercise.do_you_exercise')} </label>
                <select className="form-control" name="is_exercise" onChange={handleChange} value={getValue(is_exercise)}>
                    <option value='' defaultValue>---{t('excercise.select')}---</option>
                    <option value={true}>{t('excercise.yes')}</option>
                    <option value={false}>{t('excercise.no')}</option>
                </select>
            </div>
            <div className="form-group form-left padding-right excercise-and-other-second">
                <label>{t('excercise.type_frequency_of_exercise')} </label>
                <input type="text" className="form-control" name="exercise_frequency" onChange={handleChange} value={exercise_frequency ? exercise_frequency : ''} />
            </div>
            <div className="form-group form-left excercise-and-other-third">
                <label>{t('excercise.do_you_snore')}</label>
                <select className="form-control" name="is_sleep_snore" onChange={handleChange} value={getValue(is_sleep_snore)}>
                    <option value='' defaultValue>---{t('excercise.select')}---</option>
                    <option value={true}>{t('excercise.yes')}</option>
                    <option value={false}>{t('excercise.no')}</option>
                </select>
            </div>
        </React.Fragment>
    )
}
export const Sexual = ({ personal_history, handleChange, getValue, t }) => {
    let { sexual_partners, use_condom, is_sexually_active, is_hiv_tested, hiv_test_result, hiv_test_comment, last_hiv_test } = personal_history;
    return (
        <React.Fragment>
            <div className="heading">
                <h6>{t('sexual.sexual')}</h6>
            </div>
            <div className="form-group form-left substance-abuse-four">
                <label>{t('sexual.number_of_partners')}</label>
                <input type="text" className="form-control" name="sexual_partners" onChange={handleChange} value={sexual_partners ? sexual_partners : ''} />
            </div>
            <div className="form-group form-left pl-0 excercise-and-other-third">
                <label>{t('sexual.use_of_condom')} </label>
                <select className="form-control" name="use_condom" onChange={handleChange} value={use_condom ? use_condom : ''}>
                    <option value='' defaultValue>---{t('sexual.select')}---</option>
                    <option value="always">{t('sexual.always')}</option>
                    <option value="sometimes">{t('sexual.sometimes')}</option>
                    <option value="never">{t('sexual.never')}</option>
                </select>
            </div>
            <div className="form-group form-left pl-0 excercise-and-other-third">
                <label>{t('sexual.sexually_active')}</label>
                <select className="form-control" name="is_sexually_active" onChange={handleChange} value={getValue(is_sexually_active)}>
                    <option value='' defaultValue>---{t('sexual.select')}---</option>
                    <option value={true}>{t('sexual.yes')}</option>
                    <option value={false}>{t('sexual.no')}</option>
                </select>
            </div>
            <br />
            <div className="form-group form-left padding-right sexual-second">
                <label>{t('sexual.hiv_test')} </label>
                <select className="form-control" name="is_hiv_tested" onChange={handleChange} value={getValue(is_hiv_tested)}>
                    <option value='' defaultValue>---{t('sexual.select')}---</option>
                    <option value={true}>{t('sexual.yes')}</option>
                    <option value={false}>{t('sexual.no')}</option>
                </select>
            </div>
            <div className="form-group form-left pl-0 excercise-and-other-second">
                <label>{t('sexual.last_hiv_test_date')}</label>
                <input type="text" className="form-control" name="last_hiv_test" onChange={handleChange} value={last_hiv_test || ''} disabled={!is_hiv_tested ? 'disabled' : ''} />
            </div>
            <div className="form-group form-left  pl-0 excercise-and-other-third">
                <label>{t('sexual.hiv_test_result')}</label>
                <select className="form-control" name="hiv_test_result" onChange={handleChange} value={hiv_test_result ? hiv_test_result : ''} disabled={!is_hiv_tested ? 'disabled' : ''}>
                    <option value='' defaultValue>---{t('sexual.select')}---</option>
                    <option value="normal">{t('sexual.normal')}</option>
                    <option value="abnormal">{t('sexual.abnormal')}</option>
                </select>
            </div>
            <div className="form-group full">
                <label className="blue-label">{t('sexual.hiv_test_result_comment')} </label>
                <textarea className="form-control" name="hiv_test_comment" placeholder={t('sexual.type_here')} onChange={handleChange} value={hiv_test_comment || ''} disabled={!is_hiv_tested ? 'disabled' : ''}></textarea>
            </div>
        </React.Fragment>)
}
export const SubstanceAbuse = ({ substance, addSubstanceAbuseBlock, handleChange, removeSubstanceAbuse, getValue, t }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>{t('substance_abuse.substance_abuse')}</h6>
        </div>
        {substance && substance.map((item, index) => (
            <SubstanceBlock
                item={item}
                index={index}
                handleChange={handleChange}
                key={index}
                removeSubstanceAbuse={removeSubstanceAbuse}
                getValue={getValue}
                t={t}
            />
        ))}
        <button onClick={addSubstanceAbuseBlock} className="add-new btn btn-default btn-outline-primary">{t('substance_abuse.add')}</button>
    </React.Fragment>
}
export const SubstanceBlock = ({ item, index, handleChange, removeSubstanceAbuse, getValue, t }) => {
    return (
        <React.Fragment key={index}>
            <div className="cancel_section" style={sectionSeperator}>
                <div className="form-group form-left substance-abuse-first">
                    <label>{t('substance_abuse.substance')} </label>
                    <select className="form-control" name="substance_type" onChange={e => handleChange(e, index)} value={item.substance_type}>
                        <option value=""></option>
                        <option value="caffeine">{t('substance_abuse.caffeine')}</option>
                        <option value="tobacco">{t('substance_abuse.tobacco')}</option>
                        <option value="alcohol">{t('substance_abuse.alcohol')}</option>
                        <option value="street-drugs">{t('substance_abuse.street_drugs')}</option>
                    </select>
                </div>
                <div className="form-group form-left substance-abuse-second">
                    <label>{t('substance_abuse.currently')} </label>
                    <select className="form-control" name="is_currently_use" onChange={e => handleChange(e, index)} value={getValue(item.is_currently_use)} disabled={!item.substance_type ? 'disabled' : ''}>
                        <option value='' defaultValue>---{t('substance_abuse.select')}---</option>
                        <option value="true">{t('substance_abuse.yes')}</option>
                        <option value="false">{t('substance_abuse.no')}</option>
                    </select>
                </div>
                <div className="form-group form-left substance-abuse-third">
                    <label>{t('substance_abuse.previously_used')} </label>
                    <select className="form-control" name="is_previously_used" onChange={e => handleChange(e, index)} value={getValue(item.is_previously_used)} disabled={!item.substance_type ? 'disabled' : ''}>
                        <option value='' defaultValue>---{t('substance_abuse.select')}---</option>
                        <option value="true">{t('substance_abuse.yes')}</option>
                        <option value="false">{t('substance_abuse.no')}</option>
                    </select>
                </div>
                <div className="form-group form-left substance-abuse-four">
                    <label>{t('substance_abuse.type_Amount_Frequency')} </label>
                    <input type="text" className="form-control" name="type_quantity" onChange={e => handleChange(e, index)} value={item.type_quantity ? item.type_quantity : ''} disabled={!item.substance_type ? 'disabled' : ''} />
                </div>
                <div className="form-group form-left substance-abuse-five">
                    <label>{t('substance_abuse.how_long')} </label>
                    <input type="text" className="form-control" name="how_long_years" onChange={e => handleChange(e, index)} value={item.how_long_years ? item.how_long_years : ''} disabled={!item.substance_type ? 'disabled' : ''} />
                </div>
                <div className="form-group form-left substance-abuse-six">
                    <label>{t('substance_abuse.if_stopped_then_when')} </label>
                    <input type="text" className="form-control" name="stopped_when_reason" onChange={e => handleChange(e, index)} value={item.stopped_when_reason ? item.stopped_when_reason : ''} disabled={!item.substance_type ? 'disabled' : ''} />
                </div>
                <span className="cancel" data-id={index} onClick={removeSubstanceAbuse}></span>
            </div>
        </React.Fragment>
    )
}
export const Vaccine = ({ tetanus_vaccacines, influenza_vaccines, hiv_vaccines, handleChange, t }) => {
    return (
        <React.Fragment>
            <div className="heading">
                <h6>{t('vaccine.vaccines')}</h6>
            </div>
            <div className="form-group form-left padding-right vaccines-first">
                <label>{t('vaccine.tetanus')} </label>
                <input type="date" id="tetanus" className="form-control" name="tetanus_vaccacines" onChange={handleChange} max={moment().format('YYYY-MM-DD')} value={tetanus_vaccacines ? tetanus_vaccacines : ''} />
            </div>
            <div className="form-group form-left padding-right vaccines-second">
                <label>{t('vaccine.influenza')} </label>
                <input type="date" id="influenza" className="form-control" name="influenza_vaccines" onChange={handleChange} max={moment().format('YYYY-MM-DD')} value={influenza_vaccines ? influenza_vaccines : ''} />
            </div>
            <div className="form-group form-left vaccines-third">
                <label>{t('vaccine.hpv')} </label>
                <input type="date" id="hpv" className="form-control" name="hiv_vaccines" onChange={handleChange} max={moment().format('YYYY-MM-DD')} value={hiv_vaccines ? hiv_vaccines : ''} />
            </div>
        </React.Fragment>
    )
}