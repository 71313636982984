import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { concat, isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';
import * as PrescriptionOrderAction from '../../actions/prescriptionOrderAction';
import PatientInfo from '../../components/PatientInfo';
import DoctorInfo from '../../components/DoctorInfo';
import PinModal from "../../components/Signature/PinModal";
import Notification from '../../utils/notification';
import { getDateFormat, getDurationType } from '../../utils/helpers';

class SummaryPrescriptionPage extends React.Component {
    constructor(props) {
        super(props);

        if (isEmpty(this.props.prescription_order)) {
            window.location = '/pharmacy/prescriptions/search';
        }
        this.state = {
            prescriptionOrder: {
                prescription_id: this.props.prescription_order.prescription_id,
                prescription_date: this.props.prescription_order.prescription_date,
                patient_id: this.props.prescription_order.patient_id,
                pos_reference_no: this.props.prescription_order.pos_reference_no,
                pos: this.props.prescription_order.pos,
                reference_number: this.props.prescription_order.reference_number,
                pharmacy: this.props.user,
                patient_info: this.props.prescription_order.patient_info,
                medicines: this.props.prescription_order.prescription_medicines,
                reminder_date: this.props.prescription_order.reminder_date,
            },
            showPinModal: false,
            isloading: '',
            order_id: ''
        };

        this.handleSubmitPrint = this.handleSubmitPrint.bind(this);
        this.showPinModal = this.showPinModal.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onSuccessRedirect = this.onSuccessRedirect.bind(this);
        this.setLoading = this.setLoading.bind(this);
    }

    showPinModal = () => {
        this.setState({ showPinModal: true });
    }
    handleClose = () => {
        this.setState({ showPinModal: false });
        this.props.history.push('/orders/pending-signature');
    }

    onSuccessRedirect = () => {
        this.setState({ showPinModal: false });
        this.setLoading(false);
        this.props.history.push('/prescription/orders/list');
    }

    setLoading = (value) => {
        this.setState({
            isloading: value
        });
    }

    handleSubmitPrint = (action) => {
        let postData = {
            prescription_id: this.state.prescriptionOrder.prescription_id,
            pos_number: this.state.prescriptionOrder.pos_reference_no,
            reference_number: this.state.prescriptionOrder.reference_number,
            assigned_to: null,
            order_status: 'signature_pending',
            order_items: [],
            reminder_date: this.state.prescriptionOrder.reminder_date,
        };

        this.state.prescriptionOrder.medicines.map((medicine) => {
            if (medicine.medicine_given) {
                let data = {
                    prescription_item_id: medicine.id,
                    drug_id: medicine.drug_id ? medicine.drug_id : '',
                    quantity: parseInt(medicine.quantity),
                    drug_sku_id: medicine.drug_sku_id,
                    drug_sku_number: medicine.drug_sku_number,
                    package_size: medicine.package_size,
                    unit_given: parseInt(medicine.unit_given),
                    unit_dispatched: parseInt(medicine.unit_dispatched),
                    drug_coverages: medicine.selected_coverages,
                    remark: medicine.ordremarks
                }
                postData.order_items = concat(postData.order_items, data);
            }

            return true;
        });
        this.props.submitPrescriptionOrder(postData, action, this.props.history, (response) => {
            if (!isEmpty(response) && !isEmpty(response.order_id)) {
                Notification.show('success', { message: this.props.t('order_created_success') });
                this.setState({ order_id: response.order_id });
                this.showPinModal();
            }
        });
    }

    render() {
        const { t } = this.props
        const { prescription_id, patient_info, medicines, reference_number, pos_reference_no, reminder_date } = this.state.prescriptionOrder;

        return (<React.Fragment>
            <Helmet>
                <title>{t('pharmacist_summary')}</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title no-heading">
                        <a href={"/prescriptions/" + prescription_id + "/orders/create"}>&#8249; {t('create_order')}</a>
                        <span className="prescription-details">{t('prescription_id')}: <i>{prescription_id}</i></span>
                    </h4>

                    <h4 className="page-title font-size-16">{t('prescription_order_summary')}</h4>
                    <div className="row">
                        <div className="col-md-24">
                            <PatientInfo patient_info={patient_info} />
                        </div>
                        <div className="col-md-24">
                            {this.props.prescription_order && this.props.prescription_order.prescription_type !== 'pharmacy-prescription' && <DoctorInfo doctor_info={this.props.prescription_order.user_profile_info}
                                doctor_location={this.props.prescription_order && this.props.prescription_order.created_at_location} />}
                        </div >
                    </div>

                    <div className="content-wrapper padding-bottom-10">
                        <h6>{t('pharmacy_info')}</h6>
                        <ul className="prescription-patient-details">
                            <li>{t('pharmacy_name')}: <span>{this.props.pharmacy && this.props.pharmacy.service_provider && this.props.pharmacy.service_provider.name}</span></li>
                            <li>{t('registration_number')}: <span>{this.props.pharmacy && this.props.pharmacy.service_provider && this.props.pharmacy.service_provider.license_number ? this.props.pharmacy.service_provider.license_number : '--'}</span></li>
                        </ul>
                    </div>

                    <div className="content-wrapper padding-bottom-10">
                        <ul className="prescription-patient-details">
                            <li>{t('pos_number')}: <span>{pos_reference_no && pos_reference_no}</span></li>
                            <li>{t('reference_number')}: <span>{reference_number && reference_number}</span></li>
                        </ul>
                    </div>

                    <h4 className="page-title font-size-16">{t('prescribed_medicine')}</h4>
                    <div className="content-wrapper">
                        <table className="table table table-responsive common-table">
                            <thead>
                                <tr>
                                    <th>{t('brand_name')}</th>
                                    <th>{t('generic_name')}</th>
                                    <th>Frequency</th>
                                    <th>{t('duration')}</th>
                                    <th>{t('unit_required')}</th>
                                    <th>{t('unit-dispensed')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {medicines && medicines.map((medicine, idx) => (
                                    medicine.medicine_given && <tr>
                                        <td>{medicine.drug_name}</td>
                                        <td>{medicine.drug_generic_name}</td>
                                        <td>{medicine.dosage}</td>
                                        <td>{medicine.duration}{getDurationType(medicine.duration_type)}</td>
                                        <td>{medicine.remaining_unit} {medicine.drug_type === "tablespoon" ? "ml" : "pc"}</td>
                                        <td>{medicine.unit_dispatched} {medicine.drug_type === "tablespoon" ? "ml" : "pc"}</td>
                                    </tr>

                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="content-wrapper padding-bottom-10">
                        <ul className="prescription-patient-details">
                            <li>{t('set_reminder')} : <span>{reminder_date && getDateFormat(reminder_date)}</span></li>
                        </ul>
                    </div>

                    <div className="button-wrapper align-right">
                        {/* <button class="btn btn-primary" onClick={this.handleSubmitPrint.bind(this, 'submit-print')}>Submit and Print</button> */}
                        <button class="btn btn-primary" onClick={this.handleSubmitPrint.bind(this, 'submit')}>{t('submit')}</button>
                    </div>

                </div>
            </div>
            {this.state.showPinModal &&
                <PinModal show={this.state.showPinModal} handleClose={this.handleClose} id={this.state.order_id} type="orders" onSuccess={this.onSuccessRedirect} setLoading={this.setLoading} />
            }
        </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    prescription_order: state.prescription_order.new,
    user: state.session.user,
    selected_doctor: state.selected_doctor,
    pharmacy: state.selected_user_profile
});

const mapActionsToProps = ({
    submitPrescriptionOrder: PrescriptionOrderAction.submit
})

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/pharmacy/SummaryPrescriptionPage')(SummaryPrescriptionPage));
