import React from 'react';
import PrescriptionService from '../../../services/prescriptionService';
import UrgentCareService from '../../../services/urgentCareService';
import Notification from '../../../utils/notification';
import * as ButtonComponents from '../Buttons';
import ViewPresentingCondition from './ViewPresentingCondition';
import AddPresentingConditionBlock from '../PresentingConditionBlock';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

class AddPresentingCondition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            savedPresentConditions: { symptoms: [], review_of_system: [] },
            draftPresentCondition: { symptoms: "", review_of_system: {} },
            showAddComponentBlock: false,
            showAddButton: true,
            showSubmitButton: false
        }
    }
    componentDidMount = () => {
        this.getPatientPresentCondition();
    }

    getPatientPresentCondition = () => {
        this.props.showLoader();
        UrgentCareService.getPatientPresentCondition(this.props.prescriptionId).then(resp => {

            let savedReviewOfSystem = resp.data.review_of_system.filter(rs => rs.status === "save");
            let draftReviewOfSystem = resp.data.review_of_system.filter(rs => rs.status === "draft");
            let savedSymtoms = resp.data.symptoms;
            if (draftReviewOfSystem.length) {
                let draftSymtoms = savedSymtoms.pop();
                this.setState({
                    draftPresentCondition: {
                        symptoms: draftSymtoms.value,
                        review_of_system: draftReviewOfSystem[0],
                        patient_id: this.props.patientId,
                    }
                }, () => {
                    this.handleAddButton()
                });
            }
            this.setState({
                savedPresentConditions: {
                    symptoms: savedSymtoms,
                    review_of_system: savedReviewOfSystem,
                    patient_id: this.props.patientId,
                    showAddButton: true
                },
            })
            if (draftReviewOfSystem.length === 0 && savedReviewOfSystem.length === 0 )
                this.handleAddButton()
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            console.log(error);
            Notification.show('error', error.response.data)
        })
    };

    handleRemoveComponent = (event) => {
        this.setState({ showAddComponentBlock: false, showAddButton: true, showSubmitButton: false, draftPresentCondition: { symptoms: "", review_of_system: {} } });
        this.props.handleIsPrescriptionEdited('present_condition', false);
    };

    handleSubmitButton = (e, type = null) => {
        this.props.handleSubmitComponent(true, "Presenting History")
    }

    handleAddButton = (e, type = null) => {
        this.setState({ showAddComponentBlock: true, showAddButton: false, showSubmitButton: true });
    }

    handlePresentConditionSaveAndNext = (e, type = null) => {
        e.preventDefault();
        if (_.isEmpty(this.state.draftPresentCondition) && (type === "save_and_next" || type === "draft_and_next")) {
            this.props.handleSaveAndNext();
            return false;
        }
        let status = ['save', 'save_and_next'].includes(type) ? "save" : "draft";
        let draftPresentCondition = this.state.draftPresentCondition;
        draftPresentCondition["review_of_system"] = draftPresentCondition['review_of_system']['problems'] !== undefined ? draftPresentCondition['review_of_system']['problems'] : [];
        draftPresentCondition["status"] = status;
        draftPresentCondition["patient_id"] = this.props.patientId;

        this.props.showLoader();
        UrgentCareService.createPatientPresentCondition(this.props.prescriptionId, draftPresentCondition).then(_resp => {
            this.props.hideLoader();
            if (type === "save_and_next" || type === "draft_and_next") {
                this.props.handleSaveAndNext();
            } else if (type === "save" || type === "draft") {
                this.handleRemoveComponent();
                this.getPatientPresentCondition();
            }
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    changeHandler = (type, data) => {
        let draftPresentCondition = this.state.draftPresentCondition;
        if (type === "review_of_system") {
            draftPresentCondition[type]['problems'] = data
        } else {
            draftPresentCondition[type] = data
        }
        this.setState({ draftPresentCondition: draftPresentCondition });
        this.props.handleIsPrescriptionEdited('present_condition');
    }
    render() {
        const { t } = this.props;
        return (
            <div className=" lg-height">
                <div className="content-wrapper">
                    {this.state.savedPresentConditions.review_of_system && this.state.savedPresentConditions.review_of_system.map((data, index) => (
                        <ViewPresentingCondition
                            symptoms={this.state.savedPresentConditions.symptoms[index]}
                            review_of_system={data}
                        />
                    ))}

                    {this.state.showAddComponentBlock && <AddPresentingConditionBlock
                        changeHandler={this.changeHandler}
                        handleCancelButton={this.handleRemoveComponent}
                        symptoms={this.state.draftPresentCondition.symptoms}
                        review_of_system={this.state.draftPresentCondition.review_of_system}
                        patient_id={this.state.patient_id}
                    />}

                    {this.state.showSubmitButton && <div className="bottom-btns">
                        <ButtonComponents.PrimaryButton
                            handleChange={(e) => this.handlePresentConditionSaveAndNext(e, 'save')}
                            buttonType={"submit"}
                            buttonName={t('submit_history')}

                        />
                    </div>}
                    {this.state.showAddButton && <div className="bottom-btns">
                        <ButtonComponents.PrimaryButton
                            handleChange={this.handleAddButton}
                            buttonType={"add"}
                            buttonName={t('add_history')}
                        />
                    </div>}

                </div>

                <div className="text-right bottom-btns">
                    <ButtonComponents.PrimaryButton
                        handleChange={this.handlePresentConditionSaveAndNext}
                        buttonType={"save_and_next"}
                        buttonName={t('save_next')}
                    />
                    <ButtonComponents.OutlineButton
                        handleChange={this.props.handlePrevious}
                        buttonName={t('previous')}
                    />
                    <ButtonComponents.PrimaryButton
                        handleChange={this.handlePresentConditionSaveAndNext}
                        buttonType={"draft_and_next"}
                        buttonName={t('save_draft_next')}
                        disabled={!this.state.showAddComponentBlock}
                    />
                    <ButtonComponents.PrimaryButton
                        handleChange={this.handlePresentConditionSaveAndNext}
                        buttonType={"draft"}
                        buttonName={t('save_draft')}
                        disabled={!this.state.showAddComponentBlock}
                    />
                </div>
            </div>
        )
    }
}
export default (withTranslation('components/UrgentCare/PresentingCondition/AddPresentingCondition')(AddPresentingCondition));