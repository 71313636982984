import React from 'react';
import { connect } from 'react-redux';
import * as MedicineAction from '../../actions/medicineAction';
import { Helmet } from 'react-helmet';
import DataGrid from '../../components/DataGrid';
import _ from 'lodash';
import SimpleReactValidator from 'simple-react-validator';
import { withTranslation } from 'react-i18next';
import * as HELPER from '../../utils/helpers';

class ListMedicinePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tags: {
                name: "",
                generic_name: "",
            },
            filter: {
                name: "",
                generic_name: "",
            }
        }
        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" }
        });
        this.validator = new SimpleReactValidator({ locale: HELPER.getLanguage() });
    }

    columns = () => {
        let { t } = this.props;
        return [
            {
                dataField: 'id',
                text: t('id'),
                hidden: true
            }, {
                dataField: 'name',
                text: t('medication_name'),
                sort: true
            }, {
                dataField: 'generic_drug.name',
                text: t('generic_name'),
                // sort: true
            }, {
                dataField: 'strength',
                text: t('strength'),
            }, {
                dataField: 'dosage_form.label',
                text: t('dosage_form'),
            }];
    }

    handleChange = (e) => {
        const filter = this.state.filter;
        filter[e.target.name] = e.target.value ? e.target.value : undefined;
        this.setState({ filter });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        /* External javascript function  */
        window['searchToggle']();
        this.setState({
            tags: {
                name: this.state.filter.name,
                generic_name: this.state.filter.generic_name,
            }
        });

        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" },
            filter: this.state.filter
        });
    }

    resetSearch = () => {

        this.setState({
            filter: {
                name: "",
                generic_name: "",
            },
            tags: {
                name: "",
                generic_name: "",
            },
        });

        window['searchToggle']();

        this.props.paginate({
            sortBy: { sortField: "updated_at", sortOrder: "desc" }
        });
    }

    removeFilter = (e) => {

        const filter = this.state.filter;
        const tags = this.state.tags;

        filter[e.target.dataset.tag] = '';
        tags[e.target.dataset.tag] = '';

        this.setState({ filter: filter, tags: tags });

        this.props.paginate({ filter: this.state.filter });
    }

    handleTableChange = (
        type,
        { page, sizePerPage, sortField, sortOrder, searchText }
    ) => {
        if (type === "search") {
            page = 1;
        }

        const filter = this.state.filter;
        this.props.paginate({ page, sizePerPage, sortBy: { sortField, sortOrder }, searchText, filter });
    };

    render() {
        let { data, meta, isLoading, t } = this.props;
        let tags = this.state.tags;

        return (<React.Fragment>
            <Helmet>
                <title>{t('medication')}</title>
            </Helmet>
            <div class="row">
                <div class="col-md-24">
                    <h4 class="page-title">{t('medication')}</h4>
                    <div className="search-wrapper">
                        <label>{t('search')}</label>
                        <div className="search-box clearfix">
                            {(Object.keys(tags)).map((tag) => {
                                if (tag !== 'doctor_id' && tags[tag] !== '' && !(_.isEmpty(tags[tag]))) {
                                    return <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
                                }
                                return <></>
                            })
                            }
                            <span className="search-box-icon"></span>

                            <div className="search-container">
                                <form onSubmit={this.handleSubmit}>
                                    <ul>
                                        <li>
                                            <span>{t('medication_name')}</span>
                                            <input type="text" value={this.state.filter.name} onChange={this.handleChange} name="name"></input>
                                        </li>
                                        <li>
                                            <span>{t('generic_name')}</span>
                                            <input type="text" value={this.state.filter.generic_name} onChange={this.handleChange} name="generic_name"></input>
                                        </li>
                                        <li>
                                            <button type="button" onClick={this.resetSearch} className="btn btn-secondary">{t('reset')}</button>
                                            <button type="submit" className="btn btn-primary">{t('search')}</button>
                                        </li>
                                    </ul>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-wrapper">
                <DataGrid
                    loading={isLoading}
                    columns={this.columns()}
                    data={data}
                    meta={meta}
                    handleTableChange={this.handleTableChange}
                />
            </div>
        </React.Fragment>);
    }
}

const mapStateToProps = (state) => ({
    ...state.medicine_list
});

const mapActionsToProps = ({
    paginate: MedicineAction.list
})

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/medicine/ListMedicinePage')(ListMedicinePage));