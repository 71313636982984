import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Tab, Tabs } from "react-bootstrap";
import * as CardAction from "../../actions/cardAction";
import _ from 'lodash';
import DataGrid from "../../components/DataGrid";
import { Link } from "react-router-dom";
import { fullNameStr } from '../../utils/helpers';
import { getDateFormat } from "../../utils/helpers";
import { withTranslation } from 'react-i18next';

class ReissueCardsPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            key: 'pending',
            pending_filter: {
                service_provider_id: this.props.selected_user_profile.service_provider_id,
                user_location_id: this.props.selected_user_profile.user_location_id,
                status: 'delivered',
                is_delivered: true
            },
            history_filter: {
                service_provider_id: this.props.selected_user_profile.service_provider_id,
                user_location_id: this.props.selected_user_profile.user_location_id,
                status: 'completed',
                is_issued: true
            }
        }
    }

    componentDidMount() {
        this.props.reissueCardList({
            sortBy: { sortField: "updated_at", sortOrder: "desc" },
            filter: this.state.pending_filter
        })
    }

    componentWillReceiveProps(props) {
        this.setState({
            list: props.list.data,
        });
    }

    requestList = key => {
        this.setState({
            key: key
        })
        let filter = key === "pending" ? this.state.pending_filter : this.state.history_filter
        this.props.reissueCardList({
            sortBy: { sortField: "updated_at", sortOrder: "desc" }, filter: filter
        })
    }

    columns = () => {
        let { t } = this.props;
        if (this.state.key === "pending") {
            return [
                {
                    dataField: "date",
                    text: t('date'),
                    formatter: this.dateFormatter,
                },
                {
                    dataField: 'patient_name',
                    text: t('patient_name'),
                    formatter: this.patientNameFormatter,
                },
                {
                    dataField: "patient_contact_number",
                    text: t('patient_contact_number'),
                },
                {
                    dataField: 'actions',
                    text: t('actions'),
                    formatter: this.actionFormatter
                }
            ];
        }
        else {
            return [
                {
                    dataField: "date",
                    text: t('date'),
                    formatter: this.dateFormatter,
                },
                {
                    dataField: 'patient_name',
                    text: t('patient_name'),
                    formatter: this.patientNameFormatter,
                },
                {
                    dataField: "patient_contact_number",
                    text: t('patient_contact_number'),
                },
                {
                    dataField: "date_of_dispatch",
                    text: t('date_of_dispatch'),
                    formatter: this.dateFormatter,
                },
                {
                    dataField: "received_by",
                    text: t('received_by'),
                },
                {
                    dataField: "issued_by",
                    text: t('issued_by'),
                },
            ];
        }
    }

    patientNameFormatter = (cell, row) => {
        if (row.patient_info) {
            return fullNameStr(row.patient_info)
        }
    }

    actionFormatter = (cell, row) => {
        return <Link size="sm" to={"/assign-card/" + row.request_id} title="Assign Card"><span className="icon file"></span></Link>
    }

    dateFormatter = (cell, row) => {
        return getDateFormat(row.date)
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, searchText }) => {
        if (type === "search") {
            page = 1;
        }
        let filter = this.state.key === "pending" ? this.state.pending_filter : this.state.history_filter
        this.props.reissueCardList({
            page, sizePerPage, sortBy: { sortField: "updated_at", sortOrder: "desc" }, searchText, filter: filter
        })
    }

    render() {
        const { data, meta } = this.props.list;
        const { t } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>{t('patient_card_reissue')}</title>
                </Helmet>
                <section className="container-fluid">
                    <div className="row">
                        <div className="col-md-24 requester-tabs">
                            <h4 className="page-title">{t('patient_card_reissue')}</h4>
                        </div>
                        <div className="col-md-24 mt-3 request-pending">
                            <Tabs
                                defaultActiveKey="pending"
                                onSelect={this.requestList.bind(this)}
                            >
                                <Tab eventKey="pending" title={t('pending')}>
                                    <div className="content-wrapper lg-height">
                                        {_.isEmpty(this.state.list) && (
                                            <div>{t('no_pending_cards')}</div>
                                        )}
                                        {!_.isEmpty(this.state.list) &&
                                            this.state.key === "pending" && <DataGrid
                                                columns={this.columns()}
                                                data={data}
                                                meta={meta}
                                                handleTableChange={this.handleTableChange}
                                            />}
                                    </div>
                                </Tab>
                                <Tab eventKey="history" title={t('history')}>
                                    <div className="content-wrapper lg-height">
                                        {_.isEmpty(this.state.list) && (
                                            <div>{t('no_history')}</div>
                                        )}
                                        {!_.isEmpty(this.state.list) &&
                                            this.state.key === "history" && <DataGrid
                                                columns={this.columns()}
                                                data={data}
                                                meta={meta}
                                                handleTableChange={this.handleTableChange}
                                            />}
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        selected_user_profile: state.selected_user_profile,
        list: state.reissue_card,
    }
};

const mapActionsToProps = {
    reissueCardList: CardAction.reissueCardList,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/doctor/ReissueCardsPage')(ReissueCardsPage))