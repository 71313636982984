import React from "react";
import { Modal } from 'react-bootstrap';
import CptService from '../services/cptService';
import Notification from '../utils/notification';
import * as LoaderAction from '../actions/loaderAction';
import { connect } from 'react-redux';
import _ from 'lodash';
import SimpleReactValidator from 'simple-react-validator';
import { withTranslation } from 'react-i18next';
import * as HELPER from '../utils/helpers';

class AddCpt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            options: '',
            cpt: '',
            selectedCpt: {
                cpt_code: '',
                full_description: '',
            },
            crosswalks: '',
            selectedCrosswalk: [],
            isChecked: false
        }
        this.validator = new SimpleReactValidator({
            locale: HELPER.getLanguage(),
            messages: {
                required: this.props.t('cpt_required')
            }
        });
    }

    handleChange = evt => {
        // this.props.showLoader();
        this.setState({
            cpt: evt.target.value
        })
        if (_.isEmpty(evt.target.value)) {
            this.setState({
                selectedCpt: {
                    cpt_code: '',
                    full_description: '',
                },
                crosswalks: '',
                selectedCrosswalk: [],
                isChecked: false
            })
        }
        !_.isEmpty(evt.target.value) && CptService.searchwithPagination({ searchText: evt.target.value }).then(resp => {
            this.setState({
                options: resp.data.data
            })
            // this.props.hideLoader();
        }).catch(error => {
            // this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }

    handleHideBlock = () => {
        this.validator.hideMessages()
        this.setState({
            isLoading: false,
            options: '',
            cpt: '',
            selectedCpt: {
                cpt_code: '',
                full_description: '',
            },
            crosswalks: '',
            selectedCrosswalk: [],
            isChecked: false
        })
        this.props.handleCptBlock()
    }

    onClick = (option) => {
        this.setState({
            selectedCpt: {
                full_description: option.full_description,
                cpt_code: option.cpt_code
            },
            cpt: option.cpt_code + "- " + option.full_description,
            options: '',
        })
    }

    handleCrosswalk = (evt) => {
        if (evt.target.checked) {
            this.props.showLoader();
            this.setState({
                isChecked: !this.state.isChecked
            })
            CptService.getCptCrosswalk(this.state.selectedCpt.cpt_code).then(resp => {
                this.setState({
                    crosswalks: resp.data.data
                })
                this.props.hideLoader();
            }).catch(error => {
                this.props.hideLoader();
                Notification.show('error', error.response.data)
            })
        }

        if (!evt.target.checked) {
            this.setState({
                crosswalks: '',
                selectedCrosswalk: [],
                isChecked: !this.state.isChecked
            })
        }
    }

    selectedCrosswalk = (index) => {
        const { crosswalks } = this.state
        const options = crosswalks.slice();

        if (options[index]) {
            options[index].checked = !options[index].checked;
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }
        if(!this.state.selectedCpt.cpt_code){
            return false;
        }
        let selectedData = ''
        if (!_.isEmpty(this.state.crosswalks)) {
            let options = this.state.crosswalks
            selectedData = options.filter(option => option.checked)
        }
        const data = {
            selectedCpt: this.state.selectedCpt,
            selectedCrosswalk: selectedData
        }
        this.props.handleCptData(data)
        this.handleHideBlock()
    }

    renderCrosswalks = (crosswalk, index) => {
        return (<React.Fragment key={index}>
            <li key={index} id={"id" + index}><input type="checkbox" id={"crosswalk" + index} name="crosswalk" onClick={e => this.selectedCrosswalk(index)} />
                <label htmlFor={"crosswalk" + index}></label> <span>{crosswalk.icd_code} - {crosswalk.full_description}</span></li></React.Fragment>)
    }

    render() {
        const { selectedCpt } = this.state
        const { t } = this.props;
        return (
            <>
                <Modal size='lg' centered aria-labelledby="contained-modal-title-vcenter" show={this.props.show} onHide={this.handleHideBlock} className="original-prescription">
                    <Modal.Header closeButton>
                        <h6>{t('search_cpt')}</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="margin-bottom">
                            <div className="prescription-confirm">
                                <div className="input-wrapper">
                                    <input type="text" name="cpt" onChange={this.handleChange} className="form-control" autoComplete="off" autoCorrect="off" value={this.state.cpt} />
                                    {this.validator.message(t('cpt'), this.state.cpt, 'required')}
                                </div>
                            </div>
                            {this.state.options && <ul className="suggesstion-list">
                                {this.state.options.map((option, index) => (
                                    <li key={option.cpt_code} id={"id" + index} className="" onClick={e => this.onClick(option)} data-option={option}>{option.cpt_code} - {option.full_description}</li>
                                ))}
                            </ul>}
                        </div>
                        <div className="clearfix">
                            <input type="checkbox" id="confirm_crosswalk" name="confirm_crosswalk" onClick={this.handleCrosswalk} disabled={!selectedCpt.cpt_code} checked={this.state.isChecked} />
                            <label htmlFor="confirm_crosswalk">{t('add_crosswalk_icd')}</label>
                            {this.state.crosswalks && <ul className="cpt-list">
                                {this.state.crosswalks.map((crosswalk, index) => (
                                    this.renderCrosswalks(crosswalk, index)
                                ))}
                            </ul>}
                            <button type="submit" className="btn btn-primary float-right" onClick={e => this.handleSubmit(e)}>{t('submit')}</button>
                        </div>

                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
const mapActionsToProps = ({
    showLoader: LoaderAction.loaderShow,
    hideLoader: LoaderAction.loaderHide
})

export default connect(null, mapActionsToProps)(withTranslation('components/AddCpt')(AddCpt));
