import React from 'react';
import * as AddVitalComponents from './components/AddVitalComponents';
import PrescriptionService from '../../../services/prescriptionService';
import SimpleReactValidator from 'simple-react-validator';
import Notification from '../../../utils/notification';
import { hasRole } from '../../../utils/auth';
import { isNull } from 'lodash';
import { removeAutoSaveWithInterval, setAutoSaveWithInterval } from '../../../utils/helpers';
import { withTranslation } from 'react-i18next';
import * as HELPER from '../../../utils/helpers';

class VitalInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vital: {
                patient_age: null,
                patient_gender: '',
                patient_gender_other: ''
            },
        }
        this.vitalValidator = new SimpleReactValidator({ locale: HELPER.getLanguage() });
    }
    componentDidMount() {
        PrescriptionService.getVital(this.props.prescriptionId).then(resp => {
            this.setVitalInfo(resp.data)
            this.props.hideLoader();
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
        setAutoSaveWithInterval(this.props.selected_user_profile, this.handleVitalSaveAndNext)
    }

    componentWillUnmount() {
        removeAutoSaveWithInterval()
    }

    componentDidUpdate(prevProps) {
        let {vital} = this.state
        if (this.props.patient_dob !== prevProps.patient_dob) {
            this.setVitalInfo(this.state.vital)
        }

        if (this.props.patient_gender !== prevProps.patient_gender) {
            this.setVitalInfo(this.state.vital)
          }
      }

    setVitalInfo = (vital_info) => {
        this.setState({
            vital: {
                patient_height: vital_info.patient_height ? parseFloat(vital_info.patient_height) : '',
                patient_weight: vital_info.patient_weight ? parseFloat(vital_info.patient_weight) : '',
                patient_blood_pressure_max: vital_info.patient_blood_pressure_max ? parseFloat(vital_info.patient_blood_pressure_max) : '',
                patient_blood_pressure_min: vital_info.patient_blood_pressure_min ? parseFloat(vital_info.patient_blood_pressure_min) : '',
                patient_pulse_rate: vital_info.patient_pulse_rate ? parseFloat(vital_info.patient_pulse_rate) : '',
                patient_pulse_ox: vital_info.patient_pulse_ox ? parseFloat(vital_info.patient_pulse_ox) : '',
                patient_rbg: vital_info.patient_rbg ? parseFloat(vital_info.patient_rbg) : '',
                patient_temperature: vital_info.patient_temperature ? parseFloat(vital_info.patient_temperature) : '',
                patient_bmi: !isNull(vital_info.patient_bmi) ? parseFloat(vital_info.patient_bmi) : (vital_info.patient_height && vital_info.patient_weight ? this.calculateBMI(vital_info.patient_height, vital_info.patient_weight) : ''),
                patient_age:  vital_info.patient_age ? parseInt(vital_info.patient_age) : (this.props.patient_dob ? this.calculateAge(this.props.patient_dob): ''),
                patient_gender: (this.state.vital.patient_gender) || this.props.patient_gender,
                patient_bmi_category: vital_info.patient_bmi_category ,
                patient_gender_other: vital_info.patient_gender_other,
                patient_blood_group: vital_info.patient_blood_group,
                patient_allergy: vital_info.patient_allergy,
                patient_urinalysis: vital_info.patient_urinalysis,
                patient_id: this.props.patientId,
                nurse_remark: vital_info.nurse_remark,
                patient_comorbidities: vital_info.patient_comorbidities,
                patient_respiratory_rate: vital_info.patient_respiratory_rate,
            },
        });
    }
    
    calculateBMI = (height, weight) => {
        height = parseFloat(height);
        weight = parseFloat(weight);
        if (height && weight)
            return parseFloat(((weight) / (height * height * 0.0001)).toFixed(2));
        return ''
    }

    showBMICategory = (bmi,age,gender) => {
        let {vital} = this.state;
        gender = (vital.patient_gender=="other" && vital.patient_age<=19) ? vital.patient_gender_other : gender
        if (bmi && gender && age>=0) {
            PrescriptionService.getBmiCategory({
                'bmiValue': bmi,
                'age': age,
                'gender': gender
            }).then(resp => {
                
                vital.patient_bmi_category = resp.data.bmi_category;
                this.setState({ vital });
            }).catch(error => {
                Notification.show('error', error.response.data)
            })
        }
    }

    calculateAge = (dateOfBirth) => {
        let today = new Date();
        let birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
        {
            age--;
        }
        return age;
    }

    handleChange = (e) => {
        let { vital } = this.state;
        if (["patient_allergy", "patient_comorbidities"].includes(e.target.name)) {
            let value = e.target.value ? (e.target.value).split(",") : [];
            vital[e.target.name] = value;
        } else {
            vital[e.target.name] = e.target.value;
        }

        if(e.target.name==="patient_gender_other") 
            vital.patient_gender_other = e.target.value

        vital.patient_age = this.state.vital.patient_age ? parseInt(this.state.vital.patient_age) : (this.props.patient_dob ? this.calculateAge(this.props.patient_dob): '')

        this.setState({ vital });
        this.props.handleIsPrescriptionEdited('vital')
    }

    handleBlur = (e) => {
        let { vital } = this.state;

        vital.patient_age = this.state.vital.patient_age ? parseInt(this.state.vital.patient_age) : (this.props.patient_dob ? this.calculateAge(this.props.patient_dob): '')
        if(e.target.name==="patient_height" || e.target.name==="patient_weight"){
            vital.patient_bmi = this.calculateBMI(vital.patient_height, vital.patient_weight)
            this.showBMICategory(vital.patient_bmi,vital.patient_age,vital.patient_gender)
        }
        this.setState({ vital });
        this.props.handleIsPrescriptionEdited('vital')
    }

    handleVitalSaveAndNext = (e = null, type = null) => {
        e && e.preventDefault();
        type = e ? type : "auto_save";
        if (!this.vitalValidator.allValid()) {
            this.vitalValidator.showMessages();
            this.forceUpdate();
            return false;
        }
        e && this.props.showLoader();
        PrescriptionService.createVital(this.props.prescriptionId, this.state.vital).then(resp => {
            this.setVitalInfo(resp.data);
            e && this.props.hideLoader();
            type !== "auto_save" && !hasRole(this.props.selected_user_profile, ["doctor-nurse"]) && this.props.handleSaveAndNext();

        }).catch(error => {
            e && this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    showNurseRemark = () => {
        let { vital } = this.state;
        return (hasRole(this.props.selected_user_profile, ["doctor-nurse"])) || (vital.nurse_remark && hasRole(this.props.selected_user_profile, ['doctor']))
    }
    render() {
        let { vital } = this.state;
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="content-wrapper lg-height">
                    <div className="content-wrapper padding-bottom-10">
                        <ul className="prescription-patient-details">
                            <li>
                                <AddVitalComponents.Height
                                    patientId={this.props.patientId}
                                    height={vital.patient_height}
                                    handleChange={this.handleChange}
                                    handleBlur={this.handleBlur}
                                    t={t}
                                />
                            </li>
                            <li>
                                <AddVitalComponents.Weight
                                    patientId={this.props.patientId}
                                    weight={vital.patient_weight}
                                    handleChange={this.handleChange}
                                    handleBlur={this.handleBlur}
                                    t={t}
                                />
                            </li>
                            <li>
                                <AddVitalComponents.BloodPressure
                                    patientId={this.props.patientId}
                                    maxBloodPressure={vital.patient_blood_pressure_max}
                                    minBloodPressure={vital.patient_blood_pressure_min}
                                    handleChange={this.handleChange}
                                    t={t}
                                />
                            </li>
                            <li>
                                <AddVitalComponents.RespiratoryRate
                                    patientId={this.props.patientId}
                                    respiratory_rate={vital.patient_respiratory_rate}
                                    handleChange={this.handleChange}
                                    validator={this.vitalValidator}
                                    t={t}
                                />
                            </li>
                            <li>
                                <AddVitalComponents.PulseRate
                                    patientId={this.props.patientId}
                                    pulse_rate={vital.patient_pulse_rate}
                                    handleChange={this.handleChange}
                                    validator={this.vitalValidator}
                                    t={t}
                                />
                            </li>
                            <li>
                                <AddVitalComponents.PulseOx
                                    patientId={this.props.patientId}
                                    pulse_ox={vital.patient_pulse_ox}
                                    handleChange={this.handleChange}
                                    validator={this.vitalValidator}
                                    t={t}
                                />
                            </li>
                            <li>
                                <AddVitalComponents.Rbg
                                    patientId={this.props.patientId}
                                    rbg={vital.patient_rbg}
                                    handleChange={this.handleChange}
                                    validator={this.vitalValidator}
                                    t={t}
                                />
                            </li>
                            <li>
                                <AddVitalComponents.Temperature
                                    patientId={this.props.patientId}
                                    temperature={vital.patient_temperature}
                                    handleChange={this.handleChange}
                                    t={t}
                                />
                            </li>
                            <li>
                                <AddVitalComponents.BloodGroup
                                    blood_group={vital.patient_blood_group}
                                    handleChange={this.handleChange}
                                    selected_user_profile={this.props.selected_user_profile}
                                    t={t}
                                />
                            </li>
                            <li>
                                <AddVitalComponents.BMI
                                    patientId={this.props.patientId}
                                    bmi={vital.patient_bmi}
                                    t={t}
                                />
                            </li>
                            <li>
                                <AddVitalComponents.Category
                                    bmiCategory={vital.patient_bmi_category}
                                    age={vital.patient_age}
                                    gender={vital.patient_gender}
                                    genderOther={vital.patient_gender_other}
                                    handleChange={this.handleChange}
                                    t={t}
                                />
                            </li>
                        </ul>
                        <ul className="prescription-patient-details react-tags-input-wrapper">
                            <li>
                                <AddVitalComponents.Urinalysis
                                    urinalysis={vital.patient_urinalysis}
                                    handleChange={this.handleChange}
                                    t={t}
                                />
                            </li>
                        </ul>
                        <ul className="prescription-patient-details react-tags-input-wrapper">
                            <li>
                                <AddVitalComponents.Allergy
                                    allergy={vital.patient_allergy}
                                    handleChange={this.handleChange}
                                    t={t}
                                />
                            </li>
                        </ul>
                        <ul className="prescription-patient-details react-tags-input-wrapper">
                            <li>
                                <AddVitalComponents.Comorbidities
                                    comorbidities={vital.patient_comorbidities}
                                    handleChange={this.handleChange}
                                    t={t}
                                />
                            </li>
                        </ul>
                        {this.showNurseRemark() && <ul className="prescription-patient-details react-tags-input-wrapper">
                            <li>
                                <AddVitalComponents.NurseRemark
                                    nurse_remark={vital.nurse_remark}
                                    handleChange={this.handleChange}
                                    selected_user_profile={this.props.selected_user_profile}
                                    t={t}
                                />
                            </li>
                        </ul>}
                    </div>
                </div>
                {hasRole(this.props.selected_user_profile, ["doctor-nurse"]) && <div className="bottom-btns">
                    <button className="btn btn-primary" onClick={(e) => this.handleVitalSaveAndNext(e, 'save')}>{t('save')}</button>
                </div>}
                {hasRole(this.props.selected_user_profile, ['junior-doctor', "associate-doctor", "doctor", "paramedic-doctor"]) && <div className="bottom-btns">
                    <button className="btn btn-primary" onClick={(e) => this.handleVitalSaveAndNext(e, 'save_and_next')}>{t('save_next')}</button>
                </div>}
            </React.Fragment>
        )
    }
}
export default (withTranslation('components/Prescription/Vital/AddVital')(VitalInfo))
