import React from 'react';

export const Throat = ({ handleChange, checkIfProblemExist ,t }) => {
    return <React.Fragment>
        <h6>{t('throat.throat')}</h6>
        <input type="checkbox" id='throat-normal' name='throat-normal' onChange={e => handleChange(e, 'throat')} checked={checkIfProblemExist('throat', 'throat-normal') ? 'checked' : ''} />
        <label htmlFor='throat-normal'>{t('throat.normal')}</label>
    </React.Fragment>
}
export const Tonsils = ({ handleChange, checkIfProblemExist ,t }) => {
    return <React.Fragment>
        <div className="options-details">
            <label className="heading">{t('throat.tonsils')} </label>
            <input type="checkbox" id='throat-tonsils-swollen' name='throat-tonsils-swollen' onChange={e => handleChange(e, 'throat')} checked={checkIfProblemExist('throat', 'throat-tonsils-swollen') ? 'checked' : ''} />
            <label htmlFor='throat-tonsils-swollen'>{t('throat.swollen')}</label>
            <input type="checkbox" id='throat-tonsils-exudate' name='throat-tonsils-exudate' onChange={e => handleChange(e, 'throat')} checked={checkIfProblemExist('throat', 'throat-tonsils-exudate') ? 'checked' : ''} />
            <label htmlFor='throat-tonsils-exudate'>{t('throat.exudate')}</label>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist ,t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('type_here')} onChange={e => handleChange(e, 'throat')} disabled={checkIfProblemExist('throat', 'throat-normal') ? 'disabled' : ''} value={getCategoryComment('throat') ? getCategoryComment('throat') : ''}></textarea>
    </React.Fragment>
}
export const Erythema = ({ handleChange, checkIfProblemExist ,t }) => {
    return <React.Fragment>
        <input type="checkbox" id='throat-erythema' name='throat-erythema' onChange={e => handleChange(e, 'throat')} checked={checkIfProblemExist('throat', 'throat-erythema') ? 'checked' : ''} />
        <label htmlFor='throat-erythema'>{t('throat.erythema')}</label>
    </React.Fragment>
}