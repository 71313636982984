import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class ContextHelp extends React.Component {

    render() {
        const { t } = this.props;
        return (<React.Fragment>
           <div className="help-box">
                <div className="heading">
                    <h4>{t('permissions')}</h4>
                    <a href className="icon cross"> </a>
                </div>
                <div className="content">
                    <p className="desc">daisy content</p>
                </div>
                <div className="section-cover">
                    <div className="left-side">
                        <span className="current-section"></span>{t('of')}
                        <span className="total-section"></span>
                    </div>
                    <div className="right-side">
                        <a href className="previous disable">{t('previous')}</a>
                        <a href className="next">{t('next')}</a>
                    </div>
                </div>
            </div>
            <div className="overlay"></div>
        </React.Fragment>);
    }
}

export default connect(null, null)(withTranslation('pages/ContextHelp')(ContextHelp));