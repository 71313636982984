import React from "react";
import Notification from '../../utils/notification';
import { hasRole } from '../../utils/auth';
import * as ButtonComponents from './Buttons';
import PersonalNote from '../Prescription/Recommendation/components/PersonalNote';
import PatientRemarksBlock from '../Prescription/PatientRemarksBlock';
import { SPECIALIZATION } from '../../config';
import DoctorSearch from '../Prescription/DoctorSearch';
import UrgentCareService from "../../services/urgentCareService";
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

class RemarkReferralBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit_refer_remark: true,
            disable_doctor_search: true,
            prescription: {
                refer_to: {}
            },
        }
        this.remarkReferralValidator = props.validator
    }

    componentDidMount() {
        this.getRemarkReferral()
    }

    componentDidUpdate(prevProps) {
        if (this.props.refer_to !== prevProps.refer_to) {
            this.getRemarkReferral()
        }
    }

    getRemarkReferral(){
        let props = this.props;
        this.setState({
            prescription: {
                refer_to: {
                    doctor_id: props.refer_to ? props.refer_to.user_id : null,
                    doctor_name: props.refer_to ? props.refer_to.full_name : null,
                    doctor_specialization: props.refer_to ? props.refer_to.specialization : null,
                    refer_remark: props.refer_to ? props.refer_to.remark : null,
                },
                personal_note: props.personal_note ? props.personal_note : null,
                remarks: props.remarks ? props.remarks : null,
                patient_id: props.patientId
            },
        })
    }

    handleRemarkReferralFinalSubmit = (e, type = null) => {
        let status = ['save', 'save_and_next'].includes(type) ? "save" : "draft"

        this.setState(prevState => ({
            prescription: {
                ...prevState.prescription,
                status: status
            }
        }), () => {
            if (this.props.refer_to.id) {
                this.setState(prevState => ({
                    prescription: {
                        ...prevState.prescription,
                        refer_to: {
                            ...prevState.prescription.refer_to,
                            status: status,
                            id: this.props.refer_to.id
                        },
                    }
                }), () => {
                    this.addRemarkReferral(type)
                })
            } else {
                this.addRemarkReferral(type)
            }
        })
    }

    addRemarkReferral = (type) => {
        if (!this.remarkReferralValidator.allValid()) {
            this.remarkReferralValidator.showMessages();
            this.forceUpdate();
            return false;
        }
        this.props.showLoader();
        UrgentCareService.createRemarkReferral(this.props.prescriptionId, this.state.prescription).then(resp => {
            this.props.hideLoader();
            this.props.handleIsPrescriptionEdited('recommendation_remark_referral', false);
             if (type === "save") {
                this.handleSubmit();
            }else if (type === "draft_and_next") {
                this.props.handleSaveAndNext();
            }
            this.props.getPrescriptionRecommendation();
            
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    handleChange = (e) => {
        let { prescription } = this.state;
        if (e.target.name === 'doctor_specialization') {
            if (e.target.value !== null && e.target.value !== "") {
                prescription.refer_to = {
                    doctor_id: '',
                    doctor_name: '',
                    doctor_specialization: e.target.value,
                    refer_remark: '',
                };
                this.setState({
                    prescription,
                    disable_doctor_search: false,
                    edit_refer_remark: false,
                });
            } else {
                prescription.refer_to = {
                    doctor_id: '',
                    doctor_name: '',
                    doctor_specialization: '',
                    refer_remark: '',
                };
                this.setState({
                    prescription,
                    disable_doctor_search: true,
                    edit_refer_remark: true,
                });
            }
        } else if (e.target.name === 'refer_remark') {
            prescription.refer_to['refer_remark'] = e.target.value;
        } else {
            prescription[e.target.name] = e.target.value;
        }
        this.setState({ prescription });
        this.props.handleIsPrescriptionEdited('recommendation_remark_referral')
    }
    //Refer To doctor
    handleReferToDoctor = (data) => {
        let { prescription } = this.state;
        prescription.refer_to = data;
        this.setState({ prescription });
        this.props.handleIsPrescriptionEdited('recommendation_remark_referral')
    }
    //urgent care prescripton create
    handleSubmit = () => {
       this.props.showLoader();
        UrgentCareService.prescriptionSubmit(this.props.prescriptionId).then(resp => {
            window.location = '/patients/' + this.props.patientId + '/prescriptions/' + resp.data.prescription_id;
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    render() {
        const { t } = this.props;
        return (
            <div>
                <PersonalNote
                    note={this.state.prescription.personal_note}
                    handleChange={this.handleChange}
                />
                <div className="content-wrapper-nobg">
                    <div className="row">
                        <div className="col-md-14">
                            <div className="content-wrapper">
                                <h6>{t('remark')}
                                    <PatientRemarksBlock patientId={this.props.patientId} />
                                </h6>
                                <textarea className="form-control remark" rows="2" name="remarks" value={this.state.prescription.remarks} onChange={this.handleChange} placeholder={t('type_remark')} disabled={this.state.edit_remark}></textarea>
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div className="content-wrapper margin-bottom">
                                <h6>{t('refer_doctor')}</h6>
                                {<select className="form-control margin-bottom" onChange={this.handleChange} value={this.state.prescription.refer_to.doctor_specialization} name="doctor_specialization" disabled={!hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor"]) ? "disabled" : ""}>
                                    <option value="">{t('select_specialization')}</option>
                                    {Object.keys(SPECIALIZATION).map(function (key) {
                                        return <option key={key} className="travelcompany-input" value={SPECIALIZATION[key]}>
                                            {SPECIALIZATION[key]}
                                        </option>;
                                    })}
                                </select>}
                                <DoctorSearch
                                    handleDoctorSearch={this.handleReferToDoctor}
                                    refer_to={this.state.prescription.refer_to}
                                    disabled={this.state.disable_doctor_search}
                                    validator={this.remarkReferralValidator}
                                />
                                <textarea className="form-control mt-2" rows="2" name="refer_remark" value={this.state.prescription.refer_to.refer_remark} onChange={this.handleChange} placeholder={t('remark')} disabled={this.props.edit_refer_remark}></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bottom-btns">
                    {hasRole(this.props.selected_user_profile, ['doctor', "associate-doctor", "paramedic-doctor"]) &&                     
                        <ButtonComponents.PrimaryButton
                            handleChange={this.handleRemarkReferralFinalSubmit}
                            buttonType={"save"}
                            buttonName={t('final_submit')}
                        />}
                    <ButtonComponents.OutlineButton
                        handleChange={this.props.handlePrevious}
                        buttonName={t('previous')}
                    />
                    <ButtonComponents.PrimaryButton
                        handleChange={this.handleRemarkReferralFinalSubmit}
                        buttonType={"draft"}
                        buttonName={t('save_draft')}
                    />
                </div>

            </div>
        );
    }
}
 
export default (withTranslation('components/UrgentCare/RemarkReferralBlock')(RemarkReferralBlock));