import React, { Component } from "react";
import { connect } from "react-redux";
import TestDetails from "../../components/Report/TestDetails";
import ReferralDetails from "../../components/Report/ReferralDetails";
import PatientReportDetails from "../../components/Report/PatientReportDetails";
import { Form, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import SimpleReactValidator from "simple-react-validator";
import ReferralService from "../../services/referralService";
import _ from "lodash";
import Notification from "../../../src/utils/notification";
import moment from "moment";
import { getAgeString } from "../../../src/utils/helpers";
import * as loaderActions from '../../actions/loaderAction';
import { hasRole } from '../../utils/auth';
import RoleSerivce from '../../services/roleService';
import PrintReferralReportButton from "../../components/Report/PrintReferralReportButton";
import PrintCompletedReportPage from "../../components/Report/PrintCompletedReportPage";
import TestDetailsPhlebotomist from "../../components/Report/TestDetailsPhlebotomist";

class LabReportDateEntryClerk extends Component {
  constructor(props) {
    super(props);
    const params = this.props.match.params;
    this.state = {
      formData: {
        name: "",
        phone: "",
        doctor: "",
        sample_collected_at: "",
        address: "",
        age: "",
        doctor_contact: "",
        sample_collected_on: "",
        uploaded_referral: null,
        lab_id: "",
        comments: "",
      },
      testsData: [],
      testDetailsValues: [],
      id: params.id,
      downloadUrl: '',
      referral_form_data: [],
      isRefferd: false,
      initialBmi: ""
    };
    this.validator = new SimpleReactValidator();
    this.testDetailsRef = React.createRef();
    this.referralDetailsRef = React.createRef();
  }

  componentDidMount() {
    this.props.loaderShow();
    const id = this.state.id;
    const today_date = moment().format("YYYY-MM-DD");

    ReferralService
      .getReferrals(id)
      .then((resp) => {
        const refData = resp.data;
        let address = refData.address;
        address =
          (address.address_line_1 ? address.address_line_1 + ", " : "") +
          (address.address_line_2 ? address.address_line_2 + ", " : "") +
          (address.address_line_3 ? address.address_line_3 + ", " : "") +
          (address.parish ? address.parish + ", " : "") +
          (address.city ? address.city + ", " : "") +
          (address.pincode ? address.pincode : "");
        this.setState({
          formData: {
            name: refData.name,
            phone: refData.contact_number,
            doctor: refData.doctor_name,
            sample_collected_at: refData.sample_collected_at,
            address: address,
            age: getAgeString(refData.date_of_birth, today_date),
            patient_height: refData.patient_height,
            patient_weight: refData.patient_weight,
            patient_bmi: refData.patient_bmi,
            doctor_contact: refData.doctor_contact_number,
            doctor_email: refData.doctor_email,
            sample_collected_on: moment(refData.sample_collected_on).format(
              "YYYY-MM-DD"
            ),
            created_on: moment(refData.verified2.verified_at).format(
              "YYYY-MM-DD"
            ),
            uploaded_referral: (refData.uploaded_referral) ? refData.uploaded_referral : null,
            lab_id: refData.lab_id,
            comments: refData.comments,
          },
          // testsData: refData.referral_tests,
          serviceProviderName: refData.service_provider.name,
          serviceProviderAddress: refData.service_provider.address,
          isRefferd: refData.is_referred,
          initialBmi: refData.patient_bmi,
        });

        const updatedTestData = refData.referral_tests.map((row) => {
          if (row.lims_test.name === "BMI") {
            return { ...row, ['value']: (row.value ? row.value : refData.patient_bmi) };
          }
          return row;
        });
        this.setState({
          testsData: updatedTestData,
        });


        this.props.loaderHide();
      })
      .catch((error) => {
        Notification.show("error", error.response.data);
        this.props.loaderHide();
      });

    ReferralService
      .getReferralsDownload(id)
      .then((resp) => {
        this.setState({
          downloadUrl: resp.data.download_url
        })
      })
      .catch((error) => {
        Notification.show("error", error.response.data);
        this.props.loaderHide();
      });
  }

  handleCheckboxChange(e, name) {
    this.setState({ [name]: e.target.checked });
  }

  handleChange = e => this.setState({ isRefferd: e.target.checked });

  handleSubmit = (e) => {
    // Handle submit button click
    this.props.loaderShow();
    e.preventDefault();

    if (this.referralDetailsRef.current) {
      if (!this.referralDetailsRef.current.isValid()) {
        this.props.loaderHide();
        return false;
      }
    }

    if (this.testDetailsRef.current) {
      if (!this.testDetailsRef.current.isValid()) {
        this.props.loaderHide();
        return false;
      }
    }

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      this.props.loaderHide();
      return false;
    }

    const requestData = {
      doctor_name: this.state.formData.doctor,
      doctor_contact_number: this.state.formData.doctor_contact,
      doctor_email: this.state.formData.doctor_email,
      patient_height: this.state.formData.patient_height,
      patient_weight: this.state.formData.patient_weight,
      patient_bmi: this.state.formData.patient_bmi,
      lab_id: this.state.formData.lab_id,
      comments: this.state.formData.comments,
      tests: this.state.testsData.map((test) => ({
        id: test.id,
        test_id: test.test_id,
        value: test.value,
        sample_nos: test.sample_nos,
        flag: test.flag,
      })),
      is_referred: this.state.isRefferd,      
    };

    ReferralService.sendForVerification(this.state.id, requestData).then((resp) => {
      Notification.show('success', { message: 'Report has been sent for verification' });
      this.props.loaderHide();
      this.props.history.goBack();
    }).catch(error => {
      Notification.show('error', error.message);
      this.props.loaderHide();
    })
  };

  handleDraftSubmit = (e) => {
    this.props.loaderShow();
    e.preventDefault();

    if (this.referralDetailsRef.current) {
      if (!this.referralDetailsRef.current.isValid()) {
        this.props.loaderHide();
        return false;
      }
    }

    const requestData = {
      doctor_name: this.state.formData.doctor,
      doctor_contact_number: this.state.formData.doctor_contact,
      doctor_email: this.state.formData.doctor_email,
      patient_height: this.state.formData.patient_height,
      patient_weight: this.state.formData.patient_weight,
      patient_bmi: this.state.formData.patient_bmi,
      lab_id: this.state.formData.lab_id,
      comments: this.state.formData.comments,
      tests: this.state.testsData.map((test) => ({
        id: test.id,
        test_id: test.test_id,
        value: test.value,
        sample_nos: test.sample_nos,
        flag: test.flag,
      })),
      is_referred: this.state.isRefferd,     
    };
    ReferralService.saveAsDraft(this.state.id, requestData).then((resp) => {
      Notification.show('success', { message: 'Report has been saved successfully' });
      this.props.loaderHide();
      this.props.history.goBack();
    }).catch(error => {
      Notification.show('error', error.response.message);
      this.props.loaderHide();
    })
  };

  handleTestDetailsChange = (value, rowId) => {
    const updatedData = this.state.testsData.map((row) => {
      if (row.id === rowId) {
        return { ...row, ['value']: value };
      }
      return row;
    });
    this.setState({
      testsData: updatedData,
    });
  };

  handlePhlebotomistSubmit = (e) => {
    this.props.loaderShow();
    e.preventDefault();

    if (this.referralDetailsRef.current) {
      if (!this.referralDetailsRef.current.isValid()) {
        this.props.loaderHide();
        return false;
      }
    }

    const requestData = {
      tests: this.state.testsData.map((test) => ({
        id: test.id,
        test_id: test.test_id,
        sample_nos: test.sample_nos
      })),
    };
    ReferralService.saveAsPhlebotomist(this.state.id, requestData).then((resp) => {
      Notification.show('success', { message: 'Report has been saved successfully' });
      this.props.loaderHide();
      this.props.history.goBack();
    }).catch(error => {
      Notification.show('error', error.response.message);
      this.props.loaderHide();
    })
  };

  handleTestDetailsChange = (value, rowId) => {
    const updatedData = this.state.testsData.map((row) => {
      if (row.id === rowId) {
        if (_.isEmpty(value)) {
          return { ...row, ['value']: value, ['flag']: '' };
        }
        if (!row.flag && row.lims_test.type == "select") {
          return { ...row, ['value']: value, ['flag']: value };
        }
        return { ...row, ['value']: value };
      }
      return row;
    });
    this.setState({
      testsData: updatedData,
    });
  };

  getFlagValue = (lower_limit, upper_limit, val) => {
    if (val == null || isNaN(val) || (val == 0 && lower_limit == null && upper_limit == null)) {
      return null;
    }

    let lowerLimit = parseFloat(lower_limit);
    let upperLimit = parseFloat(upper_limit);
    let value = parseFloat(val);
    let flag = "";

    if (value == 0) {
      flag = "low";
    }
    else {
      if ((lowerLimit == 0 || lowerLimit === null) && upperLimit) {
        if (value > upperLimit) {
          flag = "high";
        } else {
          flag = "normal";
        }
      } else if ((upperLimit == 0 || upperLimit === null) && lowerLimit) {
        if (value <= lowerLimit) {
          flag = "low";
        } else {
          flag = "normal";
        }
      } else if (!lowerLimit && !upperLimit) {
        flag = "";
      } else {
        if (value >= lowerLimit && value <= upperLimit) {
          flag = "normal";
        } else if (value <= lowerLimit) {
          flag = "low";
        } else if (value > upper_limit) {
          flag = "high";
        }
      }
    }
    return flag;
  };

  handleOnBlurTestDetails = (value, rowId) => {
    const updatedData = this.state.testsData.map((row) => {
      if (row.id === rowId && !row.flag) {
        let flag = this.getFlagValue(row.lims_test.lower_limit, row.lims_test.upper_limit, value);
        return { ...row, ['flag']: flag };
      }
      return row;
    });
    this.setState({
      testsData: updatedData,
    });
  };

  onTestFlagChange = (value, rowId) => {
    const updatedData = this.state.testsData.map((row) => {
      if (row.id === rowId) {
        return { ...row, ['flag']: value };
      }
      return row;
    });
    this.setState({
      testsData: updatedData,
    });
  };


  handleSampleNosDetailsChange = (value, rowId) => {
    const updatedData = this.state.testsData.map((row) => {
      if (row.id === rowId) {
        return { ...row, ['sample_nos']: value };
      }
      return row;
    });
    this.setState({
      testsData: updatedData,
    });
  };

  reset = () => {
    const updatedData = this.state.testsData.map((row) => {
      return { ...row, ["value"]: '', ["sample_nos"]: '' };
    });
    this.setState({
      testsData: updatedData,
    });
  };

  handleReferralDetailsChange = (name, value) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }), () => {
      if (['patient_height', 'patient_weight'].includes(name)) {
        let height = parseFloat(this.state.formData.patient_height);
        let weight = parseFloat(this.state.formData.patient_weight);
        let bmi = '';
        if (height && weight)
          bmi = parseFloat(((weight) / (height * height * 0.0001)).toFixed(2));

        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            patient_bmi: bmi,
          },
        }), () => {
          if (!this.state.initialBmi) {
            const updatedTestData = this.state.testsData.map((row) => {
              if (row.lims_test.name === "BMI") {
                return { ...row, ['value']: (this.state.formData.patient_bmi) };
              }
              return row;
            });
            this.setState({
              testsData: updatedTestData,
            });
          }
        });
      }
    });
  };

  render() {
    return (
      <div>
        <div>
          <Helmet>
            <title>Lab Report ({RoleSerivce.getRoleNameBySlug(this.props.selected_profile.role_slug)})</title>
          </Helmet>
          <div className="row">
            <div className="col-md-24">
              <h4 className="page-title">Report ({RoleSerivce.getRoleNameBySlug(this.props.selected_profile.role_slug)})</h4>
              <div className="content-wrapper add-lab-report">
                {hasRole(this.props.selected_profile, ['data-entry-clerk']) &&
                  <ReferralDetails ref={this.referralDetailsRef} formData={this.state.formData} downloadUrl={this.state.downloadUrl} onReferralDetailsChange={this.handleReferralDetailsChange} />
                }
                {hasRole(this.props.selected_profile, ['pathology', 'pathology-receptionist', 'pathology-phlebotomist', "pathology-lab-tech", "pathology-med-tech", "pathologist"]) &&
                  <PatientReportDetails formData={this.state.formData} downloadUrl={this.state.downloadUrl} />
                }
                <br />
                {hasRole(this.props.selected_profile, ['data-entry-clerk']) &&
                  <TestDetails
                    ref={this.testDetailsRef}
                    onTestDetailsChange={this.handleTestDetailsChange}
                    handleOnBlurTestDetails={this.handleOnBlurTestDetails}
                    onSampleNosDetailsChange={this.handleSampleNosDetailsChange}
                    onTestFlagChange={this.onTestFlagChange}
                    testsData={this.state.testsData}
                    resetValue={this.reset}
                    disabledValue={false}
                  />
                }
                {hasRole(this.props.selected_profile, ['pathology', 'pathology-receptionist', "pathology-lab-tech", "pathology-med-tech", "pathologist"]) &&
                  <TestDetails
                    ref={this.testDetailsRef}
                    onTestDetailsChange={this.handleTestDetailsChange}
                    onSampleNosDetailsChange={this.handleSampleNosDetailsChange}
                    testsData={this.state.testsData}
                    resetValue={this.reset}
                    disabledValue={true}
                  />
                }
                {hasRole(this.props.selected_profile, ['pathology-phlebotomist']) &&
                  <TestDetailsPhlebotomist
                    ref={this.testDetailsRef}
                    onTestDetailsChange={this.handleTestDetailsChange}
                    onSampleNosDetailsChange={this.handleSampleNosDetailsChange}
                    testsData={this.state.testsData}
                    resetValue={this.reset}
                    disabledValue={false}
                  />
                }
                {hasRole(this.props.selected_profile, ['pathology-phlebotomist']) &&
                  <div className="labReport-box">
                    <Button
                      variant="primary"
                      className="btn btn-primary ml-2 mt-3"
                      size="sm"
                      onClick={this.handlePhlebotomistSubmit}
                    >
                      SAVE
                    </Button>

                    <PrintReferralReportButton
                      content={this.state}
                      component={PrintCompletedReportPage}
                      title="Print"
                      id={this.props.match.params}
                    />
                  </div>
                }
                {hasRole(this.props.selected_profile, ['pathology', 'pathology-receptionist', "pathology-lab-tech", "pathology-med-tech", "pathologist"]) &&
                  <PrintReferralReportButton
                    content={this.state}
                    component={PrintCompletedReportPage}
                    title="Print"
                    id={this.props.match.params}
                  />}
                <br />
                {hasRole(this.props.selected_profile, ['data-entry-clerk']) &&
                  <div className="labReport-box">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={this.handleDraftSubmit}
                    >
                      SAVE AS DRAFT
                    </Button>

                    <Button
                      variant="primary"
                      size="sm"
                      onClick={this.handleSubmit}
                      className="ml-3"
                    >
                      SEND FOR VERIFICATION
                    </Button>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={this.reset}
                    >
                      RESET
                    </Button>
                    <div className="form-check form-check-inline ml-2">
                      <input type="checkbox" id={'insurance_type-no'} name="isRefferd" checked={this.state.isRefferd} onChange={this.handleChange} value={this.state.isRefferd} />
                      <label htmlFor={'insurance_type-no'} className="m-0 h6">Referred</label>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selected_profile: state.selected_user_profile,
});

const mapActionsToProps = {
  loaderShow: loaderActions.loaderShow,
  loaderHide: loaderActions.loaderHide,
};

export default connect(mapStateToProps, mapActionsToProps)(LabReportDateEntryClerk);
