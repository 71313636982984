import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import OverallExamination from './components/AddEdit/AddOverallExamination';
import PrescriptionService from '../../../services/prescriptionService';
import Notification from '../../../utils/notification';
import { hasRole } from '../../../utils/auth';
import { removeAutoSaveWithInterval, setAutoSaveWithInterval } from '../../../utils/helpers';
import { withTranslation } from 'react-i18next';

class ExaminationBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            examination: [],
            examination_note: '',
            other_examination_note: '',
            investigation: ''
        }
    }
    componentDidMount() {
        this.props.showLoader();
        PrescriptionService.getExamination(this.props.prescriptionId).then(resp => {
            this.setState({
                examination: resp.data.examination ? resp.data.examination : [],
                examination_note: resp.data.examination_note ? resp.data.examination_note : '',
                other_examination_note: resp.data.other_examination_note ? resp.data.other_examination_note : '',
                investigation: resp.data.investigation ? resp.data.investigation : ''
            })
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
        setAutoSaveWithInterval(this.props.selected_user_profile, this.handleExaminationSaveAndExit)
    }

    componentWillUnmount() {
        removeAutoSaveWithInterval()
    }
    handleOverallExamination = (overall_examination) => {
        this.setState({ examination: overall_examination });
        this.props.handleIsPrescriptionEdited('examination')
    }
    handleNoteChanges = (e) => {
        if( e.target.name === "examination_note" ){
            this.setState({ examination_note: e.target.value })
        }else{
            this.setState({ other_examination_note: e.target.value })
        }
        this.props.handleIsPrescriptionEdited('examination')
    }
    handleInvestigationChanges = (e) =>{
        this.setState({ investigation: e.target.value });
        // this.props.handleIsPrescriptionEdited('examination')
    }
    handleExaminationSaveAndExit = (e = null, type = null) => {
        e && e.preventDefault();
        type = e ? type : "auto_save";
        e && this.props.showLoader();
        PrescriptionService.createExamination(this.props.prescriptionId, {
            'patient_id': this.props.patientId,
            'examination': this.state.examination,
            'examination_note': this.state.examination_note,
            'other_examination_note': this.state.other_examination_note,
            'investigation': this.state.investigation,
        }).then(_resp => {
            e && this.props.hideLoader();
            type !== "auto_save" && (!hasRole(this.props.selected_user_profile, ['junior-doctor']) || type === 'save') && this.props.handleSaveAndNext();
        }).catch(error => {
            e && this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="lg-height">
                    <Accordion>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">{t('overall_examination')}</Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <OverallExamination
                                        handleOverallExamination={this.handleOverallExamination}
                                        examination={this.state.examination}
                                        examination_note={this.state.examination_note}
                                        other_examination_note={this.state.other_examination_note}
                                        investigation={this.state.investigation}
                                        handleNoteChanges={this.handleNoteChanges}
                                        handleInvestigationChanges={this.handleInvestigationChanges}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
                {/* NOTE:Change its submit function */}
                <div className="bottom-btns">
                    {hasRole(this.props.selected_user_profile, ['junior-doctor']) && <div className="bottom-btns">
                        <button className="btn btn-primary" onClick={this.handleExaminationSaveAndExit}>{t('save')}</button>
                    </div>}
                    {hasRole(this.props.selected_user_profile, ["associate-doctor", "doctor", "paramedic-doctor"]) && <div className="bottom-btns">
                        <button className="btn btn-primary" onClick={e => this.handleExaminationSaveAndExit(e, 'save_and_next')}>{t('save_next')}</button>
                    </div>}
                    <button className="btn btn-default btn-outline-primary" onClick={this.props.handlePrevious}>{t('previous')}</button>
                </div>
            </React.Fragment>
        )
    }
}
export default (withTranslation('components/Prescription/Examination/AddExamination')(ExaminationBlock));
