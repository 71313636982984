import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getDateFormat } from '../../utils/helpers';
import DataGrid from "../../components/DataGrid";
import * as CovidCenterActions from '../../actions/covidCenterAction';
import { capitalize } from 'lodash';
import SearchComponent from '../../components/Covid/SearchComponent';
import SendReportModal from '../../components/Covid/SendReportModal';
import CovidCenterService from '../../services/covidCenterService';
import * as loaderAction from '../../actions/loaderAction';
import { Link } from "react-router-dom";
import { hasRole } from '../../utils/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import Notification from '../../utils/notification';
import { withTranslation } from 'react-i18next';

class CovidCompleteReportPage extends React.Component {
    constructor(props) {
        super(props);
        const { params: { type } } = this.props.match;

        this.state = {
            type: type,
            exportCSV: hasRole(this.props.selected_user_profile, ['covid-center-owner']),
            filter: {
                status: 'fulfilled'
            },
            showSendReportModal: false,
            selectedPatient: {}
        };
    }
    componentDidMount() {
        this.getCompletedReport()
    }

    getCompletedReport = () => {
        this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: this.state.filter });
    }

    columns = () => {
        let { t } = this.props;
        return hasRole(this.props.selected_user_profile, ['covid-center-owner']) ? [{
            dataField: 'reported_at',
            text: t('date_reported'),
            formatter: this.dateTimeFormat,
        }, {
            text: t('patient_name'),
            dataField: 'patient_name',
        }, {
            text: t('reported_by'),
            dataField: 'reported_by',
        }, {
            text: t('test_result'),
            dataField: 'result',
            formatter: (cell, row) => capitalize(t(cell))
        }, {
            text: t('total_amount'),
            dataField: 'total_fee',
            formatter: this.amountFormatter,
        }, {
            text: t('cash'),
            dataField: 'cash',
            formatter: this.amountFormatter,
        }, {
            text: t('card'),
            dataField: 'card',
            formatter: this.amountFormatter,
        }, {
            text: t('insurance'),
            dataField: 'insurance',
            formatter: this.amountFormatter,
        }, {
            text: t('pooja_points'),
            dataField: 'pooja-loyalty-amount',
            formatter: this.amountFormatter,
        }, {
            dataField: 'actions',
            text: t('actions'),
            isDummyField: true,
            formatter: this.actionFormatter,
            csvExport: false
        }] : [{
            text: t('patient_name'),
            dataField: 'patient_name',
        }, {
            dataField: 'collected_at',
            text: t('date_collected'),
            formatter: this.dateTimeFormat,
        }, {
            dataField: 'received_at',
            text: t('date_received'),
            formatter: this.dateTimeFormat,
        }, {
            dataField: 'reported_at',
            text: t('date_reported'),
            formatter: this.dateTimeFormat,
        }, {
            text: t('conducted_by'),
            dataField: 'conducted_by',
        }, {
            text: t('test_result'),
            dataField: 'result',
            formatter: (cell, row) => capitalize(t(cell))
        }, {
            dataField: 'actions',
            text: t('actions'),
            isDummyField: true,
            formatter: this.actionFormatter
        }];
    };
    amountFormatter = (cell, row) => {
        if (cell) {
            return `$${cell}`
        }
        return '--';
    }
    dateTimeFormat = (cell, row) => {
        return getDateFormat(cell)
    }

    actionFormatter = (cell, row) => {
        return <React.Fragment>
            <Link to="#" className="ml-2" onClick={e => this.download(e, row.id)}><span className="icon file"></span></Link>
            {!row.email_sent && (moment() < moment(row.report_generated_at).add(7, 'days') && <><Link to="#" className="ml-2" onClick={e => this.sendReport(row.id)}><FontAwesomeIcon icon={faEnvelope} /></Link>
                <Link to="#" className="ml-3" onClick={e => this.showSendReportModal(row)}><span className="icon edit"></span></Link></>)}
        </React.Fragment>
    };
    download = (e, id) => {
        e.preventDefault();
        this.props.loaderShow();
        CovidCenterService.downloadReports(id).then(resp => {
            if (resp.data && resp.data.download_url) {
                window.open(resp.data.download_url, "_blank")
            }
            this.props.loaderHide()
        }).catch(err => {
            this.props.loaderHide()
            Notification.show('error', err.response.data);
        })
    }
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (type === 'search') {
            page = 1;
        }
        this.props.paginate({ page, sizePerPage, sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: this.state.filter });
    }
    onSearch = (filter) => {
        let filterData = {
            ...filter,
            ...this.state.filter
        }
        this.props.paginate({ sortBy: { sortField: "updated_at", sortOrder: "asc" }, filter: filterData });
    }

    showSendReportModal = (patient) => {
        this.setState({ showSendReportModal: true, selectedPatient: patient });
    }

    hideSendReportModal = () => this.setState({ showSendReportModal: false })

    sendReport = (id, email = "") => {
        let postData = {
            "id": id,
            "email": email
        }
        this.props.loaderShow()
        CovidCenterService.sendReport(postData).then(resp => {
            this.props.loaderHide()
            this.hideSendReportModal()
            this.getCompletedReport();

            Notification.show('success', { message: this.props.t('email_sent') })
        }).catch(err => {
            this.props.loaderHide()
            if (err.response)
                Notification.show('error', err.response.data);
            console.log(err)
        })
    }

    render() {
        let { data, meta } = this.props.covid_reports;
        const { t } = this.props;
        return <React.Fragment>
            <Helmet>
                <title>{hasRole(this.props.selected_user_profile, ['covid-center-owner']) ? t('test_list') : t('completed_reports')}</title>
            </Helmet>
            <div className="row">
                <div className="col-md-24">
                    <h4 className="page-title">{hasRole(this.props.selected_user_profile, ['covid-center-owner']) ? t('test_list') : t('completed_reports')}</h4>
                </div>
            </div>
            <SearchComponent onSearch={this.onSearch} list_type="completed" />
            <div className="content-wrapper">
                {data && <DataGrid
                    columns={this.columns()}
                    data={data}
                    meta={meta}
                    handleTableChange={this.handleTableChange}
                    noDataIndication={t('no_record')}
                    export_csv={this.state.exportCSV}
                    export_class="btn btn-default btn-outline-primary"
                />}
            </div>
            {this.state.showSendReportModal && <SendReportModal show={this.state.showSendReportModal}
                onHide={this.hideSendReportModal}
                selectedPatient={this.state.selectedPatient}
                sendReport={this.sendReport}
            />}
        </React.Fragment>
    }
}
const mapStateToProps = (state) => ({
    covid_reports: state.covid_reports,
    selected_user_profile: state.selected_user_profile
});

const mapActionsToProps = {
    paginate: CovidCenterActions.paginate,
    loaderShow: loaderAction.loaderShow,
    loaderHide: loaderAction.loaderHide,
};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('pages/covid-center/CovidCompleteReportPage')(CovidCompleteReportPage));