import React from 'react';
import { PersonalHistory } from '../../../../../config/add_prescription';
import { getDateFormat } from '../../../../../utils/helpers'

export const Abuse = (abuseInfo) => {
    let { t } = abuseInfo;
    return (
        <div className="content-wrapper padding-bottom-10 margin-bottom-none">
            <h6 className="inner_heading">{t('abuse.abuse')}</h6>
            <ul className="prescription-filed-details full-width">
                <li> {t('abuse.text')}
                    <span>{abuseInfo.is_abuse ? t('abuse.yes') : (abuseInfo.is_abuse === false ? t('abuse.no') : '--')}</span>
                </li>
            </ul>
            <ul className="prescription-filed-details full-width">
                <li> {t('abuse.details')}:
                    <span>{abuseInfo.abuse_reason ? abuseInfo.abuse_reason : '--'}</span></li>
            </ul>
        </div>)
}
export const SubstanceAbuse = ({ substanceAbuse }) => {
    let { t } = substanceAbuse;
    return (
        <div className="content-wrapper padding-bottom-10 margin-bottom-none">
            <h6 className="inner_heading">{t('substance_abuse.substance_abuse')}</h6>
            <ul className="prescription-filed-details auto-width">
                <li>{t('substance_abuse.substance')} :
                    <span>{t('substance_abuse.' + substanceAbuse.substance_type)}</span>
                </li>
                <li>{t('substance_abuse.currently')} :
                    <span>{substanceAbuse.is_currently_use === true ? t('substance_abuse.Yes') : (substanceAbuse.is_currently_use === false ? t('substance_abuse.no') : '--')}</span>
                </li>
                <li>{t('substance_abuse.previously_used')} :
                    <span>{substanceAbuse.is_previously_used === true ? t('substance_abuse.Yes') : (substanceAbuse.is_previously_used === false ? t('substance_abuse.no') : '--')}</span>
                </li>
                <li>{t('substance_abuse.type_Amount_Frequency')} :
                    <span>{substanceAbuse.type_quantity}</span>
                </li>
                <li>{t('substance_abuse.how_long')} ?
                    <span>{substanceAbuse.how_long_years}</span>
                </li>
                <li>{t('substance_abuse.if_stopped_then_when')}
                    <span>{substanceAbuse.stopped_when_reason}</span>
                </li>
            </ul>
        </div>
    )
}
export const Vaccine = (vaccineInfo) => {
    let { t } = vaccineInfo;
    return (
        <div className="content-wrapper padding-bottom-10 margin-bottom-none">
            <h6 className="inner_heading">{t('vaccine.vaccines')}</h6>
            <ul className="prescription-filed-details auto-width">
                <li>{t('vaccine.tetanus')}:
                    <span>{getDateFormat(vaccineInfo.tetanus_vaccacines)}</span></li>
                <li>{t('vaccine.influenza')}:
                    <span>{getDateFormat(vaccineInfo.influenza_vaccines)}</span></li>
                <li>{t('vaccine.hpv')}:
                    <span>{getDateFormat(vaccineInfo.hiv_vaccines)}</span></li>
            </ul>
        </div>
    )
}
export const Excercise = (excerciseInfo) => {
    let { t } = excerciseInfo;
    return (
        <div className="content-wrapper padding-bottom-10 margin-bottom-none">
            <h6 className="inner_heading">{t('excercise.exercise_and_other')}</h6>
            <ul className="prescription-filed-details auto-width">
                <li>{t('excercise.do_you_exercise')} :
                    <span>{excerciseInfo.is_exercise === true ? t('excercise.yes') : (excerciseInfo.is_exercise === false ? t('excercise.no') : '--')}</span>
                </li>
                <li>{t('excercise.type_frequency_of_exercise')} :
                    <span>{excerciseInfo.exercise_frequency ? excerciseInfo.exercise_frequency : '--'}</span>
                </li>
                <li>{t('excercise.do_you_snore')} :
                    <span>{excerciseInfo.is_sleep_snore === true ? t('excercise.yes') : (excerciseInfo.is_sleep_snore === false ? t('excercise.no') : '--')}</span>
                </li>
            </ul>
        </div>
    )
}
export const Sexual = ({ personal_history, t }) => {
    let { sexual_partners, use_condom, is_sexually_active, is_hiv_tested, hiv_test_result, hiv_test_comment, last_hiv_test } = personal_history;
    return (
        <div className="content-wrapper padding-bottom-10 margin-bottom-none">
            <h6 className="inner_heading">{t('sexual.sexual')}</h6>
            <ul className="prescription-filed-details auto-width">
                <li>{t('sexual.number_of_partners')}:
                    <span>{sexual_partners || '--'}</span>
                </li>
                <li>{t('sexual.sexually_active')}:
                    <span>{is_sexually_active === true ? t('sexual.yes') : (is_sexually_active === false ? t('sexual.no') : '--')}</span>
                </li>
                <li>{t('sexual.use_of_condom')} :
                    <span>{use_condom ? t("sexual." + use_condom) : '--'}</span>
                </li>
            </ul>
            <ul className="prescription-filed-details auto-width">
                <li>{t('sexual.hiv_test')}:
                    <span>{is_hiv_tested === true ? t('sexual.yes') : (is_hiv_tested === false ? t('sexual.no') : '--')}</span>
                </li>
                <li>{t('sexual.last_hiv_test_date')}:
                    <span>{last_hiv_test || '--'}</span>
                </li>
                <li>{t('sexual.hiv_test_result')} :
                    <span>{hiv_test_result ? t("sexual." + hiv_test_result) : '--'}</span>
                </li>
                <li>{t('sexual.hiv_test_result_comment')} :
                    <span>{hiv_test_comment || '--'}</span>
                </li>
            </ul>
        </div >
    )
}
