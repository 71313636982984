import React from 'react';
import * as AddPresentingConditionComponents from './PresentingCondition/components/AddPresentingConditionComponents';
import { Accordion, Card, Button } from 'react-bootstrap';
import ReviewOfSystem from './PresentingCondition/components/AddReviewOfSystem';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

class AddPresentingConditionBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            present_condition: {
                symptoms: this.props.symptoms,
                review_of_system: this.props.review_of_system,
                patient_id: this.props.patient_id
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.symptoms !== prevProps.symptoms) {
            this.setState({
                present_condition: {
                    symptoms: this.props.symptoms,
                    review_of_system: this.props.review_of_system,
                    patient_id: this.props.patient_id
                }
            })
        }
    }

    handleSymptoms = (e) => {
        this.props.changeHandler('symptoms',  e.target.value);
        // this.props.handleIsPrescriptionEdited('examination')
    }

    handleReviewSystem = (review_system) => {
        this.props.changeHandler('review_of_system',  review_system);
    }
    render() {
        const { t } = this.props;
        return (
            <div>
                <span className="history-cancel top-initial" onClick={this.props.handleCancelButton}></span>
                {<AddPresentingConditionComponents.MentionSymptons
                    changeHandler={this.handleSymptoms}
                    value={this.state.present_condition.symptoms}
                    t={t}
                />}
                <br />
                <Accordion>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="1">{t('review_of_systems')}</Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <ReviewOfSystem
                                    handleReviewSystem={this.handleReviewSystem}
                                    review_of_system={_.has(this.state.present_condition.review_of_system, 'problems') ? this.state.present_condition.review_of_system.problems : []}
                                    // review_of_system={this.state.present_condition.review_of_system.problems.problems}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        )
    }
}
export default (withTranslation('components/UrgentCare/PresentingConditionBlock')(AddPresentingConditionBlock));
