import React from 'react';

export const Respiratory = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <h6>{t('respiratory.respiratory')}</h6>
        <input type="checkbox" id='respiratory-normal' name='respiratory-normal' onChange={e => handleChange(e, 'respiratory')} checked={checkIfProblemExist('respiratory', 'respiratory-normal') ? 'checked' : ''} />
        <label htmlFor='respiratory-normal'>{t('respiratory.normal')}</label>
    </React.Fragment>
}
export const ChestExpansion = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <label className="heading">{t('respiratory.chest_expansion')} </label>
        <div className="options-details">
            {/* chest expansion normal */}
            <input type="checkbox" id='respiratory-chest-expansion-normal' name='respiratory-chest-expansion-normal' onChange={e => handleChange(e, 'respiratory')} checked={checkIfProblemExist('respiratory', 'respiratory-chest-expansion-normal') ? 'checked' : ''} />
            <label htmlFor='respiratory-chest-expansion-normal'>{t('respiratory.normal')}</label>
            {/* chest expansion Decreased */}
            <input type="checkbox" id='respiratory-chest-expansion-decreased' name='respiratory-chest-expansion-decreased' onChange={e => handleChange(e, 'respiratory')} checked={checkIfProblemExist('respiratory', 'respiratory-chest-expansion-decreased') ? 'checked' : ''} />
            <label htmlFor='respiratory-chest-expansion-decreased'>{t('respiratory.decreased')}</label>
            {/* chest expansion bilateral */}
            <input type="checkbox" id='respiratory-chest-expansion-bilateral' name='respiratory-chest-expansion-bilateral' onChange={e => handleChange(e, 'respiratory')} checked={checkIfProblemExist('respiratory', 'respiratory-chest-expansion-bilateral') ? 'checked' : ''} />
            <label htmlFor='respiratory-chest-expansion-bilateral'>{t('respiratory.bilateral')}</label>
            {/* chest expansion unilateral right*/}
            <input type="checkbox" id='respiratory-chest-expansion-unilateral-right' name='respiratory-chest-expansion-unilateral-right' onChange={e => handleChange(e, 'respiratory')} checked={checkIfProblemExist('respiratory', 'respiratory-chest-expansion-unilateral-right') ? 'checked' : ''} />
            <label htmlFor='respiratory-chest-expansion-unilateral-right'>{t('respiratory.unilateral_right')}</label>
            {/* chest expansion unilateral left*/}
            <input type="checkbox" id='respiratory-chest-expansion-unilateral-left' name='respiratory-chest-expansion-unilateral-left' onChange={e => handleChange(e, 'respiratory')} checked={checkIfProblemExist('respiratory', 'respiratory-chest-expansion-unilateral-left') ? 'checked' : ''} />
            <label htmlFor='respiratory-chest-expansion-unilateral-left'>{t('respiratory.unilateral_left')}</label>
        </div>
    </React.Fragment>
}
export const AirEntry = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <label className="heading">{t('respiratory.air_entry')} </label>
        <div className="options-details">
            {/* air entry Normal */}
            <input type="checkbox" id='respiratory-air-entry-normal' name='respiratory-air-entry-normal' onChange={e => handleChange(e, 'respiratory')} checked={checkIfProblemExist('respiratory', 'respiratory-air-entry-normal') ? 'checked' : ''} />
            <label htmlFor='respiratory-air-entry-normal'>{t('respiratory.normal')}</label>
            {/* air entry Decreased */}
            <input type="checkbox" id='respiratory-air-entry-decreased' name='respiratory-air-entry-decreased' onChange={e => handleChange(e, 'respiratory')} checked={checkIfProblemExist('respiratory', 'respiratory-air-entry-decreased') ? 'checked' : ''} />
            <label htmlFor='respiratory-air-entry-decreased'>{t('respiratory.decreased')}</label>
            {/* air entry Bilateral */}
            <input type="checkbox" id='respiratory-air-entry-bilateral' name='respiratory-air-entry-bilateral' onChange={e => handleChange(e, 'respiratory')} checked={checkIfProblemExist('respiratory', 'respiratory-air-entry-bilateral') ? 'checked' : ''} />
            <label htmlFor='respiratory-air-entry-bilateral'>{t('respiratory.bilateral')}</label>
            {/* air entry unilateral Right*/}
            <input type="checkbox" id='respiratory-air-entry-unilateral-right' name='respiratory-air-entry-unilateral-right' onChange={e => handleChange(e, 'respiratory')} checked={checkIfProblemExist('respiratory', 'respiratory-air-entry-unilateral-right') ? 'checked' : ''} />
            <label htmlFor='respiratory-air-entry-unilateral-right'>{t('respiratory.unilateral_right')}</label>
            {/* air entry unilateral left*/}
            <input type="checkbox" id='respiratory-air-entry-unilateral-left' name='respiratory-air-entry-unilateral-left' onChange={e => handleChange(e, 'respiratory')} checked={checkIfProblemExist('respiratory', 'respiratory-air-entry-unilateral-left') ? 'checked' : ''} />
            <label htmlFor='respiratory-air-entry-unilateral-left'>{t('respiratory.unilateral_left')}</label>
            {/* air entry creps */}
            <input type="checkbox" id='respiratory-air-entry-creps' name='respiratory-air-entry-creps' onChange={e => handleChange(e, 'respiratory')} checked={checkIfProblemExist('respiratory', 'respiratory-air-entry-creps') ? 'checked' : ''} />
            <label htmlFor='respiratory-air-entry-creps'>{t('respiratory.creps')}</label>
            {/* air entry rhonchi */}
            <input type="checkbox" id='respiratory-air-entry-rhonchi' name='respiratory-air-entry-rhonchi' onChange={e => handleChange(e, 'respiratory')} checked={checkIfProblemExist('respiratory', 'respiratory-air-entry-rhonchi') ? 'checked' : ''} />
            <label htmlFor='respiratory-air-entry-rhonchi'>{t('respiratory.rhonchi')}</label>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('type_here')} onChange={e => handleChange(e, 'respiratory')} disabled={checkIfProblemExist('respiratory', 'respiratory-normal') ? 'disabled' : ''} value={getCategoryComment('respiratory') ? getCategoryComment('respiratory') : ''}></textarea>
    </React.Fragment>
}