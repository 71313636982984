import React from 'react';
import { connect } from 'react-redux';
import DashboardService from '../../services/dashboardService';
import _ from 'lodash';
import Notification from '../../utils/notification';
import ServiceProviderLocations from '../ServiceProviderLocations'
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import CovidTestGraph from './CovidTestGraph'
import CovidCenterWorkflow from './CovidCenterWorkflow';
import CovidCenterReportCount from './CovidCenterReportCount';
import { withTranslation } from 'react-i18next';

class CovidCenterDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: {
                user_location: '',
                start_date: "",
                end_date: "",
                covid_test_group_by: 'week',
            },
            covid_test_data: {
                x_axis_label: [],
                counts: [{
                    label: this.props.t("total"),
                    backgroundColor: "#0000ff",
                    data: []
                }, {
                    label: this.props.t("positive"),
                    backgroundColor: "#ff0000",
                    data: []
                }, {
                    label: this.props.t("negative"),
                    backgroundColor: "#008000",
                    data: []
                }]
            },
            show_start_date: new Date(),
            show_end_date: new Date(),
            tags: {
                date_filter: "",
                location_filter: ""
            }
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        this.getTestConductedData();
    }

    getTestConductedData = () => {
        const { filter } = this.state
        const filters = {
            user_location: filter.user_location,
            start_date: filter.start_date,
            end_date: filter.end_date,
            covid_test_group_by: filter.covid_test_group_by
        }

        DashboardService.getCovidTestGraphInfo(filters).then(resp => {
            if (resp.data.counts) {
                const graph_data = {}
                graph_data.x_axis_label = resp.data.x_axis_label;
                let total = _.map(resp.data.counts, 'total');
                let positive = _.map(resp.data.counts, 'positive');
                let negative = _.map(resp.data.counts, 'negative');
                let counts = [];
                counts.push({ label: this.props.t("total"), backgroundColor: "#0000ff", data: total })
                counts.push({ label: this.props.t("positive"), backgroundColor: "#ff0000", data: positive })
                counts.push({ label: this.props.t("negative"), backgroundColor: "#008000", data: negative })
                graph_data.counts = counts;
                this.setState({
                    covid_test_data: graph_data
                })
            }
        }).catch(error => {
            Notification.show('error', error.response.data)
        })

    }

    handleEvent = (event, picker) => {
        this.setState({
            filter: {
                start_date: (picker.startDate._d) ? moment(picker.startDate._d).format('YYYY-MM-DD') : '',
                end_date: (picker.endDate._d) ? moment(picker.endDate._d).format('YYYY-MM-DD') : '',
                user_location: this.state.filter.user_location,
                covid_test_group_by: this.state.filter.covid_test_group_by,
                type: ''
            },
            show_start_date: picker.startDate._d,
            show_end_date: picker.endDate._d,
            tags: {
                date_filter: (picker.startDate._d && picker.endDate._d) ? moment(picker.startDate._d).format('DD-MM-YYYY') + ' to ' + moment(picker.endDate._d).format('DD-MM-YYYY') : '',
                location_filter: this.state.tags.location_filter
            },
        });
    }

    handleLocationFilter = (user_location_id, location_name = null) => {
        const filter = this.state.filter;
        filter.type = ''
        filter.user_location = user_location_id ? user_location_id : undefined;
        this.setState({
            filter: filter,
            tags: {
                date_filter: this.state.tags.date_filter,
                location_filter: location_name
            }
        });
    }

    handleSubmit = () => {
        window['searchToggle']();
        this.getTestConductedData();
    }

    resetSearch = () => {
        const filter = this.state.filter;
        filter.user_location = "";
        filter.start_date = "";
        filter.end_date = "";
        filter.type = "";
        this.setState({
            filter: filter,
            show_start_date: new Date(),
            show_end_date: new Date(),
            tags: {
                date_filter: "",
                location_filter: ""
            }
        });
        window['searchToggle']();
        this.getTestConductedData();
    }

    removeFilter = (e) => {
        const filter = this.state.filter;
        const tags = this.state.tags;
        filter.type = ''
        if (e.target.dataset.tag === 'date_filter') {
            filter["start_date"] = '';
            filter["end_date"] = '';
        } else {
            filter['user_location'] = '';
        }
        tags[e.target.dataset.tag] = '';
        this.setState({ filter: filter, tags: tags });
        this.getTestConductedData();
    }

    handleGraphs = (view) => {
        const { filter } = this.state;
        filter.covid_test_group_by = view;
        this.setState({
            filter: filter
        }, () => {
            this.getTestConductedData()
        });
    }

    renderTags(tags) {
        let options;
        return (Object.keys(tags)).map((tag) => {
            if (tags[tag] !== '' && !(_.isEmpty(tags[tag]))) {
                options = <span className="search-list">{tags[tag]} <span className='removesearch' data-tag={tag} onClick={this.removeFilter.bind(this)}> x </span> </span>
            } else {
                return false
            }
            return options
        });
    }

    render() {
        const { t } = this.props;
        return (<React.Fragment>
            <div className="row">
                <CovidCenterReportCount />
                <CovidCenterWorkflow />
                {/* filter box */}
                <div className="col-md-24">
                    <div className="filter-wrapper">
                        <div className="search-wrapper">
                            <label>{t('filter')}</label>
                            <div className="search-box clearfix">
                                {this.state.tags && this.renderTags(this.state.tags)}
                                <span className="search-box-icon"></span>
                                <div className="search-container">
                                    <ul>
                                        <li>
                                            <span>{t('select_date')}</span>
                                            <DateRangePicker
                                                startDate={this.state.show_start_date}
                                                onApply={this.handleEvent}
                                                endDate={this.state.show_end_date}
                                            >
                                                <input
                                                    type="text"
                                                    className="search-input"
                                                    name="start_date"
                                                    autoComplete="off"
                                                    defaultValue={this.state.filter.start_date ? moment(this.state.filter.start_date).format('DD-MM-YYYY') : ''}
                                                ></input>
                                                <input
                                                    type="text"
                                                    className="search-input"
                                                    name="end_date"
                                                    autoComplete="off"
                                                    defaultValue={this.state.filter.end_date ? moment(this.state.filter.end_date).format('DD-MM-YYYY') : ''}
                                                ></input>
                                            </DateRangePicker>
                                        </li>
                                        <li>
                                            <span>{t('select_location')}</span>
                                            <ServiceProviderLocations
                                                service_provider_id={this.props.selected_user_profile.service_provider_id}
                                                handleLocationChange={this.handleLocationFilter}
                                                user_location_id={this.state.filter.user_location}
                                            />
                                        </li>
                                        <li>
                                            <button type="button" className="btn btn-secondary" onClick={this.resetSearch}>{t('reset')}</button>
                                            <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>{t('search')}</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* covid test graph */}
                {this.state.covid_test_data && <div className="col-md-24"><CovidTestGraph
                    covid_test_graph_data={this.state.covid_test_data}
                    renderDashboardGraph={this.handleGraphs}
                    t={t} /></div>}
            </div>
        </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    selected_user_profile: state.selected_user_profile,
    dashboard_info: state.dashboard_info,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withTranslation('components/Dashboard/CovidCenterDashboard')(CovidCenterDashboard));

