import React from 'react';
import DashboardService from '../../services/dashboardService';
import Notification from '../../utils/notification';
import Card from './Card';
import { withTranslation } from 'react-i18next';

class CovidCenterWorkflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalTest: '',
      positive: '',
      negative: '',
    }
  }

  componentDidMount() {
    Promise.all([this.getCovidCenterDashboardCounts("total-test"), this.getCovidCenterDashboardCounts("positive"), this.getCovidCenterDashboardCounts("negative")])
    this.interval = setInterval(() => { Promise.all([this.getCovidCenterDashboardCounts("total-test"), this.getCovidCenterDashboardCounts("positive"), this.getCovidCenterDashboardCounts("negative")]) }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getCovidCenterDashboardCounts = (type) => {
    return new Promise((resolve, reject) => {
      return resolve(
        DashboardService.renderCovidCenterCountInfo(type)
          .then((resp) => {
            if (type === "total-test") {
              this.setState({
                totalTest: resp.data.total_test_count,
              });
            } else if (type === "positive") {
              this.setState({
                positive: resp.data.positive_count,
              });
            } else if (type === "negative") {
              this.setState({
                negative: resp.data.negative_count,
              });
            }
          })
          .catch((error) => {
            Notification.show("error", error.response.data);
            return false;
          })
      );
    })
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div className="col-md-8">
          <Card
            imgSrc="/images/clockwise-rotation.png"
            label={t('total_covid_test')}
            count={this.state.totalTest}
          />
        </div>
        <div className="col-md-8">
          <Card
            imgSrc="/images/clockwise-rotation.png"
            label={t('positive')}
            count={this.state.positive}
          />
        </div>
        <div className="col-md-8">
          <Card
            imgSrc="/images/clockwise-rotation.png"
            label={t('negative')}
            count={this.state.negative}
          />
        </div>
      </React.Fragment>
    )
  }
}
export default (withTranslation('components/Dashboard/CovidCenterWorkflow')(CovidCenterWorkflow));