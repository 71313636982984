import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import * as Helper from "../utils/helpers";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';

class MedicineOrderListBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            options: []
        }
    }

    columns = () => {
        let { t } = this.props;
        return [
            {
                dataField: "order_id",
                text: t('rx_id'),
                sort: true
            },
            {
                dataField: "prescription_id",
                text: t('prescription_id'),
                sort: true
            },
            {
                formatter: this.patientFullName,
                text: t('patient_name'),
                sort: true
            },
            {
                dataField: "status",
                text: t("status"),
                sort: true
            },
            {
                dataField: "created_at",
                text: t('created_on'),
                sort: true,
                formatter: this.dateFormatter
            },
            {
                dataField: "actions",
                text: t('actions'),
                isDummyField: true,
                formatter: this.actionFormatter
            }
        ];
    };

    dateFormatter = (cell, row) => {
        return Helper.getDateFormat(cell);
    };

    actionFormatter = (cell, row) => {
        return (
            <Link to={"/operate/order/" + row.order_id}>
                <span className="icon file"></span>
            </Link>
        );
    };

    patientFullName = (cell, row) => {
        return Helper.fullNameStr(row.patient_info)
    }

    render() {
        return (
            <BootstrapTable
                bootstrap4
                bordered={false}
                keyField="order_id"
                columns={this.columns()}
                data={this.props.orders}
            />
        )
    }
}
export default (withTranslation('components/MedicineOrderListBlock')(MedicineOrderListBlock));