import React from 'react';

export const Skin = ({ handleChange, checkIfProblemExist, t }) => {
    return <React.Fragment>
        <div className="heading">
            <h6>{t('skin.skin')}</h6>
        </div>
        <div className="form-group full">
            <div className="options-details">
                <input type="checkbox" id="skin-redness" name="skin-redness" onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', "skin-redness") ? 'checked' : ''} />
                <label htmlFor="skin-redness">{t('skin.redness')}</label>
                <input type="checkbox" id="rash" name="rash" onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', "rash") ? 'checked' : ''} />
                <label htmlFor="rash">{t('skin.rash')}</label>
                <input type="checkbox" id="nodules-bumps" name="nodules-bumps" onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', "nodules-bumps") ? 'checked' : ''} />
                <label htmlFor="nodules-bumps">{t('skin.nodules_bumps')}</label>
                <input type="checkbox" id='hair-loss' name='hair-loss' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'hair-loss') ? 'checked' : ''} />
                <label htmlFor='hair-loss'>{t('skin.hair_loss')}</label>
                <input type="checkbox" id='color-changes-of-hands-or-feet' name='color-changes-of-hands-or-feet' onChange={e => handleChange(e, 'skin')} checked={checkIfProblemExist('skin', 'color-changes-of-hands-or-feet') ? 'checked' : ''} />
                <label htmlFor='color-changes-of-hands-or-feet'>{t('skin.color_changes_hand_feet')}</label>
            </div>
        </div>
    </React.Fragment>
}
export const Comment = ({ handleChange, getCategoryComment, t }) => {
    return <React.Fragment>
        <label className="heading-comment">{t('skin.comment')} </label>
        <textarea className="form-control" name="comments" placeholder={t('skin.type_here')} onChange={e => handleChange(e, 'skin')} value={getCategoryComment('skin') ? getCategoryComment('skin') : ''}></textarea>
    </React.Fragment>
}